
import React from 'react';

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';


class GiftValidation extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>

        <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props.isOpen} >
          <MDBModalDialog size="sm" >
            <MDBModalContent>
              <MDBModalBody>
                <div className="flexBox-plain padding">
                  <table>
                    <th className='redErrors'>{this.props.isOk ? 'Confirmation' : 'Alert!'}</th>
                    <tbody>
                      {
                        this.props.displayMessage.map((val, i) => (
                          <tr key={i} >
                            <td className='b1'>{val}</td>
                            <br />
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                {this.props.isOk ?
                  <>
                    <div>
                      <span>Would you like to still continue with saving Gift?</span>
                    </div>
                    <br />
                  </> : null}
                <div className="flexAlignRight padding">
                  <div className="padding-hor">
                    {this.props.isOk ? <MDBBtn size='sm' onClick={this.props.supressGiftPriceValidation}>
                      Yes
                        </MDBBtn>
                      : null}
                      &nbsp;&nbsp;
                    <MDBBtn size='sm' onClick={this.props.toggleValidation}>
                      {this.props.isOk ? 'No' : 'Close!'}
                    </MDBBtn>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    )
  }

}

export default GiftValidation;