import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBFormInline } from "mdbreact";
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
    MDBTabsPane, MDBTabsContent, MDBDropdown, MDBSelect, MDBBtn
} from 'mdb-react-ui-kit';
import '../Cards/Cards.scss';
import Link from '@material-ui/core/Link';
import { MDBDataTableV5, MDBInput } from 'mdbreact';
import DeleteIcon from '@material-ui/icons/Delete';


class RecipientAddGrid extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            peopleSelection: {
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        width: '20'

                    },
                    {
                        label: 'Email',
                        field: 'email',
                        width: '20'

                    },
                    {
                        label: 'Company',
                        field: 'company_name',
                        width: '30'
                    },
                    {
                        label: 'Division',
                        field: 'division_name',
                        sort: 'asc',
                        width: '80'
                    },
                    {
                        label: 'Category',
                        field: 'category_name',
                        sort: 'asc',
                        width: '80'
                    },
                    {
                        label: 'Title',
                        field: 'title_name',
                        sort: 'asc',
                        width: '80'
                    },
                    {
                        label: <><Link href="#" id="expansionPanel" className="FR"
                            onClick={(event) => {
                                this.props.deleteAll();
                            }}
                            color="primary" >
                            delete all
                        </Link></>,
                        field: 'delete',
                        sort: 'asc',
                        width: '20'
                    }
                ],
                rows: props.peopleSelection ? props.peopleSelection.map((record, index) => {
                    return {
                        name: record?.name,
                        company_name: record.company_name,
                        email: record.email,
                        division_name: record.division_name,
                        category_name: record.category_name,
                        title_name: record.title_name,
                        delete: <><DeleteIcon style={{ fontSize: 15 }}
                            onClick={(event) => {
                                event.preventDefault();
                                props.deleteSelection(record)
                            }}
                        /></>
                    }
                }) : []
            }
        }
    }
    deleteAll() {
        this.props.deleteAll();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            peopleSelection: {
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        width: '20'

                    },
                    {
                        label: 'Email',
                        field: 'email',
                        width: '20'

                    },
                    {
                        label: 'Company',
                        field: 'company_name',
                        width: '30'
                    },
                    {
                        label: 'Division',
                        field: 'division_name',
                        sort: 'asc',
                        width: '80'
                    },
                    {
                        label: 'Category',
                        field: 'category_name',
                        sort: 'asc',
                        width: '80'
                    },
                    {
                        label: 'Title',
                        field: 'title_name',
                        sort: 'asc',
                        width: '80'
                    },
                    {
                        label: <><Link href="#" id="expansionPanel" className="FR"
                            onClick={(event, newValue) => {
                                event.preventDefault();
                                this.deleteAll();
                            }}
                            color="primary" >
                            delete all
                        </Link></>,
                        field: 'delete',
                        sort: 'asc',
                        width: '20'
                    }
                ],
                rows: nextProps.peopleSelection ? nextProps.peopleSelection.map((record, index) => {
                    return {
                        name: record?.name,
                        company_name: record.company_name,
                        email: record.email,
                        division_name: record.division_name,
                        category_name: record.category_name,
                        title_name: record.title_name,
                        delete: <><DeleteIcon style={{ fontSize: 15 }}
                            onClick={(event) => {
                                event.preventDefault();
                                nextProps.deleteSelection(record)
                            }}
                        /></>
                    }
                }) : []
            }
        });
    }
    render() {
        return (
            <>
                <div id="cardCreation">
                    <MDBDataTableV5
                        hover
                        entriesOptions={[10, 25, 50, 75]} responsive id="dashboard"
                        entries={10}
                        pagesAmount={4}
                        data={this.state.peopleSelection}
                        searchTop={true}
                        searchBottom={false} barReverse
                        noRecordsFoundLabel=" "
                    />
                </div>
            </>
        );
    }
}
export default RecipientAddGrid;