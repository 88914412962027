import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { MDBIcon } from "mdbreact";
import { MDBInput } from "mdbreact";
import Typography from '@material-ui/core/Typography';
import { validatePriorityLevel } from '../../Common/helper';
import './NewPerson.scss';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

export default class CardSigner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewCardSigner: false
        }
    }

    toggleNewCardSigner = () => {
        this.setState({ showNewCardSigner: !this.state.showNewCardSigner });
    }

    render() {
        return (
            <FormControl component="fieldset" className="cardSignerContainer">
                <h6 className="mrgn-tp-10px">Card Signers <Button className="btn_lft_padding" variant="contained" size="small" color="primary" onClick={() => this.toggleNewCardSigner()}>New Signer</Button></h6>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" checked={this.props?.personDetails?.is_card_signer} onChange={(e) => this.props.handleOnChange(e,"is_card_signer")}/>}
                        label={<Typography variant="caption">Is a Card Signer</Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="start"
                        control={<MDBInput id="priorityLevel" value={this.props?.personDetails?.priority_level}  onChange={(e) => (validatePriorityLevel(e.target.value) || !e.target.value) ? this.props.handleOnChange(e,"priority_level") : null }/>}
                        label={<Typography variant="caption">Priority Level</Typography>}
                        labelPlacement="start"
                    />
                </FormGroup>
                {
                    this.props.personCardSignerList?.card_signers?.map(signer => (
                        <div className="signer" id={signer?.signer_is_active === 0 ? "inactive" : "active"} key={signer?.card_signer_id}>
                            {signer?.card_signer_name ? signer?.card_signer_name : signer.card_signer_first_name + " " + signer.card_sginer_last_name}
                            <MDBIcon icon={signer?.signer_is_active === 0 ? "redo-alt" : "trash-alt"} onClick={() => this.props?.handleNewSigner(signer,signer?.signer_is_active === 0 ? "redo" : "delete")}/>
                        </div>
                    ))
                }
                {
                    this.state.showNewCardSigner && (
                        <div className="cardSignerAdd">
                            <Autocomplete
                                id="cardSigner"
                                options={this.props?.list}
                                getOptionLabel={(option) => option.text ? option.text : ''}
                                filterOptions={filterOptions}
                                style={{ width: '100%' }}
                                onChange={(event,value) => this.props?.handleNewSigner(value,"add")}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </div>
                    )
                }
            </FormControl>
        );
    }
}
