import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import * as COLORS from '../constants/materialThemeConstants';

const MaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY_COLOR,
    },
    secondary: {
      main: COLORS.SECONDARY_COLOR,
    },
    tertiary: {
      main: COLORS.TERTIARY_COLOR,
    },
    septanary: {
      main: COLORS.SEPTANARY_COLOR,
    }
  },
  typography: {
    fontFamily: [
      'Verdana',
      'acumin-pro-condensed',
      'sans-serif'
    ].join(','),
  },
});

export default MaterialTheme;