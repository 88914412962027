import React from 'react';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TextField from '@material-ui/core/TextField';
import './PeopleManagement.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

const Filters = (props) => (
    <div className="filterContainer">
        <div className="row">
            <div className="col-3">
                <Autocomplete
                    multiple
                    variant="body1"
                    size="small"
                    limitTags={1}
                    options={props?.filterList?.company}
                    value={props?.selectedFilters.company}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.text}
                    filterOptions={filterOptions}
                    style={{ width: '100%' }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, transform: "scale(0.8)" }}
                                checked={selected}
                            />
                            <label style={{ fontSize: "11px" }}>{option.text}</label>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Company"
                            InputProps={{ ...params.InputProps, disableUnderline: true }} />
                    )}
                    onChange={(event, value) => props?.handleFilterChange(event, value, "company")}
                />
            </div>
            <div className="col-3">
                <Autocomplete
                    multiple
                    variant="body1"
                    size="small"
                    limitTags={1}
                    options={props?.filterList?.division}
                    value={props?.selectedFilters.division}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.text}
                    filterOptions={filterOptions}
                    style={{ width: '100%' }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                size="small"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, transform: "scale(0.8)" }}
                                checked={selected}
                            />
                            <label style={{ fontSize: "11px" }}>{option.text}</label>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Division"
                            InputProps={{ ...params.InputProps, disableUnderline: true }} />
                    )}
                    onChange={(event, value) => props?.handleFilterChange(event, value, "division")}
                />
            </div>
            <div className="col-3">
                <Autocomplete
                    multiple
                    variant="body1"
                    size="small"
                    limitTags={1}
                    options={props?.filterList?.category}
                    value={props?.selectedFilters.category}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.text}
                    filterOptions={filterOptions}
                    style={{ width: '100%' }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                size="small"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, transform: "scale(0.8)" }}
                                checked={selected}
                            />
                            <label style={{ fontSize: "11px" }}>{option.text}</label>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Category"
                            InputProps={{ ...params.InputProps, disableUnderline: true }} />
                    )}
                    onChange={(event, value) => props?.handleFilterChange(event, value, "category")}
                />
            </div>
            <div className="col-3">
                <Autocomplete
                    multiple
                    variant="body1"
                    size="small"
                    limitTags={1}
                    options={props?.filterList?.title}
                    value={props?.selectedFilters.title}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.text}
                    filterOptions={filterOptions}
                    style={{ width: '100%' }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                size="small"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, transform: "scale(0.8)" }}
                                checked={selected}
                            />
                            <label style={{ fontSize: "11px" }}>{option.text}</label>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Title"
                            InputProps={{ ...params.InputProps, disableUnderline: true }} />
                    )}
                    onChange={(event, value) => props?.handleFilterChange(event, value, "title")}
                />
            </div>
            </div>
            <div className="row" id="belowLine">
            <div className="col-3">
                <Autocomplete
                    multiple
                    variant="body1"
                    size="small"
                    limitTags={1}
                    options={props?.filterList?.status}
                    value={props?.selectedFilters.status}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.text}
                    filterOptions={filterOptions}
                    style={{ width: '100%' }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                size="small"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, transform: "scale(0.8)" }}
                                checked={selected}
                            />
                            <label style={{ fontSize: "11px" }}>{option.text}</label>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Status"
                            InputProps={{ ...params.InputProps, disableUnderline: true }} />
                    )}
                    onChange={(event, value) => props?.handleFilterChange(event, value, "status")}
                />
            </div>
            <div className="col-3">
                <Autocomplete
                    multiple
                    variant="body1"
                    size="small"
                    limitTags={1}
                    options={props?.filterList?.customGroup}
                    value={props?.selectedFilters.customGroup}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.text}
                    filterOptions={filterOptions}
                    style={{ width: '100%' }}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                size="small"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, transform: "scale(0.8)" }}
                                checked={selected}
                            />
                            <label style={{ fontSize: "11px" }}>{option.text}</label>
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Custom Group"
                            InputProps={{ ...params.InputProps, disableUnderline: true }} />
                    )}
                    onChange={(event, value) => props?.handleFilterChange(event, value, "customGroup")}
                />
            </div>
            <div className="col-3" id="clearFilters" onClick={props.handleClearFilter}>
                <Button>clear filters</Button>
            </div>
        </div>
    </div>
);

export default Filters;