import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBFormInline, MDBInput } from "mdbreact";
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
    MDBTabsPane, MDBTabsContent, MDBDropdown, MDBSelect, MDBBtn
} from 'mdb-react-ui-kit';
import TextField from '@material-ui/core/TextField';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants.js';
import Grid from '../Grid/Grid';
import "../GiftCreation/CreateGift.scss";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Materials from '../Materials/Materials';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import GiftValidation from '../Common/GiftValidation';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';


class CreateGiftPage extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            giftId: props?.match?.params?.giftId,
            disabled: props?.match?.path.indexOf('viewgift') != -1 ? true : false,
            divisions: null,
            //shows: null,
            giftName: '',
            giftSenders: [],
            giftRecipients: [],
            gifters: [],
            existingGiftTitles: [],
            recipientToAddBySearch: [],
            occassion: '',
            sendDate: null,
            gl1: null,
            gl2: null,
            giftAddress: null,
            notes: null,
            isDefaultForAllAddress: false,
            selectedTab: 'recipientsTab',// props.defaultTab,
            gridOpen: false,
            fromPage: props.fromPage,
            vendorsList: [],
            ocassionList: [],
            showList: [],
            allShowsList: [],
            loaderOpen: false,
            formErrors: { giftName: '', DateField: '', Division: '' },
            formValid: false,
            giftNameValid: false,
            divisionValid: false,
            giftDateValid: false,
            showMaterials: false,
            openValidation: false,
            isBlocked: false,
            displayMessage: [],
            selectedOcassion: null,
            selectedShow: null,
            permissions: false,
            deleteAllRecipients: false,
            deleteAllSenders: false,
            giftMessage: '',
            isCopy: props?.match?.params?.copyId == 1 ? true : false,
            isGiftValidation: false,
            createdBy: '',
            updatedBy: '',
            giftsLoader: false,
            gridLoader: false
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({
            //loaderOpen: true
            giftsLoader: true,
            gridLoader: true
        })
        this.fetchGiftData();
        this.fetchGridData();
    }
    toggleValidation = () => {
        this.setState({ openValidation: !this.state.openValidation });
    }
    tabSelection = (value) => {
        this.setState({
            selectedTab: value
        })
    }
    fetchGiftData = async () => {
        let giftRecordId = this.state.giftId;
        let tenantId = localStorage.getItem("active_tenant_id");
        let GetLogginUsers = Constants.GifterServiceBaseUrl + '/loggedInUser';
        let GetShows = Constants.GifterServiceBaseUrl + '/shows?tenantId=' + tenantId;
        let GetOccasions = Constants.GifterServiceBaseUrl + '/occasions?tenantId=' + tenantId;
        let GetGiftRecordNames = Constants.GifterServiceBaseUrl + '/giftNames?tenantId=' + tenantId;
        let GetGiftRecordHeader = Constants.GifterServiceBaseUrl + '/giftHeader?giftRecordId=' + giftRecordId;
        let urls = [];
        let occassion = [];
        let shows = [];
        let allShowsList =[];
        let sendDate = null;
        let giftAddress = '';
        let notes = '';
        let isDefaultForAllAddress = false;
        let divs = null;
        let gl1 = null;
        let gl2 = null;
        let giftMessage = null;
        let divisionValid = false;
        let giftDateValid = false;
        let giftNameValid = false;
        let selectedOcassion = null;
        let selectedShow = null;
        let createdBy = '';
        let updatedBy = '';
        let permissions = false;
        urls = [GetLogginUsers, GetGiftRecordNames, GetGiftRecordHeader, 
            GetOccasions, GetShows]
        try {
            const res = await Promise.all(urls.map(url => GifterService.getData(url)));
            const data = res.map((res) => res.data);
            let accessmodules = data[0][0].tenants.filter(item => item.tenant_id == tenantId)[0].access_roles.filter(res => res.module_name == 'Gifts')[0];
            let permissionValue = data[0][0].tenants.filter(item=>item.tenant_id == tenantId)[0].permissions["Create Data"];
            if(permissionValue == true || permissionValue == false)
            {
                this.setState({permissions : permissionValue})
            }
            this.setState({ allShowsList : data[4] })
            divs = accessmodules.divisions;
            shows = accessmodules.shows;
            divs.map(obj => ({ ...obj, selected: false }))
            shows.map(obj => ({ ...obj, selected: false }))
            if (data && data.length > 0) {
                occassion = data[3] ? data[3] : [];
                if (data[2] && data[2].length > 0) {
                    accessmodules.divisions.forEach((division) => {
                        if (division.org_unit_id == data[2][0]?.org_unit_id) {
                            division['selected'] = true;
                            divisionValid = true;
                            console.log(division)
                        }
                    })
                    if (data[2][0]?.gift_send_date) {
                        giftDateValid = true;
                        // let date = new Date(data[2][0].gift_send_date);
                        let date = new Date(data[2][0].gift_send_date.replace(/-/g, '\/').replace(/T.+/, ''));
                        sendDate = date.getFullYear() + '-' +
                            ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))
                            + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
                    }
                    giftNameValid = data[2][0].name != '' ? true : false
                    createdBy = data[2][0].created_by ? 'Created By ' + data[2][0].created_by : ''
                    updatedBy = data[2][0].updated_by ? ' , Edited By ' + data[2][0].updated_by : ''
                    if (data[2][0].show_id) {
                        selectedShow = accessmodules.shows.filter(item => item.show_id == data[2][0].show_id)[0]
                    } 
                    if (data[2][0].occasion_id) {
                        selectedOcassion = occassion.filter(item => item.occasion_id == data[2][0].occasion_id)[0]
                    }
                    gl1 = data[2][0]?.gift_gl_no ? data[2][0]?.gift_gl_no.split('-')[0] : '';
                    gl2 = data[2][0]?.gift_gl_no ? data[2][0]?.gift_gl_no.split('-')[1] : '';
                    giftAddress = data[2][0].gift_address;
                    giftMessage = data[2][0].gift_message ? data[2][0].gift_message : null;
                    isDefaultForAllAddress = data[2][0].gift_is_default_all_recipients;
                    notes = data[2][0].gift_note;
                }
                this.setState({
                    divisions: divs, showList: shows,
                    existingGiftTitles: data[1], giftName: this.state.isCopy ? '' : (data[2].length > 0 ? data[2][0].name : ''),
                    updatedBy: updatedBy, createdBy: createdBy,
                    sendDate: sendDate, giftAddress: giftAddress,
                    notes: notes, isDefaultForAllAddress: isDefaultForAllAddress,
                    ocassionList: occassion, gl1: gl1, gl2: gl2,
                    giftDateValid: giftDateValid, divisionValid: divisionValid, giftNameValid: giftNameValid, giftMessage: giftMessage,
                    selectedShow: selectedShow, 
                    selectedOcassion: selectedOcassion
                }, () => {
                    console.log(this.state)
                    this.setState({
                        loaderOpen: false,
                        giftsLoader: false
                    })
                });
            }
        }
        catch {
            throw Error("Promise failed");
            this.setState({
                loaderOpen: false,
                giftsLoader: false
            }, () => { this.validateForm() })
        }         
    };
    fetchGridData = async () => {
        let giftRecordId = this.state.giftId;
        let tenantId = localStorage.getItem("active_tenant_id");
        let GetGiftRecordSenders = Constants.GifterServiceBaseUrl + '/giftRecordSenders?giftRecordId=' + giftRecordId;
        let GetGiftRecordRecipients = Constants.GifterServiceBaseUrl + '/giftRecordRecipients?giftRecordId=' + giftRecordId;
        let GetGiftRecordRecipientsGifts = Constants.GifterServiceBaseUrl + '/giftRecordRecipientsGifts?giftRecordId=' + giftRecordId;
        let GetVendors = Constants.GifterServiceBaseUrl + '/vendors?tenantId=' + tenantId;
        let urls = [];
        urls = [ GetGiftRecordSenders, GetGiftRecordRecipients, GetGiftRecordRecipientsGifts, GetVendors]
        try {
            const res = await Promise.all(urls.map(url =>
                GifterService.getData(url)));
            const data = res.map((res) => res.data);
            let gifterRecords = data[2] && data[2].length > 0 ? data[2] : [];
            if (gifterRecords.length == 0) {
                gifterRecords = [{
                    'distributions': [], 'gift_event_card_id': giftRecordId && giftRecordId > 0 ? Number(giftRecordId) : null,
                    'gift_other_cost': 0,
                    'gift_shipping_delivery_cost': 0,
                    'gift_tax': 0,
                    'gift_total_cost': 0,
                    'gift_total_price': 0,
                }]
            }
            let vendorOption = { 'name': 'Add Vendor', vendor_id: -1 }
            let vendorList = data[3] ? data[3] : [];
            vendorList.splice(0, 0, vendorOption)
            this.setState({ 
                vendorsList: vendorList, gifters: gifterRecords,
                giftSenders: data[0], giftRecipients: data[1],
            }, () => {
            console.log(this.state)
            this.setState({
                loaderOpen: false,
                gridLoader: false
            }, () => {
                this.validateForm();
                if (this.state.isCopy) {
                    this.copyGift()
                }
            })
        })
    } catch {
        throw Error("Promise failed");
        this.setState({
            loaderOpen: false,
            gridLoader: false
        }, () => { this.validateForm() })
    }
};
    // fetchData = async () => {
    //     let giftRecordId = this.state.giftId;//67
    //     let tenantId = localStorage.getItem("active_tenant_id");
    //     let GetGiftRecordHeader = Constants.GifterServiceBaseUrl + '/giftHeader?giftRecordId=' + giftRecordId;
    //     let GetGiftRecordNames = Constants.GifterServiceBaseUrl + '/giftNames?tenantId=' + tenantId;
    //     //let GetActivePersons = Constants.GifterServiceBaseUrl + '/activePersons?tenantId=' + tenantId;
    //     let GetOccasions = Constants.GifterServiceBaseUrl + '/occasions?tenantId=' + tenantId;
    //     let GetShows = Constants.GifterServiceBaseUrl + '/shows?tenantId=' + tenantId;
    //     let GetGiftRecordSenders = Constants.GifterServiceBaseUrl + '/giftRecordSenders?giftRecordId=' + giftRecordId;
    //     let GetGiftRecordRecipients = Constants.GifterServiceBaseUrl + '/giftRecordRecipients?giftRecordId=' + giftRecordId;
    //     let GetGiftRecordRecipientsGifts = Constants.GifterServiceBaseUrl + '/giftRecordRecipientsGifts?giftRecordId=' + giftRecordId;
    //     let GetLogginUsers = Constants.GifterServiceBaseUrl + '/loggedInUser';
    //     let GetVendors = Constants.GifterServiceBaseUrl + '/vendors?tenantId=' + tenantId;
    //     let urls = [];
    //     let occassion = [];
    //     let shows = [];
    //     let allShowsList =[];
    //     let sendDate = null;
    //     let giftAddress = '';
    //     let notes = '';
    //     let isDefaultForAllAddress = false;
    //     let divs = null;
    //     //let shows = null;
    //     let gl1 = null;
    //     let gl2 = null;
    //     let giftMessage = null;
    //     let divisionValid = false;
    //     let giftDateValid = false;
    //     let giftNameValid = false;
    //     let vendorList = [];
    //     let selectedOcassion = null;
    //     let selectedShow = null;
    //     let createdBy = '';
    //     let updatedBy = '';
    //     let permissions = false;
    //     urls = [GetLogginUsers, GetGiftRecordNames, GetGiftRecordHeader, GetGiftRecordSenders,
    //         GetGiftRecordRecipients,
    //         GetGiftRecordRecipientsGifts,
    //         GetVendors, GetOccasions, GetShows]
    //     try {
    //         const res = await Promise.all(urls.map(url =>
    //             GifterService.getData(url)));
    //         const data = res.map((res) => res.data);
    //         let accessmodules = data[0][0].tenants.filter(item => item.tenant_id == tenantId)[0].access_roles.filter(res => res.module_name == 'Gifts')[0];
    //         let permissionValue = data[0][0].tenants.filter(item=>item.tenant_id == tenantId)[0].permissions["Create Data"];
    //         if(permissionValue == true || permissionValue == false)
    //         {
    //             this.setState({permissions : permissionValue})
    //         }
    //         this.setState({ allShowsList : data[8] })
    //         divs = accessmodules.divisions;
    //         shows = accessmodules.shows;
    //         divs.map(obj => ({ ...obj, selected: false }))
    //         shows.map(obj => ({ ...obj, selected: false }))
    //         if (data && data.length > 0) {
    //             occassion = data[7] ? data[7] : [];
    //             //show = data[8] ? data[8] :[];
    //             //console.log(data);
    //             //occassion.map(obj => ({ ...obj, selected: false }))
    //             if (data[2] && data[2].length > 0) {
    //                 accessmodules.divisions.forEach((division) => {
    //                     if (division.org_unit_id == data[2][0]?.org_unit_id) {
    //                         division['selected'] = true;
    //                         divisionValid = true;
    //                         console.log(division)
    //                     }
    //                 })
    //                 // accessmodules.shows.forEach((show) => {
    //                 //     if (show.show_id == data[2][0].show_id) {
    //                 //         show['selected'] = true;                                                        
    //                 //     }
    //                 // })
    //                 if (data[2][0]?.gift_send_date) {
    //                     giftDateValid = true;
    //                     // let date = new Date(data[2][0].gift_send_date);
    //                     let date = new Date(data[2][0].gift_send_date.replace(/-/g, '\/').replace(/T.+/, ''));
    //                     sendDate = date.getFullYear() + '-' +
    //                         ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))
    //                         + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
    //                 }
    //                 giftNameValid = data[2][0].name != '' ? true : false
    //                 createdBy = data[2][0].created_by ? 'Created By ' + data[2][0].created_by : ''
    //                 updatedBy = data[2][0].updated_by ? ' , Edited By ' + data[2][0].updated_by : ''
    //                 if (data[2][0].show_id) {
    //                     selectedShow = accessmodules.shows.filter(item => item.show_id == data[2][0].show_id)[0]
    //                 } 
    //                 if (data[2][0].occasion_id) {
    //                     selectedOcassion = occassion.filter(item => item.occasion_id == data[2][0].occasion_id)[0]
    //                 }
    //                 gl1 = data[2][0]?.gift_gl_no ? data[2][0]?.gift_gl_no.split('-')[0] : '';
    //                 gl2 = data[2][0]?.gift_gl_no ? data[2][0]?.gift_gl_no.split('-')[1] : '';
    //                 giftAddress = data[2][0].gift_address;
    //                 giftMessage = data[2][0].gift_message ? data[2][0].gift_message : null;
    //                 isDefaultForAllAddress = data[2][0].gift_is_default_all_recipients;
    //                 notes = data[2][0].gift_note;
    //             }
    //             let gifterRecords = data[5] && data[5].length > 0 ? data[5] : [];
    //             if (gifterRecords.length == 0) {
    //                 gifterRecords = [{
    //                     'distributions': [], 'gift_event_card_id': giftRecordId && giftRecordId > 0 ? Number(giftRecordId) : null,
    //                     'gift_other_cost': 0,
    //                     'gift_shipping_delivery_cost': 0,
    //                     'gift_tax': 0,
    //                     'gift_total_cost': 0,
    //                     'gift_total_price': 0,
    //                 }]
    //             }
    //             let vendorOption = { 'name': 'Add Vendor', vendor_id: -1 }
    //             vendorList = data[6] ? data[6] : [];
    //             vendorList.splice(0, 0, vendorOption)
    //             this.setState({
    //                 divisions: divs, showList: shows,
    //                 existingGiftTitles: data[1], giftName: data[2].length > 0 ? data[2][0].name : '',
    //                 updatedBy: updatedBy, createdBy: createdBy,
    //                 giftSenders: data[3], giftRecipients: data[4],
    //                 gifters: gifterRecords
    //                 , recipientToAddBySearch: [],// data[6],
    //                 sendDate: sendDate, giftAddress: giftAddress,
    //                 notes: notes, isDefaultForAllAddress: isDefaultForAllAddress,
    //                 vendorsList: vendorList, ocassionList: occassion, gl1: gl1, gl2: gl2, //showList: show,
    //                 giftDateValid: giftDateValid, divisionValid: divisionValid, giftNameValid: giftNameValid, giftMessage: giftMessage,
    //                 selectedShow: selectedShow, 
    //                 selectedOcassion: selectedOcassion
    //             }, () => {
    //                 console.log(this.state)
    //                 this.setState({
    //                     loaderOpen: false
    //                 }, () => {
    //                     this.validateForm();
    //                     if (this.state.isCopy) {
    //                         this.copyGift()
    //                     }
    //                 })
    //             });
    //         }
    //     } catch {
    //         throw Error("Promise failed");
    //         this.setState({
    //             loaderOpen: false
    //         }, () => { this.validateForm() })
    //     }
    // };
    _handleTextFieldChange = (event, newValue) => {
        let r = event.target.value;
        this.setState({
            sendDate: event.target.value,
            isBlocked: true
        }, () => { this.validateField('giftDate', r) });
    };
    addInviteeFromSearch = (newValue, tab = '') => {
        if (tab == 'sendersTab') {
            let senders = this.state.giftSenders;
            senders.splice(0, 0, newValue);
            let filteredList = this.mergeDuplicates(senders);
            this.setState({
                giftSenders: filteredList,
                isBlocked: true
            }, () => { this.state.formValid ? null : this.validateForm() });
        }
        if (tab == 'recipientsTab') {
            let recipients = this.state.giftRecipients;
            recipients.splice(0, 0, newValue);
            let filteredList = this.mergeDuplicates(recipients);
            this.setState({
                giftRecipients: filteredList,
                isBlocked: true
            }, () => {this.state.formValid ? null : this.validateForm() });
        }
    }
    updateVendorList = (row) => {
        this.setState({
            vendorsList: row,
            isBlocked: true
        });
    }
    editInviteeSearch = (newValue, tab = '') => {
        if (tab == 'sendersTab') {
            // let senders = this.state.giftSenders;
            // if (newValue.person_id) {
            //     senders = senders.filter(item => item?.person_id != newValue.person_id)
            // }
            // if (newValue.event_invitee_id) {
            //     senders = senders.filter(item => item?.event_invitee_id != newValue.event_invitee_id)
            // }
            // this.setState({
            //     giftSenders: senders
            // });
        }
        if (tab == 'recipientsTab') {
            // let recipients = this.state.giftRecipients;
            // if (newValue.person_id) {
            //     recipients = recipients.filter(item => item?.person_id != newValue.person_id)
            // }
            // if (newValue.event_invitee_id) {
            //     recipients = recipients.filter(item => item?.event_invitee_id != newValue.event_invitee_id)
            // }
            // this.setState({
            //     giftRecipients: recipients
            // });
        }
    }
    updateGiftMessage = (msg) => {
        if (msg && msg != '') {
            this.setState({ giftMessage: msg })
        }
        else { this.setState({ giftMessage: '' }) }
    }
    removeInviteFromSearch = (newValue, tab = '') => {
        if (tab == 'sendersTab') {
            let senders = this.state.giftSenders;
            if (newValue.person_id) {
                //senders = senders.filter(item => item?.person_id != newValue.person_id)
                senders.splice(senders.findIndex(el => el.person_id === newValue.person_id), 1);
            }
            if (newValue.event_invitee_id) {
                // senders = senders.filter(item => item?.event_invitee_id != newValue.event_invitee_id)
                senders.splice(senders.findIndex(el => el.event_invitee_id === newValue.event_invitee_id), 1);
            }
            this.setState({
                giftSenders: senders,
                isBlocked: true
            }, () => {this.state.formValid ? null : this.validateForm() })
        }
        if (tab == 'recipientsTab') {
            //validate recipients and gifts before deleteing
            let records = this.state.gifters;
            let distributions = records[0]?.distributions;
            let distinctRecipients = [];
            distributions.forEach(r => {
                r.recipients.forEach(a => {
                    distinctRecipients.push(a);
                })
            })
            const key = 'person_id';
            const arrayUniqueByKey = [...new Map(distinctRecipients.map(item =>
                [item[key], item])).values()];

            let recipients = this.state.giftRecipients;
            //check for matching records
            //let match = recipients.filter(o1 => !arrayUniqueByKey.some(o2 => o1.person_id === o2.person_id))
            var match = arrayUniqueByKey?.filter(item => item.person_id == newValue.person_id);
            if (match && match.length > 0) {
                this.setState({
                    displayMessage: ['The Selected Recipient has a Gift Item associated to him.']
                });
                this.toggleValidation();
                return;
            }
            if (newValue.person_id) {
                recipients = recipients.filter(item => item?.person_id != newValue.person_id)
            }
            if (newValue.event_invitee_id) {
                recipients = recipients.filter(item => item?.event_invitee_id != newValue.event_invitee_id)
            }
            this.setState({
                giftRecipients: recipients,
                isBlocked: true
            }, () => {this.state.formValid ? null : this.validateForm() })
        }
    }
    deleteAllInvitees = (tab) => {
        if (tab == 'recipientsTab') {
            let gifters = this.state.gifters;
            gifters[0].distributions = [];
            this.setState({
                giftRecipients: [],
                gifters: gifters,
                isBlocked: true,
                deleteAllRecipients: true
            }, () => {this.state.formValid ? null : this.validateForm()}),
            this.calculatePrice();
            }
        if (tab == 'sendersTab') {
            this.setState({
                giftSenders: [],
                isBlocked: true,
                deleteAllSenders: true
            }, () => {this.state.formValid ? null : this.validateForm() })
           }
        }
    onGiftsChange = () => {
        console.log("Gifts tab edited");
        this.setState({
            isBlocked: true,
        }, () => {this.state.formValid ? null : this.validateForm() })
    }
    calculatePrice = () => {
        if (this.state.gifters && this.state.gifters[0]?.distributions.length > 0) {
            let distributions = this.state.gifters[0]?.distributions;
            let totalGiftCost = 0;
            let totalPrice = 0;
            let rec = this.state.gifters;
            distributions?.forEach(element => {
                totalGiftCost += element['distribution_amount'];
            });
            totalGiftCost = Math.round(totalGiftCost * 100) / 100;
            totalPrice = totalGiftCost + (rec[0].gift_tax ? (Math.round(rec[0].gift_tax * 100) / 100) : 0)
                + (rec[0].gift_other_cost ? (Math.round(rec[0].gift_other_cost * 100) / 100) : 0)
                + (rec[0].gift_shipping_delivery_cost ? (Math.round(rec[0].gift_shipping_delivery_cost * 100) / 100) : 0);
            rec[0].gift_total_cost = totalGiftCost;
            rec[0].gift_total_price = Math.round(totalPrice * 100) / 100;
        } else {
            let rec = this.state.gifters;
            rec[0].gift_total_cost = 0;
            rec[0].gift_total_price = 0;
            rec[0].gift_shipping_delivery_cost = 0;
            rec[0].gift_other_cost = 0;
            rec[0].gift_tax = 0;
        }
    }
    deleteAllGifts = () => {
        let rec = this.state.gifters;
        rec[0].distributions = []
        rec[0].gift_total_cost = 0;
        rec[0].gift_total_price = 0
        rec[0].gift_shipping_delivery_cost = 0;
        rec[0].gift_other_cost = 0;
        rec[0].gift_tax = 0;
        this.setState({ 
            gifters: rec, 
            isBlocked: true
        }, () => {this.state.formValid ? null : this.validateForm() })
    }
    _handlePriceTextFieldChange = (event, newValue) => {
        event.preventDefault();
        let val = Number(event.target.value);
        let rec = this.state.gifters;
        if (event.target.id == 'tax') {
            rec[0].gift_tax = val;
        }
        if (event.target.id == 'othercost') {
            rec[0].gift_other_cost = val;
        }
        if (event.target.id == 'shipment') {
            rec[0].gift_shipping_delivery_cost = val;
        }
        this.setState({
            gifters: rec,
            isBlocked: true
        })
        this.calculatePrice();
    };
    addPeople = (people, tab = '') => {
        if (tab == 'sendersTab') {
            let records = this.state.giftSenders;
            people.map(obj => ({ ...obj, gift_sender_id: null }))
            people.map(item => {
                records.push(item);
            });
            let filteredList = this.mergeDuplicates(records);
            this.setState({
                giftSenders: filteredList,
                isBlocked: true
            }, () => {this.state.formValid ? null : this.validateForm() })
        }
        if (tab == 'recipientsTab') {
            let records = this.state.giftRecipients;
            people.map(obj => ({ ...obj, gift_recipient_id: null }))
            people.map(item => {
                records.push(item);
            });
            let filteredList = this.mergeDuplicates(records);
            this.setState({
                giftRecipients: filteredList,
                isBlocked: true 
                }, () => {this.state.formValid ? null : this.validateForm()})
           }
        }
    editCallbackFromGrid = (rows) => {
        debugger;
        if (this.state.selectedTab == 'sendersTab') {
            this.setState({
                giftSenders: rows,
                isBlocked: true
            }, () => {this.state.formValid ? null : this.validateForm() })
        }
        if (this.state.selectedTab == 'recipientsTab') {
            this.setState({
                giftRecipients: rows,
                isBlocked: true
            }, () => {this.state.formValid ? null : this.validateForm()})
        }
    }
    setImportState = (rows, tab = '') => {
        if (tab == 'sendersTab') {
            let records = this.state.giftSenders;
            rows.map(obj => (
                obj['event_invitee_id'] = null,
                obj['guests'] = [],
                //obj['gift_sender_id'] = null,
                records.push(obj)
            ))
            let filteredList = this.mergeDuplicates(records);
            this.setState({
                giftSenders: filteredList,
                isBlocked: true
            })
        }
        if (tab == 'recipientsTab') {
            let records = this.state.giftRecipients;
            rows.map(obj => (
                obj['event_invitee_id'] = null,
                obj['guests'] = [],
                // obj['gift_recipient_id'] = null,
                records.push(obj)
            ))
            let filteredList = this.mergeDuplicates(records);
            this.setState({
                giftRecipients: filteredList,
                isBlocked: true
            })
        }
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let giftNameValid = this.state.giftNameValid;
        let giftDateValid = this.state.giftDateValid;
        let divisionValid = this.state.divisionValid;

        switch (fieldName) {
            case 'giftName':
                giftNameValid = value != '';
                fieldValidationErrors.giftName = giftNameValid ? '' : ' is invalid';
                break;
            case 'giftDate':
                giftDateValid = value != null;
                fieldValidationErrors.DateField = giftDateValid ? '' : ' is invalid';
                break;
            case 'giftDivision':
                divisionValid = value != '0';
                fieldValidationErrors.Division = divisionValid ? '' : ' is invalid';
                break;
            case 'Address':
                eventAddressValid = value != '';
                fieldValidationErrors.Address = eventAddressValid ? '' : ' is invalid';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            giftNameValid: giftNameValid,
            giftDateValid: giftDateValid,
            divisionValid: divisionValid,
        }, this.validateForm);
    }
    validateForm() {
        let formValid = (this.state.giftNameValid || this.state.giftId > 0)
            && this.state.giftDateValid && this.state.divisionValid;
        if (formValid && this.state.isDefaultForAllAddress) {
            formValid = this.state.giftAddress != null && this.state.giftAddress != '' ? true : false
        }
        this.setState({
            formValid: formValid
        });
    }
    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }
    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };
    giftNameUpdate = (titleName) =>{
        this.setState({
            giftName: titleName,
            isBlocked: true
        });
        this.validateField('giftName', titleName)
    }
    _handleGiftNameChange = (event) => {
        let existingTitles = this.state.existingGiftTitles;
        let isAdd = this.state.giftId > 0 ? false : true;
        let titleName = event.target.value ? event.target.value : '';
        _.debounce(this.giftNameUpdate(titleName), 50);
        //}, () => { this.validateField('giftName', titleName) })
    };
    _divisionChanges = (event) => {
        if (event.target.value != '0') {
            let divisionId = event.target.value;
            let divisions = this.state.divisions;
            divisions.map(obj => ({ ...obj, selected: false }))
            divisions.forEach((division) => {
                if (division.org_unit_id == divisionId) {
                    division['selected'] = true;
                }
            })
            this.setState({
                divisions: divisions,
                isBlocked: true
            }, () => { this.validateField('giftDivision', '1') });
        } else { this.validateField('giftDivision', 0) }
    }
    _showsChanges = (event, newValue) => {
        if (event.target.value != '0') {
            let showId = event.target.value;
            let shows = this.state.showList;
            shows.map(obj => ({ ...obj, selected: false }))
            shows.forEach((show) => {
                if (show.show_id == showId) {
                    show['selected'] = true;
                }
            })
            this.setState({
                showList: shows,
                isBlocked: true
            });
        };
    };

    handleMaterials = () => {
        this.setState({ showMaterials: !this.state.showMaterials });
    }

    _ocassionChanges = (event, newValue) => {
        if (event.target.value != '0') {
            let ocID = event.target.value;
            let ocassions = this.state.ocassionList;
            ocassions.map(obj => ({ ...obj, selected: false }))
            ocassions.forEach((oc) => {
                if (oc.occasion_id == ocID) {
                    oc['selected'] = true;
                }
            })
            this.setState({
                ocassionList: ocassions,
                isBlocked: true
            });
        };
    };

    saveGift = () => {
        let records = this.state.gifters;
        let distributions = records[0]?.distributions;
        if (distributions <= 0) {
            this.setState({
                displayMessage: ['Add atleast one Gift Item']
            });
            this.toggleValidation();
            return;
        }
        let validationArray = [];
        let vendorChcek = distributions.filter((item) => (!item.vendor_id));
        let giftCheck = distributions.filter((item) => (!item.gift_id));
        let recipientCheck = distributions.filter((item) => (!item.recipients || item.recipients.length == 0));
        if (vendorChcek.length > 0) {
            this.setState({
                displayMessage: ['Vendor must be chosen for each gift']
            });
            this.toggleValidation();
            return;
        }
        if (giftCheck.length > 0) {
            this.setState({
                displayMessage: ['Gift must be chosen for each gift']
            });
            this.toggleValidation();
            return;
        }
        if (recipientCheck.length > 0) {
            this.setState({
                displayMessage: ['Recipients must be chosen for each gift']
            });
            this.toggleValidation();
            return;
        }
        let distinctRecipients = [];
        distributions.forEach(r => {
            r.recipients.forEach(a => {
                distinctRecipients.push(a);
            })
        })
        const key = 'person_id';
        const arrayUniqueByKey = [...new Map(distinctRecipients.map(item =>
            [item[key], item])).values()];
        let spendingYear = this.state.sendDate.split('-')[0];
        let validationMessage = ''
        arrayUniqueByKey.forEach(val => {
            let recipient_person_id = val.person_id;
            let recipient_name = val.first_name;
            //  let recipient_year_limit = val.year_spend_limit ? val.year_spend_limit : 500;
            let yearWiseSpending = val.spending_limits && val.spending_limits.length > 0 ? val.spending_limits.filter(item => item.period == spendingYear) : null;
            let recipient_year_limit = yearWiseSpending && yearWiseSpending.length > 0 ? yearWiseSpending[0].year_limit : 500;
            let recipient_spent = yearWiseSpending && yearWiseSpending.length > 0 ? yearWiseSpending[0].limit_spent : 0;
            //let recipient_spent = val.limit_spent ? val.limit_spent : 0;
            distributions.forEach(r => {
                let recipient_amount_per_gift = 0;
                r.recipients.forEach(a => {
                    if (a.person_id == recipient_person_id) {
                        recipient_amount_per_gift += (r.distribution_amount / r.recipients.length) ? (r.distribution_amount / r.recipients.length) : 0;
                        if (recipient_amount_per_gift) {
                            recipient_spent += recipient_amount_per_gift;
                            if (recipient_spent > recipient_year_limit) {
                                let name = 'Recipient  ' + (a.name ? a.name : (a.first_name + ' ' + a.last_name)) + '  reached a spent value  ';
                                let spent = recipient_spent + ' $ ' + ' which exceeds his actual limit ' + recipient_year_limit + '\n'
                                let message = name + spent;
                                //validationMessage += message;
                                validationArray.push(message);
                                this.setState({ isGiftValidation: true })
                            }
                        }
                    }
                })
            })
        })
        if (validationArray && validationArray.length > 0) {
            if (this.isGiftValidation) {

            } else {

                this.setState({
                    displayMessage: validationArray
                });
                this.toggleValidation();
                return;
            }

        } else { this.saveGift1() }

        console.log(arrayUniqueByKey);
    }
    copyGift = () => {
        this.setState({ giftId: 0, giftName:"" })
    }
    supressGiftPriceValidation = () => {
        this.toggleValidation();
        this.saveGift1();
    }

    saveGift1 = () => {
        this.setState({ formValid: false })
        let gifters = [...this.state.gifters];
        if (this.state.giftId == 0) {
            gifters[0]['gift_event_card_id'] = null
            this.setState({ gifters: gifters })
        }

        gifters[0].distributions.forEach((r, distIndex) => {
            let cloneRecipients = [...r.recipients];
            let actualRecipients = []
            cloneRecipients.forEach((a, index) => {
                let recipient = { ...a };
                let recipientAmount = (r.distribution_amount) / r.recipients.length;
                recipient['recipient_amount'] = recipientAmount;
                actualRecipients.push(recipient);
            })
            r.recipients = actualRecipients;
        })
        let record = {
            "gift_event_card_id": this.state.giftId > 0 ? this.state.giftId : null,
            "name": this.state.giftName,
            "org_unit_id": this.state.divisions.filter(item => item.selected == true)?.[0].org_unit_id,
            "org_unit_name": this.state.divisions.filter(item => item.selected == true)?.[0].org_unit_name,
            //"show_id": this.state.shows.filter(item => item.selected == true).length > 0 ? this.state.shows.filter(item => item.selected == true)?.[0].show_id : null,
            //"show_name": this.state.shows.filter(item => item.selected == true).length > 0 ? this.state.shows.filter(item => item.selected == true)?.[0].show_name : null,
            "show_id": this.state.selectedShow ? this.state.selectedShow.show_id : null,
            "show_name": this.state.selectedShow ? this.state.selectedShow.name : null,
            "occasion_id": this.state.selectedOcassion ? this.state.selectedOcassion.occasion_id : null,
            "occasion_name": this.state.selectedOcassion ? this.state.selectedOcassion.name : null,
            "gift_send_date": this.state.sendDate ? this.state.sendDate : null,
            "gift_gl_no": (this.state.gl1 || this.state.gl2) ? ((this.state.gl1 ? this.state.gl1 : '') + '-'
                + (this.state.gl2 ? this.state.gl2 : '')) : null,
            "gift_address": this.state.giftAddress != '' ? this.state.giftAddress : null,
            "gift_is_default_all_recipients": this.state.isDefaultForAllAddress && this.state.isDefaultForAllAddress == 1 ? true : false,
            "gift_note": this.state.notes != '' ? this.state.notes : null,
            "gift_total_price": this.state.gifters[0].gift_total_price,
            "gift_tax": this.state.gifters[0].gift_tax,
            "gift_shipping_delivery_cost": this.state.gifters[0].gift_shipping_delivery_cost,
            "gift_other_cost": this.state.gifters[0].gift_other_cost,
            "gift_total_cost": this.state.gifters[0].gift_total_cost,
            "gift_senders": this.state.giftSenders,
            "gift_recipients": this.state.giftRecipients,
            "gift_distribution": gifters,
            "is_delete_all_recipients": this.state.deleteAllRecipients,
            "is_delete_all_senders": this.state.deleteAllSenders,
            "gift_message": this.state.giftMessage
        }
        console.log("eventObject", record)
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/giftRecord', record)
            .then((response) => {
                let orgId = this.state.giftId;
                NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
                if (response.data.length > 0) {
                    let res = response?.data?.[0].gift_event_card_id;
                    if (orgId > 0) {
                        NotificationManager.info('Updated Successfully', 'Gift');
                        setTimeout(() => {
                            this.setState({
                                giftId: res, giftsLoader: true, gridLoader: true, isBlocked: false, isGiftValidation: false,
                                deleteAllRecipients: false, deleteAllSenders: false
                            });
                            this.fetchGiftData();
                            this.fetchGridData();
                        }, 2500);

                    } else {
                        NotificationManager.info('Created Successfully', 'Gift');
                        setTimeout(() => {
                            this.setState({ giftId: res, giftsLoader: true, gridLoader: true, isBlocked: false, isGiftValidation: false, deleteAllRecipients: false, deleteAllSenders: false, isCopy: false });
                            this.props.history.push('/gift/' + res + '/0');
                            //window.location.reload();
                            this.fetchGiftData();
                            this.fetchGridData();
                        }, 2500);
                    }
                } else {
                    this.setState({ formValid: true })
                    NotificationManager.error(response?.data?.message ? response?.data?.message : 'Error', 'Gift');
                }
            },
                (err) => {
                    this.setState({ formValid: true })
                    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
                    NotificationManager.error('Save Failed', 'Gift');
                    console.log("People Management grid service error: " + err);
                });
    }
    showAdd = (event, value) => {
        if (event.charCode == 13) {
            if(this.state.permissions == true)
            {
                event.preventDefault();
                let name = event.target.value;
                let newShow =
                {
                    "show_id": null,
                    "show_name": name,
                    "is_active": 1
                }
                let showExists = this.state.allShowsList.find(sw=>sw.show_name==name) ? true : false;
                //console.log(showExists);
                if(showExists)
                {
                    alert("Show already exists. Please contact admin for privileges");
                }
                else
                {
                    GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/showAdd', newShow)
                    .then((response) => {
                        if (response.data.length > 0) {
                            let shw =
                            {
                                "show_id": response.data[0].show_id,
                                "show_name": name,
                            }
                            let shows = this.state.showList;
                            shows.push(shw);
                            this.setState({ showList: shows, selectedShow: shw, isBlocked : true, allShowsList: shows})
                            //NotificationManager.info('Show created successfully', 'Gift');
                        }
                    },
                    (err) => {
                        console.log("Show error: " + err);
                    });
                }
                
            }
            else{
                alert('You dont have rights to add. Please contact admin');
                //NotificationManager.info('You dont have rights. Please contact admin ', 'Gift'); 
            }   
        }
    }
    ocassionAdd = (event, value) => {
        if (event.charCode == 13) {
            event.preventDefault();
            let name = event.target.value;
            let newOcassion =
            {
                "occasion_id": null,
                "occasion_name": name,
                "is_active": 1
            }

            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/occasionAdd', newOcassion)
                .then((response) => {
                    if (response.data.length > 0) {
                        let ocs =
                        {
                            "occasion_id": response.data[0].occasion_id,
                            "name": name,
                        }
                        let ocassions = this.state.ocassionList;
                        ocassions.push(ocs);
                        this.setState({ ocassionList: ocassions, selectedOcassion: ocs, isBlocked: true })
                    }
                },
                    (err) => {
                        console.log("Ocassion error: " + err);
                    });
        }
    }
    mergeDuplicates = (data) => {
        let filteredArr = data.reduce((acc, current) => {
            const x = acc.find(item => item.person_id === current.person_id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        return filteredArr
    }
    giftAddressChange = _.debounce((id, val) => this.setState({ giftAddress: val, isBlocked: true }, () => { this.validateForm() }), 50);
    notesChange = _.debounce((id, val) => this.setState({ notes: val, isBlocked: true }, () => { this.state.formValid ? null:this.validateForm() }), 50);
    giftMessageChange = _.debounce((id, val) => this.setState({giftMessage : val, isBlocked: true }, () => { this.validateForm() }), 50);
    render() {
        return (
            <>
                {this.state.loaderOpen ? (<>
                    <div className="tableLoader">
                        {/* <CircularProgress /> */}
                        <Backdrop className='backdrop' open={this.state.loaderOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                </>) : (<>
                    <div id="giftcreation">
                        <Prompt when={this.state.isBlocked} message={"You have unsaved changes. Are you sure you want to leave this page?"} />
                        <form className="demoForm" onSubmit={this.submitHandler}>
                            <div disabled={this.state.giftsLoader && this.state.gridLoader}>
                                <div class='row'>
                                    <div class='col-xs-12'>
                                        <span className='detailsLabel'>{this.state.createdBy + this.state.updatedBy} </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className={`col-lg-4 col-md-4 F25 form-group ${this.errorClass(this.state.formErrors.giftName)}`}>
                                        <MDBInput id="eventName" placeholder="Gift Name.." className="form-control" disabled={this.state.disabled}
                                            onChange={this._handleGiftNameChange.bind(this)} value={this.state.giftName} 
                                            required name="giftName" type="text">
                                        </MDBInput>
                                    </div>
                                    <div class="col-lg-8 col-md-8" style={{ textAlign: "right", marginBottom: "0.5rem" }} id="btnRight" >

                                        <MDBBtn color="primary" onClick={this.handleMaterials.bind(this)}>
                                            <MDBIcon icon="calendar-check" className="mr-1" />&nbsp;&nbsp;Create Materials
                            </MDBBtn>
                                        <MDBBtn type='button' color="primary" onClick={this.copyGift}
                                            disabled={this.state.giftId == 0 || this.state.isCopy}>
                                            <MDBIcon icon="copy" className="mr-1" />&nbsp;&nbsp;Copy
                            </MDBBtn>
                            &nbsp;
                            <MDBBtn color="primary" type="submit" onClick={this.saveGift} disabled={!this.state.formValid || this.state.disabled || !this.state.isBlocked}>
                                            <MDBIcon icon="save" className="mr-1" />&nbsp;&nbsp;Save
                            </MDBBtn>
                            &nbsp;
                        </div>

                                </div>
                            </div>

                            <Materials isOpen={this.state.showMaterials} disabled={this.state.disabled}
                                toggleMaterials={this.handleMaterials.bind(this)}
                                fromPage="Gift"
                                isDefaultForAllAddress={this.state.isDefaultForAllAddress}
                                giftAddress = {this.state.giftAddress}
                                senders={this.state.giftSenders ? this.state.giftSenders : []}
                                recipients={this.state.giftRecipients ? this.state.giftRecipients : []}
                                gifters={this.state.gifters ? this.state.gifters : []}
                                id={this.state.giftId}
                                name={this.state.giftName}
                                address={this.state.giftAddress}
                                occassion={this.state.selectedOcassion?.name}
                                shows={this.state.selectedShow?.name}
                                giftMessage={this.state.giftMessage}
                            />
                            {this.state.giftsLoader ? <LinearProgress /> : ""}
                            <MDBCard id="giftCard" className='mb-3' style={{ height: '100%' }} disabled={this.state.disabled}>
                                <MDBCardBody>
                                    <MDBRow style={{ padding: '0.55rem 0 0.55rem 0' }} disabled={this.state.giftsLoader && this.state.gridLoader}>
                                        <div className={`col-md-5 form-group ${this.errorClass(this.state.formErrors.Division)}`} id="giftDivision">
                                            <MDBContainer className="evntblock">
                                                <MDBRow>
                                                    <MDBCol className='MB1'>
                                                        <label ><span className="redStar">*</span>Division:</label>
                                                        <select className="browser-default custom-select form-test-control" id='giftSelect'
                                                            onChange={this._divisionChanges.bind(this)}>
                                                            <option value='0'>Select</option>
                                                            {this.state.divisions ?
                                                                (this.state.divisions.map((row) => (
                                                                    <option value={row.org_unit_id}
                                                                        selected={row.selected}>{row.org_unit_name}</option>
                                                                ))) : null}
                                                        </select>
                                                    </MDBCol>
                                                </MDBRow>
                                                {/*<MDBRow>
                                                    <MDBCol className='MB1'>
                                                        <label ><span className="whitStar">*</span>Show:</label>
                                                        <select class="browser-default custom-select" id='giftSelect'
                                                            onChange={this._showsChanges.bind(this)}>
                                                            <option value='0'>Select</option>
                                                            {this.state.shows ?
                                                                (this.state.shows.map((row) => (
                                                                    <option value={row.show_id}
                                                                        selected={row.selected}>{row.show_name}</option>
                                                                ))) : null}
                                                        </select>
                                                    </MDBCol>
                                                </MDBRow>*/}
                                                <MDBRow>
                                                    <label className='PTN1'><span className="whitStar">*</span>Show:</label>
                                                    <MDBCol col='3' id='rowShow'>
                                                        <Autocomplete
                                                            style={{ width: 175 }}
                                                            options={this.state.showList && this.state.showList.length > 0 ? this.state.showList : []}
                                                            getOptionLabel={(option) => option ? (option?.show_name) : ''}
                                                            value={this.state.selectedShow ? this.state.selectedShow : []}
                                                            onChange={(event, newValue) => {
                                                                this.setState({ selectedShow: newValue, isBlocked: true })
                                                            }}
                                                            onKeyPress={(event, newValue) => {
                                                                this.showAdd(event, newValue)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Show"
                                                                    variant="outlined" />
                                                            )}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <label className='PTN1'><span className="whitStar">*</span>Occasion:</label>
                                                    <MDBCol col='3' id='rowoccassion'>
                                                        <Autocomplete
                                                            style={{ width: 175 }}
                                                            options={this.state.ocassionList && this.state.ocassionList.length > 0 ? this.state.ocassionList : []}
                                                            getOptionLabel={(option) => option ? (option?.name) : ''}
                                                            value={this.state.selectedOcassion ?
                                                                this.state.selectedOcassion : []}
                                                            onChange={(event, newValue) => {
                                                                this.setState({ selectedOcassion: newValue, isBlocked: true })
                                                            }}
                                                            onKeyPress={(event, newValue) => {
                                                                this.ocassionAdd(event, newValue)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} placeholder="Ocassion"
                                                                    variant="outlined" />
                                                            )}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol id="sendDate" className={`form-group ${this.errorClass(this.state.formErrors.DateField)}`}>
                                                        <label><span className="redStar">*</span>Send Date:</label>
                                                        <TextField className='form-test-control'
                                                            type="date" name='sendDate'
                                                            format="MM/dd/yyyy"
                                                            placeholder="MM/DD/YYYY"
                                                            variant="outlined"
                                                            onChange={this._handleTextFieldChange.bind(this)}
                                                            value={this.state.sendDate ? this.state.sendDate : ''}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol>
                                                        <label><span className="whitStar">*</span>GL#:</label>
                                                        <input type="number" className="gl" min='0' value={this.state.gl1} onChange={e => {
                                                            let r = e.target.value
                                                            this.setState({ gl1: r, isBlocked: true })
                                                        }} />
                                                        <span className="timepicker">-</span>
                                                        <input type="number" className="gl2" min='0' value={this.state.gl2} onChange={e => {
                                                            let r = e.target.value
                                                            this.setState({
                                                                gl2: r, isBlocked: true
                                                            })
                                                        }} />
                                                    </MDBCol>
                                                </MDBRow>

                                            </MDBContainer>
                                        </div>

                                        <div class="col-md-7" id="giftDivision" style={{ float: "right" }}>
                                            <MDBContainer className="addressblock">
                                                <MDBRow>
                                                    <MDBCol>
                                                        <label>Address:
                                    </label>
                                                        <textarea class="textareabox"
                                                            id="eventAddress" rows="3" defaultValue={this.state.giftAddress}
                                                           onChange={e => this.giftAddressChange('giftAddress', e.target.value)}
                                                                /* {
                                                                let r = e.target.value
                                                                this.setState({ giftAddress: r, isBlocked: true }, () => { this.validateForm() })}*/
                                                        ></textarea>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol id="cardArress">
                                                        <div class="form-check">
                                                            <input
                                                                class="form-check-input"
                                                                type="checkbox"
                                                                value=""
                                                                id="flexCheckDefault"
                                                                checked={this.state.isDefaultForAllAddress}
                                                                onChange={e => {
                                                                    this.setState({ isDefaultForAllAddress: e.target.checked, isBlocked: true }
                                                                        , () => { this.validateForm() })
                                                                }}
                                                            />
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                Use Address for all Recipients
                                                    </label>
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol>
                                                        <label>Notes:</label>
                                                        <textarea class="textareabox"
                                                            id="notes" rows="3" value={this.state.notes}
                                                            onChange={e => this.notesChange('notes', e.target.value)}
                                                            /* onBlur={e => {
                                                                let r = e.target.value
                                                                this.setState({ notes: r, isBlocked: true })
                                                            }} defaultValue={this.state.notes} */></textarea>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol>
                                                        <label>Gift Message:</label>
                                                        <textarea class="textareabox"
                                                            id="giftMessage" rows="3" defaultValue={this.state.giftMessage}
                                                            onChange={e => this.giftMessageChange('giftMessage', e.target.value)}
                                                            /* onBlur={e => {
                                                                let r = e.target.value
                                                                this.setState({ giftMessage: r, isBlocked: true })
                                                            }}  */></textarea>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBContainer>

                                        </div>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </form>
                        {this.state.gridLoader ? <LinearProgress /> : ""}
                        <Grid giftSenders={this.state.giftSenders ? this.state.giftSenders : []}
                            giftRecipients={this.state.giftRecipients ? this.state.giftRecipients : []}
                            recipientToAddBySearch={this.state.recipientToAddBySearch ? this.state.recipientToAddBySearch : []}
                            removeInviteFromSearch={this.removeInviteFromSearch}
                            editInviteeSearch={this.editInviteeSearch}
                            updateVendorList={this.updateVendorList}
                            deleteAllInvitees={this.deleteAllInvitees}
                            fromPage='gift' gifters={this.state.gifters ? this.state.gifters : []}
                            addInviteeFromSearch={this.addInviteeFromSearch}
                            calculatePrice={this.calculatePrice}
                            deleteAllGifts={this.deleteAllGifts}
                            vendorsList={this.state.vendorsList}
                            defaultTab='recipientsTab' giftId={this.state.giftId}
                            tabSelection={this.tabSelection} addPeople={this.addPeople}
                            editCallbackFromGrid={this.editCallbackFromGrid}
                            setImportState={this.setImportState.bind(this)} giftMessage={this.state.giftMessage} updateGiftMessage={this.updateGiftMessage}
                            disabled={this.state.disabled}
                            onGiftsChange={this.onGiftsChange}
                            gridLoader={this.state.gridLoader}></Grid>
                        {/* {this.state.selectedTab && this.state.selectedTab == 'giftsTab' && this.state.giftId > 0
                            ? <></> : null} */}
                    </div>
                </>)
                }
                <NotificationContainer />
                <GiftValidation isOpen={this.state.openValidation} isOk={this.state.isGiftValidation}
                    toggleValidation={this.toggleValidation} supressGiftPriceValidation={this.supressGiftPriceValidation}
                    displayMessage={this.state.displayMessage} />
            </>
        );
    }
}

export default CreateGiftPage;
