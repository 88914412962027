import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBNavbarLink, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { fade, makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import logo from '../../assets/logo.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import ConfirmDialog from '../Common/ConfirmDialog';

// const useStyles = makeStyles((theme) => ({
//     adminTitle: {
//         fontSize: '12px',
//         color: '#ffffff'
//     }
// }));
// const classes = useStyles();

export default withRouter(withOktaAuth(class HeaderContent extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     //collapse: false,
        //     //isLoggedIn: props.isLoggedIn,
        //     //showNavRight: false,
        //     //user_name: localStorage.getItem("user_name")
        // };
        //this.onClick = this.onClick.bind(this);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.state = {
            confirmSaveChanges: false,
            switch_tenant_id: 0,
            switch_tenant_name: ""
        }
    }

    // onClick() {
    //     this.setState({
    //         collapse: !this.state.collapse,
    //     });
    // }

    async logout() {
        for (var i = 0; i < localStorage.length; i++){
            var key = localStorage.key(i);;
            if(key.indexOf("okta-") == -1)
            localStorage.removeItem(key);
        }
        
        // Redirect to '/' after logout
        this.props.authService.logout('/');
    }


    async login() {
        // Redirect to '/' after logout
        this.props.authService.login('/');
    }
    handleSideNavActive = (value, routePath) => {
        this.props.setActiveTab(value);
        this.props.history.push(routePath, this.state);
        //this.props.handleSideNavActive(value, routePath);
    }

    switchTenant = (tenant_id, tenant_name) => {
        //this.props.switchTenant(tenant_id);
        this.setState({ switch_tenant_id: tenant_id, switch_tenant_name: tenant_name, confirmSaveChanges: !this.state.confirmSaveChanges });
    }

    confirmCancelChanges = (isCancel) => {
        if (isCancel) {
            this.props.switchTenant(this.state.switch_tenant_id);
        }
        else {
            this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
        }
    }
    // componentWillReceiveProps(nextProps){
    //     console.log(nextProps);
    // }


    render() {
        const bgColorBlue = { backgroundColor: '#0062AF' }
        const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
        const ptli = { paddingTop: '0.1rem' }

        return (
            <div id="headerContent">
                <Router fixed="top">
                    <header  >
                        <div className="fixed-top">
                            <MDBNavbar bgColor="primary" dark expand="md" fixed sticky >
                                <MDBContainer fluid>

                                    {/* <MDBNavbarToggler
                                type='button'
                                data-target='#navbarRightAlign'
                                aria-controls='navbarRightAlign'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={this.props.handleSideNav}
                                className = "d-block d-lg-none"
                                >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler> */}


                                    <MDBNavbarBrand className='mb-0 h1' >
                                        {/* <MDBDropdown className="d-none">
                            <MDBDropdownToggle nav className="bgColorBlue shadow-none" >                               
                                <MDBIcon icon='bars' fas />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <MDBDropdownItem active={false} onClick = {this.handleSideNavActive.bind(this, 'CE', "/event")}>
                                    <MDBNavbarLink active={false} >
                                        Create Event
                                    </MDBNavbarLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem active={false} onClick = {this.handleSideNavActive.bind(this, 'CG', "/gift")} >
                                    <MDBNavbarLink link active={false}>
                                        Create Gift
                                    </MDBNavbarLink>
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown> */}
                                        <MDBNavbarLink link active={false} className="nav-link-override" >
                                            <img
                                                src={logo}
                                                alt='Logo' className="imageWidth"
                                                onClick={this.handleSideNavActive.bind(this, 'LP', "/")}
                                            />
                                            {/* <h1 className="headerFont" id='tracker-planner'> */}
                                        </MDBNavbarLink>
                                        <h1 className="app-title">
                                            GIFTER
                                </h1>
                                        {/* </h1> */}
                                    </MDBNavbarBrand>
                                    {/* <MDBCollapse center navbar show={this.state.showNavRight} id="navbarCenterAlign">
                            <MDBNavbarNav  fullWidth={false} className='mb-2 mb-lg-0'>
                                <MDBNavbarItem style={ptli}>
                                <h1 className="app-suite-title">
                                     STUDIO DIRECTOR
                                </h1> 
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                            </MDBCollapse> */}

                                    {/* <MDBNavbarToggler
                            type='button'
                            data-target='#navbarRightAlign'
                            aria-controls='navbarRightAlign'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({showNavRight : !this.state.showNavRight})}
                            
                            >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>
                        */}
                                    {/* <div className="headerSpace"></div> */}
                                    {/* <MDBCollapse navbar show={this.state.showNavRight} id="navbarRightAlign"> */}
                                    <ConfirmDialog isOpen={this.state.confirmSaveChanges}
                                        confirmCancelChanges={this.confirmCancelChanges.bind(this)}
                                        displayMessage={"Are you sure you want to switch tenant - " + this.state.switch_tenant_name + ". Any unsaved changes will be lost?"}>
                                    </ConfirmDialog>
                                    <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>
                                        <MDBNavbarItem className="d-none d-lg-block">
                                            <span style={adminTitle}>Signed in as: &nbsp;
                                        <b> {this.props.user_full_name} </b>
                                                <br />
                                                <i> {this.props.active_tenant_role ? this.props.active_tenant_role + ", " + this.props.active_tenant_name : ""} </i>
                                            </span>
                                        </MDBNavbarItem>

                                        <MDBNavbarItem className="padding-hor-25" >
                                            <MDBDropdown >
                                                <MDBDropdownToggle tag="a" className="bgColorBlue shadow-none text-white" >
                                                    {/* <AccountCircle style={{ fontSize: 30 }} color="inherit" /> */}
                                                    <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                </MDBDropdownToggle>
                                                <MDBDropdownMenu className="text-end border border-quinary">
                                                    {/* <MDBDropdownItem active={false} className="d-block d-lg-none" onClick = {this.handleSideNavActive.bind(this, 'CE', "/event")}>
                                            <MDBDropdownLink active={false} to='/event'>
                                                Create Event
                                            </MDBDropdownLink>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem active={false} className="d-block d-lg-none" onClick = {this.handleSideNavActive.bind(this, 'CG', "/gift")}>
                                            <MDBDropdownLink active={false} to='/gift'>
                                                Create Gift
                                            </MDBDropdownLink>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem active={false} className={!this.state.isLoggedIn ? "hide" : "show"} 
                                                    disabled={!this.state.isLoggedIn} >
                                                <MDBDropdownLink active={false} to='/'>Logout</MDBDropdownLink >
                                        </MDBDropdownItem>             */}

                                                    {this.props.tenants && this.props.tenants.length > 0 && this.props.tenants.map((tenant) => {
                                                        if (tenant.tenant_id != this.props.active_tenant_id)
                                                            return <MDBDropdownItem data-active={false} key={tenant.tenant_id}
                                                                onClick={this.switchTenant.bind(this, tenant.tenant_id, tenant.tenant_name)}>
                                                                <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                    <small>{tenant.tenant_name}</small>
                                                                </MDBDropdownLink>
                                                            </MDBDropdownItem>
                                                    })}
                                                    {/* {this.props.active_tenant_permissions && this.props.active_tenant_permissions.map((permission) => { */}

                                                    {this.props.active_tenant_permissions.isCreateEvent == true ?
                                                        (<div className="d-block d-lg-none">
                                                            <MDBDropdownItem active={false} onClick={this.handleSideNavActive.bind(this, 'CE', "/event/0")}>
                                                                <MDBDropdownLink active={false} to='/'>Create Event</MDBDropdownLink>
                                                            </MDBDropdownItem>
                                                        </div>
                                                        )
                                                        : ""
                                                    }
                                                    {this.props.active_tenant_permissions.isCreateGift == true ?
                                                        (
                                                            <div className="d-block d-lg-none">
                                                                <MDBDropdownItem active={false} onClick={this.handleSideNavActive.bind(this, 'CG', "/gift/0")}>
                                                                    <MDBDropdownLink active={false} to='/'>Create Gift</MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            </div>
                                                        )
                                                        : ""
                                                    }
                                                    {this.props.active_tenant_permissions.isCreateCard == true ?
                                                        (
                                                            <div className="d-block d-lg-none">
                                                                <MDBDropdownItem active={false} onClick={this.handleSideNavActive.bind(this, 'CC', "/card/0")}>
                                                                    <MDBDropdownLink active={false} to='/'>Create Card</MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            </div>
                                                        )
                                                        : ""
                                                    }
                                                    {this.props.active_tenant_permissions.isCreatePeople == true ?
                                                        (
                                                            <div className="d-block d-lg-none">
                                                                <MDBDropdownItem active={false} onClick={this.handleSideNavActive.bind(this, 'MP', "/manageUsers")}>
                                                                    <MDBDropdownLink active={false} to='/'>Manage People</MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            </div>
                                                        )
                                                        : ""
                                                    }
                                                    {this.props.active_tenant_permissions.isCreateData == true ?
                                                        (
                                                            <div className="d-block d-lg-none">
                                                                <MDBDropdownItem active={false} onClick={this.handleSideNavActive.bind(this, 'MD', "/manageData")}>
                                                                    <MDBDropdownLink active={false} to='/'>Manage Data</MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            </div>
                                                        )
                                                        : ""
                                                    }
                                                    {this.props.active_tenant_permissions.isCreateUser == true ?
                                                        (
                                                            <div className="d-block d-lg-none">
                                                                <MDBDropdownItem active={false} onClick={this.handleSideNavActive.bind(this, 'UM', "/userRoleManagers")}>
                                                                    <MDBDropdownLink active={false} to='/'>Manage Users</MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            </div>
                                                        )
                                                        : ""
                                                    }
                                                    <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}
                                                        disabled={!this.props.isLoggedIn} onClick={this.logout} >
                                                        <MDBDropdownLink className="fw-bold" active={false} to='/'>Logout</MDBDropdownLink >
                                                    </MDBDropdownItem>

                                                </MDBDropdownMenu>
                                            </MDBDropdown>
                                        </MDBNavbarItem>

                                    </MDBNavbarNav>
                                    {/* </MDBCollapse> */}
                                </MDBContainer>
                            </MDBNavbar>
                        </div>
                    </header>
                </Router>
            </div>
        );
    }
}
));
//export default HeaderContent;