import React from 'react';
import TableComponent from '../../Common/Table';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';

const configObject = {
    headings: [{
        headingLabel: "Item Name",
        icon: "sort-alpha-down"
    },
    {
        headingLabel: "Price",
        icon: ""
    },
    {
        headingLabel: "Status",
        icon: ""
    },
    {
        headingLabel: "Actions",
        icon: ""
    }],
    dataNodes: ["gift_name", "price", "is_active"],
    primaryKey: "gift_name",
    actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};

export default class GiftSupplied extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Item Name",
            configObject: configObject,
            vendorGifts: [],
            listToRender: []
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ vendorGifts: nextProps.vendorGifts }, () => {
            this.sortList();
        });
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
    }

    handleTableSort = (sortBy) => {
        let configObject = this.state.configObject;
        this.setState({ isSortByAsc: this.state.sortCount % 2 === 0 ? true : false }, () => {
            let newHeadingArray = configObject.headings.map(item => {
                if (item.headingLabel === sortBy) {
                    item.icon = this.state.isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
                }
                return item;
            });
            configObject.headings = newHeadingArray;
            this.setState({ page: 0, configObject: configObject }, () => {
                this.sortList();
            })
        })
    }

    sortList = () => { 
        let sortedList;
        if(this.state.sortBy === "Item Name" ) {
            if (this.state.isSortByAsc) {
                sortedList = this.state.vendorGifts?.sort((a, b) => (a.gift_name.toString().split(' ').join('') > b.gift_name.toString().split(' ').join('')) ? 1 : -1);
            } else {
                sortedList = this.state.vendorGifts?.sort((a, b) => (b.gift_name.toString().split(' ').join('') > a.gift_name.toString().split(' ').join('')) ? 1 : -1);
            }
            this.setState({ vendorGifts: sortedList, page: 0 }, () => {
                this.handlePagination();
            });
        }
    }

    componentDidMount() {
        this.setState({ vendorGifts: this.props.vendorGifts }, () => {
            this.sortList();
        });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => {
            this.handlePagination();
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
            this.handlePagination();
        });
    }

    handlePagination = () => {
        let startIndex = this.state.page * this.state.rowsPerPage;
        let lastIndex = startIndex + this.state.rowsPerPage;
        let list = this.state.vendorGifts?.slice(startIndex, lastIndex);
        let modifiedList = list?.map(item => item.is_active === 1 || item.is_active === "Active" ?
            { ...item, is_active: "Active", price: "$" + item.price } : { ...item, is_active: "Inactive", price: "$" + item.price });
        this.setState({ listToRender: modifiedList });
    }

    render() {
        return (
            <div className="giftSuppliedContainer">
                <div className="row">
                    <div className="col-9">
                        <h6>Gifts Supplied</h6>
                    </div>
                    <div className="col-3">
                        <Button variant="contained" size="small" color="primary" type="submit" onClick={() => this.props.handleAddGift()}>Add Gift</Button>
                    </div>
                </div>
                <div className="giftTable">
                    <TableComponent
                        list={this.state.listToRender ? this.state.listToRender : []}
                        config={configObject}
                        isLoading={this.props.isTableLoading}
                        sortCallback={this.handleTableColumnClick}
                        editCallback={this.props.handleGiftEdit} />
                    <TablePagination
                        component="div"
                        count={this.state.vendorGifts ? this.state.vendorGifts?.length : 0}
                        rowsPerPageOptions={[10, 15, 20]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        );
    }

}