import React from 'react';
import { Checkbox } from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MDBIcon } from 'mdbreact';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

class Action extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleAddOrDelete = (action) => {
        switch (this.props.field) {
            case "phone_list":
                if (action === "add") {
                    let newPhone = {
                        "person_phone_id": null,
                        "phone": "",
                        "is_primary": 0,
                        "is_active": 0,
                        "canDelete": true
                    }
                    this.props.handleChange(newPhone, this.props.field, action);
                } else if (action === "delete" && this.props?.item.canDelete) {
                    this.props.handleChange(this.props.item, this.props.field, action);
                }
                break;
            case "category_list":
                if (action === "add") {
                    let newCategory = {
                        "person_category_id": null,
                        "category_id": null,
                        "category_name": "",
                        "is_primary": 0,
                        "is_active": 0,
                        "canDelete": true
                    }
                    this.props.handleChange(newCategory, this.props.field, action);

                } else if (action === "delete" && this.props?.item.canDelete) {
                    this.props.handleChange(this.props.item, this.props.field, action);
                }
                break;
            case "email_list":
                if (action === "add") {
                    let newEmail = {
                        "person_email_id": null,
                        "email": "",
                        "is_primary": 0,
                        "is_active": 0,
                        "isValid": true,
                        "canDelete": true
                    }
                    this.props.handleChange(newEmail, this.props.field, action);

                } else if (action === "delete" && this.props?.item.canDelete) {
                    this.props.handleChange(this.props.item, this.props.field, action);
                }
                break;
            case "address_list":
                if (action === "add") {
                    let newAdrress = {
                        "person_extension_id": null,
                        "address": "",
                        "address_type": "",
                        "is_primary": 0,
                        "is_active": 0,
                        "canDelete": true
                    }
                    this.props.handleChange(newAdrress, this.props.field, action);

                } else if (action === "delete" && this.props?.item.canDelete) {
                    this.props.handleChange(this.props.item, this.props.field, action);
                }
                break;
            case "comp_div_title_list":
                if (action === "add") {
                    let newobj = {
                        "p_comp_div_title_id": null,
                        "company_id": null,
                        "company_name": null,
                        "division_id": null,
                        "division_name": null,
                        "title_id": null,
                        "title_name": null,
                        "is_primary": 0,
                        "is_active": 0,
                        "canDelete": true
                    }
                    this.props.handleChange(newobj, this.props.field, action);

                } else if (action === "delete" && this.props?.item.canDelete) {
                    this.props.handleChange(this.props.item, this.props.field, action);
                }
                break;
        }
    }

    handlePrimaryAndActive = (field_param, value) => {
        this.props.handlePrimaryAndActive(this.props.field, field_param, this.props.item, value);
    }

    render() {
        return (
            // <div className="actionContainer">
            <>
                <div className="row">
                    <div className="col-4">
                        {/* {this.props?.index === 0 ? <label>{"primary"}</label> : <div className="block"></div>} */}
                        <Checkbox color="primary"
                            checked={this.props.item?.is_primary}
                            style={{ transform: "scale(0.7)", fontSize: "1rem" }}
                            checkedIcon={<CancelPresentationIcon style={{ fill: '#000000' }} />}
                            onChange={(e) => this.handlePrimaryAndActive("is_primary", e.target.checked)} />
                    </div>
                    <div className="col-3">
                        {/* {this.props?.index === 0 ? <label>{"active"}</label> : <div className="block"></div>} */}
                        <Checkbox color="primary"
                            checked={this.props.item?.is_active}
                            style={{ transform: "scale(0.7)", fontSize: "1rem" }}
                            checkedIcon={<CancelPresentationIcon style={{ fill: '#000000' }} />}
                            onChange={(e) => this.handlePrimaryAndActive("is_active", e.target.checked)} />
                    </div>

                    {
                        ((this.props.length - 1) === this.props.index) && <div className= {this.props.area === "company" ? "col-1 addOrDelete" : "col-1 addDel"} id={this.props.area === "company" ? "addOrDelete_" + this.props.index : "addDel_" + this.props.index}>
                            <AddCircleIcon className="action-icon" style={{ fill: '#000000', fontSize: "1rem" }} id={this.props.field + this.props.index + "add"} onClick={() => this.handleAddOrDelete("add")} />
                        </div>
                    }
                    {
                        this.props?.item?.canDelete && 
                            <div className= {this.props.area === "company" ? "col-1 addOrDelete" : "col-1 addDel"} 
                                    id={this.props.area === "company" ? "addOrDelete" : "addDel"}>
                                <RemoveCircleIcon className="action-icon" style={{ fill: '#000000', fontSize: "1rem" }}
                                 id={this.props.field + this.props.index + "delete"} onClick={() => this.handleAddOrDelete("delete")}/>
                                 {/* <MDBIcon onClick={this.handleAddOrDelete.bind(this, "delete")} icon="minus-circle" 
                                    component={"span"} className="mr-4 text-black padding-hor action-icon"
                                /> */}
                            </div>
                        
                    }

                </div>
            {/* </div> */}
            </>
        );
    }
}

export default Action;