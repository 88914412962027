import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
    MDBTabsPane, MDBTabsContent, MDBDropdown, MDBSelect, MDBBtn, MDBDatepicker,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBTooltip,
    MDBIcon
} from 'mdb-react-ui-kit';

import Button from '@material-ui/core/Button';
import ImportPeople from './ImportPeople';
import PreviewImport from './previewImport';
import GifterService from '../../services/service';
import * as Constants from '../../constants/constants';
import { Base64 } from 'js-base64';
//var XLSX = require('xlsx');
import XLSX from 'xlsx';

class Import extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            isOpen: this.props.isOpen,
            // selectedFiles: undefined,
            // selectedFile: undefined    
            showImportPeople: false,
            showPreviewImport: false,
            showDuplicatePeople: false,
            duplicatesData: [],
            selectedFile: undefined,
            importPeopleData: {
                columns: [
                    {
                        label: 'First Name',
                        headerName: 'First Name',
                        field: 'first_name',
                        width: 200,
                        flex: 0.2
                    },
                    {
                        label: 'Last Name',
                        headerName: 'Last Name',
                        field: 'last_name',
                        width: 150,
                        flex: 0.2
                    },
                    {
                        label: 'Category',
                        headerName: 'Category',
                        field: 'category_name',
                        width: 70,
                        flex: 0.3
                    },
                    {
                        label: 'Division',
                        headerName: 'Division',
                        field: 'division_name',
                        width: 100,
                        flex: 0.3
                    },
                    {
                        label: 'Title',
                        headerName: 'Title',
                        field: 'title_name',
                        width: 100,
                        flex: 0.3
                    },
                    {
                        label: 'Company',
                        headerName: 'Company',
                        field: 'company_name',
                        width: 150,
                        flex: 0.3
                    }
                ],
                rows: []
            },
            importPeopleRowCount: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ showImportPeople: nextProps.showImportPeople });
    }
    toggleImportPeople = () => {
        //this.setState({ showImportPeople: !this.state.showImportPeople });
        this.props.toggleImportPeople();
    }

    handleFileSelect = (selFile) => {
        this.setState({
            selectedFile: selFile
        });
    }

    previewExcel = () => {
        //this.setState({ showImportPeople: !this.state.showImportPeople });
        this.props.toggleImportPeople();
        this.setState({ showPreviewImport: !this.state.showPreviewImport });
        if (this.state.selectedFile != undefined) {
            let formData = new FormData();
            var reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            if (rABS) reader.readAsBinaryString(this.state.selectedFile);
            else reader.readAsArrayBuffer(this.state.selectedFile);
            //reader.readAsArrayBuffer(this.state.selectedFile);      
            //reader.readAsBinaryString(this.state.selectedFile);    
            reader.onloadend = (evt) => {
                if (evt.target.readyState == FileReader.DONE) {

                    var data = evt.target.result;// reader.result; //new Uint8Array(reader.result);//
                    //Base64.fromUint8Array(reader.result);
                    //var workbook = XLSX.read(data, { type: 'binary' });
                    //formData.append('excel_file', data );

                    var workbook = XLSX.read(evt.target.result, {
                        type: rABS ? 'binary' : 'array'
                    });
                    var sheet_name_list = workbook.SheetNames;
                    var json_input = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]],
                        { header: 1, defval: "", blankrows: false });
                    formData.append('excel_file', json_input);
                    GifterService.postData(Constants.GifterServiceBaseUrl + '/importPreview', json_input)
                        .then((response) => {

                            if (response.data.code == 400)
                                alert("Excel Import: " + response.data.message);
                            else {
                                console.log("Gift Service selected file  for import preview: " + response.data);

                                var results = response.data.map((row) => ({ ...row, id: row.excel_row_no }));

                                this.setState({
                                    importPeopleData: {
                                        ...this.state.importPeopleData, rows: results
                                    },
                                    importPeopleRowCount: results.length
                                });

                                let duplicates = results.filter((data) => data.is_duplicate);
                                if (duplicates && duplicates.length > 0)
                                    this.setState({
                                        showDuplicatePeople: true,
                                        duplicatesData: duplicates
                                    });

                            }
                        },
                            (err) => {
                                console.log("Gift Service error for import people: " + err);
                                alert("Import People failed :" + err);
                            });

                }
            }

        }
    }

    toggleDuplicatePeople = () => {
        this.setState({ showDuplicatePeople: !this.state.showDuplicatePeople });
    }

    importPeople = (importData) => {

        var results = this.props.data || [];
        // if(this.state.selectedTab === 'eventsTab')
        // results = this.state.rows;
        // else if(this.state.selectedTab === 'giftsTab')
        // results = this.state.giftRecipients;

        // var importRes = importData.map((item) => {
        //     return {               
        //         person_id: item.person_id,
        //         first_name: item.first_name,
        //         last_name: item.last_name,
        //         display_name: item.display_name,
        //         dob: item.dob,
        //         is_card_signer: item.is_card_signer,
        //         category_id: item.category_id,
        //         category_name: item.category_name,
        //         company_id: item.company_id,
        //         company_name: item.company_name,
        //         division_id: item.division_id,
        //         division_name: item.division_name,
        //         title_id: item.title_id,
        //         title_name: item.title_name,
        //         address: item.address,
        //         address_type: item.address_type,
        //         phone: item.phone,
        //         email: item.email,
        //         guests:[]
        //     }
        // });
        // importData.map(obj => ({ ...obj, event_invitee_id: null }))
        // importData.map(obj => ({ ...obj, guests: [] }))

        importData.forEach(element => {
            element['event_invitee_id'] = null;
            element['guests'] = null;
            element['gift_sender_id'] = null;
            element['gift_recipient_id'] = null;
        });

        console.log("Results -->" + results);
        console.log("Import Results -->" + importData);

        var finalResult = importData.concat(results.filter(({ person_id }) => !importData.find(p => p.person_id == person_id)));

        finalResult.forEach(item => {
            results.forEach(y => {
                if (y.person_id == item.person_id) {
                    if (y.event_invitee_id) {
                        item['event_invitee_id'] = y.event_invitee_id
                    }
                    if (y.gift_sender_id) {
                        item['gift_sender_id'] = y.gift_sender_id
                    }
                    if (y.gift_recipient_id) {
                        item['gift_recipient_id'] = y.gift_recipient_id
                    }
                }
            })
        })

        this.props.updateData(finalResult);

        //this.togglePreviewImport();
        this.setState({
            showImportPeople: false,
            showDuplicatePeople: false,
            showPreviewImport: false,
            duplicatesData: [],
            selectedFile: undefined,
            importPeopleData: { ...this.state.importPeopleData, rows: [] },
            importPeopleRowCount: 0
        });

    }

    finishDuplicateCheck = (finalResults) => {
        var res = finalResults.map((row) => ({ ...row, id: row.excel_row_no }));
        this.setState({ importPeopleData: { ...this.state.importPeopleData, rows: res } });
    }

    togglePreviewImport = () => {
        //this.setState({ showPreviewImport: !this.state.showPreviewImport });
        this.setState({
            showImportPeople: false,
            showDuplicatePeople: false,
            showPreviewImport: !this.state.showPreviewImport,
            duplicatesData: [],
            selectedFile: undefined,
            importPeopleData: { ...this.state.importPeopleData, rows: [] },
            importPeopleRowCount: 0
        });
    }

    render() {
        return (
            <>

                <ImportPeople isOpen={this.props.showImportPeople}
                    toggleImportPeople={this.toggleImportPeople.bind(this)}
                    previewExcel={this.previewExcel.bind(this)}
                    handleFileSelect={this.handleFileSelect.bind(this)}
                    selectedFile={this.state.selectedFile}
                >
                </ImportPeople >

                <PreviewImport isOpen={this.state.showPreviewImport}
                    togglePreviewImport={this.togglePreviewImport.bind(this)}
                    selectedFileName={this.state.selectedFile?.name || ''}
                    importPeopleData={this.state.importPeopleData}
                    duplicatesData={this.state.duplicatesData}
                    showDuplicatePeople={this.state.showDuplicatePeople}
                    toggleDuplicatePeople={this.toggleDuplicatePeople.bind(this)}
                    importPeople={this.importPeople.bind(this)}
                    finishDuplicateCheck={this.finishDuplicateCheck.bind(this)}
                >
                </PreviewImport>

            </>
        )
    }
}
export default Import;