import React, { Component } from 'react';

//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import "../styles/app.scss";
import { Route, Switch, withRouter } from 'react-router-dom';
import {Security, withOktaAuth, SecureRoute, LoginCallback } from '@okta/okta-react';
import authService from "../app.config"
import Home from "./Home";
import IdleTimer from 'react-idle-timer'
import * as Constants from '../constants/constants';
import ErrorPage from '../components/ErrorPage';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MaterialTheme from './MaterialTheme';


export default withRouter(withOktaAuth(class App extends Component {
  
  constructor(props) {
    super(props);     
    this.idleTimer = null   
    this.onIdle = this._onIdle.bind(this);
    this.state = {
      isLoggedOut : false,
      errorId : 0,
      errorDesc: ''
    }
  }
 
  _onIdle(e) {
 
    authService.logout();
    this.setState({
      isLoggedOut : true,
      errorId: '440',
      errorDesc : 'Session is timed out. Please login again!'
    });

  }

  componentDidMount() {
    console.log("Loading App");
  }
  render() {
    return (
      this.state.isLoggedOut ? <ErrorPage isLoggedIn = {!this.state.isLoggedOut} errorId = {this.state.errorId} errorDesc = {this.state.errorDesc} ></ErrorPage>:
      <>
      <ThemeProvider theme={MaterialTheme}>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}     
          onIdle={this.onIdle}      
          debounce={250}
          timeout={1000 * 60 * Constants.IDLE_TIMEOUT} />
          <Security authService = {authService} >
               <Switch>  
               <Route path='/callback' component={LoginCallback}/>
               <SecureRoute true path='/'  component={Home}/>
               </Switch>    
          </Security>
      </ThemeProvider>
     </>
    );
  }
}
)//withOktaAuth
); //withRouter


