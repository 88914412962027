export const userList = [
    {
        userId: "US001",
        name: "Andy Sandberg",
        email: "andy.sandberg@snl.com",
        company: "Universal Pictures",
        division: "Talent",
        category: "Actor",
        title: "Lead Actor",
        status: "Active"
    },
    {
        userId: "US002",
        name: "Ben Affleck",
        email: "benny.a@email.com",
        company: "CAA",
        division: "Features - Talent",
        category: "Actor",
        title: "Lead Actor",
        status: "Active"
    },
    {
        userId: "US003",
        name: "Cindy Louhou",
        email: "C.Louhou@universal.com",
        company: "Universal Pictures",
        division: "Talent",
        category: "Show",
        title: "Lead Actress",
        status: "Active"
    }, {
        userId: "US004",
        name: "Danny Devito",
        email: "TheDannyD@sunny.com",
        company: "CAA",
        division: "Literary Agency",
        category: "Agent",
        title: "Supporting Actor",
        status: "Inactive"
    },
    {
        userId: "US005",
        name: "Earl Smith",
        email: "ESmith@Esmith.com",
        company: "Earl Smith Productions ",
        division: "Corporate",
        category: "Show",
        title: "Supporting Actor",
        status: "Active"
    }
];

export const configObject = {
    headings: [{
        headingLabel: "Name",
        icon: "sort-alpha-down"
    },
    {
        headingLabel: "Email",
        icon: ""
    },
    {
        headingLabel: "Company",
        icon: ""
    },
    {
        headingLabel: "Division",
        icon: ""
    },
    {
        headingLabel: "Category",
        icon: ""
    },
    {
        headingLabel: "Title",
        icon: ""
    },
    {
        headingLabel: "Status",
        icon: ""
    },
    {
        headingLabel: "Actions",
        icon: ""
    }],
    dataNodes: ["name", "email", "company_name", "division_name", "category_name", "title_name", "is_active"],
    primaryKey: "person_id",
    actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};
export const lookupPeopleconfigObject = {
    headings: [
        {
            headingLabel: "Select",
            icon: "sort-alpha-down"
        }, {
            headingLabel: "Name",
            icon: "sort-alpha-down"
        },
        {
            headingLabel: "Email",
            icon: ""
        },
        {
            headingLabel: "Company",
            icon: ""
        },
        {
            headingLabel: "Division",
            icon: ""
        },
        {
            headingLabel: "Category",
            icon: ""
        },
        {
            headingLabel: "Title",
            icon: ""
        }],
    dataNodes: ["check", "name", "email", "company_name", "division_name", "category_name", "title_name"],
    primaryKey: "person_id",
    actions: [],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};
export const userRoleConfigObject = {
    headings: [{
        headingLabel: "Name",
        icon: "sort-alpha-down"
    },
    {
        headingLabel: "Email",
        icon: ""
    },
    {
        headingLabel: "Roles",
        icon: ""
    },
    {
        headingLabel: "App Access",
        icon: ""
    },
    {
        headingLabel: "Status",
        icon: ""
    },
    {
        headingLabel: "Actions",
        icon: ""
    }],
    dataNodes: ["name", "email", "roles", "division_access", "status"],
    primaryKey: "email",
    actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};
export const customGroupPersonsConfigObject = {
    headings: [{
        headingLabel: "Name",
        icon: "sort-alpha-down"
    },
    {
        headingLabel: "Company",
        icon: ""
    },
    {
        headingLabel: "Division",
        icon: ""
    },
    {
        headingLabel: "Title",
        icon: ""
    },
    {
        headingLabel: "Category",
        icon: ""
    },
    {
        headingLabel: "Actions",
        icon: ""
    }],
    dataNodes: ["name", "company_name", "division_name", "title_name", "category_name"],
    primaryKey: "person_id",
    actions: ["trash-alt"],
    changeColourToRed: ["Inactive"]
};
