import React, { useState, useEffect, useCallback, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupedButtons from "../GroupedButtons/GroupedButtons";
import { MDBBtn } from 'mdb-react-ui-kit';
import { MDBIcon } from "mdbreact";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormatColorResetRounded } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants.js';
import {
    MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter
}
    from 'mdbreact';
import {
    MDBCard
} from 'mdb-react-ui-kit';
import GiftValidation from '../Common/GiftValidation';

function Row(props) {
    const { row } = props;
    const vendorList = props.vendorList ? props.vendorList : []
    const index = props.index;
    const giftRecipients = props.giftRecipients ? props.giftRecipients : []
    let counterCallback = (value) => {
        if (value) {
            props.counterCallback(row, index, value, false)
            props.onGiftsChange();
        }
    }
    return (
        <React.Fragment>
            <TableRow>
                <TableCell key={props.index}>
                    <Autocomplete
                        className="tags-standard"
                        options={vendorList && vendorList.length > 0 ? vendorList : []}
                        getOptionLabel={(option) => option ? (option?.name) : ''}
                        value={vendorList && vendorList.length > 0 ?
                            vendorList.filter(item => item.vendor_id == row.vendor_id)[0] : []}
                        onInputChange={(event, value) => { props.setInitialGift(row, index) }}
                        onChange={(event, newValue) => {
                            if (newValue && newValue?.vendor_id != -1) {
                                let GetGiftVendors = Constants.GifterServiceBaseUrl + '/vendorGifts?vendorId=' + newValue.vendor_id;
                                GifterService.getData(GetGiftVendors)
                                    .then((response) => {
                                        debugger;
                                        let data = response.data && response.data.length > 0 ? response.data[0].gifts : []
                                        data = data && data.length > 0 ? data.filter(item => item.is_active == 1) : data;
                                        let giftOption = { 'gift_name': 'Add Gift', gift_id: -1 }
                                        data.splice(0, 0, giftOption)
                                        props.refreshGiftDetails(data, index, newValue);
                                    });
                            } else if (newValue && newValue?.vendor_id == -1) {
                                props.toggleVendorModal(index, row);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Vendors"
                                margin="normal" />
                        )}
                    />
                </TableCell>
                <TableCell>
                    <Autocomplete
                        className="tags-standard"
                        options={row && row.giftList && row.giftList.length > 0 ? row.giftList : []}
                        getOptionLabel={(option) => option ? (option?.gift_name) : ''}
                        value={row && row.gift_id && row.giftList && row.giftList.length > 0 ?
                            row.giftList.filter(item => item.gift_id == row.gift_id)[0] : []}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.gift_id != -1) {
                                props.giftChange(newValue, index)
                            } else if (newValue && newValue.gift_id == -1) {
                                let vendorInfo = vendorList.filter(item => item.vendor_id == row.vendor_id)[0]
                                props.toggleGiftModal(row, index, vendorInfo)
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Gifts"
                                margin="normal" />
                        )}
                    />
                </TableCell>
                <TableCell>
                    {'$' + row.gift_price}
                    {/* {row && row.gift_price ? '$' + row.gift_price : ''} */}
                </TableCell>
                <TableCell><GroupedButtons row={row} index={index}
                    quantity={row.quantity ? row.quantity : 1} counterCallback={counterCallback} /></TableCell>
                <TableCell>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {'$' + row.distribution_amount}
                </TableCell>
                <TableCell>
                    <Autocomplete multiple size="small" autoHighlight
                        limitTags={1}
                        style={{ width: 200 }}
                        className="tags-standard"
                        options={giftRecipients && giftRecipients.length > 0 ? giftRecipients : []}
                        value={row && row.recipients && row.recipients.length > 0 ?
                            row.recipients : []}
                        getOptionLabel={(option) => option ? (option?.first_name + '  ' + option?.last_name) : ''}
                        onChange={(event, newValue) => {
                            props.recipientChange(newValue, index)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Recipients"
                                margin="normal" />
                        )}
                    />
                </TableCell>
                <TableCell>
                    <MDBBtn color="primary" size='small' className='pt6' onClick={(event) => {
                        event.preventDefault();
                        props.toggleMessageModal(index, row);
                    }}>
                        <MDBIcon icon="rocket-chat" className="mr-1"
                        /> Gift Message
                            </MDBBtn>
                </TableCell>
                <TableCell>
                    <TableCell><DeleteIcon style={{ fontSize: 20 }} className="ML2" key={index}
                        onClick={(event) => {
                            event.preventDefault();
                            props.removeGift(index);
                        }} /></TableCell>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}



class GiftsGridTable extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            giftsGridData: props.giftsGridData && props.giftsGridData.length > 0 ? props.giftsGridData[0] : [],
            vendorsList: props.vendorsList,
            vendorGiftsList: [],
            giftRecipients: props.giftRecipients,
            gifters: props.giftsGridData && props.giftsGridData.length > 0 ? props.giftsGridData : [],
            vendorModal: false,
            giftModal: false,
            messageModal: false,
            recordIndex: 0,
            rowSelected: null,
            vendorSelected: null,
            giftRowSelected: null,
            giftMessage: '',
            openValidation: false,
            displayMessage: [],
            disabled: props.disabled ? props.disabled : false
        }
        this.vendorNameRef = React.createRef();
        this.giftNameRef = React.createRef();
        this.PriceRef = React.createRef();
    }
    toggleValidation = () => {
        this.setState({ openValidation: !this.state.openValidation });
    }
    loadGifts(vendorId) {
        let GetGiftVendors = Constants.GifterServiceBaseUrl + '/vendorGifts?vendorId=' + vendorId;
        GifterService.getData(GetGiftVendors)
            .then((response) => {
                console.log("Gift Service started for Vendor Gifts: " + response.data.records);
                this.setState({
                    vendorGiftsList: response.data.records
                });
            },
                (err) => {
                    console.log("Gift Service error for Vendor Gifts: " + err);
                });
    }
    toggleVendorModal = (index, row) => {
        if (row) {
            this.setState({
                vendorModal: !this.state.vendorModal,
                rowSelected: row,
                recordIndex: index,
                giftRowSelected: row,
            });
        } else { this.setState({ vendorModal: !this.state.vendorModal }) }
    };
    toggleMessageModal = (index, row) => {
        if (row) {
            this.setState({
                messageModal: !this.state.messageModal,
                rowSelected: row,
                recordIndex: index,
                giftMessage: row.gift_message ? row.gift_message : ''
            });
        } else { this.setState({ messageModal: !this.state.messageModal }) }
    };
    addVendor = () => {
        let newVendorName = this.vendorNameRef.current.value;
        if (newVendorName == '') {
            this.setState({
                displayMessage: ['Vendor Name is Required']
            });
            this.toggleValidation();
            return
        }
        if (this.state.vendorsList && this.state.vendorsList.length > 0) {
            if (newVendorName && newVendorName != '') {
                let existingVendor = this.state.vendorsList.filter(item => item.name == this.vendorNameRef.current.value)?.length > 0 ? true : false;
                if (existingVendor) {
                    this.setState({
                        displayMessage: ['A Vendor With Same Name Already Exists']
                    });
                    this.toggleValidation();
                    return
                } else {
                    this.toggleVendorModal(0, null);
                }
            }
        } else {
            this.toggleVendorModal(0, null);
        }
        let giftIndex = this.state.recordIndex;
        let vendor = {
            "vendor_id": null,
            "name": newVendorName,
            "address": null,
            "phone": null,
            "email": null,
            "notes": null,
            "is_active": 1
        }
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/vendorAdd', vendor)
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    let tenantId = localStorage.getItem("active_tenant_id");
                    let GetVendors = Constants.GifterServiceBaseUrl + '/vendors?tenantId=' + tenantId;
                    GifterService.getData(GetVendors)
                        .then(response => {
                            let vendorOption = { 'name': 'Add Vendor', vendor_id: -1 }
                            let vendorList = response && response.data ? response.data : [];
                            vendorList.splice(0, 0, vendorOption)
                            this.setState({ vendorsList: vendorList });

                            let newVendor = vendorList.filter(item => item.name == newVendorName)[0]

                            let giftOption = { 'gift_name': '', gift_id: 0, price: 0 }
                            let vendorInfo = { 'vendor_id': newVendor.vendor_id, name: newVendor.name }
                            this.setNewAdd([], this.state.recordIndex, vendorInfo, giftOption);
                        },
                            (err) => {
                                console.log("Load Divisions Error-->" + err);
                            })
                }
            },
                (err) => {
                    console.log("Vendor Add error: " + err);
                });
    };
    addGift = () => {
        let giftIndex = this.state.recordIndex;
        let giftName = this.giftNameRef.current.value;
        let price = this.PriceRef.current.value && this.PriceRef.current.value != '' ? this.PriceRef.current.value
            : 0;

        //  let price = this.PriceRef.current.value && this.PriceRef.current.value != '' ? this.PriceRef.current.value : 0;
        if (giftName == '' || price == '' || price < 0) {
            this.setState({
                displayMessage: ['Values for Gift Name and Price are Required']
            });
            this.toggleValidation();
            return
        }
        if (this.state.giftRowSelected && this.state.giftRowSelected.giftList?.length > 0) {
            if (giftName && giftName != '') {
                let existingGift = this.state.giftRowSelected.giftList.filter(item => item.gift_name == giftName)?.length > 0 ? true : false;
                if (existingGift) {
                    this.setState({
                        displayMessage: ['A Gift With Same Name Already Exists For the Selected Vendor']
                    });
                    this.toggleValidation();
                    return
                } else {
                    this.toggleGiftModal(null, 0, null);
                }
            }
        } else {
            this.toggleGiftModal(null, 0, null);
        }
        let gift = {
            "vendor_id": this.state.giftRowSelected.vendor_id,
            "name": this.state.giftRowSelected.vendor_name,
            "is_active": 1,
            "address": this.state.vendorSelected?.address,
            "phone": this.state.vendorSelected?.phone,
            "email": this.state.vendorSelected?.email,
            "notes": this.state.vendorSelected?.notes,
            "gifts":
                [
                    {
                        "gift_id": null,
                        "gift_name": giftName,
                        "price": Number(price),
                        "is_active": 1,
                    }
                ]
        }
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/vendorAdd', gift)
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    let GetGiftVendors = Constants.GifterServiceBaseUrl + '/vendorGifts?vendorId=' + this.state.giftRowSelected.vendor_id;
                    GifterService.getData(GetGiftVendors)
                        .then((response) => {
                            debugger;
                            let data = response.data && response.data.length > 0 ? response.data[0].gifts : []
                            data = data && data.length > 0 ? data.filter(item => item.is_active == 1) : data
                            let newGift = data.filter(item => item.gift_name == giftName)[0]
                            let giftOption = { 'gift_name': 'Add Gift', gift_id: -1 }
                            let vendorInfo = { 'vendor_id': this.state.giftRowSelected.vendor_id, name: this.state.giftRowSelected.vendor_name }
                            data.splice(0, 0, giftOption)
                            this.setNewAdd(data, this.state.recordIndex, vendorInfo, newGift);
                        });
                }
            },
                (err) => {
                    console.log("Gift Add error: " + err);
                });
    };

    toggleGiftModal = (row, index, vendor) => {
        if (row) {
            this.setState({
                giftModal: !this.state.giftModal,
                rowSelected: row,
                recordIndex: index,
                giftRowSelected: row, vendorSelected: vendor
            });
        } else { this.setState({ giftModal: !this.state.giftModal }) }
    };
    refreshGiftDetails = (rowGifts, index, newValue) => {
        let gifts = this.state.giftsGridData ? this.state.giftsGridData : [];
        let distributions = gifts.distributions;
        distributions[index]['giftList'] = rowGifts;
        distributions[index].gift_id = 0;
        distributions[index].gift_price = 0;
        distributions[index].gift_name = '';
        distributions[index].distribution_amount = 0;
        distributions[index].quantity = 1;
        //vendor Changes
        distributions[index].vendor_id = newValue.vendor_id;
        distributions[index].vendor_name = newValue.name;
        gifts.distributions = distributions;
        this.setState({
            giftsGridData: gifts,
            vendorList: this.state.vendorsList
        });
        this.props.onGiftsChange();
        this.props.calculatePrice();
    }
    setNewAdd = (rowGifts, index, vendorInfo, giftInfo) => {
        let gifts = this.state.giftsGridData ? this.state.giftsGridData : [];
        let distributions = gifts.distributions;
        distributions[index]['giftList'] = rowGifts;
        distributions[index].gift_id = giftInfo.gift_id;
        distributions[index].gift_price = giftInfo.price;
        distributions[index].gift_name = giftInfo.gift_name;
        distributions[index].distribution_amount = Math.round(giftInfo.price * 1);
        distributions[index].quantity = 1;
        //vendor Changes
        distributions[index].vendor_id = vendorInfo.vendor_id;
        distributions[index].vendor_name = vendorInfo.name;
        gifts.distributions = distributions;
        this.setState({
            giftsGridData: gifts,
            vendorList: this.state.vendorsList
        });
        this.props.calculatePrice();
    }
    setGiftMessage = (index, message) => {
        let gifts = this.state.giftsGridData ? this.state.giftsGridData : [];
        let distributions = gifts.distributions;
        distributions[index].gift_message = message;
        gifts.distributions = distributions;
        this.setState({
            giftsGridData: gifts,
        });
        this.props.onGiftsChange();
    }

    giftChange = (newValue, index) => {
        let data = this.state.giftsGridData ? this.state.giftsGridData : [];
        let distributions = data.distributions;
        distributions[index].gift_id = newValue.gift_id;
        distributions[index].gift_name = newValue.gift_name;
        distributions[index].gift_price = newValue.price;
        distributions[index].distribution_amount = newValue.price;
        distributions[index].quantity = 1;
        data.distributions = distributions;
        this.setState({
            giftsGridData: data
        });
        this.props.onGiftsChange();
        this.props.calculatePrice();
    }
    recipientChange = (newValue, index) => {
        if (newValue) {
            let data = this.state.giftsGridData ? this.state.giftsGridData : [];
            let distributions = data.distributions;
            newValue.forEach(item => {
                if (!item.gift_recipient_id || item.gift_recipient_id == 0) {
                    item['gift_recipient_id'] = null;
                } if (!item.gift_distribution_id || item.gift_distribution_id == 0) {
                    item['gift_distribution_id'] = null;
                } if (!item.gift_distribution_recipients_id || item.gift_distribution_recipients_id == 0) {
                    item['gift_distribution_recipients_id'] = null;
                }
            })
            var dupResults = [...newValue];
            //  dupResults[current] = duplicateData;
            

            distributions[index].recipients = dupResults;
            data.distributions = distributions;
            this.props.onGiftsChange();
            this.setState({
                giftsGridData: data
            });
        }
    }
    onGiftsChange = () => {
        this.props.onGiftsChange();
    }
    setInitialGift = (row, index) => {
        let gifts = this.state.giftsGridData.distributions;
        if (!gifts[index]['giftList'] || (gifts[index]['giftList'] && gifts[index]['giftList'].length == 0)) {
            if (row.vendor_id) {
                let GetGiftVendors = Constants.GifterServiceBaseUrl + '/vendorGifts?vendorId=' + row.vendor_id;
                GifterService.getData(GetGiftVendors)
                    .then((response) => {
                        debugger;
                        let data = response.data && response.data.length > 0 ? response.data[0].gifts : []
                        data = data && data.length > 0 ? data.filter(item => item.is_active == 1) : data
                        let giftOption = { 'gift_name': 'Add Gift', gift_id: -1 }
                        data.splice(0, 0, giftOption)
                        // const data = response.data[0].gifts
                        gifts[index]['giftList'] = data;
                        gifts[index].vendor_id = row.vendor_id;
                        let giftsGridData = this.state.giftsGridData;
                        giftsGridData.distributions = gifts;
                        this.setState({
                            giftsGridData: giftsGridData
                        })
                    });
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            giftsGridData: nextProps.giftsGridData && nextProps.giftsGridData.length > 0 ? nextProps.giftsGridData[0] : [],
            vendorsList: nextProps.vendorsList,
            giftRecipients: nextProps.giftRecipients,
            gifters: nextProps.giftsGridData || [],
        });
    }
    counterCallback = (row, index, value, fromGitChange = false) => {
        if (value) {
            let data = this.state.giftsGridData ? this.state.giftsGridData : [];
            let distributions = data.distributions;
            distributions[index].distribution_amount = Math.round(value * distributions[index].gift_price);
            distributions[index].quantity = value;
            data.distributions = distributions;
            this.setState({
                giftsGridData: data
            })
            this.props.calculatePrice();
        }

    }
    deleteAllGifters = () => {
        this.setState({
            giftsGridData: []
        })
        this.props.deleteAllGifts();
    }
    removeGift = (index) => {
        let data = this.state.giftsGridData;
        let distributors = data.distributions;
        distributors.splice(index, 1);
        data.distributions = distributors
        this.setState({
            giftsGridData: data
        })
        this.props.onGiftsChange();
        this.props.calculatePrice();
    }
    calculatePrice = () => {
        this.props.calculatePrice();
    }
    _handlePriceTextFieldChange = (event, newValue) => {
        event.preventDefault();
        let val = Number(event.target.value);
        let rec = this.state.gifters;
        if (event.target.id == 'tax') {
            rec[0].gift_tax = Math.round(val * 100) / 100;
        }
        if (event.target.id == 'othercost') {
            rec[0].gift_other_cost = Math.round(val * 100) / 100;
        }
        if (event.target.id == 'shipment') {
            rec[0].gift_shipping_delivery_cost = Math.round(val * 100) / 100;
        }
        this.setState({
            gifters: rec,
        }, () => {this.props?.onGiftsChange() })
        this.calculatePrice();
    };
    render() {
        return (
            <>
                <TableContainer component={Paper} disabled={this.state.disabled}>
                    <Table aria-label="collapsible table">
                        <TableHead id="tblHead">
                            <TableRow>
                                <TableCell className="FW800 W10 TLC">Vendor</TableCell>
                                <TableCell className="FW800 W10 TLC" >Item</TableCell>
                                <TableCell className="FW800 W2 TLC" >Price</TableCell>
                                <TableCell className="FW800 W6 TLC">Qty</TableCell>
                                <TableCell className="FW800 W6 TLC">Sub Total</TableCell>
                                <TableCell className="FW800 W6 TLC">Recipient</TableCell>
                                <TableCell className="FW800 W6 TLC">Message</TableCell>
                                <TableCell className="FW800 W8">
                                    <Link href="#" id="expansionPanel" className="FR"
                                        onClick={(event, newValue) => {
                                            event.preventDefault();
                                            this.deleteAllGifters();
                                        }}
                                        color="primary" >
                                        delete all
                                </Link>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (this.state.giftsGridData && this.state.giftsGridData.distributions && this.state.giftsGridData.distributions.length > 0) ?
                                    this.state.giftsGridData?.distributions.map((row, index) => (
                                        <Row row={row} index={index} key={index}
                                            selectedTab={this.state.selectedTab}
                                            vendorList={this.state.vendorsList}
                                            refreshGiftDetails={this.refreshGiftDetails}
                                            setInitialGift={this.setInitialGift}
                                            counterCallback={this.counterCallback}
                                            giftChange={this.giftChange}
                                            recipientChange={this.recipientChange}
                                            removeGift={this.removeGift}
                                            calculatePrice={this.calculatePrice()}
                                            giftRecipients={this.state.giftRecipients}
                                            toggleGiftModal={this.toggleGiftModal}
                                            toggleVendorModal={this.toggleVendorModal}
                                            toggleMessageModal={this.toggleMessageModal}
                                            onGiftsChange={this.onGiftsChange}
                                        />
                                    )) : null}

                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.gifters && this.state.gifters.length > 0
                    ? <> <MDBCard id="giftCard" className='mb-3' style={{ height: '100%' }} disabled={this.state.disabled}>
                        <div class='conatiner'>
                            <div class="row">
                                <div class="col-lg-2 P2">
                                    <lable className='costLabel'>Total Gift Cost</lable>
                                    <br />
                                    <label className='priceLable'>$ {this.state.gifters ? this.state.gifters[0].gift_total_cost : 0}</label>
                                </div>
                                <div class="col-lg-2 P2">
                                    <lable className='costLabel'>Tax</lable>
                                    <br />
                                    <Input
                                        id="tax" type='number'
                                        value={this.state.gifters && this.state.gifters[0].gift_tax != 0 ? this.state.gifters[0].gift_tax : null}
                                        onChange={this._handlePriceTextFieldChange.bind(this)}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                </div>
                                <div class="col-lg-3 P2">
                                    <lable className='costLabel'>Shipping/Delivery</lable>
                                    <br />
                                    <Input
                                        id="shipment" type='number'
                                        value={this.state.gifters && this.state.gifters[0].gift_shipping_delivery_cost != 0 ? this.state.gifters[0].gift_shipping_delivery_cost : null}
                                        onChange={this._handlePriceTextFieldChange.bind(this)}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                </div>
                                <div class="col-lg-2 P2">
                                    <lable className='costLabel'>Other Costs</lable>
                                    <br />
                                    <Input
                                        id="othercost" type='number'
                                        value={this.state.gifters && this.state.gifters[0].gift_other_cost != 0 ? this.state.gifters[0].gift_other_cost : null}
                                        onChange={this._handlePriceTextFieldChange.bind(this)}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                </div>
                                <div class="col-lg-2 P2">
                                    <lable className='costLabel'>Total Price</lable>
                                    <br />
                                    <label className='priceLable'>$ {this.state.gifters ? this.state.gifters[0].gift_total_price : 0}</label>
                                </div>
                            </div>
                        </div>
                    </MDBCard>

                    </> : null}

                <>
                    <MDBModal isOpen={this.state.vendorModal} toggle={this.toggleVendorModal} >
                        <MDBModalHeader
                            className="text-left"
                            titleClass="w-100 font-weight-bold"
                            toggle={this.toggleModal}>
                            New Vendor
                        </MDBModalHeader>
                        <MDBModalBody >
                            {/* <TextField id="outlined-basic" className="col-md-12" label="Name:" variant="outlined" size="small" /> */}
                            <input type='text' placeholder='Vendor Name' ref={this.vendorNameRef}></input>
                        </MDBModalBody>
                        <MDBModalFooter className="justify-content-center">
                            <button type="button" onClick={() => {
                                //this.toggleVendorModal(0, null);
                                this.addVendor();
                            }}>
                                Add
                            </button>
                            <button type="button" class='primary' onClick={() => {
                                this.toggleVendorModal(0, null);
                            }}>
                                Close
                            </button>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.giftModal} toggle={this.toggleGiftModal}>
                        <MDBModalHeader
                            className="text-left"
                            titleClass="w-100 font-weight-bold"
                            toggle={this.toggleGiftModal}>
                            New Gift Item
                        </MDBModalHeader>
                        <MDBModalBody >
                            <input type='text' placeholder='Gift Name' ref={this.giftNameRef}></input>
                            <br />
                            <input type='number' placeholder='Gift Price $' ref={this.PriceRef}></input>
                        </MDBModalBody>
                        <MDBModalFooter className="justify-content-center">
                            <button type="button" onClick={() => {
                                // this.toggleGiftModal(null, 0, null);
                                this.addGift();
                            }} >
                                Add
                        </button>
                            <button type="button" class='primary' onClick={() => {
                                this.toggleGiftModal(null, 0);
                            }}>
                                Close
                            </button>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.messageModal} toggle={this.toggleMessageModal} >
                        <MDBModalHeader
                            className="text-left"
                            titleClass="w-100 font-weight-bold"
                            toggle={this.toggleMessageModal}>
                            Gift Message
                        </MDBModalHeader>
                        <MDBModalBody >
                            <textarea class="textareabox"
                                id="eventAddress" rows="10" cols='5'
                                placeholder='Gift Message' value={this.state.giftMessage} onChange={(event) => {
                                    this.setState({ giftMessage: event.target.value })
                                }}
                            //value={this.state.distributionAddress}
                            ></textarea>
                        </MDBModalBody>
                        <MDBModalFooter className="justify-content-center">
                            <button type="button" onClick={() => {
                                this.toggleMessageModal(0, null);
                                this.setGiftMessage(this.state.recordIndex, this.state.giftMessage);
                            }}>
                                Add
                            </button>
                            <button type="button" class='primary' onClick={() => {
                                this.toggleMessageModal(0, null);
                            }}>
                                Close
                            </button>
                        </MDBModalFooter>
                    </MDBModal>
                    <GiftValidation isOpen={this.state.openValidation} toggleValidation={this.toggleValidation}
                        displayMessage={this.state.displayMessage} />
                </>
            </>
        );
    }
}

export default GiftsGridTable;