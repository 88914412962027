import React, { useState, useEffect } from "react";
import { MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal, MDBContainer, MDBIcon, MDBBtn }
    from "mdb-react-ui-kit";
import Divider from '@material-ui/core/Divider';
import { Component } from "react";
import { withRouter } from 'react-router-dom';
import QueryData from './QueryData';
import ExportDetails from './ExportDetails';
import MessageCards from './MessageCards';
import Envelopes from './Envelopes';
import AveryLabels from './AveryLabels';

class MaterialMainContent extends Component {
    _isMounted = false;
    container = null;
    constructor(props) {
        super(props);      

    }

    handleSideNavActive = (value, routePath) => {
        this.setState({
            activeTab: value
        } );
        this.props.history.push(routePath, this.state);
    }

    toggleMaterialContent = () =>{
        this.props.toggleMaterialContent();
    }


    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
        }
    }

    render() {       
            
                if(this.props.activeTab === "EX") {
                    return (
                        <div >
                            <ExportDetails fromPage= {this.props.fromPage}
                            id={this.props.id}
                            name={this.props.name}
                            toggleMaterials = {this.props.toggleMaterials}>

                            </ExportDetails>
                        </div>
                    );
                }
                if(this.props.activeTab === "QD") {
                    return (
                        <div >
                            <QueryData fromPage= {this.props.fromPage}
                            id={this.props.id}
                            name={this.props.name}
                            toggleMaterials = {this.props.toggleMaterials}
                            >
                            </QueryData>
                        </div>
                    );
                }   
                if(this.props.activeTab === "MC") {
                    return (
                        <div >
                            <MessageCards fromPage= {this.props.fromPage}
                            recipients = {this.props.recipients}
                            senders={this.props.senders}                                    
                            gifters={this.props.gifters}
                            id={this.props.id}
                            name={this.props.name}
                            toggleMaterials = {this.props.toggleMaterials}
                            giftMessage = {this.props.giftMessage}
                            >

                            </MessageCards>
                        </div>
                    );
                }
                if(this.props.activeTab === "EV") {
                    return (
                        <div >
                           <Envelopes fromPage= {this.props.fromPage}
                           isDefaultForAllAddress = {this.props.isDefaultForAllAddress}
                           giftAddress = {this.props.giftAddress}
                           recipients = {this.props.recipients}
                           senders={this.props.senders}                                    
                           gifters={this.props.gifters}
                           id={this.props.id}
                           name={this.props.name}
                           toggleMaterials = {this.props.toggleMaterials}>

                           </Envelopes>
                        </div>
                    );
                }   
                if(this.props.activeTab === "AL") {
                    return (
                        <div >
                           <AveryLabels fromPage= {this.props.fromPage}
                           recipients = {this.props.recipients}
                           senders={this.props.senders}                                    
                           gifters={this.props.gifters}
                           id={this.props.id}
                           name={this.props.name}
                           toggleMaterials = {this.props.toggleMaterials}
                           address = {this.props.address}    
                           occassion = {this.props.occassion}
                           >
                           </AveryLabels>

                        </div>
                    );
                }
             
                <MDBBtn onClick={this.toggleMaterialContent.bind(this)}>
                    Close Materials
                </MDBBtn>
        
    }

}

export default MaterialMainContent;