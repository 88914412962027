import React from 'react';
import { userList, lookupPeopleconfigObject } from '../PeopleManagement/MockData/userList';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableComponent from '../Common/Table';
import { MDBIcon } from "mdbreact";
import { MDBBtn } from 'mdb-react-ui-kit';
import GifterService from '../../services/service';
import * as Constants from '../../constants/constants';
import LookupFilter from './LookupFilter';
import './PeopleManagement.scss';
import RecipientAddGrid from '../LookupPeople/RecipientsGrid';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';

class PeopleManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            expanded: 'panel1',
            filterList: {
                company: [],
                division: [],
                category: [],
                title: [],
                status: [{ value: 0, text: "Inactive" }, { value: 1, text: "Active" }],
                customGroup: []
            },
            selectedFilterOptions: {
                company: null,
                division: null,
                category: null,
                title: null,
                status: null,
                customGroup: null
            },
            selectedFilters: {
                company: [],
                division: [],
                category: [],
                title: [],
                status: [{ value: 1, text: "Active" }],
                customGroup: []
            },
            showFilter: true,
            userList: userList,
            peopleList: [],
            formattedPeopleList: [],
            isPeopleListFetchOnProgress: false,
            sortBy: "Name",
            searchTerm: null,
            isSortByAsc: true,
            sortCount: 0,
            configObject: lookupPeopleconfigObject,
            selectAllPeople: false,
            peopleSelection: [],
            isSelectGridReq: true,
            pageName: props.pageName && props.pageName != '' ? props.pageName : '',
            searchCancelToken: null
        };
    }

    componentDidMount() {
        this.fetchPeopleList();
        this.fetchDivisions();
        this.fetchTitles();
        this.fetchCompanies();
        this.fetchCategories();
        this.fetchCustomGroups();
    }
    handleChange = (panel) => (event, newExpanded) => {
        this.setState({ expanded: newExpanded ? panel : false })
    };
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => {
            this.fetchPeopleList();
        });
    }

    fetchDivisions = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/divisions?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((division) => ({ value: division.Division_id, text: division.Division_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        division: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Divisions Error-->" + err);
                })
    }

    fetchTitles = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/titles?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((title) => ({ value: title.title_id, text: title.title_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        title: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Titles Error-->" + err);
                })
    }

    fetchCompanies = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/companies?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((company) => ({ value: company.company_id, text: company.company_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        company: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Companies Error-->" + err);
                })
    }

    fetchCategories = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/categories?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((category) => ({ value: category.category_id, text: category.category_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        category: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Categories Error-->" + err);
                })
    }

    fetchCustomGroups = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/customGroups?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((cgItem) => ({ value: cgItem.custom_group_id, text: cgItem.custom_group_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        customGroup: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load CustomGroup Error-->" + err);
                })
    }

    fetchPeopleList = () => {
        this.setState({ isPeopleListFetchOnProgress: true });
        let option = {
            "tenant_id": 1,
            "division_filters": this.state.showFilter ? this.state.selectedFilterOptions.division : null,
            "company_filters": this.state.showFilter ? this.state.selectedFilterOptions.company : null,
            "category_filters": this.state.showFilter ? this.state.selectedFilterOptions.category : null,
            "title_filters": this.state.showFilter ? this.state.selectedFilterOptions.title : null,
            "custom_group_filters": this.state.showFilter ? this.state.selectedFilterOptions.customGroup : null,
            "status_filters": '1',
            "page_count": this.state.rowsPerPage,
            "page_no": this.state.page,
            "order_by_clause": this.state.sortBy,
            "order_by": this.state.isSortByAsc ? "asc" : "desc",
            "search_filters": this.state.searchTerm ? this.state.searchTerm : null
        }
        console.log("option", option)
        if(this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({searchCancelToken: cancelToken});
        GifterService.getDataParamsWithCancel(Constants.GifterServiceBaseUrl + '/personsAll', option, cancelToken)
            .then((response) => {
                this.setState({ peopleList: response.data, isPeopleListFetchOnProgress: false, selectAllPeople: false }, () => {
                    this.formatPeopleList();
                });
            },
                (err) => {
                    this.setState({ isPeopleListFetchOnProgress: false });
                    console.log("People Management grid service error: " + err);
                });

    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
            this.fetchPeopleList();
        });
    }

    handleSearch = (event) => {
        this.setState({ searchTerm: event.target.value, page: 0 }, () => {
            this.fetchPeopleList();
        });
    }

    handleFilterChange = (event, value, field) => {
        let options = value?.length !== 0 ? value.map(item => item.value).join(',').toString() : null;
        this.setState(prevState => ({
            selectedFilterOptions: {
                ...prevState.selectedFilterOptions,
                [field]: options
            },
            selectedFilters: {
                ...prevState.selectedFilters,
                [field]: value
            }
        }), () => {
            this.fetchPeopleList();
        })
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (sortBy) => {
        let configObject = this.state.configObject;
        this.setState({ isSortByAsc: this.state.sortCount % 2 === 0 ? true : false }, () => {
            let newHeadingArray = configObject.headings.map(item => {
                if (item.headingLabel === sortBy) {
                    item.icon = this.state.isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
                } else if (sortBy !== this.state.sortBy && item.headingLabel === this.state.sortBy) {
                    item.icon = "";
                }
                return item;
            });
            delete configObject.headings
            configObject.headings = newHeadingArray;
            this.setState({ sortBy: sortBy, page: 0, configObject: configObject }, () => {
                this.fetchPeopleList();
            })
        })
    }

    handleClearFilter = () => {
        let selectedFilterOptions = {
            company: null,
            division: null,
            category: null,
            title: null,
            status: null,
            customGroup: null
        };
        let selectedFilters = {
            company: [],
            division: [],
            category: [],
            title: [],
            status: [],
            customGroup: []
        }
        this.setState({
            selectedFilterOptions: selectedFilterOptions, selectedFilters: selectedFilters,
            peopleSelection: [], selectAllPeople: false, page: 0,
            rowsPerPage: 10,
        }, () => {
            this.fetchPeopleList();
        });
    }

    handleShowFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    }

    handleOnEditClick = (personId) => {
        this.handleNavigation('/editPerson/' + personId, 'EP');
    }
    selectAllCallback = (event, selection) => {
        let lst = this.state.formattedPeopleList;
        lst.map((node, index) => (node.check = event.target.checked))
        if (event.target.checked) {
            let records = this.state.peopleSelection;
            if (records.length > 0) {
                lst.map(item => {
                    records.push(item);
                });
            } else { records = lst }
            records = this.mergeDuplicates(records);
            this.setState({ peopleSelection: records });
        }
        this.setState({ selectAllPeople: event.target.checked, formattedPeopleList: lst });
    }
    arrayCheckUncheck = (event, row) => {
        let records = this.state.peopleSelection;
        if (event.target.checked) {
            records.push(row);
        } else {
            var removeIndex = records.map(item => item.person_id).indexOf(row.person_id);
            if (removeIndex != -1)
                records.splice(removeIndex, 1)
        }
        records = this.mergeDuplicates(records);
        let selectAll = this.state.formattedPeopleList.filter(item => !item.check).length > 0 ? false : true;
        this.setState({ peopleSelection: records, selectAllPeople: selectAll });
    }
    deleteSelection = (row) => {
        let records = this.state.peopleSelection;
        var removeIndex = records.map(item => item.person_id).indexOf(row.person_id);
        if (removeIndex != -1)
            records.splice(removeIndex, 1)
        this.setState({ peopleSelection: records });
    }
    deleteAll = () => {
        this.setState({ peopleSelection: [] });
    }

    handleNavigation = (path, value) => {
        this.props.history.push(path, value);
    }

    formatPeopleList = () => {
        let formattedList = this.state.peopleList.records.map(list => {
            return {
                "person_id": list.person_id,
                "name": list.first_name + " " + list.last_name,
                "email": list.email,
                "company_name": list.company_name,
                "division_name": list.division_name,
                "category_name": list.category_name,
                "title_name": list.title_name,
                "is_active": list.is_active ? "Active" : "Inactive",
                "check": false,

                //By Ram for Adding to Parent list
                "event_invitee_id": null,
                "gift_event_card_id": null,
                "first_name": list.first_name,
                "last_name": list.last_name,
                "person_category_id": list.person_category_id,
                "person_id": list.person_id,
                "category_id": list.category_id,
                "p_comp_div_title_id": list.p_comp_div_title_id,
                "company_id": list.cmpany_id,
                "division_id": list.division_id,
                "title_id": list.title_id,
                "person_extension_id": list.person_extension_id,
                "person_phone_id": list.person_phone_id,
                "person_email_id": list.person_email_id,
                "guests": []
            }
        })
        this.setState({ formattedPeopleList: formattedList });
    }

    addPeople = () => {
        let people = this.state.peopleSelection;
        people = this.mergeDuplicates(people);
        this.props.addPeople(people)
    }
    mergeDuplicates = (data) => {
        let filteredArr = data.reduce((acc, current) => {
            const x = acc.find(item => item.person_id === current.person_id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        return filteredArr
    }
    closePopup = () => {
        this.handleClearFilter();
        this.props.toggleLookupPeople()
    }

    render() {
        let list = this.state.userList?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return (<>
            {/* <div>
                <h6 class="modal-title" id="exampleModalLabel">{this.state.pageName}</h6>
                <span className="flexAlignRight MNN175pt">
                    <MDBIcon size="2x" icon="times-circle" onClick={this.closePopup}></MDBIcon>
                </span>
            </div> */}
            <div className="peopleManagement" >
                {/* <div><h5>{this.state.pageName}</h5></div> */}
                <div className="userTableHeaderContainer">
                    <div id="userTableHeader">
                        <div className="row">
                            {/* <div className="col-2" id="search"><TextField label="Search Name:" variant="outlined" 
                            onChange={(event) => this.handleSearch(event)} /></div> */}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.state.showFilter ?
                                        <LookupFilter filterList={this.state.filterList}
                                            selectedFilters={this.state.selectedFilters}
                                            handleFilterChange={this.handleFilterChange}
                                            handleClearFilter={this.handleClearFilter}
                                            handleSearch={this.handleSearch}
                                            addPeople={this.addPeople} /> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='userTable'>
                            <Accordion square
                                expanded={this.state.expanded === 'panel1'}
                                onChange={this.handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <h5>Search Results</h5>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableComponent
                                        list={this.state.formattedPeopleList}
                                        config={this.state.configObject}
                                        isLoading={this.state.isPeopleListFetchOnProgress}
                                        sortCallback={this.handleTableColumnClick}
                                        editCallback={this.handleOnEditClick}
                                        selectAllCallback={this.selectAllCallback}
                                        arrayCheckUncheck={this.arrayCheckUncheck}
                                        isSelectGridReq={this.state.isSelectGridReq}
                                        selectAllPeople={this.state.selectAllPeople}
                                        peopleSelection={this.state.peopleSelection}
                                        isSelectGridReq={this.state.isSelectGridReq} />
                                    <TablePagination
                                        component="div"
                                        count={this.state.peopleList?.query_record_count ? this.state.peopleList.query_record_count : 0}
                                        rowsPerPageOptions={[10, 25, 50, 100, 200]}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion square expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <h5>Selection Results</h5>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.isSelectGridReq ?
                                        <> <div className="tableContainer">
                                            <RecipientAddGrid peopleSelection={this.state.peopleSelection}
                                                deleteSelection={this.deleteSelection}
                                                deleteAll={this.deleteAll}></RecipientAddGrid>
                                        </div></> : null}
                                </AccordionDetails>
                            </Accordion>
                            <div className='FR M1'>
                                <MDBBtn color="primary" className='MR1' onClick={(event, newValue) => {
                                    this.addPeople();
                                    this.handleClearFilter();
                                }}>Add</MDBBtn>
                                <MDBBtn color="secondary" onClick={(event, newValue) => {
                                    this.handleClearFilter();
                                    this.props.toggleLookupPeople()
                                }}>Close</MDBBtn>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default PeopleManagement;