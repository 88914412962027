import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid grey',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    label: {
        fontSize: '0.8rem'
    }
}));

const filter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

export default function AddOrEditModal(props) {
    const classes = useStyles();
    return (
        <div className="addEditContainer">
            <Modal
                open={props?.open}
                onClose={props?.handleClose}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <h6><b>{props.modalMode + " " + props.activeTabDisplayName}</b></h6>
                    <div className="row">
                        <div className="col-3">
                            <label className={classes.label}>{props.activeTabDisplayName}</label>
                        </div>
                        <div className="col-9">
                            <Autocomplete
                                id={props.activeTabName}
                                options={props?.dropDownList}
                                value={props.activeTabEditObject}
                                freeSolo
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option ? option : "";
                                    } else
                                        if (option?.inputValue) {
                                            return option.inputValue;
                                        } else {
                                            if (option?.text?.includes("Add '")) {
                                                option.text = option.text?.match(/'([^']+)'/)[1];
                                            }
                                            return option.text ? option.text : "";
                                        }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params?.inputValue !== '') {
                                        filtered.push({
                                            value: params.inputValue,
                                            text: `Add '${params.inputValue}'`,
                                        });
                                    }

                                    return filtered;
                                }}
                                style={{ width: '100%' }}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        props.handleEdit(newValue, "autocomplete")
                                    } else if (newValue && newValue?.inputValue) {
                                        props.handleEdit(newValue.inputValue, "autocomplete")
                                    } else {
                                        if (newValue?.text?.includes("Add '")) {
                                            newValue.text = newValue.text.match(/'([^']+)'/)[1];
                                        }
                                        props.handleEdit(newValue, "autocomplete")
                                    }
                                }}
                                renderOption={(option) => option.text}
                                renderInput={(params) => <TextField {...params} variant="outlined" onChange={(e) => props.handleEdit(e.target.value, "name")} />}
                            />
                        </div>
                    </div>
                    {
                        props?.activeTabName !== "Shows" && props?.activeTabName !== "Occasions" && (
                            <div className="row">
                                <div className="col-3">
                                    <label className={classes.label}>Status</label>
                                </div>
                                <div className="col-9">
                                    <Select
                                        id="is_active"
                                        value={props.activeTabEditObject.is_active}
                                        onChange={(event) => props.handleEdit(event.target.value, "status")}
                                        disableUnderline
                                    >
                                        <MenuItem value={1}>Active</MenuItem>
                                        <MenuItem value={0}>Inactive</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        )
                    }
                    <div className="row" id="buttonRow">
                        <div className="col-6">
                            <Button variant="contained" size="small" color="primary" onClick={() => props?.handleSave()} disabled={props?.isPosting || props?.isSaveDisabled}>{props?.isPosting ? <CircularProgress size={22} /> : "Save"}</Button>
                        </div>
                        <div className="col-6">
                            <Button variant="contained" size="small" color="primary" onClick={() => props?.handleClose()}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
