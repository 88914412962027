
import React from 'react';

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';


class ConfirmDialog extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
  }


  //****Do this logic in the calling component******///
  //   confirmCancelChanges = (isCancel) => {
  //     if(isCancel)       
  //     this.props.togglePreviewImport();

  //     this.setState({confirmSaveChanges : !this.state.confirmSaveChanges});
  // }

  render() {
    return (
      <>

        {/* <MDBPopcofirm btnClassName="d-none" modal isOpen={this.props.isOpen} >
                  <MDBPopconfirmMessage>Are you sure you want to cancel the excel import?</MDBPopconfirmMessage>
                  <MDBPopconfirmButtons>
                      <MDBBtn color='float' size='sm' onClick={this.props.confirmCancelChanges.bind(this, false)}>
                        No
                      </MDBBtn>
                      <MDBBtn size='sm' onClick={this.props.confirmCancelChanges.bind(this, true)}>
                        Yes
                      </MDBBtn>
                  </MDBPopconfirmButtons>
              </MDBPopcofirm>  */}

        <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props.isOpen} >
          {/* getOpenState={(e) =>  this.setState({ isOpen: e })}> */}
          <MDBModalDialog size="sm" >
            <MDBModalContent>
              {/* <MDBModalHeader>
                      <MDBModalTitle>Modal title</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.toggleImportPeople}></MDBBtn>
                    </MDBModalHeader> */}
              <MDBModalBody>
                {/* <div className="flexBox-plain padding">
                      <div className="flexAlignRight">                        
                          <MDBIcon size="2x" icon="times-circle" onClick={this.props.toggleImportPeople}></MDBIcon>                          
                      </div>                      
                    </div> */}
                <div className="flexBox-plain padding">
                  {this.props.displayMessage}
                </div>
                <div className="flexAlignRight padding">
                  <div className="padding-hor">
                    <MDBBtn color='float' size='sm' onClick={this.props.confirmCancelChanges.bind(this, false)}>
                      No
                        </MDBBtn>
                  </div>
                  <div className="padding-hor">
                    <MDBBtn size='sm' onClick={this.props.confirmCancelChanges.bind(this, true)}>
                      Yes
                        </MDBBtn>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    )
  }

}

export default ConfirmDialog;