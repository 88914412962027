import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBFormInline } from "mdbreact";
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
    MDBTabsPane, MDBTabsContent, MDBDropdown, MDBSelect, MDBBtn
} from 'mdb-react-ui-kit';
import '../Cards/Cards.scss';
import CreateIcon from '@material-ui/icons/Create';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants.js';
import TextField from '@material-ui/core/TextField';
import {
    Grid,
    Card,
    CardHeader,
} from "@material-ui/core/";
import FormElement from '../../Form/Form';
import { MDBDataTableV5, MDBInput } from 'mdbreact';
import DeleteIcon from '@material-ui/icons/Delete';
import Import from '../Import/Import';
import Materials from '../Materials/Materials';
import LookupModal from '../LookupPeople/LookupModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Prompt } from 'react-router-dom';
import { MDBModal, MDBModalBody } from 'mdbreact';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Tooltip from '@material-ui/core/Tooltip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

class CardsPage extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            cardId: props?.match?.params?.cardId,
            divisions: null,
            existingCards: [],
            cardName: '', distributionAddress: '',
            cardRecordName: [], cardRecordRecipients: [], cardRecordSigners: [],
            datatable: [],
            isKeepChangesToSigners: false,
            originalDataset: [],
            signersData: [],
            isSortByAsc: false,
            filterVisibility: false,
            showImportPeople: false,
            showMaterials: false,
            showLookupPeople: false,
            isBlocked: false,
            page: 0,
            rowsPerPage: 10,
            recipientToAddBySearch: [],// props.recipientToAddBySearch,
            cardSignersList: [],
            companiesList: [], titlesList: [],
            formErrors: { cardName: '', Division: '', distributionAddress: '' },
            formValid: false,
            cardNameValid: false,
            divisionValid: false,
            distributionAddressValid: false,
            loaderOpen: false, loader: false,
            filter: { byName: '', byCompany: [], byTitle: [] },
            disabled: props?.match?.path.indexOf('viewcard') != -1 ? true : false,
            modal: false,
            data: {},
            deleteAll: false,
            isCopy: props?.match?.params?.copyId == 1 ? true : false
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loaderOpen: true
        })
        this.fetchData();
    }
    fetchData = async () => {
        let cardId = this.state.cardId;
        let tenantId = localStorage.getItem("active_tenant_id");
        let GetCardRecordHeader = Constants.GifterServiceBaseUrl + '/cardHeader?cardRecordId=' + cardId;
        let GetCardRecordNames = Constants.GifterServiceBaseUrl + '/cardRecordNames?tenantId=' + tenantId;
        let GetCardRecordRecipients = Constants.GifterServiceBaseUrl + '/cardRecipients?cardRecordId=' + cardId;
        let GetLogginUsers = Constants.GifterServiceBaseUrl + '/loggedInUser';
        let GetCardSignerList = Constants.GifterServiceBaseUrl + '/cardSignerList?tenantId=' + tenantId;
        let GetCompanies = Constants.GifterServiceBaseUrl + '/companiesAll?tenantId=' + tenantId;
        let GetTitles = Constants.GifterServiceBaseUrl + '/titles?tenantId=' + tenantId;
        let urls = [];
        let distributionAddress = '';
        let cardName = '';
        let divs = null;
        let divisionValid = false;
        let cardNameValid = false;
        let distributionAddressValid = false;
        urls = [GetLogginUsers, GetCardRecordHeader, GetCardRecordNames, GetCardRecordRecipients,
            //GetActivePersons, 
            GetCardSignerList, GetCompanies, GetTitles]
        try {
            const res = await Promise.all(urls.map(url =>
                GifterService.getData(url)));
            const data = res.map((res) => res.data);
            if (data && data.length > 0) {
                let accessmodules = null;
                let accessRoles = data[0][0].tenants.filter(item => item.tenant_id == tenantId)[0].access_roles;
                if (accessRoles && accessRoles.length > 0) {
                    let eventAccessModules = accessRoles.filter(item => item.module_name == 'Events')?.length > 0 ? accessRoles.filter(item => item.module_name == 'Events') : [];
                    let giftAccessModules = accessRoles.filter(item => item.module_name == 'Gifts')?.length > 0 ? accessRoles.filter(item => item.module_name == 'Gifts') : [];
                    if (eventAccessModules.length > 0 && giftAccessModules.length == 0) {
                        accessmodules = eventAccessModules[0];
                        divs = accessmodules.divisions
                    } else if (eventAccessModules.length == 0 && giftAccessModules.length > 0) {
                        accessmodules = giftAccessModules[0];
                        divs = accessmodules.divisions
                    } else if (eventAccessModules.length > 0 && giftAccessModules.length > 0) {
                        var finalResult = eventAccessModules[0].divisions.concat(giftAccessModules[0].divisions.filter(({ org_unit_id }) => !eventAccessModules[0].divisions.find(p => p.org_unit_id == org_unit_id)));
                        divs = finalResult && finalResult.length > 0 ? finalResult : []
                    }
                }
                divs.forEach((division) => {
                    if (division.org_unit_id == data[1][0]?.org_unit_id) {
                        division['selected'] = true;
                        divisionValid = true;
                    }
                })
                cardName = data[1][0]?.name;
                cardNameValid = cardName != '';
                distributionAddress = data[1][0]?.distribution_address;
                distributionAddressValid = distributionAddress && distributionAddress != ''
                this.setState({
                    divisions: divs,
                    cardName: cardName, distributionAddress: distributionAddress,
                    cardRecordName: data[2], cardRecordRecipients: data[3] ? data[3] : [],
                    datatable: data[3] ? data[3] : [],
                    recipientToAddBySearch: [],
                    cardSignersList: data[4],
                    companiesList: data[5], titlesList: data[6], originalDataset: data[3] ? data[3] : [], cardNameValid: cardNameValid,
                    divisionValid: divisionValid, distributionAddressValid: distributionAddressValid, isKeepChangesToSigners: false
                }, () => {
                    this.setState({
                        loaderOpen: false
                    }, () => {
                        this.validateForm();
                        if (this.state.isCopy) {
                            this.copyCard()
                        }
                    })
                });
            }
        } catch {
            throw Error("Promise failed");
        }
    };
    copyCard = () => {
        this.setState({ cardId: 0 , cardName: ""})
    }
    toggleLookupPeople = () => {
        this.setState({ showLookupPeople: !this.state.showLookupPeople });
    }

    toggleImportPeople = () => {
        this.setState({ showImportPeople: !this.state.showImportPeople });
    }

    updateData = (finalResult) => {
        finalResult.map(obj => ({ ...obj, card_signers: null }))
        finalResult.map(obj => ({ ...obj, card_recipient_id: null }))
        finalResult.map(obj => ({ ...obj, gift_event_card_id: null }))
        this.setState({
            datatable: finalResult, page: 0,
            originalDataset: finalResult,
            rowsPerPage: 10
        });
    }
    handleMaterials = () => {
        this.setState({ showMaterials: !this.state.showMaterials });
    }
    addPeople = (people) => {
        if (people && people.length > 0) {
            this.toggleLookupPeople();
            let persons = [...new Set(people.map(item => item.person_id))];
            GifterService.getDataParams(Constants.GifterServiceBaseUrl + '/selectedPersonInfo', persons)
                .then((response) => {
                    people = response.data;
                    people.map(obj => ({ ...obj, card_recipient_id: null }))
                    people.map(obj => ({ ...obj, gift_event_card_id: null }))
                    var results = this.state.datatable;
                    var finalResult = results.concat(people.filter(({ person_id }) => !results.find(p => p.person_id == person_id)));
                    this.setState({
                        datatable: finalResult, page: 0,
                        originalDataset: finalResult,
                        rowsPerPage: 10,
                        isBlocked: true
                    })
                },
                    (err) => {
                        console.log("Selected Person Infoservice error: " + err);
                    });
        }
    }
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    headingSort = () => {
        const { isSortByAsc } = this.state;
        let sortedList = [];
        if (!this.state.isSortByAsc) {
            sortedList = this.state.datatable?.sort((a, b) => (a.last_name.toString().toLowerCase() > b.last_name.toString().toLowerCase()) ? 1 : -1);
        } else {
            sortedList = this.state.datatable?.sort((a, b) => (b.last_name.toString().toLowerCase() > a.last_name.toString().toLowerCase()) ? 1 : -1);
        }
        this.setState({ isSortByAsc: !isSortByAsc, datatable: sortedList });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    }
    addInviteeFromSearch = (newValue) => {
        let recipients = this.state.datatable;
        newValue['gift_event_card_id'] = null;
        newValue['card_recipient_id'] = null;
        recipients.splice(0, 0, newValue);
        var finalResult = recipients.concat([newValue].filter(({ person_id }) => !recipients.find(p => p.person_id == person_id)));
        // recipients = this.getUniqueItemsByProperties(recipients, 'persion_id');
        this.setState({
            datatable: finalResult, originalDataset: finalResult, isBlocked: true

        });
    }
    onChangeHandle = async value => {
        let option = {
            "searchString": value,
        }
        console.log("option", option)
        GifterService.getData(Constants.GifterServiceBaseUrl + '/activePersons?searchString=' + value)
            .then((response) => {
                if (response && response.data && response.data.length > 0) {
                    let r = response.data;
                    this.setState({ recipientToAddBySearch: r });
                } else {
                    this.setState({ recipientToAddBySearch: [] });
                }
            },
                (err) => {
                    this.setState({ recipientToAddBySearch: [] });
                });
    };
    addCardSignersFromSearch = (newValue, index) => {
        let recipients = this.state.datatable;
        let recipient = recipients[index];
        let cardsigner = recipient.card_signers ? recipient.card_signers : [];
        let ifSignerExists = cardsigner.filter(item => item.signer_person_id == newValue.person_id)?.length > 0 ? true : false;
        if (!ifSignerExists) {
            let newSigner = {
                'signer_person_id': newValue.person_id,
                'signer_first_name': newValue.first_name,
                'signer_last_name': newValue.last_name,
                'signer_order': null,
                'signer_is_active': 1,
                'person_card_signer_id': null
            }
            cardsigner.push(newSigner);
            recipient.card_signers = cardsigner;
            recipients[index] = recipient;
            this.setState({
                datatable: recipients,
                originalDataset: recipients,
                isBlocked: true
            });
        }
    }
    handleCompanyChange = (event, value) => {
        let filter = this.state.filter;
        filter.byCompany = value && value.length > 0 ? value : []
        this.setState({
            filter: filter,
        }, () => { this.filter() });
    }
    handleSearchNameChange = (event) => {
        let filter = this.state.filter;
        filter.byName = event.target.value;
        this.setState({
            filter: filter,
        }, () => { this.filter() });
    }
    handleTitleChange = (event, value) => {
        let filter = this.state.filter;
        filter.byTitle = value && value.length > 0 ? value : []
        this.setState({
            filter: filter,
        }, () => { this.filter() });
    }
    filter = () => {
        let data = this.state.originalDataset;
        let filter = this.state.filter;
        if (filter.byName != '') {
            data = data.filter(o =>
                Object.keys(o).some(k => (o['first_name'].toLowerCase().includes(filter.byName.toLowerCase()))
                    || (o['last_name'].toLowerCase().includes(filter.byName.toLowerCase()))
                ));
        }
        if (filter.byCompany.length > 0) {
            data = data.filter(el => {
                return filter.byCompany.find(element => {
                    return element.company_id === el.company_id;
                });
            });
        }
        if (filter.byTitle.length > 0) {
            data = data.filter(el => {
                return filter.byTitle.find(element => {
                    return element.title_id === el.title_id;
                });
            });
        }
        this.setState({
            datatable: data, page: 0, rowsPerPage: 10
        });
    }

    filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return arr2.find(element => {
                return element.company_id === el.company_id;
            });
        });
        return res;
    }
    cardNameUpdate = (value) =>{
        this.setState({
            cardName: value,
            isBlocked: true
        });
        this.validateField('cardName', cardName);
    }
    _handleCardNameChange = (value) => {
        _.debounce(this.cardNameUpdate(value), 50);
        /* let cardName = event.target.value ? event.target.value : '';
        _.debounce(()=>
        this.setState({
            cardName: cardName,
            isBlocked: true
        }, () =>{ this.validateField('cardName', cardName) }),50); */
        //}, () => { this.validateField('cardName', cardName) })
    };
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let cardNameValid = this.state.cardNameValid;
        let divisionValid = this.state.divisionValid;
        let distributionAddressValid = this.state.distributionAddressValid;

        switch (fieldName) {
            case 'cardName':
                cardNameValid = value != '';
                fieldValidationErrors.cardName = cardNameValid ? '' : ' is invalid';
                break;
            case 'cardDivision':
                divisionValid = value != '0';
                fieldValidationErrors.Division = divisionValid ? '' : ' is invalid';
                break;
            case 'distributionAddress':
                distributionAddressValid = value != '';
                fieldValidationErrors.distributionAddress = distributionAddressValid ? '' : ' is invalid';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            eventNameValid: cardNameValid,
            divisionValid: divisionValid,
            distributionAddressValid: distributionAddressValid,
        }, this.validateForm);
    }
    validateForm() {
        this.setState({
            formValid: (this.state.cardNameValid || this.state.cardId > 0) && this.state.divisionValid &&
                this.state.distributionAddressValid
        });
    }
    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    _divisionChanges = (val) => {
        if (val.target.value != '0') {
            let divisionId = val.target.value;
            let divisions = this.state.divisions;
            divisions.map(obj => ({ ...obj, selected: false }))
            divisions.forEach((division) => {
                if (division.org_unit_id == divisionId) {
                    division['selected'] = true;
                }
            })
            this.setState({
                divisions: divisions,
                isBlocked: true
            }, () => { this.validateField('cardDivision', '1') });
        } else { this.validateField('cardDivision', 0) }
    }
    saveCard = () => {
        this.setState({ formValid: false })
        let cardRecipients = this.state.datatable && this.state.datatable.length > 0 ? this.state.datatable : [];
        if (this.state.cardId == 0) {
            cardRecipients.map(item => {
                item['gift_event_card_id'] = null;
                item['card_recipient_id'] = null;
            });
        }
        let cardObj = {

            "gift_event_card_id": this.state.cardId > 0 ? Number(this.state.cardId) : null,
            "name": this.state.cardName,
            "org_unit_id": this.state.divisions.filter(item => item.selected == true)?.[0].org_unit_id,
            "org_unit_name": this.state.divisions.filter(item => item.selected == true)?.[0].org_unit_name,
            "distribution_address": this.state.distributionAddress,
            // "card_recipients": this.state.datatable,
            "card_recipients": cardRecipients,
            "is_save_signer_master": this.state.isKeepChangesToSigners,
            "is_delete_all_recipients": this.state.deleteAll
        }
        console.log("cardObject", cardObj)
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/cardRecord', cardObj)
            .then((response) => {
                let orgId = this.state.cardId;
                NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
                if (response.data.length > 0) {
                    let res = response?.data?.[0].gift_event_card_id;
                    if (orgId > 0) {
                        NotificationManager.info('Updated Successfully', 'Card');
                        setTimeout(() => {
                            this.setState({ cardId: res, loaderOpen: true, isBlocked: false, deleteAll: false });
                            this.fetchData();
                        }, 1500);

                    } else {
                        NotificationManager.info('Created Successfully', 'Card');
                        setTimeout(() => {
                            this.setState({ cardId: res, loaderOpen: true, isBlocked: false, deleteAll: false, isCopy: false });
                            this.props.history.push('/card/' + res + '/0');
                            this.fetchData();
                            //window.location.reload();
                        }, 1500);
                    }
                } else {
                    this.setState({ formValid: true })
                    NotificationManager.error(response?.data?.message ? response?.data?.message : 'Error', 'Card');
                }
            },
                (err) => {
                    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
                    this.setState({ formValid: true })
                    NotificationManager.error('Save Failed', 'Card');
                    console.log("People Management grid service error: " + err);
                });
    }
    toggleModal = (row) => {
        let r = this.state.modal;
        this.setState({
            modal: !r,
            data: !r ? row : null
        });
    };
    editCallback = (row) => {
        let r = this.state.datatable;
        let person_id = row.person_id;
        let record = r.filter(item => item.person_id == person_id);
        if (record && record.length > 0) {
            row['card_recipient_id'] = record[0].card_recipient_id;
        }
        else {
            row['card_recipient_id'] = null;
            r.push(row)
        }
        r = r.map(function (item) { return item.person_id == person_id ? row : item; });
        this.setState({
            datatable: r,
            modal: false
        });
    }
    onInputChange =_.debounce((id, val) => this.setState({ distributionAddress: val, isBlocked: true }, () => { this.validateField('distributionAddress',val) }), 50);

    render() {
        let r = this.state.datatable ? this.state.datatable : [];
        let records = r?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return (
            <>
                {this.state.loaderOpen ? (<>
                    <div className="tableLoader">
                        <Backdrop className='backdrop' open={this.state.loaderOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                </>) : (<>
                    <div id="cardCreation">
                        <Prompt when={this.state.isBlocked} message={"You have unsaved changes. Are you sure you want to leave this page?"} />
                        <form className="demoForm">
                            <div>
                                <div className="row">
                                    <div className={`col-lg-4 col-md-4 F25 form-group ${this.errorClass(this.state.formErrors.cardName)}`} disabled={this.state.disabled}>
                                        <MDBInput id="cardName" placeholder="Card Name.." type="text"
                                            className="form-control" required name="cardName" value={this.state.cardName}
                                            onChange={(event) => this._handleCardNameChange(event.target.value)}>
                                        </MDBInput>
                                    </div>
                                    <div className="col-lg-8 col-md-8" style={{ textAlign: "right", marginBottom: "1rem" }}>
                                        <MDBBtn color="primary" onClick={this.handleMaterials.bind(this)}>
                                            <MDBIcon icon="file-image" className="ml-1" />&nbsp;&nbsp;Materials
                            </MDBBtn>
                            &nbsp;
                            <MDBBtn type='button' color="primary" onClick={this.copyCard}
                                            disabled={this.state.cardId == 0 || this.state.isCopy}>
                                            <MDBIcon icon="copy" className="mr-1" />&nbsp;&nbsp;Copy
                            </MDBBtn>
                            &nbsp;
                            <MDBBtn color="primary" type="submit" onClick={this.saveCard} disabled={!this.state.formValid || this.state.disabled || !this.state.isBlocked}>
                                            <MDBIcon icon="save" className="mr-1" />&nbsp;&nbsp;Save
                            </MDBBtn>
                            &nbsp;
                        </div>
                                </div>
                            </div>
                            <MDBCard id="eventCard" className='mb-3' style={{ height: '100%' }} disabled={this.state.disabled}>
                                <MDBCardBody>
                                    <MDBRow style={{ padding: '0.55rem 0 0.55rem 0' }}>
                                        <div className={`col-lg-5 col-md-6 col-sm-6 form-group ${this.errorClass(this.state.formErrors.Division)}`} id="cardDivision">
                                            <MDBContainer className="evntblock">
                                                <MDBRow>
                                                    <MDBCol>
                                                        <label ><span className="redStar">*</span>Division:
                                    </label>
                                                        <select className="browser-default custom-select form-test-control" id='eventSelect'
                                                            onChange={this._divisionChanges.bind(this)}>
                                                            <option value='0'>Select</option>
                                                            {this.state.divisions ?
                                                                (this.state.divisions.map((row) => (
                                                                    <option value={row.org_unit_id}
                                                                        selected={row.selected}>{row.org_unit_name}</option>
                                                                ))) : null}
                                                        </select>

                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBContainer>
                                        </div>

                                        <div className="col-lg-7 col-md-6" id="cardDivision" style={{ float: "right" }}>
                                            <MDBContainer className="addressblock">
                                                <MDBRow>
                                                    <MDBCol className={`form-group ${this.errorClass(this.state.formErrors.distributionAddress)}`}>
                                                        <label><span className="redStar">*</span>
                                            Distribution Address:
                                    </label>
                                                        <textarea className="textareabox form-test-control"
                                                            id="eventAddress" rows="1"
                                                            defaultValue={this.state.distributionAddress != null ? this.state.distributionAddress : ''}
                                                            onChange={e => this.onInputChange('distributionAddress',e.target.value)
                                                                /* et r = e.target.value
                                                                this.setState({ distributionAddress: r, isBlocked: true })
                                                                this.validateField('distributionAddress', r) */
                                                                // , () => { this.validateField('distributionAddress', r) })
                                                            }
                                                            ></textarea>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBContainer>

                                        </div>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>

                            <div>
                                <Grid container spacing={1}>
                                    <div className="row W100">
                                        <div className="col-lg-8 col-md-12">
                                            <Grid container>
                                                <Card>
                                                    <div className="container" disabled={this.state.disabled}>
                                                        <div className="row">
                                                            <div className="col-5 cardLabel">
                                                                Card Recipients
                                                    </div>
                                                            <div className="col-7 cardRecipientButtons">
                                                                <MDBBtn className="btnSize" outline size="sm"
                                                                    color={this.state.filterVisibility ? 'senary' : 'white'}
                                                                    onClick={(event, newValue) => {
                                                                        event.preventDefault();
                                                                        this.setState({
                                                                            filterVisibility: !this.state.filterVisibility
                                                                        })
                                                                    }}
                                                                >
                                                                    <MDBIcon fas size='1x' icon="filter" className="mr-2 text-quinary" />
                                                                </MDBBtn>
                                                        &nbsp;&nbsp;
                                                        <MDBBtn color="primary" onClick={this.toggleImportPeople.bind(this)}>
                                                                    <MDBIcon style={{ fontSize: 12 }} icon="arrow-circle-down" />&nbsp;&nbsp;Import
                                                                    {/* <MDBIcon icon='file-download'></MDBIcon> &nbsp; Import */}
                                                                </MDBBtn>
                                                        &nbsp;
                                                        <MDBBtn color="primary" onClick={(event, newValue) => {
                                                                    event.preventDefault();
                                                                    this.toggleLookupPeople()
                                                                }}>
                                                                    <MDBIcon icon='plus-circle' style={{ fontSize: 12 }}></MDBIcon> &nbsp;
                                                                    Recipients
                                                        </MDBBtn>

                                                            </div>
                                                            <Import showImportPeople={this.state.showImportPeople}
                                                                toggleImportPeople={this.toggleImportPeople.bind(this)}
                                                                data={this.state.datatable} //pass the state object of final set to merge with import
                                                                updateData={this.updateData.bind(this)}
                                                            />

                                                            <Materials isOpen={this.state.showMaterials}
                                                                toggleMaterials={this.handleMaterials.bind(this)}
                                                                fromPage="Card"
                                                                recipients={this.state.cardRecordRecipients ? this.state.cardRecordRecipients : []}
                                                                signers={this.state.cardSignersList ? this.state.cardSignersList : []}
                                                                id={this.state.cardId}
                                                                name={this.state.cardName}
                                                                address={this.state.distributionAddress}
                                                            >

                                                            </Materials>

                                                        </div>
                                                    </div>
                                                    {this.state.filterVisibility ? <>
                                                        <div className="container" disabled={this.state.disabled}>
                                                            <div className='row' id="cardFilter">
                                                                <div className='col-3 PT2'>
                                                                    <MDBInput placeholder="Search Name.."
                                                                        type="text" value={this.state.filter.byName}
                                                                        onChange={(event) => {
                                                                            this.handleSearchNameChange(event)
                                                                        }}></MDBInput>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <Autocomplete
                                                                        multiple
                                                                        variant="body1"
                                                                        size="small"
                                                                        limitTags={1}
                                                                        options={this.state.companiesList}
                                                                        value={this.state.filter.byCompany}
                                                                        disableCloseOnSelect
                                                                        getOptionLabel={(option) => option.company_name}
                                                                        filterOptions={filterOptions}
                                                                        style={{ width: '100%' }}
                                                                        renderOption={(option, { selected }) => (
                                                                            <React.Fragment>
                                                                                <Checkbox
                                                                                    icon={icon}
                                                                                    checkedIcon={checkedIcon}
                                                                                    style={{ marginRight: 8, transform: "scale(0.8)" }}
                                                                                    checked={selected}
                                                                                />
                                                                                <label style={{ fontSize: "11px" }}>{option.company_name}</label>
                                                                            </React.Fragment>
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} variant="standard" label="Company"
                                                                                InputProps={{ ...params.InputProps, disableUnderline: true }} />
                                                                        )}
                                                                        onChange={(event, value) => this.handleCompanyChange(event, value)}
                                                                    />
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Autocomplete
                                                                        multiple
                                                                        variant="body1"
                                                                        size="small"
                                                                        limitTags={1}
                                                                        options={this.state.titlesList}
                                                                        value={this.state.filter.byTitle}
                                                                        disableCloseOnSelect
                                                                        getOptionLabel={(option) => option.title_name}
                                                                        filterOptions={filterOptions}
                                                                        style={{ width: '100%' }}
                                                                        renderOption={(option, { selected }) => (
                                                                            <React.Fragment>
                                                                                <Checkbox
                                                                                    icon={icon}
                                                                                    checkedIcon={checkedIcon}
                                                                                    style={{ marginRight: 8, transform: "scale(0.8)" }}
                                                                                    checked={selected}
                                                                                />
                                                                                <label style={{ fontSize: "11px" }}>{option.title_name}</label>
                                                                            </React.Fragment>
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} variant="standard" label="Title"
                                                                                InputProps={{ ...params.InputProps, disableUnderline: true }} />
                                                                        )}
                                                                        onChange={(event, value) => this.handleTitleChange(event, value)}
                                                                    />
                                                                </div>
                                                                <div className='col-2 MT3N' id='resetFilter'>
                                                                    <Link href="#" id="expansionPanel" className="FR"
                                                                        onClick={(event, newValue) => {
                                                                            event.preventDefault();
                                                                            let filter = this.state.filter;
                                                                            filter.byName = '';
                                                                            filter.byCompany = [];
                                                                            filter.byTitle = [];
                                                                            this.setState({ filter: filter }, () => { this.filter() })
                                                                        }}
                                                                        color="primary" >
                                                                        reset filters
                                                        </Link>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </> : null}
                                                    <TableContainer component={Paper} disabled={this.state.disabled}>
                                                        <Table size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow className='tblHead'>
                                                                    <TableCell className='tblHead'>First Name</TableCell>
                                                                    <TableCell className='tblHead'>Last Name<MDBIcon icon={this.state.isSortByAsc ? "sort-alpha-up" : "sort-alpha-down"} onClick={this.headingSort} /></TableCell>
                                                                    <TableCell className='tblHead'>Company</TableCell>
                                                                    <TableCell className='tblHead'>Division</TableCell>
                                                                    <TableCell className='tblHead'>Title</TableCell>
                                                                    <TableCell className='tblHead'>
                                                                        <Link href="#" className=""
                                                                            onClick={(event, newValue) => {
                                                                                event.preventDefault();
                                                                                this.setState({
                                                                                    datatable: [], page: 0, rowsPerPage: 10, deleteAll: true
                                                                                });
                                                                            }}
                                                                            color="primary" >
                                                                            delete all
                                                                </Link>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {records?.map((row, index) => (
                                                                    <TableRow key={row.person_id} className='H25'>
                                                                        <TableCell className='F12 W14'>{row.first_name}</TableCell>
                                                                        <TableCell className='F12 W14'>{row.last_name}</TableCell>
                                                                        <TableCell className='F12 W14'>{row.company_name}</TableCell>
                                                                        <TableCell className='F12 W14'>{row.division_name}</TableCell>
                                                                        <TableCell className='F12 W14'>{row.title_name}</TableCell>
                                                                        <TableCell className='F12 W14'>
                                                                            <div className='FR'>
                                                                                <CreateIcon style={{ fontSize: 15 }} className='MR1'
                                                                                    onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        this.toggleModal(row);
                                                                                    }} />
                                                                                <DeleteIcon style={{ fontSize: 15 }}
                                                                                    onClick={(event) => {
                                                                                        let r = this.state.datatable;
                                                                                        r.splice(r.findIndex(el => el.person_id === row.person_id), 1);
                                                                                        this.setState({ datatable: r });
                                                                                    }} /></div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                <TableRow>

                                                                    <TableCell>
                                                                        <div class='row'>
                                                                            <div class='col-9' >
                                                                                <Autocomplete
                                                                                    className="tags-standard"
                                                                                    options={this.state.recipientToAddBySearch}
                                                                                    getOptionLabel={(option) => (option.first_name + ' ' + option.last_name)}
                                                                                    onChange={(event, newValue) => {
                                                                                        if (newValue && newValue != '') {
                                                                                            this.addInviteeFromSearch(newValue)
                                                                                        }
                                                                                    }}
                                                                                    renderInput={params => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            label="Search By Name"
                                                                                            margin="normal"
                                                                                            onChange={ev => {
                                                                                                // dont fire API if the user delete or not entered anything
                                                                                                if (ev.target.value !== "" || ev.target.value !== null) {
                                                                                                    this.onChangeHandle(ev.target.value);
                                                                                                }
                                                                                            }}
                                                                                            InputProps={{
                                                                                                ...params.InputProps,
                                                                                                endAdornment: (
                                                                                                    <React.Fragment>
                                                                                                        {this.state.loading ? (
                                                                                                            <CircularProgress color="inherit" size={20} />
                                                                                                        ) : null}
                                                                                                        {params.InputProps.endAdornment}
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            }}
                                                                                        />

                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <div class='col-3 MT1pt25'>
                                                                                <Tooltip title="Add Person">
                                                                                    <AddCircleRoundedIcon style={{ fontSize: 20 }}
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            let row = {
                                                                                                "event_invitee_id": null,
                                                                                                "gift_event_card_id": null,
                                                                                                "card_recipient_id": null,
                                                                                                "person_id": null,
                                                                                                "first_name": "",
                                                                                                "last_name": "",
                                                                                                "display_name": "",
                                                                                                "dob": null,
                                                                                                "is_card_signer": 0,
                                                                                                "person_category_id": null,
                                                                                                "category_id": null,
                                                                                                "category_name": "",
                                                                                                "p_comp_div_title_id": null,
                                                                                                "company_id": null,
                                                                                                "company_name": "",
                                                                                                "division_id": null,
                                                                                                "division_name": null,
                                                                                                "title_id": null,
                                                                                                "title_name": "",
                                                                                                "person_extension_id": null,
                                                                                                "address": null,
                                                                                                "address_type": null,
                                                                                                "person_phone_id": null,
                                                                                                "phone": null,
                                                                                                "person_email_id": null,
                                                                                                "email": null,
                                                                                                "newUser": true,
                                                                                                "guests": [

                                                                                                ]
                                                                                            };
                                                                                            this.toggleModal(row);
                                                                                        }} /></Tooltip></div>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        component="div"
                                                        count={this.state.datatable.length}
                                                        rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
                                                        page={this.state.page}
                                                        onChangePage={this.handleChangePage}
                                                        rowsPerPage={this.state.rowsPerPage}
                                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    />
                                                </Card>
                                            </Grid>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <Grid container disabled={this.state.disabled}>
                                                <Card>
                                                    <Card>

                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-5 cardLabel">
                                                                    Signers
                                            </div>
                                                                <div className="col-7 cardRecipientchk" id='formChk'>
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value=""
                                                                            id="flexCheckDefault"
                                                                            checked={this.state.isKeepChangesToSigners}
                                                                            onChange={e => {
                                                                                this.setState({ isKeepChangesToSigners: e.target.checked, isBlocked: true })
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label F10" for="flexCheckDefault">
                                                                            Keep Changes to Signers
                                                    </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader className="bgLightGray">
                                                            Hello
                                                </CardHeader>
                                                        <MDBContainer className='signersContainer'>
                                                            {
                                                                records ? records.map((item, index) =>
                                                                    <>

                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel2a-content"
                                                                                id="panel2a-header"
                                                                            ><span className='W12 fw-bold'>{item.first_name + ' ' + item.last_name}</span>
                                                                                <span> {item.card_signers && item.card_signers.filter(r => r.signer_is_active == 1).length > 0 ? <>
                                                                                    <label>{item.card_signers ? item.card_signers.filter(r => r.signer_is_active == 1).length : ''} Card Signers</label> <br /> </>
                                                                                    : <label>No Card Signers</label>}</span>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <MDBRow className="noGutter">
                                                                                    <MDBCol md='12' className='col-example'>
                                                                                        {
                                                                                            item.card_signers && item.card_signers.filter(r => r.signer_is_active == 1).length > 0 ?
                                                                                                item.card_signers.filter(r => r.signer_is_active == 1).map((sub, subindex) =>
                                                                                                    <MDBContainer>
                                                                                                        <MDBRow>
                                                                                                            <MDBCol className="flexBox">
                                                                                                                <label className="W9R">
                                                                                                                    {sub.signer_first_name + ' ' + sub.signer_last_name}</label>
                                                                                                                <DeleteIcon className="flexAlignRight" style={{ fontSize: 20 }}
                                                                                                                    onClick={(event) => {
                                                                                                                        let r = this.state.datatable;
                                                                                                                        let recipient = r.filter(a => a.person_id == item.person_id)[0];
                                                                                                                        let cardSigners = recipient.card_signers;
                                                                                                                        //cardSigners.splice(cardSigners.findIndex(el => el.signer_person_id === sub.signer_person_id), 1);
                                                                                                                        let actualSigner = cardSigners[subindex];
                                                                                                                        actualSigner['signer_is_active'] = 0;
                                                                                                                        cardSigners[subindex] = actualSigner;
                                                                                                                        recipient.card_signers = cardSigners;
                                                                                                                        r[index] = recipient;
                                                                                                                        this.setState({ datatable: r });
                                                                                                                    }} /></MDBCol>
                                                                                                            {subindex == item.card_signers.filter(r => r.signer_is_active == 1).length - 1 ?
                                                                                                                <MDBCol>
                                                                                                                    <Autocomplete
                                                                                                                        className="tags-standard"
                                                                                                                        options={this.state.cardSignersList ? this.state.cardSignersList : []}
                                                                                                                        getOptionLabel={(option) => (option.first_name + ' ' + option.last_name)}
                                                                                                                        onChange={(event, newValue) => {
                                                                                                                            if (newValue && newValue != '') {
                                                                                                                                this.addCardSignersFromSearch(newValue, index)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        style={{ width: 300 }}
                                                                                                                        renderInput={(params) => (
                                                                                                                            <TextField {...params} placeholder="Add Signer"
                                                                                                                                margin="normal" variant="outlined" />
                                                                                                                        )}
                                                                                                                    />
                                                                                                                </MDBCol> : null}
                                                                                                        </MDBRow>
                                                                                                    </MDBContainer>
                                                                                                ) :
                                                                                                <>
                                                                                                    <MDBContainer>
                                                                                                        <MDBRow>
                                                                                                            <MDBCol>
                                                                                                                <Autocomplete
                                                                                                                    className="tags-standard"
                                                                                                                    options={this.state.cardSignersList ? this.state.cardSignersList : []}
                                                                                                                    getOptionLabel={(option) => (option.first_name + ' ' + option.last_name)}
                                                                                                                    onChange={(event, newValue) => {
                                                                                                                        if (newValue && newValue != '') {
                                                                                                                            this.addCardSignersFromSearch(newValue, index)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    style={{ width: 300 }}
                                                                                                                    renderInput={(params) => (
                                                                                                                        <TextField {...params} placeholder="Add Signer"
                                                                                                                            variant="outlined" margin="normal" />
                                                                                                                    )}
                                                                                                                />
                                                                                                            </MDBCol>
                                                                                                        </MDBRow>
                                                                                                    </MDBContainer>

                                                                                                </>
                                                                                        }

                                                                                    </MDBCol>
                                                                                </MDBRow>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </>
                                                                ) : null
                                                            }
                                                        </MDBContainer>
                                                    </Card>
                                                </Card>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </form>
                    </div>
                    <LookupModal id="lookup-people" isOpen={this.state.showLookupPeople} addPeople={this.addPeople}
                        pageName='Lookup Recipients'
                        toggleLookupPeople={this.toggleLookupPeople.bind(this)}>
                    </LookupModal >
                    <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} className="custom-class" >
                        <MDBModalBody>
                            <FormElement row={this.state.data} editCallback={this.editCallback} toggle={this.toggleModal} />
                        </MDBModalBody>
                    </MDBModal>
                    <NotificationContainer />
                </>)
                }</>
        );
    }
}

export default CardsPage;