import { TextField } from '@material-ui/core'
import React from 'react'
import { AutoComplete } from 'rsuite';

export default function TextBox(props){

    const{name, label, id, onChange, ...other} = props;
    return(   
        <TextField  
         variant = "outlined"
         placeholder = {label}
         id="addnewguest"
         name = {name}
         onKeyPress = {(e,v) => {
            if(e && e.key == "Enter"){
             onChange(e,e.target.value)
              e.target.value = "";
            }
         }} 
         {...other}
         />
         

    )
}