import React, { useState, useEffect } from "react";
import {MDBInput } from 'mdbreact';
import {  MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { Component } from "react";
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';
import EnvelopesEventJSON from '../../json/envelopes_event.json';
import EnvelopesGiftJSON from '../../json/envelopes_gift.json';
import EnvelopesCardJSON from '../../json/envelopes_card.json';
import logo_rev from '../../assets/logo_rev.jpg';
import jsPDF from "jspdf";
import FontPicker from './FontPicker';

const importAllFonts = (r) => {
    let fonts = {};
    r.keys().map((item, index) => { fonts[item.replace('./', '')] = r(item); });
    return fonts;
}

const fonts = importAllFonts(require.context('../../fonts/materials-fonts', false, /\.(txt)/));

const loadImage = (url) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.src = url;
    })
  }

class Envelopes extends Component {
    _isMounted = false;
    container = null;
    envelopes_json="";
    constructor(props) {
        super(props);   
        if(this.props.fromPage === "Event")
        this.envelopes_json = EnvelopesEventJSON;
        else if (this.props.fromPage === "Gift")
        this.envelopes_json = EnvelopesGiftJSON;
        else if (this.props.fromPage === "Card")
        this.envelopes_json = EnvelopesCardJSON;

        this.state = {
            selectedFields : {},
            selectedFont: 1,
            selectedFontName: 'Arial',
            selectedFontType: 'normal',
            selectedFontStyle: 'normal',
            selectedFontSize: 12,
            selectedFontColor: '#123456',
            colorPicker: false,
            selectedDispWidth: 4,
            selectedDispHeight: 3,
            sortBy: "",
            selectedFontFileName: {                
                normal: "arial.ttf",
                bold: "arialbd.ttf",
                italic: "ariali.ttf",
                bolditalic: "arialbi.ttf"                    
            },
            envelopesStatus: false

        }
    }

    handleSideNavActive = (value, routePath) => {
        this.setState({
            activeTab: value
        } );
        this.props.history.push(routePath, this.state);
    }

    handleFieldsChecked = (sectionType, subSectionGroupName, fieldId, event) => {
        var currState = this.state.selectedFields;
        currState[subSectionGroupName][fieldId] = event.target.checked;
        this.setState({selectedFields : currState});
    }

    handleTextFieldsChanged = (sectionType, subSectionGroupName, fieldId, event) => {
        var currState = this.state.selectedFields;
        currState[subSectionGroupName]["text_"+fieldId] = event.target.value;
        this.setState({selectedFields : currState});
    }

    handleRadioFieldsChecked = (sectionType, subSectionGroupName, fieldId, radioGroupName, event) => {
        var currState = this.state.selectedFields;
        //var groupFields = currState[subSectionGroupName];
        var groupFields = currState[subSectionGroupName][radioGroupName];
        // radioGroupItems[fields.fieldId] = fields.default;
        // checkedItems[radioGroupName] = radioGroupItems;
        for (var key in groupFields) {
                if (key === fieldId) {
                   // console.log(key + " -> " + groupFields[key]);
                    groupFields[key] = true;
                }
                else
                groupFields[key] = false;
            }
        currState[subSectionGroupName][radioGroupName] = groupFields;        

        if(radioGroupName == "sortOrder")
            this.setState({sortBy : fieldId});

        this.setState({selectedFields : currState});
    }

    envelopes = () =>{
        //alert("Exported");
        this.setState({envelopesStatus : true});
        var input = this.state.selectedFields;
        console.log("Message Cards -->" + JSON.stringify(input));
        
        //loadImage(logo_rev).then((logoImg) => {
            const doc = new jsPDF('p', 'in', 'letter');                 
            var pageWidth = doc.internal.pageSize.getWidth();
            var pageHeight = doc.internal.pageSize.getHeight();
            var sortField = this.state.sortBy;
            console.log("Page Width: "+pageWidth+"; Page Height: "+pageHeight);
            var x= this.state.selectedDispWidth;
            var y= this.state.selectedDispHeight;
            var fontColor = this.state.selectedFontColor;           
            var fontName = this.state.selectedFontName;
            var fontType = this.state.selectedFontType;
            var fontSize = this.state.selectedFontSize;
            var fontStyle = this.state.selectedFontStyle;
            //var fontBase64 = this.state.selectedFontBase64;
            var fontFileName = this.state.selectedFontFileName;
            var key="normal";
            if(fontType == "bold" && fontStyle == "italic")
            key="bolditalic";
            else if (fontType == "bold")
            key="bold";
            else if(fontStyle == "italic")
            key="italic";
            
            GifterService.fetchFile(fonts[fontFileName[key]+".txt"]).
            then((response) => {
                return response.text();
            }).
            then((result) =>{     
            doc.addFileToVFS(fontFileName[key], result);
            doc.addFont(fontFileName[key], fontName, key);
            doc.setFont(fontName, key);
            //doc.setFontType(this.state.selectedFontType);
            //if(this.state.selectedFontStyle != "")
            doc.setFontStyle(fontStyle);
            doc.setFontSize(fontSize);            
            doc.setTextColor(parseInt(fontColor.substring(1,3),16), parseInt(fontColor.substring(3,5),16), parseInt(fontColor.substring(5,7),16));

            if(this.props.fromPage== "Event" || this.props.fromPage== "Card" || (this.props.fromPage== "Gift" && this.state.selectedFields["Options"]?.["cardGroup"]?.["Gift_Recipient"])){
                
                var recipients = this.props.recipients.sort((a, b) => {
                    if (a[sortField] < b[sortField])
                      return -1;
                    if (a[sortField] > b[sortField])
                      return 1;
                    return 0;
                  });
                var cnt = recipients.length;
                recipients.map((recipient, index) => {
                    var message = "";
                    if(this.state.selectedFields["Options"]?.["name"]) 
                    message += ((recipient.first_name+" "+recipient.last_name)  || "" )+ "\n";
                    if(this.state.selectedFields["Options"]?.["address"])
                    {
                        if(this.props.isDefaultForAllAddress == 1)
                        message += this.props.giftAddress + "\n";
                        else 
                        message += (recipient.address || "") + "\n" ;
                    }
                    //  if(this.state.selectedFields["Options"]?.["address"])
                    //  message += "\n" + (recipient.address || "");
                     if(this.state.selectedFields["Options"]?.["show"])
                     message += (recipient.show || "")+"\n" ;
                    // doc.addImage(logoImg, 'JPG', x , y, 0.75, 0.75,'', 'FAST'); 
                    //var message = this.state.selectedFields["Options"]?.["cardGroup"]?.["Generic_Message"] ? this.state.selectedFields["Options"]?.["text_Generic_Message"] : "<R>";
                    var wrapWidth = x;
                    var messageWrap = doc.splitTextToSize(message, wrapWidth);                    
                    doc.text(messageWrap, pageWidth - x, pageHeight - y );
                    if(cnt > index + 1)
                    doc.addPage("letter", "p");
                });
            }
            else if(this.props.fromPage== "Gift" && this.state.selectedFields["Options"]?.["cardGroup"]?.["Gift_Item"]){
                var cnt = this.props.gifters[0].distributions.length;
                this.props.gifters[0].distributions.map((gift_dist, index) => {
                    var recipients_sort = gift_dist.recipients.sort((a, b) => {
                        if (a[sortField] < b[sortField])
                          return -1;
                        if (a[sortField] > b[sortField])
                          return 1;
                        return 0;
                    });
                    var message = "";
                    var isName = this.state.selectedFields["Options"]?.["name"];
                    var isAddress = this.state.selectedFields["Options"]?.["address"];
                    var isShow = this.state.selectedFields["Options"]?.["show"];
                    var recipients = recipients_sort.map((recipient) => {
                        console.log(recipient);
                        if(isName) 
                        message += ((recipient.first_name+" "+recipient.last_name) || "") == "" ? "": (recipient.first_name+" "+recipient.last_name) + "\n" ;
                        if(isAddress)
                        {
                            if(this.props.isDefaultForAllAddress == 1)
                            message += (this.props.giftAddress || "") ;
                            else 
                            this.props.recipients?.find(gR => gR.person_id == recipient.person_id ? message += (gR.address || "") : "") ;
                            message += "\n";
                        } 
                        // if(isAddress)
                        // message +=  (recipient.address || "") == "" ? "" : recipient.address + "\n" ;
                        if(isShow)
                        message += (recipient.show || "") == "" ? "" : recipient.show + "\n" ;
                    });     
                    
                    //if(message == "" || message == null) message = "<R>";
                    var wrapWidth = x;
                    var messageWrap = doc.splitTextToSize(message, wrapWidth); 
                    doc.text(messageWrap, pageWidth - x, pageHeight - y);

                    if(cnt > index + 1)
                    doc.addPage("letter", "p");
                });
            }
            doc.save("envelopes-"+this.props.fromPage+"-"+ this.props.name +"-"+this.props.id.toString()+".pdf");
            this.setState({envelopesStatus : false});
            }); //Fetch Font file
            //});
        
    }

    handleFontChange = (event, fontValue, fontName, fontFileName) =>{
        this.setState({selectedFont : fontValue,
                       selectedFontName: fontName, 
                       selectedFontFileName: fontFileName,
                    });
    }

    handleFontSizeChange = (event) =>{
        this.setState({selectedFontSize : event.target.value});
    }

    toggleFontColor = () => {
        this.setState({ colorPicker: !this.state.colorPicker });
    };

    saveFontColor = (value) => {
        this.setState({ selectedFontColor: value.hex });
    }

    closeFontColor = () => {
        this.setState({ colorPicker: false });
    }

    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
        //var finalJson = this.state.selectedFields;
        var finalArr = this.state.selectedFields;
        this.envelopes_json.sections.map((section) => {
            //var finalArr = finalJson[section.type] || {};
            section.subSections.map((subSection) => {
                var checkedItems = {};
                subSection.values.map((fields) => {
                    
                    if(fields.textArea)
                    checkedItems["text_"+fields.fieldId] = "";

                    if(fields.radio)
                    {
                        var radioGroupItems = checkedItems[fields.radioGroupName] || {};
                        radioGroupItems[fields.fieldId] = fields.default;
                        checkedItems[fields.radioGroupName] = radioGroupItems;
                        if(fields.radioGroupName == "sortOrder" && fields.default)
                            this.setState({sortBy : fields.fieldId});
                    }
                    else
                        checkedItems[fields.fieldId] = fields.default;
                    
                });
                finalArr[subSection.groupName] = checkedItems;
            });
               // finalJson[section.type] = finalArr;
        });
        this.setState({selectedFields : finalArr });
    }


    inputChangeHandler = (name, event) => {
        this.setState({ [name]: parseInt(event.target.value, 10)
        });
    }

    handleFontStyle = (name, event) =>{

        var fontType = this.state.selectedFontType;
        var fontStyle = this.state.selectedFontStyle;

        if(name === "bold")
        this.setState({ 
            selectedFontType: (fontType == name ? "normal" : "bold")
        });

        if(name === "italic")
        this.setState({ 
            selectedFontStyle: (fontStyle == name ? "normal" : "italic")
        });
    }



    componentWillUnmount() {
        this._isMounted = false;
    }


    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
        }
    }

    render(){
        return (
            <>
           <div>
                <div className="fst-italic mat-sub-section col-12">
                    Create a PDF containing a list Envelopes for distribution. Remember to confirm Invitee data and Address(es) accuracy before creating.
                </div>
                {/* <div className="h6 fw-bold"> 
                    {this.export_details_json.name}
                </div>
                <div className="mat-sub-section fst-italic"> 
                    {this.export_details_json.value}
                </div> */}
                {
                    this.envelopes_json.sections.map((section) => {
                        return <div > 
                            {/* className={section.fullPage ? "row" : ""} */}
                                    <div className="h6 fw-bold margin-ver-none padding-ver"> 
                                        {section.name}
                                    </div>
                                    <div className="row">
                                    
                                    {/* {section.values.map((value) => {
                                        <> */}
                                        {section.subSections.map((subSection) => {
                                            return <>                                                
                                                <div className={"padding-hor-25 col-6"}>
                                                    <div className={subSection.newSection ? "padding-ver margin-top" : "padding-ver"}>
                                                        <div className={subSection.section ? "h6 fw-bold margin-hor-minus-12 padding-ver" : "mat-sub-section-header fst-italic padding-hor-15"}>
                                                            {subSection.name}
                                                        </div>
                                                        {subSection.values.map((fields) => {
                                                            return <> 
                                                            <div className="mat-sub-section">
                                                            {fields.sectionHeader != undefined ? 
                                                            <div className= "mat-sub-section-header fst-italic padding-hor-15">
                                                                {fields.sectionHeader}
                                                            </div> :
                                                            <> </>
                                                            } 

                                                            {fields.radio !== true ?
                                                                <>
                                                                <div className="flexBox-plain align-center-vertical">
                                                                        <input type ="checkbox" value={section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                            name={"cb-"+ section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                            checked={this.state.selectedFields[subSection.groupName]?.[fields.fieldId] || false} 
                                                                            onChange={this.handleFieldsChecked.bind(this, section.type, subSection.groupName, fields.fieldId )}/>
                                                                        <label className="padding-left-3">{fields.fieldName}</label> 
                                                                    {/* <input type="textarea" className={fields.textArea ? "d-block":"d-none"} rows={4} /> */}
                                                                </div>
                                                                </>
                                                                :
                                                                <> 
                                                                    {/* <Radio
                                                                        checked={this.state.selectedFields[subSection.groupName]?.[fields.fieldId] || false} 
                                                                        onChange={this.handleFieldsChecked.bind(this,section.type, subSection.groupName, fields.fieldId )}
                                                                        value= {section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                        name={"radio-button-group-"+ fields.radioGroupName}
                                                                        inputProps={{ 'aria-label': fields.fieldId }}
                                                                    /> */}
                                                                    <div className="flexBox-plain align-center-vertical">
                                                                        <input type ="radio" value={section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                            checked={this.state.selectedFields[subSection.groupName]?.[fields.radioGroupName]?.[fields.fieldId] || false} 
                                                                            name={"radio-button-group-"+ fields.radioGroupName}
                                                                            onChange={this.handleRadioFieldsChecked.bind(this, section.type, subSection.groupName, fields.fieldId, fields.radioGroupName )}/>
                                                                        <label className="padding-left-3">{fields.fieldName}</label> 
                                                                    </div>
                                                                </>
                                                                }
                                                                <div className={fields.textArea ? "padding-hor-12":"d-none"}>
                                                                    <MDBInput type="textarea" placeholder='' onChange={this.handleTextFieldsChanged.bind(this, section.type, subSection.groupName, fields.fieldId )}
                                                                                name={"text-"+ section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                                rows={3}>

                                                                    </MDBInput>
                                                                </div>
                                                            </div>
                                                            </>})
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            })
                                        }                                        
                                    </div>
                               </div> 
                            })
                        }

                    {/* <FormControl className="form-control"> */}
                        <FontPicker 
                        selectedFont = {this.state.selectedFont}
                        selectedFontName = {this.state.selectedFontName}
                        selectedFontSize = {this.state.selectedFontSize}
                        colorPicker = {this.state.colorPicker}
                        selectedFontColor = {this.state.selectedFontColor}
                        toggleFontColor = {this.toggleFontColor.bind(this)}
                        closeFontColor={this.closeFontColor.bind(this)}
                        saveFontColor={this.saveFontColor.bind(this)}
                        handleFontStyle={this.handleFontStyle.bind(this)}
                        handleFontSizeChange={this.handleFontSizeChange.bind(this)}
                        handleFontChange={this.handleFontChange.bind(this)}

                        />


                        <div className="padding-ver-12"> 
                            <div className="h6 fw-bold margin-ver-none padding-ver">
                                Envelope Size
                            </div>
                            <div className="row mat-sub-section padding-hor-12">
                            <div class="col-12" id="material-content-dim">
                                <div class="margin-left flexBox-plain padding-ver">
                                    <div class="col-1">
                                        <label>Height:</label> 
                                    </div>
                                    <div class="col-1">
                                        {/* <input type="text" style={{width: "100%"}} />  */}
                                        <MDBInput placeholder='' value={this.state.selectedDispHeight} 
                                        name={"selectedDispHeight"} onChange={this.inputChangeHandler.bind(this, "selectedDispHeight")} type="number" >

                                        </MDBInput>
                                    </div>
                                    <div class="col-1 padding-hor">in.
                                    </div>
                                    {/* <div class="col-auto"></div> */}
                                {/* </div>
                                <div class="margin-left flexBox-plain padding-ver" > */}
                                    <div class="col-1">
                                        <label>Width:</label> 
                                    </div>
                                    <div class="col-1">
                                        {/* <input type="text" style={{width: "100%"}} />    */}
                                        <MDBInput placeholder='' value={this.state.selectedDispWidth} 
                                        name={"selectedDispWidth"} onChange={this.inputChangeHandler.bind(this, "selectedDispWidth")} type="number" >

                                        </MDBInput>                                 
                                    </div>
                                    <div class="col-1 padding-hor">in.
                                    </div>
                                    <div class="col-auto"></div>
                                </div>
                                </div>
                                <div className="col-6 padding-ver">
                                    <div className="preview-materials">
                                        {/* <div className={(this.state.selectedFields["Options"]?.["WB_Logo"] || false) ? "d-flex": "d-none"}
                                             style={{justifyContent: "center"}}>
                                        <img
                                            src={logo_rev}
                                            alt='Logo' style={{width:"30px", height: "30px" }}                                            
                                        />
                                        </div> */}
                                        <div style={{fontSize: this.state.selectedFontSize+'px', fontStyle : this.state.selectedFontStyle, fontWeight: this.state.selectedFontType, color: this.state.selectedFontColor, fontFamily: "'" + this.state.selectedFontName + "'" }}>
                                            {this.state.selectedFields["Options"]?.["name"] ? "Name" : ""}
                                            <br />
                                            {this.state.selectedFields["Options"]?.["address"] ? "Address" : ""}
                                            <br />
                                            {this.state.selectedFields["Options"]?.["show"] ? "Show" : ""}                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    {/* </FormControl> */}
                    <div className="padding-hor flexAlignRight">
                        <div className="padding-hor">
                            <MDBBtn onClick={this.envelopes.bind(this)}>
                                <MDBIcon className={this.state.envelopesStatus ? "" : "d-none"} icon="spinner" spin/> Create
                            </MDBBtn>
                        </div>
                        <div className="padding-hor">
                            <MDBBtn onClick={this.props.toggleMaterials}>
                                Cancel
                            </MDBBtn>
                        </div>
                    </div>
            </div>
            </>
        );
    }

}

export default Envelopes;