import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PageNotFound from './components/PageElements/PageNotFound';
import { withOktaAuth } from '@okta/okta-react';
import EventGiftLandingPage from './pages/EventGiftLandingPage';
import CreateEventPage from './components/EventCreation/CreateEvent';
import CreateGiftPage from './components/GiftCreation/CreateGift';
import CardsPage from './components/Cards/CardCreation';
import AttendenceManagement from './components/AttendenceManagement/AttendanceManagement';
import AttendenceManagementMD from './components/AttendenceManagement/AttendanceManagementMd';
import PeopleManagement from './components/PeopleManagement/PeopleManagement';
import DataManagement from './components/DataManagement/DataManagement';
import NewPerson from './components/PeopleManagement/AddPerson/NewPerson';
import QueryData from './components/Materials/QueryData';
import UserRoleManagement from './components/UserRoleManagement/UserRoleManagement';
import CustomGroupPersons from './components/CustomGroupPersons/CustomGroupPersons';
import VendorManagement from './components/DataManagement/Vendors/VendorManagement';

export default withOktaAuth(class Routes extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (

      <Switch>
        {/* <Route exact={true} path='/' render={(props) => { console.log("props redirect-->"+props);
                                                          return (<Redirect to= "/dashboard" />) }} /> */}
        <Route exact={true} path='/' render={(props) => <EventGiftLandingPage {...this.props} />} />
        {/* <Route exact={true} path='/event' render={(props) => <CreateEventPage {...props} />} /> */}
        {/* <Route exact={true} path='/event' render={(props) => <CreateEventPage {...props} />} >
          <Route exact={true} path='/:eventId' render={(props) => <CreateEventPage {...props} />} />
        </Route> */}

        <Route exact={true} path='/event/:eventId/:copyId' render={(props) => <CreateEventPage {...props} />} />
        <Route exact={true} path='/viewevent/:eventId' render={(props) => <CreateEventPage {...props} />} />
        <Route exact={true} path='/viewatdMngmt/:eventId' render={(props) => <AttendenceManagement {...props} />} />
        <Route exact={true} path='/viewcard/:cardId' render={(props) => <CardsPage {...props} />} />
        {/* <Route exact={true} path='/gift' render={(props) => <CreateGiftPage {...props} />} /> */}
        <Route exact={true} path='/gift/:giftId/:copyId' render={(props) => <CreateGiftPage {...props} />} />
        <Route exact={true} path='/viewgift/:giftId' render={(props) => <CreateGiftPage {...props} />} />
        {/* <Route exact={true} path='/card' render={(props) => <CardsPage {...props} />} /> */}
        <Route exact={true} path='/card/:cardId/:copyId' render={(props) => <CardsPage {...props} />} />
        <Route exact={true} path='/atdMngmt/:eventId' render={(props) => <AttendenceManagement {...props} />} />
        <Route exact={true} path='/manageUsers' render={(props) => <PeopleManagement {...props} />} />
        <Route exact={true} path='/vendor/:id' render={(props) => <VendorManagement {...props} />} />
        <Route exact={true} path='/addVendor' render={(props) => <VendorManagement {...props} />} />
        <Route exact={true} path='/manageData' render={(props) => <DataManagement {...props} />} />
        <Route exact={true} path='/atdMngmtMD' render={(props) => <AttendenceManagementMD {...props} />} />
        <Route exact={true} path='/addPerson' render={(props) => <NewPerson {...props} />} />
        <Route exact={true} path='/editPerson/:id' render={(props) => <NewPerson {...props} />} />
        <Route exact={true} path='/query' render={(props) => <QueryData {...props} />} />
        <Route exact={true} path='/userRoleManagers' render={(props) => <UserRoleManagement {...props} />} />
        <Route exact={true} path='/customGroup/:customGroupId' render={(props) => <CustomGroupPersons {...props} />} />

        <Route
          render={function () {
            return <PageNotFound />;
          }}
        />

      </Switch>
    );
  }
}
);
