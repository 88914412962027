import React, { useState, useEffect } from "react";
import {MDBInput } from 'mdbreact';
import {  MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { Component } from "react";
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';
import MessageCardEventJSON from '../../json/message_cards_event.json';
import MessageCardGiftJSON from '../../json/message_cards_gift.json';
import MessageCardCardJSON from '../../json/message_cards_card.json';
import logo_rev from '../../assets/logo_rev.jpg';
import jsPDF from "jspdf";
import FontPicker from './FontPicker';


const importAllFonts = (r) => {
    let fonts = {};
    r.keys().map((item, index) => { fonts[item.replace('./', '')] = r(item); });
    return fonts;
}

const fonts = importAllFonts(require.context('../../fonts/materials-fonts', false, /\.(txt)/));

const loadImage = (url) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.src = url;
    })
  }

class MessageCards extends Component {
    _isMounted = false;
    container = null;
    message_card_json="";
    constructor(props) {
        super(props);      
        if(this.props.fromPage === "Event")
        this.message_card_json = MessageCardEventJSON;
        else if (this.props.fromPage === "Gift")
        this.message_card_json = MessageCardGiftJSON;
        else if (this.props.fromPage === "Card")
        this.message_card_json = MessageCardCardJSON;

        this.state = {
            selectedFields : {},
            selectedFont: 1,
            selectedFontName: 'Arial',
            //selectedFontBase64: '',
            selectedFontType: 'normal',
            selectedFontStyle: 'normal',
            selectedFontSize: 12,
            selectedFontColor: '#123456',
            colorPicker: false,
            selectedDispWidth: 3,
            selectedDispHeight: 3,
            sortBy: "",
            selectedFontFileName: {                
                normal: "arial.ttf",
                bold: "arialbd.ttf",
                italic: "ariali.ttf",
                bolditalic: "arialbi.ttf"                    
            },
            messageCardsStatus: false,
            giftMessage: this.props.giftMessage

        }
    }

    handleSideNavActive = (value, routePath) => {
        this.setState({
            activeTab: value
        } );
        this.props.history.push(routePath, this.state);
    }

    handleFieldsChecked = (sectionType, subSectionGroupName, fieldId, event) => {
        var currState = this.state.selectedFields;
        currState[subSectionGroupName][fieldId] = event.target.checked;
        this.setState({selectedFields : currState});
    }

    handleTextFieldsChanged = (sectionType, subSectionGroupName, fieldId, event) => {
        var currState = this.state.selectedFields;
        currState[subSectionGroupName]["text_"+fieldId] = event.target.value;
        this.setState({selectedFields : currState});
    }

    handleRadioFieldsChecked = (sectionType, subSectionGroupName, fieldId, radioGroupName, event) => {
        var currState = this.state.selectedFields;
        //var groupFields = currState[subSectionGroupName];
        var groupFields = currState[subSectionGroupName][radioGroupName];
        // radioGroupItems[fields.fieldId] = fields.default;
        // checkedItems[radioGroupName] = radioGroupItems;
        for (var key in groupFields) {
                if (key === fieldId) {
                   // console.log(key + " -> " + groupFields[key]);
                    groupFields[key] = true;
                }
                else
                groupFields[key] = false;
            }
        currState[subSectionGroupName][radioGroupName] = groupFields;
        //currState[subSectionGroupName][fieldId] = event.target.checked;

        if(radioGroupName == "sortOrder")
            this.setState({sortBy : fieldId});

        this.setState({selectedFields : currState});
    }

    getBase64(filePath, cb) {
        let reader = new FileReader();
        //var file = new File(filePath);
        reader.readAsBinaryString(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    toBinary(string) {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
          codeUnits[i] = string.charCodeAt(i);
        }
        const charCodes = new Uint8Array(codeUnits.buffer);
        let result = '';
        for (let i = 0; i < charCodes.byteLength; i++) {
          result += String.fromCharCode(charCodes[i]);
        }
        return result;
    }

    messageCards = () =>{
        //alert("Exported");
        this.setState({messageCardsStatus : true});
        var input = this.state.selectedFields;
        console.log("Message Cards -->" + JSON.stringify(input));
        
        loadImage(logo_rev).then((logoImg) => {
            const doc = new jsPDF('p', 'in', 'letter');     
            //var base64 = '';   
            // var imgData = 'data:image/jpg;base64,';//+ Base64.encode(logo_rev);
            // var img = new Image();
            //const doc = new jsPDF('p', 'mm', 'a4');
            // doc.addImage(logo, 'PNG', 10, 10);
            // doc.save('report.pdf');
            var pageWidth = doc.internal.pageSize.getWidth();
            var pageHeight = doc.internal.pageSize.getHeight();
            var sortField = this.state.sortBy;
            console.log("Page Width: "+pageWidth+"; Page Height: "+pageHeight);
            var x= this.state.selectedDispWidth;
            var y= this.state.selectedDispHeight;
            var fontColor = this.state.selectedFontColor;           
            var fontName = this.state.selectedFontName;
            var fontType = this.state.selectedFontType;
            var fontSize = this.state.selectedFontSize;
            var fontStyle = this.state.selectedFontStyle;
            //var fontBase64 = this.state.selectedFontBase64;
            var fontFileName = this.state.selectedFontFileName;
            var key="normal";
            if(fontType == "bold" && fontStyle == "italic")
            key="bolditalic";
            else if (fontType == "bold")
            key="bold";
            else if(fontStyle == "italic")
            key="italic";

            var font_final ;//= fontBase64[key];
            
            //this.getBase64('../../fonts/materials-fonts/'+fontFileName[key]+'.ttf', (result) => {
            GifterService.fetchFile(fonts[fontFileName[key]+".txt"]).
            then((response) => {
                return response.text();
            }).
            then((result) =>{                
            // font_final = btoa(unescape(encodeURIComponent(result)));
            
            // console.log("Font Encoded Final -->"+font_final);
            // font_final =  btoa(this.toBinary(result));
            // console.log("Font Encoded Final -->"+font_final);
            // if(fontName == "Arial")
            // font_final = FontConstantsBase64.Font_Families_Base64[fontName]["normal"];
            
            // if(fontName == "Segoe Script")
            // fontName = "Segoe Script Bold"

            doc.addFileToVFS(fontFileName[key], result);
            doc.addFont(fontFileName[key], fontName, key);
            doc.setFont(fontName);
            //doc.setFontType(this.state.selectedFontType);
            //if(this.state.selectedFontStyle != "")
            doc.setFontStyle(key);
            doc.setFontSize(fontSize);            
            doc.setTextColor(parseInt(fontColor.substring(1,3),16), parseInt(fontColor.substring(3,5),16), parseInt(fontColor.substring(5,7),16));
            if(this.props.fromPage== "Event" || this.props.fromPage== "Card" || (this.props.fromPage== "Gift" && this.state.selectedFields["Options"]?.["cardGroup"]?.["Gift_Recipient"])){
                
                var recipients = this.props.recipients.sort((a, b) => {
                    if (a[sortField] < b[sortField])
                      return -1;
                    if (a[sortField] > b[sortField])
                      return 1;
                    return 0;
                  });
                
                var isWBLogo = this.state.selectedFields["Options"]?.["WB_Logo"];
                var logoHeight = 0;
                if(isWBLogo)
                  logoHeight = 0.75;
                
                var cnt = recipients.length;
                recipients.map((recipient, index) => {
                    if(isWBLogo)  
                    doc.addImage(logoImg, 'JPG', pageWidth - x, pageHeight - y , 0.6, 0.6,'', 'FAST'); 
                    
                    var message = "";
                    if(this.props.fromPage == "Gift")
                    message = this.state.selectedFields["Options"]?.["message"]?.["Generic_Message"] ? this.state.selectedFields["Options"]?.["text_Generic_Message"] : "<R>";
                    else
                    message = this.state.selectedFields["Options"]?.["Generic_Message"] ? this.state.selectedFields["Options"]?.["text_Generic_Message"] : "<R>";
                    
                    var wrapWidth = x;
                    var messageWrap = doc.splitTextToSize(message.replace("<R>", recipient.first_name+" "+recipient.last_name), wrapWidth);                    
                    doc.text(messageWrap, pageWidth - x, pageHeight - (y - logoHeight));

                    if(cnt > index + 1)
                    doc.addPage("letter", "p");
                });
            }
            else if(this.props.fromPage== "Gift" && this.state.selectedFields["Options"]?.["cardGroup"]?.["Gift_Item"]){
                
                var isWBLogo = this.state.selectedFields["Options"]?.["WB_Logo"];
                var logoHeight = 0;
                if(isWBLogo)
                  logoHeight = 0.75;
                var cnt = this.props.gifters[0].distributions.length;
                this.props.gifters[0].distributions.map((gift_dist, index) => {
                    if(isWBLogo)  
                    doc.addImage(logoImg, 'JPG', pageWidth - x, pageHeight - y, 0.6, 0.6,'', 'FAST'); 
                    
                    var recipients_sort = gift_dist.recipients.sort((a, b) => {
                        if (a[sortField] < b[sortField])
                          return -1;
                        if (a[sortField] > b[sortField])
                          return 1;
                        return 0;
                      });
                    var recipients = recipients_sort.map((recipient) => (recipient.first_name+" "+recipient.last_name)).join(', ');
                    var message = "";
                    if(this.state.selectedFields["Options"]?.["message"]?.["Generic_Message"])
                      message = this.state.selectedFields["Options"]?.["text_Generic_Message"];
                    else if(this.state.selectedFields["Options"]?.["message"]?.["Use_Custom_Gift_Messages"])
                      message = gift_dist.gift_message; 
                    
                    if(message == "" || message == null) message = "<R>";

                    var wrapWidth = x;
                    var messageWrap = doc.splitTextToSize(message.replace("<R>", recipients), wrapWidth); 
                    doc.text(messageWrap, pageWidth - x, pageHeight - (y - logoHeight));

                    if(cnt > index + 1)
                    doc.addPage("letter", "p");
                });
            }
            doc.save("message-cards-"+this.props.fromPage+"-"+ this.props.name +"-"+this.props.id.toString()+".pdf"); // will save the file in the current working directory
            this.setState({messageCardsStatus : false});
            }).
            then((err) => {
                console.log("Fetch font file error --> "+err);
                this.setState({messageCardsStatus : false});
            });//Load Font file
            
        });
        
    }

    handleFontChange = (event, fontValue, fontName, fontFileName) =>{
        this.setState({selectedFont : fontValue,
                       selectedFontName: fontName, 
                       //selectedFontBase64: fontBase64,
                       selectedFontFileName: fontFileName,
                    });
    }

    handleFontSizeChange = (event) =>{
        this.setState({selectedFontSize : event.target.value});
    }

    toggleFontColor = () => {
        this.setState({ colorPicker: !this.state.colorPicker });
    };

    saveFontColor = (value) => {
        this.setState({ selectedFontColor: value.hex });
    }

    closeFontColor = () => {
        this.setState({ colorPicker: false });
    }

    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
        //var finalJson = this.state.selectedFields;
        var finalArr = this.state.selectedFields;
        this.message_card_json.sections.map((section) => {
            //var finalArr = finalJson[section.type] || {};
            section.subSections.map((subSection) => {
                var checkedItems = {};
                subSection.values.map((fields) => {
                    
                    if(fields.textArea)
                    checkedItems["text_"+fields.fieldId] = "";

                    if(fields.fieldId == "Generic_Message")
                        checkedItems["text_"+fields.fieldId] = this.state.giftMessage || "";

                    if(fields.radio)
                    {
                        var radioGroupItems = checkedItems[fields.radioGroupName] || {};
                        radioGroupItems[fields.fieldId] = fields.default;
                        checkedItems[fields.radioGroupName] = radioGroupItems;
                        if(fields.radioGroupName == "sortOrder" && fields.default)
                            this.setState({sortBy : fields.fieldId});
                    }
                    else
                        checkedItems[fields.fieldId] = fields.default;
                    
                });
                finalArr[subSection.groupName] = checkedItems;
            });
               // finalJson[section.type] = finalArr;
        });
        this.setState({selectedFields : finalArr });
    }


    inputChangeHandler = (name, event) => {
        this.setState({ [name]: parseInt(event.target.value, 10)
        });
    }

    handleFontStyle = (name, event) =>{
        var fontType = this.state.selectedFontType;
        var fontStyle = this.state.selectedFontStyle;

        if(name === "bold")
        this.setState({ 
            selectedFontType: (fontType == name ? "normal" : "bold")
        });

        if(name === "italic")
        this.setState({ 
            selectedFontStyle: (fontStyle == name ? "normal" : "italic")
        });
    }



    componentWillUnmount() {
        this._isMounted = false;
    }


    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
        // var currState = this.state.selectedFields;
        // if(currState && currState["Options"]){
        //     if(nextProps.fromPage == "Gift")
        //         currState["Options"]["message"]["text_Generic_Message"] = nextProps.giftMessage || "";
        //     else
        //         currState["Options"]["text_Generic_Message"] = nextProps.giftMessage || "";

        //     this.setState({selectedFields : currState});
        //   }
        }
    }

    render(){
        return (
            <>
           <div>
                <div className="fst-italic mat-sub-section col-12">
                     Create a PDF containing a list of message cards to be printed. Contents are centered vertically-horizontally and text is left aligned.
                </div>
                {/* <div className="h6 fw-bold"> 
                    {this.export_details_json.name}
                </div>
                <div className="mat-sub-section fst-italic"> 
                    {this.export_details_json.value}
                </div> */}
                {
                    this.message_card_json.sections.map((section) => {
                        return <div > 
                            {/* className={section.fullPage ? "row" : ""} */}
                                    <div className="h6 fw-bold margin-ver-none padding-ver"> 
                                        {section.name}
                                    </div>
                                    <div className="row">
                                    
                                    {/* {section.values.map((value) => {
                                        <> */}
                                        {section.subSections.map((subSection) => {
                                            return <>                                                
                                                <div className={"padding-hor-25 col-6"}>
                                                    <div className={subSection.newSection ? "padding-ver margin-top" : "padding-ver"}>
                                                        <div className={subSection.section ? "h6 fw-bold margin-hor-minus-12 padding-ver" : "mat-sub-section-header fst-italic padding-hor-15"}>
                                                            {subSection.name}
                                                        </div>
                                                        {subSection.values.map((fields) => {
                                                            return <> 
                                                            <div className="mat-sub-section">
                                                            {fields.sectionHeader != undefined ? 
                                                            <div className= "mat-sub-section-header fst-italic padding-hor-15">
                                                                {fields.sectionHeader}
                                                            </div> :
                                                            <> </>
                                                            } 

                                                            {fields.radio !== true ?
                                                                <>
                                                                    <div className="flexBox-plain align-center-vertical">
                                                                        <input type ="checkbox" value={section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                            name={"cb-"+ section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                            checked={this.state.selectedFields[subSection.groupName]?.[fields.fieldId] || false} 
                                                                            onChange={this.handleFieldsChecked.bind(this, section.type, subSection.groupName, fields.fieldId )}/>
                                                                        <label className="padding-left-3">{fields.fieldName}</label> 
                                                                    </div>
                                                                    {/* <input type="textarea" className={fields.textArea ? "d-block":"d-none"} rows={4} /> */}
                                                                    
                                                                </>
                                                                :
                                                                <> 
                                                                    {/* <Radio
                                                                        checked={this.state.selectedFields[subSection.groupName]?.[fields.fieldId] || false} 
                                                                        onChange={this.handleFieldsChecked.bind(this,section.type, subSection.groupName, fields.fieldId )}
                                                                        value= {section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                        name={"radio-button-group-"+ fields.radioGroupName}
                                                                        inputProps={{ 'aria-label': fields.fieldId }}
                                                                    /> */}
                                                                    <div className="flexBox-plain align-center-vertical">
                                                                        <input type ="radio" value={section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                            checked={this.state.selectedFields[subSection.groupName]?.[fields.radioGroupName]?.[fields.fieldId] || false} 
                                                                            name={"radio-button-group-"+ fields.radioGroupName}
                                                                            onChange={this.handleRadioFieldsChecked.bind(this, section.type, subSection.groupName, fields.fieldId, fields.radioGroupName )}/>
                                                                        <label className="padding-left-3">{fields.fieldName}</label> 
                                                                    </div>
                                                                </>
                                                                }
                                                                <div className={fields.textArea ? "padding-hor-12":"d-none"}>
                                                                    <MDBInput type="textarea" placeholder=''  className='txtGenericMessage'
                                                                    onChange={this.handleTextFieldsChanged.bind(this, section.type, subSection.groupName, fields.fieldId )}
                                                                    disabled = { (fields.radio ? (this.state.selectedFields[subSection.groupName]?.[fields.radioGroupName]?.[fields.fieldId] || false): 
                                                                        (this.state.selectedFields[subSection.groupName]?.[fields.fieldId] || false)) ? false: true }
                                                                                name={"text-"+ section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                                rows={3}
                                                                                value = {this.state.selectedFields[subSection.groupName]?.[fields.radioGroupName]?.["text_"+fields.fieldId] || 
                                                                                    this.state.selectedFields[subSection.groupName]?.["text_"+fields.fieldId] || ""}>

                                                                    </MDBInput>
                                                                </div>
                                                            </div>
                                                            </>})
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            })
                                        }                                        
                                    </div>
                               </div> 
                            })
                        }

                    {/* <FormControl className="form-control"> */}
                        <FontPicker 
                        selectedFont = {this.state.selectedFont}
                        selectedFontName = {this.state.selectedFontName}
                        selectedFontSize = {this.state.selectedFontSize}
                        colorPicker = {this.state.colorPicker}
                        selectedFontColor = {this.state.selectedFontColor}
                        toggleFontColor = {this.toggleFontColor.bind(this)}
                        closeFontColor={this.closeFontColor.bind(this)}
                        saveFontColor={this.saveFontColor.bind(this)}
                        handleFontStyle={this.handleFontStyle.bind(this)}
                        handleFontSizeChange={this.handleFontSizeChange.bind(this)}
                        handleFontChange={this.handleFontChange.bind(this)}

                        />


                        <div className="padding-ver-12"> 
                            <div className="h6 fw-bold margin-ver-none padding-ver">
                                Preview
                            </div>
                            <div className="row mat-sub-section padding-hor-12">
                            <div class="col-4" id="material-content-dim">
                                <div class="margin-left flexBox-plain padding-ver">
                                    <div class="col-4">
                                        <label>Height:</label> 
                                    </div>
                                    <div class="col-3">
                                        {/* <input type="text" style={{width: "100%"}} />  */}
                                        <MDBInput placeholder='' value={this.state.selectedDispHeight} 
                                        name={"selectedDispHeight"} onChange={this.inputChangeHandler.bind(this, "selectedDispHeight")} type="number" >

                                        </MDBInput>
                                    </div>
                                    <div class="col-1 padding-hor">in.
                                    </div>
                                    <div class="col-auto"></div>
                                </div>
                                <div class="margin-left flexBox-plain padding-ver" >
                                    <div class="col-4">
                                        <label>Width:</label> 
                                    </div>
                                    <div class="col-3">
                                        {/* <input type="text" style={{width: "100%"}} />    */}
                                        <MDBInput placeholder='' value={this.state.selectedDispWidth} 
                                        name={"selectedDispWidth"} onChange={this.inputChangeHandler.bind(this, "selectedDispWidth")} type="number" >

                                        </MDBInput>                                 
                                    </div>
                                    <div class="col-1 padding-hor">in.
                                    </div>
                                    <div class="col-auto"></div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="preview-materials">
                                    <div className={(this.state.selectedFields["Options"]?.["WB_Logo"] || false) ? "d-flex": "d-none"}
                                            style={{justifyContent: "center"}}>
                                    <img
                                        src={logo_rev}
                                        alt='Logo' style={{width:"30px", height: "30px" }}                                            
                                    />
                                    </div>
                                    <div style={{fontSize: this.state.selectedFontSize+'px', fontStyle : this.state.selectedFontStyle, 
                                                fontWeight: this.state.selectedFontType, color: this.state.selectedFontColor}}
                                                className= {"font-family-"+ this.state.selectedFontName.replace(/\s+/g, "-").toLowerCase()}
                                    >
                                        {(this.state.selectedFields["Options"]?.["message"]?.["Generic_Message"] || 
                                                this.state.selectedFields["Options"]?.["Generic_Message"] || false) &&
                                            this.state.selectedFields["Options"]?.["text_Generic_Message"].split("\n").map(function(item, idx) {
                                                return (
                                                    <span key={idx}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            </div>

                        </div>


                    {/* </FormControl> */}
                    <div className="padding-hor flexAlignRight">
                        <div className="padding-hor">
                            <MDBBtn onClick={this.messageCards.bind(this)}>
                                <MDBIcon className={this.state.messageCardsStatus ? "" : "d-none"} icon="spinner" spin/>   Create
                            </MDBBtn>
                        </div>
                        <div className="padding-hor">
                            <MDBBtn onClick={this.props.toggleMaterials}>
                                Cancel
                            </MDBBtn>
                        </div>
                    </div>
            </div>
            </>
        );
    }

}

export default MessageCards;