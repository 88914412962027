import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect} from '@material-ui/core';
import React from 'react'

export default function Select(props){

    const {name, label, value, error=null, columnName, columnID, onChange, options} = props;

    return(
<FormControl
        variant = "outlined"
        {...(error && {error:true})}>

            <MuiSelect displayEmpty native
            name = {name}
            value = {value}
            onChange={onChange}> 
                {
                    options.map(
                        item => (<option key={item[columnID]} value={item[columnName]}>{item[columnName]}</option>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}