import React from 'react';
import TextField from "@material-ui/core/TextField";
import { MDBInput } from "mdbreact";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class BasicInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personDetails: []
        }
    }

    componentDidMount() {
        this.setState({ personDetails: this.props.personDetails });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ personDetails: nextProps.personDetails });
    }

    getFormattedDate = (date) => {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return year + '-' + month + '-' + day;
    }

    render() {
       // var date = this.state?.personDetails?.dob ? this.getFormattedDate(new Date(this.state?.personDetails?.dob)) : null;
        var date = this.state?.personDetails?.dob ? this.getFormattedDate(new Date(this.state?.personDetails?.dob.replace(/-/g, '\/').replace(/T.+/, ''))) : null;
        return (
            <div className="basicInfoContainer">
                <h6>Basic Info</h6>
                <div className="row">
                    <div className="col-4">
                        <label className="inputLabel fs-75"><span className="required">*</span> First Name</label>
                    </div>
                    <div className="col-8">
                        <MDBInput className="col"
                            id="first_name"
                            type="text"
                            value={this.state?.personDetails?.first_name}
                            onChange={(event) => this.props?.handleOnChange(event, "first_name")}
                            required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <label className="inputLabel fs-75"><span className="required">*</span> Last Name</label>
                    </div>
                    <div className="col-8">
                        <MDBInput id="last_name"
                            type="text"
                            value={this.state?.personDetails?.last_name}
                            onChange={(event) => this.props?.handleOnChange(event, "last_name")}
                            required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <label className="inputLabel fs-75">&nbsp;&nbsp;Display As</label>
                    </div>
                    <div className="col-8">
                        <MDBInput id="display_name"
                            placeholder="Optional"
                            type="text"
                            value={this.state?.personDetails?.display_name}
                            onChange={(event) => this.props?.handleOnChange(event, "display_name")} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <label className="inputLabel fs-75">&nbsp;&nbsp;DOB</label>
                    </div>
                    <div className="col-8">
                        <TextField
                            type="date"
                            id="dob"
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            variant="outlined"
                            value={date ? date : null}
                            onChange={(event) => this.props?.handleOnChange(event, "dob")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <label className="inputLabel fs-75">&nbsp;&nbsp;Status</label>
                    </div>
                    <div className="col-8">
                        <Select
                            id="is_active"
                            value={this.state?.personDetails?.is_active ? this.state?.personDetails?.is_active : false}
                            onChange={(event) => this.props?.handleOnChange(event, "is_active")}
                            disableUnderline
                        >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
        );
    }
}

export default BasicInfo;