import React from 'react';
import { MDBInput } from "mdbreact";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Action from './Action';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comp_div_title_list: [],
            companies: [],
            divisions: [],
            titles: []
        }
    }


    componentWillReceiveProps(nextProps) {
        var companies = nextProps.personDetails?.comp_div_title_list?.map((company) => ({ value: company?.company_id, text: company?.company_name?.toString(), isDisabled: company?.p_comp_div_title_id }));
        var divisions = nextProps.personDetails?.comp_div_title_list?.map((division) => ({ value: division?.division_id, text: division?.division_name?.toString(), isDisabled: division?.p_comp_div_title_id }));
        var titles = nextProps.personDetails?.comp_div_title_list?.map((title) => ({ value: title?.title_id, text: title?.title_name?.toString(), isDisabled: title?.p_comp_div_title_id }));
        this.setState({ comp_div_title_list: nextProps.personDetails?.comp_div_title_list, companies: companies, divisions: divisions, titles: titles });
    }

    componentDidMount() {
        var companies = this.props.personDetails?.comp_div_title_list?.map((company) => ({ value: company?.company_id, text: company?.company_name?.toString(), isDisabled: company?.p_comp_div_title_id }));
        var divisions = this.props.personDetails?.comp_div_title_list?.map((division) => ({ value: division?.division_id, text: division?.division_name?.toString(), isDisabled: division?.p_comp_div_title_id }));
        var titles = this.props.personDetails?.comp_div_title_list?.map((title) => ({ value: title?.title_id, text: title?.title_name?.toString(), isDisabled: title?.p_comp_div_title_id }));
        this.setState({ comp_div_title_list: this.props.personDetails?.comp_div_title_list, companies: companies, divisions: divisions, titles: titles });
    }

    handlePrimaryAndActive = (field, field_param, object, value) => {
        let newArray = [];
        if (field_param === "is_primary") {
            newArray = this.props.personDetails[field]?.map(item => {
                if (value) {
                    if (object === item) {
                        item.is_primary = 1;
                        item.is_active = 1;
                        return item;
                    }
                    else {
                        item.is_primary = 0
                        return item;
                    }
                } else {
                    if (object === item) {
                        item.is_primary = 0;
                        return item;
                    } else return item;
                }
            })
        } else if (field_param === "is_active") {
            newArray = this.props.personDetails[field]?.map(item => {
                if (object === item) {
                    if (value) {
                        item.is_active = 1
                        return item;
                    } else if (!value) {
                        if (item.is_primary === 1) {
                            this.setState({ showModal: true });
                        } else {
                            item.is_active = 0;
                        }
                        return item;
                    }
                } else return item;
            })
        }
        this.props.handleContactListState(field, newArray);
    }

    render() {
        return (
            <div className="companyContainer">
                <h6>Company</h6>
                <div className="row">
                    <div className="col-4">
                        {/* <label><strong>Company</strong></label> */}
                    </div>
                    <div className="col-2">
                        <div className="row fs-50 fw-bold padding-top-7">
                            <div className="col-4">primary</div>
                            <div className="col-3">active</div>
                        </div>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-2">
                        <div className={this.state.comp_div_title_list?.length > 1 ? "row fs-50 fw-bold padding-top-7" : "d-none"}>
                            <div className="col-4">primary</div>
                            <div className="col-3">active</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                {
                    (this.state.comp_div_title_list && this.state.comp_div_title_list?.length !== 0) ? this.state.comp_div_title_list?.map((list, index) => (
                            <>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-3">
                                        <label className="inputLabel fs-75">Company</label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            id={"company" + index}
                                            options={this.props?.list.company}
                                            value={this.state.companies[index]}
                                            disabled = {this.state.companies[index]?.isDisabled}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            style={{ width: '100%' }}
                                            onChange={(e, value) => this.props.handleCompanyInputChange("company", value, list)}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label className="inputLabel fs-75">Division</label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            id={"division" + index}
                                            options={this.props?.list.division}
                                            value={this.state.divisions[index]}
                                            disabled = {this.state.divisions[index]?.isDisabled}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            onChange={(e, value) => this.props.handleCompanyInputChange("division", value, list)}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>
                                <div className="row" id="titleRow">
                                    <div className="col-3">
                                        <label className="inputLabel fs-75">Title</label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            id={"title" + index}
                                            options={this.props?.list.title}
                                            value={this.state.titles[index]}
                                            disabled = {this.state.titles[index]?.isDisabled}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            onChange={(e, value) => this.props.handleCompanyInputChange("title", value, list)}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <Action area="company"
                                    handlePrimaryAndActive={this.handlePrimaryAndActive}
                                    index={index}
                                    item={list}
                                    handleChange={this.props.handleContactInfo}
                                    field={"comp_div_title_list"}
                                    length={this.state.comp_div_title_list?.length} />
                            </div>
                        </>
                    )) : (
                        <>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-3">
                                        <label className="inputLabel fs-75">Company</label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            id="company"
                                            options={this.props?.list.company}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            style={{ width: '100%' }}
                                            onChange={(e, value) => this.props.handleCompanyInputChange("company", value, {})}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label className="inputLabel fs-75">Division</label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            id="division"
                                            options={this.props?.list.division}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            onChange={(e, value) => this.props.handleCompanyInputChange("division", value, {})}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label className="inputLabel fs-75">Title</label>
                                    </div>
                                    <div className="col-9">
                                        <Autocomplete
                                            id="title"
                                            options={this.props?.list.title}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            onChange={(e, value) => this.props.handleCompanyInputChange("title", value, {})}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <Action area="company"
                                    handlePrimaryAndActive={this.handlePrimaryAndActive}
                                    index={0}
                                    item={{}}
                                    handleChange={this.props.handleContactInfo}
                                    field={"comp_div_title_list"}
                                    length={0} />
                            </div>
                        </>
                    )
                }
                </div>
            </div>
        );
    }
}

export default Company;