import React from 'react';
import TabContent from './TabContent';
import GifterService from '../../services/service';
import * as Constants from '../../constants/constants';
import AddOrEditModal from './AddOrEditModal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import './DataManagement.scss';

export default class DataManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: props?.history?.location.state == "CG" ? 1 : 0,
            tabList: [
                { label: "Vendors", index: 0, name: "Vendor" },
                { label: "Custom Groups", index: 1, name: "Custom Group" },
                { label: "Companies", index: 2, name: "Company" },
                { label: "Divisions", index: 3, name: "Division" },
                { label: "Categories", index: 4, name: "Category" },
                { label: "Titles", index: 5, name: "Title" },
                { label: "Shows", index: 6, name: "Show" },
                { label: "Occasions", index: 7, name: "Occasion" }
            ],
            postJson: null,
            modalMode: "",
            initialPostJsons: {
                company: {
                    "company_id": null,
                    "company_name": "",
                    "is_active": 1
                },
                division: {
                    "division_id": null,
                    "division_name": "",
                    "is_active": 1
                },
                category: {
                    "category_id": null,
                    "category_name": "",
                    "is_active": 1
                },
                title: {
                    "title_id": null,
                    "title_name": "",
                    "is_active": 1
                },
                show: {
                    "show_id": null,
                    "show_name": ""
                },
                occasion: {
                    "occasion_id": null,
                    "occasion_name": ""
                }
            },
            activeTabEditObject: {},
            tabListData: [],
            isTableLoading: false,
            showModal: false,
            dropDownList: [],
            isSaveDisabled: false,
            isPosting: false,
            saveState: "",
            saveSuccessMessage: "The changes have been saved successfully!",
            saveErrorMessage: "We couldn't save your changes",
        }
    }

    componentDidMount() {
        this.getRenderList();
    }

    isSaveEnabled = (name) => {
        if (name) {
            this.setState({ isSaveDisabled: false });
        } else {
            this.setState({ isSaveDisabled: true });
        }
    }

    handleEdit = (value, field) => {
        let postJson = this.state.postJson;
        if (field === "status") {
            postJson.is_active = value;
        } else if (field === "name") {
            switch (this.state.tabValue) {
                case 2:
                    postJson.company_name = value;
                    break;
                case 3:
                    postJson.division_name = value;
                    break;
                case 4:
                    postJson.category_name = value;
                    break;
                case 5:
                    postJson.title_name = value;
                    break;
                case 6:
                    postJson.show_name = value;
                    break;
                case 7:
                    postJson.occasion_name = value;
                    break;
            }
        }
        else if (field === "autocomplete") {
            postJson.text = typeof value === 'string' ? value : value?.text;
            switch (this.state.tabValue) {
                case 2:
                    postJson.company_name = typeof value === 'string' ? value : value?.text;
                    break;
                case 3:
                    postJson.division_name = typeof value === 'string' ? value : value?.text;
                    break;
                case 4:
                    postJson.category_name = typeof value === 'string' ? value : value?.text;
                    break;
                case 5:
                    postJson.title_name = typeof value === 'string' ? value : value?.text;
                    break;
                case 6:
                    postJson.show_name = typeof value === 'string' ? value : value?.text;
                    break;
                case 7:
                    postJson.occasion_name = typeof value === 'string' ? value : value?.text;
                    break;
            }
        }
        this.setState({ postJson: postJson }, () => {
            let nameKey = Object.keys(this.state.postJson).find(name => name.includes("name"));
            this.isSaveEnabled(this.state.postJson[nameKey]);
        });
    }

    handleSave = () => {
        console.log("Param", this.state.postJson);
        switch (this.state.tabValue) {
            case 2:
                this.postCompany();
                break;
            case 3:
                this.postDivision();
                break;
            case 4:
                this.postCategory();
                break;
            case 5:
                this.postTitle();
                break;
            case 6:
                this.postShow();
                break;
            case 7:
                this.postOccassion();
                break;
        }
    }

    getRenderList = () => {
        switch (this.state.tabValue) {
            case 0:
                this.fetchVendorsAll();
                break;
            case 1:
                this.fetchCustomGroups();
                break;
            case 2:
                this.fetchCompaniesAll();
                break;
            case 3:
                this.fetchDivisionsAll();
                break;
            case 4:
                this.fetchCategoriesAll();
                break;
            case 5:
                this.fetchTitlesAll();
                break;
            case 6:
                this.fetchShows();
                break;
            case 7:
                this.fetchOccasionsAll();
                break;
        }
    }

    getFormattedDate = (date) => {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return month + '/' + day + '/' + year;
    }

    fetchDivisionsAll = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/divisionsAll?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((division) => ({
                    id: division.Division_id,
                    name: division.Division_name.toString(),
                    is_active: (division.is_active && division.is_active === 1) ? "Active" : "Inactive",
                    updated_at: division.updated_at ? this.getFormattedDate(new Date(division.updated_at)) : "",
                    updated: (division.updated_at ? this.getFormattedDate(new Date(division.updated_at)) : "") + " " + (division.updated_by ? "by " + division.updated_by : "")
                }));
                var dropDownList = response.data.map((division) => ({ value: division.Division_id, text: division.Division_name.toString() }));
                this.setState({ tabListData: formattedArray, dropDownList: dropDownList });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load Divisions all Error-->" + err);
                })
    }

    postDivision = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (this.state.tabListData.some(item => item.name.toString() === this.state.postJson.division_name.toString() && !this.state.postJson.division_id)) {
            NotificationManager.warning(`${this.state.postJson.division_name} is already available!`, 'Warning!', 3000);
        } else {
            this.setState({ isPosting: true });
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/divisionAdd', this.state.postJson)
                .then((response) => {
                    console.log("post response", response);
                    this.getRenderList();
                    this.setState({ isPosting: false, showModal: false })
                    if (this.state.postJson.division_id) {
                        NotificationManager.success(this.state.saveSuccessMessage, 'Success!');
                    } else {
                        NotificationManager.success("New Division has been successfully created!", 'Success!');
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false, showModal: false })
                        NotificationManager.error(this.state.saveErrorMessage, 'Error!', 3000);
                        console.log("Post division error: " + err);
                    });
        }
    }

    fetchCategoriesAll = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/categoriesAll?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((category) => ({
                    id: category.category_id,
                    name: category.category_name.toString(),
                    updated_at: category.updated_at ? this.getFormattedDate(new Date(category.updated_at)) : "",
                    is_active: (category.is_active && category.is_active === 1) ? "Active" : "Inactive",
                    updated: (category.updated_at ? this.getFormattedDate(new Date(category.updated_at)) : "") + " " + (category.updated_by ? "by " + category.updated_by : "")
                }));
                var dropDownList = response.data.map((category) => ({ value: category.category_id, text: category.category_name.toString() }));
                this.setState({ tabListData: formattedArray, dropDownList: dropDownList });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load Categories all Error-->" + err);
                })
    }

    postCategory = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (this.state.tabListData.some(item => item.name.toString() === this.state.postJson.category_name.toString() && !this.state.postJson.category_id)) {
            NotificationManager.warning(`${this.state.postJson.category_name} is already available!`, 'Warning!', 3000);
        } else {
            this.setState({ isPosting: true });
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/categoryAdd', this.state.postJson)
                .then((response) => {
                    console.log("post response", response);
                    this.getRenderList();
                    this.setState({ isPosting: false, showModal: false })
                    if (this.state.postJson.category_id) {
                        NotificationManager.success(this.state.saveSuccessMessage, 'Success!');
                    } else {
                        NotificationManager.success("New Category has been successfully created!", 'Success!');
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false, showModal: false })
                        NotificationManager.error(this.state.saveErrorMessage, 'Error!', 3000);
                        console.log("Post category error: " + err);
                    });
        }
    }

    fetchCompaniesAll = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/companiesAll?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((company) => ({
                    id: company.company_id,
                    name: company.company_name.toString(),
                    updated_at: company.updated_at ? this.getFormattedDate(new Date(company.updated_at)) : "",
                    is_active: (company.is_active && company.is_active === 1) ? "Active" : "Inactive",
                    updated: (company.updated_at ? this.getFormattedDate(new Date(company.updated_at)) : "") + " " + (company.updated_by ? "by " + company.updated_by : "")
                }));
                var dropDownList = response.data.map((company) => ({ value: company.company_id, text: company.company_name.toString() }));
                this.setState({ tabListData: formattedArray, dropDownList: dropDownList });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load Companies all Error-->" + err);
                })
    }

    postCompany = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (this.state.tabListData.some(item => item.name.toString() === this.state.postJson.company_name.toString() && !this.state.postJson.company_id)) {
            NotificationManager.warning(`${this.state.postJson.company_name} is already available!`, 'Warning!', 3000);
        } else {
            this.setState({ isPosting: true });
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/companyAdd', this.state.postJson)
                .then((response) => {
                    console.log("post response", response);
                    this.getRenderList();
                    this.setState({ isPosting: false, showModal: false })
                    if (this.state.postJson.company_id) {
                        NotificationManager.success(this.state.saveSuccessMessage, 'Success!');
                    } else {
                        NotificationManager.success("New Company has been successfully created!", 'Success!');
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false, showModal: false })
                        NotificationManager.error(this.state.saveErrorMessage, 'Error!', 3000);
                        console.log("Post company error: " + err);
                    });
        }
    }


    fetchTitlesAll = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/titlesAll?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((title) => ({
                    id: title.title_id,
                    name: title.title_name.toString(),
                    updated_at: title.updated_at ? this.getFormattedDate(new Date(title.updated_at)) : "",
                    is_active: (title.is_active && title.is_active === 1) ? "Active" : "Inactive",
                    updated: (title.updated_at ? this.getFormattedDate(new Date(title.updated_at)) : "") + " " + (title.updated_by ? "by " + title.updated_by : "")
                }));
                var dropDownList = response.data.map((title) => ({ value: title.title_id, text: title.title_name.toString() }));
                this.setState({ tabListData: formattedArray, dropDownList: dropDownList });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load Titles all Error-->" + err);
                })
    }

    postTitle = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (this.state.tabListData.some(item => item.name.toString() === this.state.postJson.title_name.toString() && !this.state.postJson.title_id)) {
            NotificationManager.warning(`${this.state.postJson.title_name} is already available!`, 'Warning!', 3000);
        } else {
            this.setState({ isPosting: true });
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/titleAdd', this.state.postJson)
                .then((response) => {
                    console.log("post response", response);
                    this.getRenderList();
                    this.setState({ isPosting: false, showModal: false })
                    if (this.state.postJson.title_id) {
                        NotificationManager.success(this.state.saveSuccessMessage, 'Success!');
                    } else {
                        NotificationManager.success("New Title has been successfully created!", 'Success!');
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false, showModal: false })
                        NotificationManager.error(this.state.saveErrorMessage, 'Error!', 3000);
                        console.log("Post title error: " + err);
                    });
        }
    }

    fetchOccasionsAll = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/occasionsAll?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((occasion) => ({
                    id: occasion.occasion_id,
                    name: occasion.name.toString(),
                    updated_at: occasion.updated_at ? this.getFormattedDate(new Date(occasion.updated_at)) : "",
                    updated: (occasion.updated_at ? this.getFormattedDate(new Date(occasion.updated_at)) : "") + " " + (occasion.updated_by ? "by " + occasion.updated_by : "")
                }));
                var formattedArrayList = response.data.map((occasion) => ({ value: occasion.occasion_id, text: occasion.name.toString() }));
                this.setState({ tabListData: formattedArray, dropDownList: formattedArrayList });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load Occasions all Error-->" + err);
                })
    }

    postOccassion = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (this.state.tabListData.some(item => item.name.toString() === this.state.postJson.occasion_name.toString() && !this.state.postJson.occasion_id)) {
            NotificationManager.warning(`${this.state.postJson.occasion_name} is already available!`, 'Warning!', 3000);
        } else {
            this.setState({ isPosting: true });
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/occasionAdd', this.state.postJson)
                .then((response) => {
                    console.log("post response", response);
                    this.getRenderList();
                    this.setState({ isPosting: false, showModal: false })
                    if (this.state.postJson.occasion_id) {
                        NotificationManager.success(this.state.saveSuccessMessage, 'Success!');
                    } else {
                        NotificationManager.success("New Occasion has been successfully created!", 'Success!');
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false, showModal: false })
                        NotificationManager.error(this.state.saveErrorMessage, 'Error!', 3000);
                        console.log("Post occasion error: " + err);
                    });
        }
    }

    fetchVendorsAll = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/vendorsAll?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((vendor) => ({
                    id: vendor.vendor_id,
                    name: vendor.name.toString(),
                    is_active: (vendor.is_active && vendor.is_active === 1) ? "Active" : "Inactive",
                    updated_at: vendor.updated_at ? this.getFormattedDate(new Date(vendor.updated_at)) : "",
                    updated: (vendor.updated_at ? this.getFormattedDate(new Date(vendor.updated_at)) : "") + " " + (vendor.updated_by ? "by " + vendor.updated_by : "")
                }));
                this.setState({ tabListData: formattedArray });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load Vendors all Error-->" + err);
                })
    }

    fetchCustomGroups = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/customGroups?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((custGroup) => ({
                    id: custGroup.custom_group_id,
                    name: custGroup.custom_group_name.toString(),
                    updated_at: custGroup.updated_at ? this.getFormattedDate(new Date(custGroup.updated_at)) : "",
                    updated: (custGroup.updated_at ? this.getFormattedDate(new Date(custGroup.updated_at)) : "") + " " + (custGroup.updated_by ? "by " + custGroup.updated_by : "")
                }));
                this.setState({ tabListData: formattedArray });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load Custom Groups all Error-->" + err);
                })
    }

    fetchShows = () => {
        this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/shows?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isTableLoading: false });
                var formattedArray = response.data.map((show) => ({
                    id: show.show_id,
                    name: show.show_name.toString(),
                    updated_at: show.updated_at ? this.getFormattedDate(new Date(show.updated_at)) : "",
                    is_active: (show.is_active && show.is_active === 1) ? "Active" : "Inactive",
                    updated: (show.updated_at ? this.getFormattedDate(new Date(show.updated_at)) : "") + " " + (show.updated_by ? "by " + show.updated_by : "")
                }));
                var formattedArrayList = response.data.map((show) => ({ value: show.show_id, text: show.show_name.toString() }));
                this.setState({ tabListData: formattedArray, dropDownList: formattedArrayList });
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load shows all Error-->" + err);
                })
    }

    postShow = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (this.state.tabListData.some(item => item.name.toString() === this.state.postJson.show_name.toString() && !this.state.postJson.show_id)) {
            NotificationManager.warning(`${this.state.postJson.show_name} is already available!`, 'Warning!', 3000);
        } else {
            this.setState({ isPosting: true });
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/showAdd', this.state.postJson)
                .then((response) => {
                    console.log("post response", response);
                    this.getRenderList();
                    this.setState({ isPosting: false, showModal: false })
                    if (this.state.postJson.show_id) {
                        NotificationManager.success(this.state.saveSuccessMessage, 'Success!');
                    } else {
                        NotificationManager.success("New Show has been successfully created!", 'Success!');
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false, showModal: false })
                        NotificationManager.error(this.state.saveErrorMessage, 'Error!', 3000);
                        console.log("Post show error: " + err);
                    });
        }
    }



    handleTabChange = (event, value) => {
        this.setState({ tabValue: value }, () => {
            this.getRenderList();
        });
    }

    handleClose = () => {
        this.setState({ showModal: false });
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    vendorNavigation = (id, action) => {
        if (action === "Add") {
            this.props.history.push('/addVendor', "AV");
        } else if (action === "Edit") {
            this.props.history.push('/vendor/' + id, "VN");
        } else if (action === "Plus") {
            this.props.history.push('/customGroup/' + id, this.state.tabListData);
        }

    }
    customGroupNavigation = (id, action) => {
        if (action === "Edit") {
            this.props.history.push('/customGroup/' + id, this.state.tabListData);
        }
    }

    editCallback = (id, action) => {
        this.toggleModal();
        let editObj;
        if (action === "Edit") {
            editObj = this.state.tabListData?.find(item => item.id === id);
            this.setState({ modalMode: "Edit" });
        } else {
            this.setState({ modalMode: "Add" });
        }
        switch (this.state.tabValue) {
            case 2:
                let company = this.state.initialPostJsons.company;
                if (action === "Edit") {
                    company.company_id = editObj.id;
                    company.company_name = editObj.name;
                    company.is_active = editObj.is_active === "Active" ? 1 : 0;
                    company.value = editObj.id;
                    company.text = editObj.name;
                } else {
                    company.value = null;
                    company.text = null;
                    company.company_id = null;
                }
                this.setState({ postJson: company }, () => {
                    this.isSaveEnabled(this.state.postJson.company_name);
                });
                break;
            case 3:
                let division = this.state.initialPostJsons.division;
                if (action === "Edit") {
                    division.division_id = editObj.id;
                    division.division_name = editObj.name;
                    division.is_active = editObj.is_active === "Active" ? 1 : 0;
                    division.value = editObj.id;
                    division.text = editObj.name;
                } else {
                    division.value = null;
                    division.text = null;
                    division.division_id = null;
                }
                this.setState({ postJson: division }, () => {
                    this.isSaveEnabled(this.state.postJson.division_name);
                });
                break;
            case 4:
                let category = this.state.initialPostJsons.category;
                if (action === "Edit") {
                    category.category_id = editObj.id;
                    category.category_name = editObj.name;
                    category.is_active = editObj.is_active === "Active" ? 1 : 0;
                    category.value = editObj.id;
                    category.text = editObj.name;
                } else {
                    category.value = null;
                    category.text = null;
                    category.category_id = null;
                }
                this.setState({ postJson: category }, () => {
                    this.isSaveEnabled(this.state.postJson.category_name);
                });
                break;
            case 5:
                let title = this.state.initialPostJsons.title;
                if (action === "Edit") {
                    title.title_id = editObj.id;
                    title.title_name = editObj.name;
                    title.is_active = editObj.is_active === "Active" ? 1 : 0;
                    title.value = editObj.id;
                    title.text = editObj.name;
                } else {
                    title.value = null;
                    title.text = null;
                    title.title_id = null;
                }
                this.setState({ postJson: title }, () => {
                    this.isSaveEnabled(this.state.postJson.title_name);
                });
                break;
            case 6:
                let show = this.state.initialPostJsons.show;
                if (action === "Edit") {
                    show.show_id = editObj.id;
                    show.show_name = editObj.name;
                    show.value = editObj.id;
                    show.text = editObj.name;
                } else {
                    show.value = null;
                    show.text = null;
                    show.show_id = null;
                }
                this.setState({ postJson: show }, () => {
                    this.isSaveEnabled(this.state.postJson.show_name);
                });
                break;
            case 7:
                let occassion = this.state.initialPostJsons.occasion;
                if (action === "Edit") {
                    occassion.occasion_id = editObj.id;
                    occassion.occasion_name = editObj.name;
                    occassion.value = editObj.id;
                    occassion.text = editObj.name;
                } else {
                    occassion.value = null;
                    occassion.text = null;
                    occassion.occasion_id = null;
                }
                this.setState({ postJson: occassion }, () => {
                    this.isSaveEnabled(this.state.postJson.occasion_name);
                });
                break;
        }
    }
    addCallback = (id) => {
        //Pavani Add your Code here
    }

    render() {
        let activeTabName = this.state.tabList.find(item => item.index === this.state.tabValue)?.label;
        let activeTabDisplayName = this.state.tabList.find(item => item.index === this.state.tabValue)?.name;
        return (
            <div className="dataManagementContainer">
                <NotificationContainer />
                <div><h5>Data Maintenance</h5></div>
                <div className="headingNote">{`You are viewing data included in the ${localStorage.getItem("active_tenant_name")} - Marketing Tenant.`}</div>
                {
                    this.state.showModal && activeTabName !== "Vendors" && activeTabName !== "Custom Groups" && <AddOrEditModal open={this.state.showModal}
                        handleClose={this.handleClose} name={this.state.tabValue} activeTabName={activeTabName}
                        dropDownList={this.state.dropDownList} activeTabEditObject={this.state.postJson} activeTabDisplayName={activeTabDisplayName}
                        handleEdit={this.handleEdit} modalMode={this.state.modalMode} handleSave={this.handleSave} isPosting={this.state.isPosting}
                        isSaveDisabled={this.state.isSaveDisabled} />
                }
                <TabContent tabValue={this.state.tabValue}
                    tabList={this.state.tabList}
                    editCallback={this.editCallback}
                    addCallback={this.addCallback}
                    activeTabName={activeTabName}
                    vendorNavigation={this.vendorNavigation}
                    handleTabChange={this.handleTabChange}
                    customGroupNavigation={this.customGroupNavigation}
                    isLoading={this.state.isTableLoading}
                    list={this.state.tabListData} />
            </div>
        );
    }
}
