import React, { useState, useEffect } from "react";
import { MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal, MDBContainer, MDBIcon, MDBBtn }
    from "mdb-react-ui-kit";
import Divider from '@material-ui/core/Divider';
import { Component } from "react";
import { withRouter } from 'react-router-dom';
import QueryEventDataJSON from '../../json/query_event_data.json';
import QueryGiftDataJSON from '../../json/query_gift_data.json';
import QueryCardDataJSON from '../../json/query_card_data.json';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';
import TextField from '@material-ui/core/TextField';
//import ListBox from '../Common/ListBox';
import MultiSelectChipComponent from '../Common/MultiSelectChipComponent';

class QueryDataCards extends Component {
    _isMounted = false;
    container = null;
    //export_details_json = "";
    constructor(props) {
        super(props);           
    }

    handleSideNavActive = (value, routePath) => {
        this.setState({
            activeTab: value
        } );
        this.props.history.push(routePath, this.state);
    }

    
    // handleChangeDateFrom = (event, newValue) => {
    //     //this.props.handleChangeDateFrom(event.target.value);
    // }

    // handleChangeDateTo = (event, newValue) => {
    //    // this.props.handleChangeDateTo(event.target.value);
    // }

    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
        }
    }

    // exportDetails = () =>{
    //     //alert("Exported");
    //     GifterService.getData(Constants.GifterServiceBaseUrl + '/exportActivePersons?tenantId=' + localStorage.getItem("active_tenant_id"))
    //     .then(response => {
    //         var buffer = response.data;
    //         var blob = new Blob([s2ab(atob(response.data))], {
    //             type: 'application/vnd.ms-excel;charset=utf-8'
    //         });
            
    //         // href = URL.createObjectURL(blob);
    //         // const blob = new Blob([myJsonString], {
    //         //     type: "application/vnd.ms-excel;charset=utf-8"
    //         //   });
    //         saveAs(blob, "ExportDetails.xls");
    //         },
    //         (err) => {
    //             console.log("Load Titles Error-->" + err);
    //         })
    // }

  

    render(){
        return (
            <>
            <div>                     

                <div class="row">
                    <div class="col-6 padding">
                        <MultiSelectChipComponent items={this.props.companies} 
                            name={"Company"} 
                            handleChange = {this.props.handleChangeCompanies}
                            selectedVal = {this.props.selCompanies}
                        >
                        </MultiSelectChipComponent>
                    </div>                   
                    <div class="col-6 padding">
                        <MultiSelectChipComponent items={this.props.divisions} 
                            name={"Division"} 
                            handleChange = {this.props.handleChangeDivisions}
                            selectedVal = {this.props.selDivisions}
                        >
                        </MultiSelectChipComponent>
                    </div>
                    <div class="col-6 padding">
                        <MultiSelectChipComponent items={this.props.cards} 
                            name={"Cards"} 
                            handleChange = {this.props.handleChangeCards}
                            selectedVal = {this.props.selCards}
                        >
                        </MultiSelectChipComponent>
                    </div>
                    <div class="col-6 padding">
                        <MultiSelectChipComponent items={this.props.cardRecipients} 
                            name={"Card Recipients"} 
                            handleChange = {this.props.handleChangeCardRecipients}
                            selectedVal = {this.props.selCardRecipients}
                        >
                        </MultiSelectChipComponent>
                    </div>
                    <div class="col-6 padding">
                        <MultiSelectChipComponent items={this.props.cardSigners} 
                            name={"Card Signers"} 
                            handleChange = {this.props.handleChangeCardSigners}
                            selectedVal = {this.props.selCardSigners}
                        >
                        </MultiSelectChipComponent>
                    </div>
                    
                </div>


                {/* <div className="padding-hor flexAlignRight">
                <MDBBtn onClick={this.props.exportDetails}>Export</MDBBtn>
                <MDBBtn>Cancel</MDBBtn>
                </div> */}
             </div>
            </>
        );
    }

}


export default QueryDataCards;