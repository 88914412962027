import React from "react";
import {  MDBBtn, } from "mdb-react-ui-kit";
import { Component } from "react";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as FontConstants from '../../constants/fontConstants';
import MDBColorPicker from "mdb-react-color-picker";

class FontPicker extends Component {
    _isMounted = false;
    container = null;
    message_card_json="";
    constructor(props) {
        super(props);      
    }

     componentWillUnmount() {
        this._isMounted = false;
    }

    handleFontStyle = (name, event) =>{
        this.props.handleFontStyle(name);
    }

    handleFontChange = (event) => {
        var fontValue = event.target.value;
        var fontName = FontConstants.Font_Families_Keys[fontValue];
        var fontFileName = FontConstants.Font_Families_Style[fontValue];        
        this.props.handleFontChange(event, fontValue, fontName, fontFileName);
    }

    componentWillReceiveProps(nextProps){

    }
    
    render(){
        return (
            <>
                <div className="margin-hor-none margin-ver-12 padding-ver bg-octonary row align-center-vertical">
                            <div className="col-1">
                            <label className="padding-hor">
                                Font:
                            </label>
                            </div>
                            <div className="col-4">
                            {/* <MDBSelect
                                data={FontConstants.Font_Families}                               
                            /> */}
                            
                             <Select
                                    value={this.props.selectedFont}
                                    onChange={this.handleFontChange.bind(this)}
                                    name="text"
                                    displayEmpty
                                    // className={classes.selectEmpty}
                                    inputProps={{ 'aria-label': 'Font Family' }}                                    
                                    className="form-control"
                                    >
                                    {FontConstants.Font_Families.map((font) =>( 
                                        
                                        <MenuItem value={font.value} key={font.value} name={font.text}>
                                            {font.text}
                                        </MenuItem>
                                        // <MenuItem value={10}>Ten</MenuItem>
                                        // <MenuItem value={20}>Twenty</MenuItem>
                                        // <MenuItem value={30}>Thirty</MenuItem>
                                     ) )}
                            </Select>
                            </div>
                            <div className="col-1">
                            <label className="padding-hor">
                                Size:
                            </label>
                            </div>
                            <div className="col-2">
                            <Select
                                    value={this.props.selectedFontSize}
                                    onChange={this.props.handleFontSizeChange}
                                    displayEmpty
                                    name="text"
                                    // className={classes.selectEmpty}
                                    inputProps={{ 'aria-label': 'Font Size' }}
                                    className="form-control"
                                    >
                                    {FontConstants.Font_Size.map((font) =>( 
                                        
                                        <MenuItem value={font.value} key={font.value}>
                                            {font.text}
                                        </MenuItem>
                                        // <MenuItem value={10}>Ten</MenuItem>
                                        // <MenuItem value={20}>Twenty</MenuItem>
                                        // <MenuItem value={30}>Thirty</MenuItem>
                                     ) )}
                            </Select>
                            </div>
                            <div className="col-half padding-none">
                            <MDBBtn className="padding" onClick={this.handleFontStyle.bind(this,'bold')}>
                                B
                            </MDBBtn>
                            </div>
                            <div className="col-1 padding-none">
                            <MDBBtn className="padding" onClick={this.handleFontStyle.bind(this,'italic')}>
                                I
                            </MDBBtn>                            
                            </div>
                            <div className="col-half padding-none margin-hor-12">
                            <MDBColorPicker
                                transitionType="linear"
                                isOpen={this.props.colorPicker}
                                close={this.props.closeFontColor}
                                getValue={this.props.saveFontColor}
                                colorSpace="hex"
                                stripHeight = {140}
                                stripWidth = {5}
                            >
                                <div
                                style={{ backgroundColor: this.props.selectedFontColor, width: '100%' }}                                
                                onClick={this.props.toggleFontColor}
                                className= "pickr"
                                />
                            </MDBColorPicker>
                            </div>
                </div>
            </>
        );
    }
}

export default FontPicker;