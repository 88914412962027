import React from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import HomeIcon from '@material-ui/icons/Home';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TablePagination from '@material-ui/core/TablePagination';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { ContactSupportOutlined } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import {
    MDBContainer, MDBCol, MDBRow, MDBModal,
    MDBTableHead, MDBIcon, MDBModalBody, MDBModalHeader, MDBModalFooter
    , MDBDataTableV5, MDBInput
}
    from 'mdbreact';
import FormElement from '../../Form/Form';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CancelIcon from '@material-ui/icons/Cancel';
import TextBox from '../EventCreation/TextBox'
import CircularProgress from "@material-ui/core/CircularProgress";
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants.js';
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});



function Row(props) {
    const { row, toggleModal, toggleModal1, assignRowValue, editCallback, rsvpStatusChange } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (
        <React.Fragment>
            <TableRow className={classes.root} key={row.person_id}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {props.gridOpen || open ? <ArrowDropUpIcon style={{ fontSize: 30 }} color="primary" />
                            : <ArrowDropDownIcon color="primary" style={{ fontSize: 30 }} />}
                    </IconButton>
                </TableCell>
                <TableCell className={props.selectedTab === 'eventsTab' ? 'W15' : 'W8'}>
                    {props.selectedTab === 'eventsTab' ? (
                        <>
                            <fieldset id='nameFieldSet'>
                                <label className='W6'> {row.name ? row.name : row.first_name + ' ' + row.last_name} </label>
                                <MDBBtn color="primary" id="guest" size="sm" className='FR' onClick={() =>
                                    props.toggleModal1(row)}>Guests</MDBBtn>
                            </fieldset>
                        </>) : (<>{row.first_name}&nbsp;{row.last_name}</>)}
                </TableCell>
                <TableCell>{row.company_name}</TableCell>
                <TableCell>{row.division_name}</TableCell>
                <TableCell>{row.category_name}</TableCell>
                <TableCell>{row.title_name}</TableCell>
                {props.selectedTab === 'eventsTab' ?
                    <TableCell className='tbFW800lHead'>
                        <MDBIcon icon="check-circle" className="ml-1" style={{
                            color: row.RSVP_status != null && row.RSVP_status == true ? "green" : "gray",
                            fontSize: 16
                        }} onClick={(event, newValue) => {
                            event.preventDefault();
                            props.rsvpStatusChange(event, row, row.RSVP_status != null ? null : 1)
                        }} />
                                        &nbsp;&nbsp;
                                        <MDBIcon icon="times-circle" className="ml-1" style={{
                            color: row.RSVP_status != null && row.RSVP_status == false ? "red" : "gray",
                            fontSize: 16
                        }} onClick={(event, newValue) => {
                            event.preventDefault();
                            props.rsvpStatusChange(event, row, row.RSVP_status != null ? null : 0)
                        }} />
                                        &nbsp;&nbsp;
                                        {row.RSVP_status == null ? <label className='lblNoreply'>No Reply</label> :
                            (row.RSVP_status ? <label className='lblConfirmed'>Confirmed</label>
                                : <label className='lblDenied'>Declined</label>)}
                    </TableCell> : null}

                <TableCell>
                    <div className='FR'>
                        <CreateIcon style={{ fontSize: 20 }} className='MR1'
                            onClick={(event) => {
                                event.preventDefault();
                                props.toggleModal(row);
                            }} />
                        <DeleteIcon style={{ fontSize: 20 }} className="MR2"
                            onClick={(event) => {
                                event.preventDefault();
                                props.removeInviteFromSearch(row);
                            }} /></div></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.selectedTab === 'eventsTab' ? 8 : 7}>
                    <Collapse in={open || props.gridOpen} timeout="auto" unmountOnExit>
                        <Box>
                            <Table>
                                <TableBody>
                                    <TableRow id="tblChild">
                                        <TableCell scope="row">
                                            <fieldset id='extendedDetails'>
                                                <span className='FR MN5'>
                                                    {row.phone && row.phone != '' ?
                                                        <>
                                                            <ContactPhoneIcon style={{ fontSize: 20 }} color="primary" />&nbsp;&nbsp;
                                                            <label>{row.phone}</label>
                                                            <br />
                                                        </> : null}
                                                    <label >{row.email}</label>
                                                </span>
                                            </fieldset>
                                        </TableCell>
                                        <TableCell scope="row">
                                            <fieldset className='contentWrap'>
                                                {row.address && row.address != '' ? <span><HomeIcon style={{ fontSize: 20 }}
                                                    color="primary" />&nbsp;&nbsp;<label>{row.address}</label></span> : null}

                                            </fieldset>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};


class GridTable extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            rows: props.data,
            recipientToAddBySearch: [],
            gridOpen: props.gridOpen,
            selectedTab: props.selectedTab,
            page: props.page,
            rowsPerPage: props.rowsPerPage,
            modal: false,
            modal1: false,
            data: {},
            showGuest: [],
            value: '',
            loading: false,
            disabled: props.disabled ? props.disabled : false,
            masterId: null,
            searchCancelToken : null
        }
    }
    assignRowValue = (value) => {
        this.setState({
            data: value
        });
    }
    toggleModal = (row) => {
        let r = this.state.modal;
        this.setState({
            modal: !r,
            data: !r ? row : null
        });
    };
    rsvpStatusChange = (event, row, flag = 0) => {
        let r = this.state.rows;
        let person_id = row.person_id;
        let record = r.filter(item => item.person_id == person_id);
        if (record && record.length > 0) {
            if (flag != null) {
                row['RSVP_status'] = flag === 1 ? 1 : 0
            } else { row['RSVP_status'] = null }
        }
        r = r.map(function (item) { return item.person_id == person_id ? row : item; });
        this.setState({
            rows: r
        });
        this.state.selectedTab==='eventsTab' && this.props.modifiedInvitees(row,this.state.selectedTab,flag);        
    }
    editCallback = (row) => {
        let r = this.state.rows;
        let person_id = row.person_id;
        let record = r.filter(item => item.person_id == person_id);
        if (record && record.length > 0) {
            row['guests'] = record[0].guests;
            row['event_invitee_id'] = record[0].event_invitee_id;
            if (this.state.selectedTab == 'recipientsTab')
                row['gift_recipient_id'] = record[0].gift_recipient_id;
            if (this.state.selectedTab == 'sendersTab')
                row['gift_sender_id'] = record[0].gift_sender_id;
            if (this.state.selectedTab == 'eventsTab')
                row['RSVP_status'] = record[0]['RSVP_status'];
        } else {
            row['guests'] = [];
            row['event_invitee_id'] = null;
            if (this.state.selectedTab == 'recipientsTab')
                row['gift_recipient_id'] = null;
            if (this.state.selectedTab == 'sendersTab')
                row['gift_sender_id'] = null;
            if (this.state.selectedTab == 'eventsTab')
                row['RSVP_status'] = null;
            r.push(row)
        }
        r = r.map(function (item) { return item.person_id == person_id ? row : item; });

        this.setState({
            rows: r,
            modal: false
        });
        
        this.props.editCallbackFromGrid(r);
        this.state.selectedTab==='eventsTab' && this.props.modifiedInvitees(row,this.state.selectedTab);
    }
    toggleModal1 = (value) => {
        if (value) {
            let r = value.guests ? value.guests : []
            if (r && r.length > 0) {
                r.map(item => {
                    item['isDisable'] = false
                })
            }
            this.setState({
                value: value,
                modal1: !this.state.modal1,
            });
        } else {
            this.setState({
                modal1: !this.state.modal1,
            });
        }
    };
    handleGuestChange = (event, v) => {
        event.preventDefault();
        if (event.target.value != '') {
            let value = this.state.value;
            let guests = value.guests;
            const guest = { event_invitee_id: value.event_invitee_id, guest_name: event.target.value, isDisable: true, event_guest_id: null, isSaved: false };
            guests.push(guest)
            value.guests = guests
            this.setState({
                value: value
            });
        }
    }
    saveGuestChange = () => {
        // let rows = this.state.rows;
        // let invitee = rows.filter(item => item.person_id == actualRow.person_id);
        // this.toggleModal1(null)
        let actualRow = this.state.value;
        // let guests = actualRow.guests;
        let guests = actualRow.guests.map(item => {
            item.isSaved = true;
            return item
        })
        actualRow.guests = guests
        let r = this.state.rows;
        r = r.map(function (item) { return item.person_id == actualRow.person_id ? actualRow : item; });
        this.setState({
            rows: r,
            value: actualRow
        });
        this.toggleModal1(null);
        this.props.editCallbackFromGrid(r);
    }

    handleDisabled = (e, index) => {
        let value = this.state.value;
        let guests = value.guests;
        guests[index].isDisable = false
        value.guests = guests;
        this.setState({
            value: value
        });
    }

    handleChangeNew = (e, index) => {
        let value = this.state.value;
        let guests = value.guests;
        guests[index].guest_name = e.target.value
        value.guests = guests;
        this.setState({
            value: value
        });
    }

    handleDelete = (e, index) => {
        let value = this.state.value;
        let guests = value.guests;
        guests.splice(index, 1);
        value.guests = guests;
        this.setState({
            value: value
        });
    }

    handleCancel = (e) => {
        let value = this.state.value;
        let guests = value.guests.filter(item => item.event_guest_id !== null || item.isSaved == true)
        value.guests = guests;
        this.setState({
            value: value
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            rows: nextProps.data, recipientToAddBySearch: [],// nextProps.recipientToAddBySearch,
            page: nextProps.page,
            // rowsPerPage: nextProps.rowsPerPage,
            selectedTab: nextProps.selectedTab
        });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    }
    addInviteeFromSearch = (newValue) => {
        this.props.addInviteeFromSearch(newValue);
    }
    removeInviteFromSearch = (newValue) => {
        this.props.removeInviteFromSearch(newValue);
    }
    onChangeHandle = async value => {
        let option = {
            "searchString": value,
        }
        console.log("option", option)
        if(this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request.");
        //Save the cancel token for the current request
        var cancelToken = axios.CancelToken.source();
        this.setState({searchCancelToken: cancelToken});
        GifterService.getDataWithCancel(Constants.GifterServiceBaseUrl + '/activePersons?searchString=' + value, cancelToken)
            .then((response) => {
                if (response && response.data && response.data.length > 0) {
                    this.setState({ recipientToAddBySearch: response.data });
                } else {
                    this.setState({ recipientToAddBySearch: [] });
                }
            },
                (err) => {
                    this.setState({ recipientToAddBySearch: [] });
                });
    };


    editInviteeSearch = (newValue) => {
        this.props.editInviteeSearch(newValue);
    }

    deleteAllInvitees = () => {
        this.props.deleteAllInvitees();
    }
    componentDidMount() {
        this._isMounted = true;
    }

    render() {
        let r = this.state.rows ? this.state.rows : [];
        let records = r?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return (
            <>
                <TableContainer component={Paper} disabled={this.state.disabled}>
                    <Table aria-label="collapsible table">
                        <TableHead id="tblHead">
                            <TableRow>
                                <TableCell className="W3" />
                                <TableCell className="FW800">Name</TableCell>
                                <TableCell className="FW800" >Company</TableCell>
                                <TableCell className="FW800">Division</TableCell>
                                <TableCell className="FW800">Category</TableCell>
                                <TableCell className="FW800">Title</TableCell>
                                {this.state.selectedTab === 'eventsTab' ? <TableCell className='tbFW800lHead'>RSVP</TableCell> : null}
                                <TableCell className="FW800"><Link href="#" id="expansionPanel" className="FR"
                                    onClick={(event, newValue) => {
                                        event.preventDefault();
                                        this.deleteAllInvitees();
                                    }}
                                    color="primary" >
                                    delete all
                                </Link>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.map((row) => (
                                <Row key={row.person_id} row={row}
                                    gridOpen={this.props.gridOpen}
                                    selectedTab={this.state.selectedTab}
                                    removeInviteFromSearch={this.removeInviteFromSearch}
                                    editInviteeSearch={this.editInviteeSearch}
                                    toggleModal={this.toggleModal}
                                    toggleModal1={this.toggleModal1}
                                    assignRowValue={this.assignRowValue}
                                    editCallback={this.editCallback}
                                    rsvpStatusChange={this.rsvpStatusChange}
                                />
                            ))}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <div class='row'>
                                        <div class='col-10' >
                                            <Autocomplete
                                                className="tags-standard"
                                                options={this.state.recipientToAddBySearch}
                                                getOptionLabel={(option) => (option.first_name + ' ' + option.last_name)}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue != '') {
                                                        this.addInviteeFromSearch(newValue)
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        label="Search By Name"
                                                        margin="normal"
                                                        onChange={ev => {
                                                            // dont fire API if the user delete or not entered anything
                                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                                this.onChangeHandle(ev.target.value);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {this.state.loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    />

                                                )}
                                            />
                                        </div>
                                        <div class='col-2 MT1pt25'>
                                            <Tooltip title="Add Person">
                                                <AddCircleRoundedIcon style={{ fontSize: 20 }} onClick={(event) => {
                                                    event.preventDefault();
                                                    let row = {
                                                        "event_invitee_id": null,
                                                        "gift_event_card_id": null,
                                                        "person_id": null,
                                                        "first_name": "",
                                                        "last_name": "",
                                                        "display_name": "",
                                                        "dob": null,
                                                        "is_card_signer": 0,
                                                        "person_category_id": null,
                                                        "category_id": null,
                                                        "category_name": "",
                                                        "p_comp_div_title_id": null,
                                                        "company_id": null,
                                                        "company_name": "",
                                                        "division_id": null,
                                                        "division_name": null,
                                                        "title_id": null,
                                                        "title_name": "",
                                                        "person_extension_id": null,
                                                        "address": null,
                                                        "address_type": null,
                                                        "person_phone_id": null,
                                                        "phone": null,
                                                        "person_email_id": null,
                                                        "email": null,
                                                        "newUser": true,
                                                        "guests": [

                                                        ]
                                                    };
                                                    this.toggleModal(row);
                                                }} /></Tooltip></div>
                                    </div>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={this.state.rows.length}
                    rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={this.state.rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <br />
                <br />

                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} className="custom-class" >
                    <MDBModalBody>
                        <FormElement row={this.state.data} editCallback={this.editCallback} toggle={this.toggleModal} />
                    </MDBModalBody>
                </MDBModal>

                <MDBModal isOpen={this.state.modal1} toggle={this.toggleModal1} className="custom-class1" >
                    <MDBModalBody >
                        <h4> Edit {this.state.value.name ? this.state.value.name : this.state.value.first_name + ' ' + this.state.value.last_name} Guests: </h4>
                        <div id="guests">
                            {this.state.value && this.state.value.guests ? (this.state.value.guests.map((item, index) => (
                                item.guest_name ? (<>
                                    <div >
                                        <OutlinedInput
                                            variant="outlined"
                                            value={item.guest_name}
                                            disabled={item.isDisable}
                                            key={index}
                                            onChange={(e) => this.handleChangeNew(e, index)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <CreateIcon
                                                        onClick={(e) => this.handleDisabled(e, index)}>
                                                    </CreateIcon>
                                                    <CancelIcon
                                                        onClick={(e) => this.handleDelete(e, index)}
                                                    ></CancelIcon>
                                                </InputAdornment>
                                            } />
                                    </div>
                                </>) : null
                            ))) : null}
                        </div>
                        < div id="addnewguest">
                            <TextBox
                                label="Add New Guest"
                                name="Add New Guest"
                                onChange={this.handleGuestChange}
                            />
                        </div>
                        <div>
                            <MDBBtn className="mr-30 btnfont" type='button' onClick={(e) => this.saveGuestChange()}>Save</MDBBtn>
                            <MDBBtn className="cancel btnfont" type='button' onClick={(e) => { this.toggleModal1(null); this.handleCancel(); }}>Cancel</MDBBtn>
                        </div>

                    </MDBModalBody>
                </MDBModal>

            </>

        );
    }
}

export default GridTable;
