import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {MDBIcon} from 'mdbreact';
import {MDBSelect, MDBBtn, MDBDatepicker} from 'mdb-react-ui-kit';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import "./MultiSelectComponent.scss";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

class MultiSelectComponent extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.props.selectedTab,
            user_name: localStorage.getItem("user_name"),
            filterEvent: false,
            filterGift: false,
            inviteeRange: [0,1000],
            minInvitee: 0,
            maxInvitee: 1000,
            filterOpen: false,
            pageSize: 10,
            page: 0,
            rowCount: 10,
            dateFrom: '',
            dateTo: '',
            name: '',
            openDD: false,
            displayDD: false,            
            items: [
                // { divisionName: 'Warner Television', divisionId: 1 },
                // { divisionName: 'Warner Animation', divisionId: 2, selected: true},
                // { divisionName: 'Warner Horizon', divisionId: 3, selected: true }
            ],
            selectedItems: [],
            MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 100,
                    width: 200,
                    maxWidth: 200
                  },
                },
              }
        }
    }


    handleDD = () => {
        this.setState({
            openDD: !this.state.openDD
        })
    }

    handleChange = (event, value) => {
        //this.setState( { selectedItems: event.target.value});
        this.props.handleChange(value);
    }

    handleOpen = () => {
        this.setState({openDD: true});
    }
    handleClose = () => {
        this.setState({openDD: false});
    }
    componentWillReceiveProps(props) {
        this.setState({ items: this.props.items,
            name: this.props.name
     });
    }

    displaySelected = (selected) => {
        
        if(selected && selected.length > 0)
        {
            //this.setState({displayDD : true});
            var itmCnt = selected.length-1;
            return selected[0] + (itmCnt > 0 ? " +" + itmCnt : "");
        }
        //else
            //this.setState({displayDD : false});
    }

     render() {
        return (
            <>
                {/* <MDBBtn className = "btn-dropdown" outline onClick = {this.handleDD}>
                        {this.state.name} <MDBIcon fas icon="caret-down" className="mr-2" ></MDBIcon>
                    </MDBBtn>  */}
                    {/* className = {this.state.openDD ? "show" : "hide"}> */}
                    <div id="autocomplete-multi-select">
                    {/* <Select                                                
                        multiple
                        value={this.state.selectedItems}
                        onChange={this.handleChange}
                        // input={<Input />}
                        // renderValue={(selected) => selected +"+1"}
                        renderValue={this.displaySelected}
                        MenuProps={this.state.MenuProps}
                        open={this.state.openDD}
                        onOpen = {this.handleOpen}
                        onClose={this.handleClose}
                        className="multi-select"
                        
                        >                       
                        {this.state.items.map((item) => (
                            <MenuItem key={item.value} value={item.value} className="multi-select">
                                <Checkbox color="primary" checked={this.state.selectedItems.indexOf(item.value) > -1} />
                                <ListItemText primary={item.text} />
                            </MenuItem>
                        ))}
                    </Select>   */}

                    <Autocomplete
                        multiple                        
                        variant="body1"
                        options={this.state.items}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.text}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.text}
                            </React.Fragment>
                        )}
                        style={{ width: '100%' }}
                        filterOptions={filterOptions}
                        renderInput={(params) => (
                             <TextField {...params} variant="standard" label={this.props.name+":"} placeholder={this.props.name+":"}
                              InputProps={{ ...params.InputProps, disableUnderline: true }} /> 
                            // <Input  {...params} placeholder="Divisions" inputProps={{ 'aria-label': 'description' }} />                        
                        )}
                        renderTags={(value, getTagProps) => {
                            const numTags = value.length;     
                            
                            return (
                            //   <div className="multi-select-overflow">   
                              <Typography variant="body1">
                                {value
                                  .slice(0, 1)
                                  .map((option) => option.text)
                                  .join(", ")}                    
                                {numTags > 1 && ` +${numTags - 1} more`}
                              </Typography>
                            //   </div>
                            );
                          }}

                          onChange={this.handleChange.bind(this)}
                          value = {this.props.selectedVal}
                        />

                    </div> 
            </>
        );
    }

}

export default MultiSelectComponent;