export const Font_Families = [
    { value: '1', text: 'Arial' },
    { value: '2', text: 'Calibri' },
    { value: '3', text: 'Calibri Light' },
    { value: '4', text: 'Courier New' },
    { value: '5', text: 'Courier' },
    { value: '6', text: 'Microsoft Sans serif' },
    { value: '7', text: 'Monoton' },
    { value: '8', text: 'Monotype Corsiva' },
    { value: '9', text: 'Roman' },
    { value: '10', text: 'Segoe Print' },
    { value: '11', text: 'Segoe Script' },
    { value: '12', text: 'Segeo UI' },
    { value: '13', text: 'Tahoma' },
    { value: '14', text: 'Times New Roman' },
    { value: '15', text: 'Verdana' },
    { value: '16', text: 'Warner Bros. Sans' },
    { value: '17', text: 'Warner Bros. Sans Cond' }    
]



export const Font_Families_Keys = {
    '1' : 'Arial',
    '2' : 'Calibri',
    '3' : 'Calibri Light',
    '4' : 'Courier New',
    '5' : 'Courier',
    '6' : 'Microsoft Sans serif',
    '7' : 'Monoton',
    '8' : 'Monotype Corsiva',
    '9' : 'Roman Regular',
    '10' : 'Segoe Print',
    '11' : 'Segoe Script',
    '12' : 'Segeo UI',
    '13' : 'Tahoma',
    '14' : 'Times New Roman',
    '15' : 'Verdana',
    '16' : 'Warner Bros Sans',
    '17' : 'Warner Bros Sans Cond'   

}

export const Font_Families_Keys_CSS = {
    '1' : 'Arial',
    '2' : 'Calibri',
    '3' : 'Calibri Light',
    '4' : 'Courier New',
    '5' : 'Courier',
    '6' : 'Microsoft Sans serif',
    '7' : 'Monoton',
    '8' : '"Monotype Corsiva"',
    '9' : 'Roman',
    '10' : 'Segoe Print',
    '11' : 'Segoe Script',
    '12' : 'Segeo UI',
    '13' : 'Tahoma',
    '14' : 'Times New Roman',
    '15' : 'Verdana',
    '16' : 'Warner Bros. Sans',
    '17' : 'Warner Bros. Sans Cond'   

}

export const Font_Size = [
    //10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30
    { value : '10', text: "10"},
    { value : '12', text: "12"},
    { value : '14', text: "14"},
    { value : '16', text: "16"},
    { value : '18', text: "18"},
    { value : '20', text: "20"},
    { value : '22', text: "22"},
    { value : '24', text: "24"},
    { value : '26', text: "26"},
    { value : '28', text: "28"},
    { value : '30', text: "30"}

];

export const Font_Families_Style = {
    "1" : {  
        normal: "arial.ttf",
        bold: "arialbd.ttf",
        italic: "ariali.ttf",
        bolditalic: "arialbi.ttf"
        },
    "2" : {  
        normal: "calibri.ttf",
        bold: "calibrib.ttf",
        italic: "calibrii.ttf",
        bolditalic: "calibriz.ttf"
        },
    "3" : {  
        normal: "calibril.ttf",
        bold: "calibril.ttf",
        italic: "calibrili.ttf",
        bolditalic: "calibril.ttf"
        },  
    "4" : {  
        normal: "cour.ttf",
        bold: "courbd.ttf",
        italic: "couri.ttf",
        bolditalic: "courbi.ttf"
        },      
    "5" : {  
        normal: "coure.fon",
        bold: "coure.fon",
        italic: "coure.fon",
        bolditalic: "coure.fon"
    },     
    "6" : {  
        normal: "micross.ttf",
        bold: "micross.ttf",
        italic: "micross.ttf",
        bolditalic: "micross.ttf"
    },     
    "7" : {  
        normal: "Monoton-Regular.ttf",
        bold: "Monoton-Regular.ttf",
        italic: "Monoton-Regular.ttf",
        bolditalic: "Monoton-Regular.ttf"
    },         
    "8" : {  
        normal: "MTCORSVA.TTF",
        bold: "MTCORSVA.TTF",
        italic: "MTCORSVA.TTF",
        bolditalic: "MTCORSVA.TTF"
    },    
    "9" : {  
        normal: "roman.fon",
        bold: "roman.fon",
        italic: "roman.fon",
        bolditalic: "roman.fon"
    },  
    "10" : {  
        normal: "segoepr.ttf",
        bold: "segoeprb.ttf",
        italic: "segoepr.ttf",
        bolditalic: "segoeprb.ttf"
    },   
    "11" : {  
        normal: "segoesc.ttf",
        bold: "segoescb.ttf",
        italic: "segoesc.ttf",
        bolditalic: "segoescb.ttf"
    },     
    "12" : {  
        normal: "segoeui.ttf",
        bold: "segoeuib.ttf",
        italic: "segoeuii.ttf",
        bolditalic: "segoeuiz.ttf"
    },        
    "13" : {  
        normal: "tahoma.ttf",
        bold: "tahomabd.ttf",
        italic: "tahoma.ttf",
        bolditalic: "tahomabd.ttf"
    },   
    "14" : {  
        normal: "times.ttf",
        bold: "timesbd.ttf",
        italic: "timesi.ttf",
        bolditalic: "timesbi.ttf"
    },       
    "15" : {  
        normal: "verdana.ttf",
        bold: "verdanab.ttf",
        italic: "verdanai.ttf",
        bolditalic: "verdanaz.ttf"
    },     
    "16" : {  
        normal: "WarnerBrosSans-Regular.ttf",
        bold: "WarnerBrosSans-Bold.ttf",
        italic: "WarnerBrosSans-Regular.ttf",
        bolditalic: "WarnerBrosSans-Bold.ttf"
    },     
    "17" : {  
        normal: "WarnerBrosSansCond-Bold.ttf",
        bold: "WarnerBrosSansCond-Bold.ttf",
        italic: "WarnerBrosSansCond-Bold.ttf",
        bolditalic: "WarnerBrosSansCond-Bold.ttf"
    },                                                 
};
