import React from 'react';
import { MDBInput } from "mdbreact";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Action from './Action';
import SaveModal from './SaveModal';
import { validatePhone, validateEmail } from '../../Common/helper';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            addressTypes: [],
            showModal: false,
            message: "Primary field should be active!",
            saveState: "error",
        }
    }

    componentWillReceiveProps(nextProps) {
        var categories = nextProps.personDetails?.category_list?.map((category) => ({ value: category.category_id, text: category.category_name, isDisabled: category.person_category_id }));
        var addressTypes = nextProps.personDetails?.address_list?.map((address) => ({ value: address.address_type, text: address.address_type_name, isDisabled: address.person_extension_id }));
        this.setState({ categories: categories, addressTypes: addressTypes });
    }

    componentDidMount() {
        var categories = this.props.personDetails?.category_list?.map((category) => ({ value: category.category_id, text: category.category_name, isDisabled: category.person_category_id }));
        var addressTypes = this.props.personDetails?.address_list?.map((address) => ({ value: address.address_type, text: address.address_type_name, isDisabled: address.person_extension_id }));
        this.setState({ categories: categories, addressTypes: addressTypes });
    }

    handlePrimaryAndActive = (field, field_param, object, value) => {
        let newArray = [];
        if (field_param === "is_primary") {
            newArray = this.props.personDetails[field]?.map(item => {
                if (value) {
                    if (object === item) {
                        item.is_primary = 1;
                        item.is_active = 1;
                        return item;
                    }
                    else {
                        item.is_primary = 0
                        return item;
                    }
                } else {
                    if (object === item) {
                        item.is_primary = 0;
                        return item;
                    } else return item;
                }
            })
        } else if (field_param === "is_active") {
            newArray = this.props.personDetails[field]?.map(item => {
                if (object === item) {
                    if (value) {
                        item.is_active = 1
                        return item;
                    } else if (!value) {
                        if (item.is_primary === 1) {
                            this.setState({ showModal: true });
                        } else {
                            item.is_active = 0;
                        }
                        return item;
                    }
                } else return item;
            })
        }
        this.props.handleContactListState(field, newArray);
    }

    handleClose = () => {
        this.setState({ showModal: false });
    }

    handleEmailChange = (email, item) => {
        if (validateEmail(email) || !email) {
            this.props.handleContactInputChange(email, "email_list", item, true);
        } else {
            this.props.handleContactInputChange(email, "email_list", item, false);
        }
    }


    render() {
        return (
            <div className="contactContainer">
                <SaveModal open={this.state.showModal} handleClose={this.handleClose} className={this.state.saveState} message={this.state.message} />
                <h6>Contact</h6>
                <div className="row">
                    <div className="col-4">
                        <div className="row">
                            <div className="col-6">
                                <label><strong>Phone</strong></label>
                            </div>
                            <div className="col-6">
                                <div className="row fs-50 fw-bold padding-top-7">
                                    <div className="col-4">primary</div>
                                    <div className="col-3">active</div>
                                </div>
                            </div>
                        </div>
                        {
                            (this.props.personDetails.phone_list && this.props.personDetails.phone_list?.length !== 0) ? this.props.personDetails.phone_list?.map((item, index) => (
                                <div className="row" key={index + "phone"}>
                                    <div className="col-6 padding-right-none">
                                        {index === 0 ? (
                                            <TextField id={index + "phone"}
                                                variant="outlined"
                                                value={item?.phone}
                                                type="text"
                                                disabled={item.person_phone_id}
                                                onChange={(e) => (validatePhone(e.target.value) || !e.target.value) ? this.props.handleContactInputChange(e.target.value, "phone_list", item) : null}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => this.props.handleContactInputChange("", "phone_list", item)}
                                                                edge="end"
                                                            >
                                                                <CloseIcon fontSize={"small"}
                                                                    color={"disabled"} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        ) : (
                                            <TextField id={index + "phone"}
                                                variant="outlined"
                                                value={item?.phone}
                                                type="text"
                                                disabled={item.person_phone_id}
                                                onChange={(e) => (validatePhone(e.target.value) || !e.target.value) ? this.props.handleContactInputChange(e.target.value, "phone_list", item) : null} />
                                        )}
                                    </div>
                                    <div className="col-6">
                                        <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                            index={index}
                                            item={item}
                                            field={"phone_list"}
                                            handleChange={this.props.handleContactInfo}
                                            length={this.props.personDetails.phone_list?.length} />
                                    </div>
                                </div>
                            )) : (
                                <div className="row">
                                    <div className="col-6 padding-right-none">
                                        <TextField id={"phone0"}
                                            variant="outlined"
                                            key={"phone0"}
                                            type="text"
                                            onChange={(e) => (validatePhone(e.target.value) || !e.target.value) ? this.props.handleContactInputChange(e.target.value, "phone_list", {}) : null} />
                                    </div>
                                    <div className="col-6">
                                        <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                            index={0}
                                            item={{}}
                                            field={"phone_list"}
                                            handleChange={this.props.handleContactInfo}
                                            length={0} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-8">
                        {/* <label><strong>Category</strong></label> */}
                        <div className="row">
                            <div className="col-9">
                                <label><strong>Category</strong></label>
                            </div>
                            <div className="col-3">
                                <div className="row fs-50 fw-bold padding-top-7">
                                    <div className="col-4"> primary</div>
                                    <div className="col-3"> active</div>
                                </div>
                            </div>
                        </div>
                        {
                            (this.props.personDetails?.category_list?.length !== 0 && this.state.categories) ? this.props.personDetails?.category_list?.map((item, index) => (
                                <div className="row" key={"category" + index}>
                                    <div className="col-9">
                                        <Autocomplete
                                            id={"category" + index}
                                            disabled={this.state.categories[index]?.isDisabled}
                                            options={this.props?.list}
                                            value={this.state.categories[index] ? this.state.categories[index] : null}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            style={{ width: '100%' }}
                                            onChange={(e, value) => this.props.handleContactInputChange(value, "category_list", item)}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                            index={index}
                                            item={item}
                                            field={"category_list"}
                                            handleChange={this.props.handleContactInfo}
                                            length={this.props.personDetails?.category_list?.length} />
                                    </div>
                                </div>
                            )
                            ) : (
                                <div className="row">
                                    <div className="col-9">
                                        <Autocomplete
                                            id="category0"
                                            options={this.props?.list}
                                            defaultValue={null}
                                            getOptionLabel={(option) => option.text ? option.text : ''}
                                            getOptionSelected={(option, value) => option.value === value.value}
                                            filterOptions={filterOptions}
                                            style={{ width: '100%' }}
                                            onChange={(e, value) => this.props.handleContactInputChange(value, "category_list", {})}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                            index={0}
                                            item={{}}
                                            field={"category_list"}
                                            handleChange={this.props.handleContactInfo}
                                            length={0} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {/* <label><strong>Email</strong></label> */}
                        <div className="row">
                            <div className="col-10 padding-right-none">
                                <label><strong>Email</strong></label>
                            </div>
                            <div className="col-2">
                                <div className="row fs-50 fw-bold padding-top-7">
                                    <div className="col-4"> primary</div>
                                    <div className="col-3"> active</div>
                                </div>
                            </div>
                        </div>
                        {
                            (this.props.personDetails?.email_list && this.props.personDetails?.email_list?.length !== 0) ? this.props.personDetails?.email_list?.map((item, index) => (
                                <div className="row" key={"email" + index}>
                                    <div className="col-10">
                                        <MDBInput id={"email" + index}
                                            key={"email" + index}
                                            type="text"
                                            disabled={item?.person_email_id}
                                            value={item?.email}
                                            onChange={(e) => this.handleEmailChange(e.target.value, item)} />
                                    </div>
                                    <div className="col-2">
                                        <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                            index={index}
                                            item={item}
                                            field={"email_list"}
                                            handleChange={this.props.handleContactInfo}
                                            length={this.props.personDetails?.email_list?.length} />
                                    </div>
                                    {(!item.isValid && !item?.person_email_id) ? <div className="error">Invalid Email!</div> : null}
                                </div>
                            )) : (
                                <div className="row">
                                    <div className="col-10">
                                        <MDBInput
                                            id={"email0"}
                                            key={"email0"}
                                            type="text"
                                            onChange={(e) => this.handleEmailChange(e.target.value, {})} />
                                    </div>
                                    <div className="col-2">
                                        <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                            index={0}
                                            item={{}}
                                            field={"email_list"}
                                            handleChange={this.props.handleContactInfo}
                                            length={0} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                {/* <div className="row"> */}
                {/* <label><strong>Address</strong></label> */}
                <div className="row">
                    <div className="col-3">
                        <label><strong>Address</strong></label>
                    </div>
                    <div className="col-7">

                    </div>
                    <div className="col-2">
                        <div className="row fs-50 fw-bold padding-top-7">
                            <div className="col-4">primary</div>
                            <div className="col-3">active</div>
                        </div>
                    </div>
                </div>
                {
                    (this.props.personDetails?.address_list && this.props.personDetails?.address_list?.length !== 0) ? this.props.personDetails?.address_list?.map((item, index) => (
                        <div className="row" key={"address" + index} id="addressRow">
                            <div className="col-3">
                                <Autocomplete
                                    id={"address" + index}
                                    options={this.props?.addressType}
                                    value={this.state.addressTypes[index] ? this.state.addressTypes[index] : null}
                                    disabled={this.state.addressTypes[index]?.isDisabled}
                                    getOptionLabel={(option) => option.text ? option.text : ''}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterOptions={filterOptions}
                                    style={{ width: '100%' }}
                                    onChange={(e, value) => this.props.handleContactInputChange(value, "address_list", item, "address_type")}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            </div>
                            <div className="col-7">
                                <MDBInput id={"address" + index}
                                    key={"address" + index}
                                    type="textarea"
                                    value={item?.address}
                                    disabled={item?.person_extension_id}
                                    onChange={(e) => this.props.handleContactInputChange(e.target.value, "address_list", item, "address")} />
                            </div>
                            <div className="col-2">
                                <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                    index={index}
                                    item={item}
                                    field={"address_list"}
                                    handleChange={this.props.handleContactInfo}
                                    length={this.props.personDetails?.address_list?.length} />
                            </div>
                        </div>
                    )) : (
                        <div className="row" id="addressRow">
                            <div className="col-3">
                                <Autocomplete
                                    id={"address"}
                                    options={this.props?.addressType}
                                    defaultValue={null}
                                    getOptionLabel={(option) => option.text ? option.text : ''}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterOptions={filterOptions}
                                    style={{ width: '100%' }}
                                    onChange={(e, value) => this.props.handleContactInputChange(value, "address_list", {}, "address_type")}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            </div>
                            <div className="col-7">
                                <MDBInput id="address0"
                                    key={"address0"}
                                    type="textarea"
                                    onChange={(e) => this.props.handleContactInputChange(e.target.value, "address_list", {}, "address")} />
                            </div>
                            <div className="col-3 padding_left_24">
                                <Action handlePrimaryAndActive={this.handlePrimaryAndActive}
                                    index={0}
                                    item={{}}
                                    field={"address_list"}
                                    handleChange={this.props.handleContactInfo}
                                    length={0} />
                            </div>
                        </div>
                    )
                }
                {/* </div> */}
            </div>
        );
    }
}

export default Contact;