import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutoDropdown(props) {

  const { name, label, value, error = null, onChange, onInputChange, columnName, options, ...other } = props;

  //const selectedOption = '';
  return (
    <Autocomplete
      freeSolo
      id={name}
      name={name}
      defaultValue={value}
      //value = {selectedOption}
      options={options ? options : []}
      getOptionLabel={(option) => typeof option != 'string' ? option[columnName] : (typeof option == 'string' ? option : '')}
      onChange={(event, value) => {
        onChange(
          event, value)
      }}
      onInputChange={(event, value) => { 
        onInputChange(
        event, value, name) 
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={label} variant="outlined" {...(error && { error: true, helperText: error })} {...other} />
      )}
    />
  );
}