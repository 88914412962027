import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { MDBCol, MDBRow } from 'mdbreact';
  import {
    MDBCard, MDBCardBody,MDBCardHeader,MDBTabs,MDBTabsLink,MDBTabsItem,MDBCardText,MDBCardTitle,
    MDBTabsPane,MDBTabsContent,  MDBDropdown, MDBSelect, MDBBtn, MDBDatepicker,
    MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBTooltip,
  MDBIcon
  } from 'mdb-react-ui-kit';
  import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, 
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer, MDBNavbarLink, MDBDropdownLink 
} from 'mdb-react-ui-kit';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import MaterialSideNav from './MaterialSideNav';
import ErrorBoundary from '../../pages/ErrorBoundary';
import {
    BrowserRouter as Router
} from "react-router-dom";
import Routes from '../../Routes';
import './Materials.scss';
import MaterialMainContent from './MaterialMainContent';

class Materials extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {            
            name: this.props.name,
            isOpen: this.props.isOpen,
            // selectedFiles: undefined,
            // selectedFile: undefined  
            activeTab: "EX"
                
        }
    }
    

    componentWillReceiveProps(props) {
      this.setState({ isOpen: props.isOpen
      });
    }

    toggleSideNav = () => {

    }


    toggleMaterialContent = () =>{
        this.props.toggleMaterials();
    }

    handleSideNavActive = (value, routePath) => {
        this.setState({
            activeTab: value
        });
    }
    render() {
        return (
            <>
              <MDBModal id="materials-modal" staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props.isOpen} >             
                <MDBModalDialog size="lg" >
                  <MDBModalContent>                   
                    <MDBModalBody className="modal-body-modal">
                   <div id="headerContent">                    
                        <header>
                            <div className = "fixed-top-modal material-title">
                                <MDBNavbar bgColor="primary" dark expand="md"  fixed sticky >
                                    <MDBContainer fluid>
                                        <h3>
                                            Reports &amp; Materials
                                        </h3> 
                                        <div className="flexAlignRight">                        
                                            <MDBIcon size="2x" icon="times-circle" onClick={this.props.toggleMaterials}></MDBIcon>                          
                                        </div>
                                    </MDBContainer>
                                    
                                </MDBNavbar>
                            </div>
                        </header>
                        
                    </div>
                    <div>
                         <Router >
                            <MDBContainer fluid className="padding-none">
                                <MDBRow className = "main-content-modal margin-none">                                 
                                <MDBCol className="d-none d-lg-block col-sidenav-modal padding-none">
                                    <MaterialSideNav 
                                        handleSideNavActive = {this.handleSideNavActive.bind(this)}
                                        activeTab = {this.state.activeTab}
                                        fromPage= {this.props.fromPage}
                                    />                                
                                </MDBCol >
                                <MDBCol className="w-auto"  id="material-content">                            
                                <div>
                                    {/* <ErrorBoundary>
                                        <Routes />
                                    </ErrorBoundary> */}
                                    <MaterialMainContent activeTab = {this.state.activeTab}
                                    fromPage= {this.props.fromPage}
                                    isDefaultForAllAddress = {this.props.isDefaultForAllAddress}
                                    giftAddress = {this.props.giftAddress}
                                    recipients = {this.props.recipients}
                                    senders={this.props.senders}                                    
                                    gifters={this.props.gifters}
                                    id={this.props.id}
                                    name={this.props.name}
                                    toggleMaterials = {this.props.toggleMaterials}
                                    address = {this.props.address}    
                                    occassion = {this.props.occassion}
                                    giftMessage = {this.props.giftMessage}
                                    >
                                    </MaterialMainContent>
                                </div>
                                </MDBCol >
                                </MDBRow>                               
                            </MDBContainer>
                        </Router>
                    </div>

                    </MDBModalBody>
                 
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </>
            );
        }

}

export default Materials;