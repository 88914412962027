import axios from 'axios';
import authService from "../app.config";
import { encode, decode } from 'js-base64';
import { Base64 } from 'js-base64';
class GifterService {

    constructor() {
        console.log("Gifter service created");
    }

    async getData(httpPath) {
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenantId
        };
        return axios.get(httpPath, { headers });
    }

    async getDataWithCancel(httpPath, cancelToken) {
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenantId
        };
        return axios.get(httpPath,
            {
                headers: headers,
                cancelToken: cancelToken.token
            }
        );
    }

    async getDataParamsWithCancel(httpPath, params, cancelToken) {
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenantId
        };
        return axios.get(httpPath + "?input=" + btoa(JSON.stringify(params)), 
        { headers: headers, cancelToken: cancelToken.token });
    }

    async getDataParams(httpPath, params) {
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenantId
        };
        return axios.get(httpPath + "?input=" + btoa(JSON.stringify(params)), { headers });
    }
    async postDataParams(httpPath, body) {
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenantId
        };
        // console.log('bit64', btoa(JSON.stringify(body)))
        console.log('bit64', btoa(unescape(encodeURIComponent(JSON.stringify(body)))))
        //return axios.post(httpPath, btoa(JSON.stringify(body)), { headers });
        return axios.post(httpPath, btoa(unescape(encodeURIComponent(JSON.stringify(body)))), { headers });
    }

    async fetchData(httpPath) {
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenantId
        };

        let options = {
            headers: new Headers(headers)
        };

        return fetch(httpPath, options);
    }

    async fetchFile(httpPath) {
        return fetch(httpPath);
    }
    async postData(httpPath, body) {
        //
        let token = await authService.getAccessToken();
        let tenantId = localStorage.getItem("active_tenant_id");
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenantId
        };
        return axios.post(httpPath, JSON.stringify(body), { headers });
    }

    async uploadFile(httpPath, formData) {
        //
        let token = await authService.getAccessToken();

        let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        };
        //Base64.extendString();
        return axios.post(httpPath, // encodeGetParams(formData)
            // btoa(encodeURIComponent(formData).replace(/%([0-9A-F]{2})/g,
            // function toSolidBytes(match, p1) {
            //     return String.fromCharCode('0x' + p1);
            // }))
            //Base64.encode(formData)
            //formData.toBase64()
            //Base64.btoa(formData)
            formData
            , { headers });



    }
    encodeGetParams(params) {
        // var result = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");        
        // return btoa(result);
        return btoa(encodeURIComponent(params).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    }
}

export default new GifterService();