import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import {MDBSpinner} from 'mdb-react-ui-kit';
import MainContent from '../components/PageElements/MainContent';
import HeaderContent from '../components/PageElements/HeaderContent';
//import FooterContent from '../components/PageElements/FooterContent';
import ErrorPage from '../components/ErrorPage';
//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import 'mdb-react-ui-kit/dist/css/mdb.min.css'
//import "../index.css";
import { withOktaAuth } from '@okta/okta-react';
import GifterService from '../services/service';
import * as Constants from '../constants/constants';
import * as appConstants from '../constants/appConstants';
import UserProfile from '../json/user_data.json';
import {
  BrowserRouter as Router
} from "react-router-dom";
import PageSpinner from './PageSpinner';
//const UserProfile = require('../json/user_data.json');
export default withOktaAuth(class Home extends Component {

  constructor(props) {
    super(props);
    if (this.props.authState.accessToken == undefined)
      this.props.authService.logout('/');

    this.state = {
      isValidUser: true,
      isLoading: true,
      isLoggedIn: false,
      user_name: null,
      user_full_name: null,
      user_profile: null,
      user_profile_okta: null,
      errorId: 0,
      errorDesc: '',      
      active_tenant_id : null,
      active_tenant_name: null,
      active_tenant_role: null,
      active_tenant_permissions: {
        isAdmin: false,
        //isOpen: true,
        isCreateEvent: false,
        isCreateGift: false,
        isCreateCard: false,
        isCreatePeople: false,
        isCreateData: false,
        isCreateUser: false,
        isViewEvent: false,
        isViewGift: false,
        isViewCard: false,
        isViewPeople: false,
        isViewData: false,
        isViewUser: false,        
      },
      tenants: [],
      active_route: '/'      
    }

    this.mainContent = React.createRef();
  }

  handleSideNav = (value, routePath) =>{
    this.setState({
      //isOpen: !this.state.isOpen
      activeRoute: routePath
    });
  }

  async componentDidMount() {
    try {
      this._isMounted = true;
      await this.props.authService.getUser()
        .then(response => {
          let name = response.name
          let email = response.email;

          //console.log("User Info :" + JSON.stringify(response));
          localStorage.setItem('user_email', email);
          //localStorage.setItem('user_name', name);
          localStorage.setItem('user_full_name', name);
          //localStorage.setItem('user_profile_okta', JSON.stringify(UserProfile));
          this.setState({isLoading : true, user_full_name: name});
          GifterService.getData(Constants.GifterServiceBaseUrl + '/loggedInUser')//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
              if (this._isMounted) {
                //console.log("User Profile -->" + JSON.stringify(response));
                if (response){ // (response.data && response.data.length > 0) {
                  let userProfile = response.data[0];// UserProfile; // response.json(); //data[0];
                  //if(userprofile )
                  let userId = userProfile.user_id;
                  localStorage.setItem('user_id', userId);
                  localStorage.setItem('user_profile', JSON.stringify(userProfile));
                  
                  var is_user_provisioner = userProfile.is_user_provisioner;
                  var is_active_user = userProfile.is_active;

                  if(!is_active_user){
                    this.setState({
                      isValidUser: false,
                      isLoggedIn: false,
                      isLoading: false,
                      errorId: '401',
                      errorDesc: 'User is marked as inactive in the system. Please contact admin for Gifter application'
                    });
                  }                  
                  else{
                    if(is_user_provisioner){
                      userProfile.default_tenant_id = 0;
                    }
                    this.setState({
                      isValidUser: true,
                      isLoading: false,
                      isLoggedIn: true,
                      user_name : userProfile.name,
                      user_profile: userProfile                    
                    });                                      
                 }
                 this.processPermissions(userProfile, is_user_provisioner);
                }
                else {
                  this.setState({
                    isValidUser: false,
                    isLoggedIn: false,
                    isLoading: false,
                    errorId: '401',
                    errorDesc: 'User is not available in the system or marked as inactive. Please contact admin for Gifter application'
                  });
                }
              }
            },
              (err) => {
                console.log('Service Error : Log In -> Getting User Profile ' + err);
                this.setState({
                  isValidUser: false,
                  isLoggedIn: false,
                  isLoading: false,
                  errorId: '503',
                  errorDesc: 'User profile service is not available. Please try again and contact admin for Gifter application'
                });
              });

        },
          (err) => {
            console.log(err);
            this.setState({
              isValidUser: false,
              isLoggedIn: false,
              isLoading: false,
              errorId: '503',
              errorDesc: 'OKTA user profile service is not available. Please try again and contact admin for Gifter application'
            });
          });
    }
    catch (err) { }
  }
  processPermissions(userProfile, is_user_provisioner) {

    let permissions = appConstants.PERMISSIONS;
    let permissionObjs = [];
    if (userProfile) {           
     // debugger;
      let tenants = [];
      var default_tenant_id = userProfile.default_tenant_id;
      var active_tenant = localStorage.getItem("active_tenant_id");
      var active_tenant_name = localStorage.getItem("active_tenant_name");
      var active_tenant_role;
      this.setState({tenants: userProfile.tenants});
      userProfile.tenants.forEach((tenant) => {
        if(tenant.tenant_id == null)
        {
          tenant.tenant_id = 0;
          tenant.tenant_name = "All Tenants";
        }
        tenants.push({"tenant_id" : tenant.tenant_id, 
        "tenant_name": tenant.tenant_name});  
        localStorage.setItem("tenants", tenants);
        
        if((active_tenant == "null" || !active_tenant) && default_tenant_id == tenant.tenant_id)
        { 
          localStorage.setItem("active_tenant_id", tenant.tenant_id);
          localStorage.setItem("active_tenant_name", tenant.tenant_name);
          active_tenant = tenant.tenant_id;          
        }

        // let permissionArr = tenant.permissions;
        // let permissionObj = {
        //   "tenant_id": "",
        //   "tenant_name": ""
        // };
        // permissions.forEach((permission) => {
        //   permissionObj[permission] = false;
        // });
        // let permObj = permissionObj;
        // permObj["tenant_id"] = tenant.tenant_id;
        // permObj["tenant_name"] = tenant.tenant_name;
        // permissionArr.forEach((permission) => {
        //   permObj[permission.PermissionName] = true;
        // });

        // if (division.Groups[0].GroupName === "ADMIN" && !isAdmin) {
        //   isAdmin = true;
        // }
        // if (division.Groups[0].GroupName === "Creative" && division.Groups[0].Roles[0].RoleName === "Final Approver") {
        //   isFinalApprover = true;
        // }
        // if (userProfile.IsFinalApprover) {
        //   isFinalApprover = true
        // }

        if(active_tenant == tenant.tenant_id){
          this.processPermissionsforTenant(tenant, is_user_provisioner);
        }
        //permissionObjs.push(permObj);
      });
      //localStorage.setItem("permissions", permissionObjs);     

    }

  }


  processPermissionsforTenant(tenant, is_user_provisioner){
    let isAdmin = false;
    let isCreateEvent = false;
    var isCreateGift = false;
    var isCreateCard = false;
    var isCreatePeople = false;
    var isCreateData = false;
    var isCreateUser = false;
    var isViewEvent = false;
    var isViewGift = false;
    var isViewCard = false;
    var isViewPeople = false;
    var isViewData = false;
    var isViewUser = false;
    var eventRole = '';
    var giftRole = '';
    var cardRole = '';
    // var active_tenant_name = localStorage.getItem("active_tenant_name");
    // var active_tenant_role;
    var active_tenant_name = tenant.tenant_name;
    var active_tenant_role = tenant.access_roles.map((access_role) => 
      access_role.role_name + " ("+ access_role.module_name + ")"
    ).join(" & ");   

    tenant.access_roles.map((access_role) =>{
      if(access_role.module_name == 'Events')
        eventRole = access_role.role_name;
      else if (access_role.module_name == 'Gifts')
        giftRole = access_role.role_name;      
    });
    if(eventRole == "Admin" || giftRole == "Admin")
      cardRole = "Admin";
    else if(eventRole == "Manager" || giftRole == "Manager")
      cardRole = "Manager";
    else if(eventRole == "Business User" || giftRole == "Business User")
      cardRole = "Business User";
    else if(eventRole == "Read-Only User" || giftRole == "Read-Only User")
      cardRole = "Read-Only User";

  // }
  // if(active_tenant === tenant.tenant_id){
    var permissions_tenant = {...this.state.active_tenant_permissions};
    if (tenant.permissions && tenant.permissions["Create Event"]) {
      permissions_tenant.isCreateEvent = true;
    }
    if (tenant.permissions && tenant.permissions["Create Gift"]) {
      permissions_tenant.isCreateGift = true;
    }
    if (tenant.permissions && tenant.permissions["Create Card"]) {
      permissions_tenant.isCreateCard = true;
    }
    if (tenant.permissions && tenant.permissions["Create People"]) {
      permissions_tenant.isCreatePeople = true;
    }
    if (tenant.permissions && tenant.permissions["Create Data"]) {
      permissions_tenant.isCreateData = true;
    }      
    if (tenant.permissions && tenant.permissions["Create User"]) {
      permissions_tenant.isCreateUser = true;
    }                
    if (tenant.permissions && tenant.permissions["View Event"]) {
      permissions_tenant.isViewEvent = true;
    }
    if (tenant.permissions && tenant.permissions["View Gift"]) {
      permissions_tenant.isViewGift = true;
    }                
    if (tenant.permissions && tenant.permissions["View Card"]) {
      permissions_tenant.isViewCard = true;
    }
    if (tenant.permissions && tenant.permissions["View People"]) {
      permissions_tenant.isViewPeople = true;
    } 
    if (tenant.permissions && tenant.permissions["View Data"]) {
      permissions_tenant.isViewData = true;
    }      
    if (tenant.permissions && tenant.permissions["View User"]) {
      permissions_tenant.isViewUser = true;
    }     


    if(is_user_provisioner){
      active_tenant_role = "User Provisioner";
      permissions_tenant.isViewUser = true;
      permissions_tenant.isCreateUser = true;
    }

    this.setState({
      // isAdmin: isAdmin,
      // isCreateEvent: isCreateEvent,
      // isCreateGift: isCreateGift,
      // isCreateCard: isCreateCard,
      // isCreatePeople: isCreatePeople,
      // isViewEvent: isViewEvent,
      // isViewGift: isViewGift,
      // isViewCard: isViewCard,
      // isViewPeople: isViewPeople,
      active_tenant_id : tenant.tenant_id,
      active_tenant_name: active_tenant_name,
      active_tenant_role: active_tenant_role,   
      active_tenant_permissions: permissions_tenant,
      active_tenant_event_role: eventRole,
      active_tenant_gift_role: giftRole,
      active_tenant_card_role: cardRole
    });

    localStorage.setItem("active_tenant_id", tenant.tenant_id);
    localStorage.setItem("active_tenant_name", tenant.tenant_name);
    localStorage.setItem("active_tenant_event_role",eventRole);
    localStorage.setItem("active_tenant_gift_role", giftRole);
    localStorage.setItem("active_tenant_card_role", cardRole);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // handleSideNavActive = (value, routePath) => {       
  //   //this.props.history.push(routePath);
  //   //this.props.handleSideNavActive(value, routePath);
  //   //this.setState({active_route : routePath});
  //   this.mainContent.current.handleSideNavActive1(value, routePath);
  // }

  // handleSideNavActive1 = (value, routePath) => {
  //   this.mainContent.current.handleSideNavActive1(value, routePath);
  // }

  switchTenant = (tenant_id) => {
    //alert("Switched to Tenant -->"+tenant_id);
    var tenant = this.state.user_profile.tenants.find(tenant => tenant.tenant_id == tenant_id);
    this.processPermissionsforTenant(tenant, this.state.user_profile.is_user_provisioner);
    this.props.history.push('/', this.state);
  }

  render() {
    return (
       (this.state.isLoading?   
        
          <div className='alignCenter '>
            <div className="align-center-vertical">
              <MDBSpinner role='status' color="primary">          
              </MDBSpinner>
              <div>
                <span style={{textAlign : 'center'}}>Loading Gifter....</span>              
              </div>
            </div>
          </div>
        
         : (
      !this.state.isValidUser ? <ErrorPage isLoggedIn={this.state.isValidUser} errorId={this.state.errorId} errorDesc={this.state.errorDesc} ></ErrorPage> :

        <Router>
        <div className="h-100">
          {/* <HeaderContent {...this.state} handleSideNavActive={this.handleSideNavActive.bind(this)} 
                  authState={this.props.authState} switchTenant = {this.switchTenant.bind(this)} /> */}

          <MainContent {...this.state} {...this.props} 
                       wrappedComponentRef={this.mainContent}
                       switchTenant={this.switchTenant.bind(this)}/>
        </div>
        </Router>
      )
     )
    );
  }
}
);
