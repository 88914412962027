import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBTooltip,
  MDBIcon
} from 'mdb-react-ui-kit';

import Button from '@material-ui/core/Button';
import PeopleManagement from './PeopleManagement'


class LookupModal extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      isOpen: this.props.isOpen,
      pageName: this.props.pageName
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      isOpen: this.props.isOpen,
      pageName: this.props.pageName
    });
  }
  addPeople = (people) => {
    const filteredArr = people.reduce((acc, current) => {
      const x = acc.find(item => item.person_id === current.person_id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    this.props.addPeople(filteredArr);
  }
  toggleLookupPeople = () => {
    this.props.toggleLookupPeople();
  }

  render() {
    return (
      <>
        <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props.isOpen} id='lookupPeople'>
          <MDBModalDialog size="xl" >
            <MDBModalContent>
              <MDBModalHeader className='lookupmodalHeader'>
                <h6 class="modal-title" id="exampleModalLabel">{this.state.pageName}</h6>
                <div className="flexAlignRight">
                  <MDBIcon size="2x" icon="times-circle" onClick={this.toggleLookupPeople.bind(this)}></MDBIcon>
                </div>
              </MDBModalHeader>
              <MDBModalBody>
                <PeopleManagement addPeople={this.addPeople} toggleLookupPeople={this.toggleLookupPeople} pageName={this.state.pageName}></PeopleManagement>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    );
  }

}

export default LookupModal;