import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
// import DataTable from 'react-data-table-component';
// import {
//   MDBContainer, MDBCol, MDBRow, MDBModal,
//   MDBTableHead, MDBIcon, MDBModalBody, MDBModalHeader, MDBModalFooter
//   , MDBDataTableV5, MDBInput
// }
//   from 'mdbreact';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
  MDBTabsPane, MDBTabsContent, MDBDropdown, MDBSelect, MDBBtn, MDBDatepicker,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBTooltip,
  MDBIcon
} from 'mdb-react-ui-kit';

import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';
import "./Import.scss";
import ImportPreviewJSON from '../../json/preview_import.json';
import DuplicatePeople from './DuplicatePeople';

class PreviewImport extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      isOpen: this.props.isOpen,
      showDuplicatePeople: false,
      selectedFileName: props.selectedFileName,
      pageSize: 10,
      page: 0,
      rowCount: 10,
      duplicatesData: undefined,
      isPosting: false
      //   data: {
      //       columns: [
      //         {
      //           label: 'First Name',
      //           headerName: 'First Name',
      //           field: 'first_name',
      //           width: 200,
      //           flex:0.2
      //         },
      //         {
      //           label: 'Last Name',
      //           headerName: 'Last Name',
      //           field: 'last_name',
      //           width: 150,
      //           flex: 0.2
      //         },
      //         {
      //           label: 'Category',
      //           headerName: 'Category',
      //           field: 'category_name',
      //           width: 70,
      //           flex: 0.3
      //         },   
      //         {
      //           label: 'Division',
      //           headerName: 'Division',
      //           field: 'department_name',
      //           width: 100,
      //           flex: 0.3
      //         },
      //         {
      //           label: 'Title',
      //           headerName: 'Title',
      //           field: 'title_name',
      //           width: 100,
      //           flex:0.3
      //         },
      //         {
      //           label: 'Company',
      //           headerName: 'Company',
      //           field: 'company_name',
      //           width: 150,
      //           flex: 0.3
      //         }
      //       ],
      //       rows: [ ]
      // }      
    }


  }

  // shouldComponentUpdate(props, nextProps) {

  //   if(props.isOpen === nextProps.isOpen)
  //   return false;
  //   else
  //   return true;

  // }
  // handleFileSelect = (event) => {
  //   this.setState({
  //     selectedFiles: event.target.files,
  //     selectedFile: event.target.files[0]
  //   });
  // }
  componentWillReceiveProps(props) {
    this.setState({
      isOpen: props.isOpen
      //, selectedFile : props.selectedFile       
    });



  }
  componentDidMount() {
    this._isMounted = true;

  }

  // toggleDuplicatePeople = () => {
  //   this.setState({showDuplicatePeople : !this.state.showDuplicatePeople});
  // }

  togglePreviewImport = () => {
    this.props.togglePreviewImport();
  }

  finishDuplicateCheck = (duplicateResults) => {
    //     this.setState({
    //       data:  {...this.state.data, rows: ImportPreviewJSON
    //      },
    //      rowCount: 3 //response.data.rowCount
    //  });

    var results = this.props.importPeopleData.rows;
    var finalResults = duplicateResults.concat(results.filter(({ excel_row_no }) =>
      !duplicateResults.find(p => p.excel_row_no == excel_row_no)));
    //var finalResults = results.concat(duplicateResults.filter( ({excel_row_no}) => !results.find(p => p.excel_row_no == excel_row_no)));
    //this.setState({rows : finalResults});
    //this.togglePreviewImport();
    this.props.finishDuplicateCheck(finalResults);
    this.props.toggleDuplicatePeople();

  }

  importPeople = () => {
    this.setState({ isPosting: true });
    GifterService.postData(Constants.GifterServiceBaseUrl + '/importPersons', this.props.importPeopleData.rows)
      .then((response) => {
        console.log("Gift Service selected file  for import persons: " + response.data);

        //var results = response.data.map((row) => ({ ...row, id: row.excel_row_no }));
        this.setState({ isPosting: false });
        this.props.importPeople(response.data);
        // this.setState({
        //     importPeopleData:  {...this.state.importPeopleData, rows: results
        //     },
        //     importPeopleRowCount: results.length
        // });

        // let duplicates = response.data.filter((data) => data.isDuplicate);
        // if(duplicates && duplicates.length > 0)
        // this.setState({showDuplicatePeople : true,
        //     duplicatesData : duplicates
        // });

      },
        (err) => {
          this.setState({ isPosting: false });
          console.log("Gift Service error for import people: " + err);
          alert("Import People failed :" + err);
        });

    //this.props.importPeople(this.props.importPeopleData.rows);
  }

  render() {
    return (
      <>
        <MDBModal id="previewImport" staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props.isOpen} >
          {/* getOpenState={(e) =>  this.setState({ isOpen: e })}> */}
          <MDBModalDialog size="xl" >
            <MDBModalContent>
              {/* <MDBModalHeader>
                      <MDBModalTitle>Modal title</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.togglePreviewImport}></MDBBtn>
                    </MDBModalHeader> */}

              <MDBModalHeader className="row margin-ver">
                <div className="flexBox-plain col-12">
                  {/* <div className="flexAlignRight"> */}
                  <MDBModalTitle className="h6 text-primary">{this.props.selectedFileName}</MDBModalTitle>
                  {/* </div>   */}
                  <div className="flexAlignRight">
                    <MDBIcon size="2x" icon="times-circle" onClick={this.props.togglePreviewImport}></MDBIcon>
                  </div>

                </div>
                <div className="fst-italic col-12">
                  People not found in the system are highlighted and will have a new record created
                    </div>
              </MDBModalHeader>
              <MDBModalBody className="padding-none">
                <div className="flexBox-plain" id="previewImport">

                  <DataGrid
                    columns={this.props.importPeopleData.columns}
                    rows={this.props.importPeopleData.rows}
                    autoHeight
                    pagination
                    rowHeight={40}
                    pageSize={this.state.pageSize}
                    page={this.state.page}
                    rowsPerPageOptions={[2, 4, 10, 25, 50, 75, 100]}
                    rowCount={this.props.importPeopleRowCount}
                    getRowClassName={(params) => {
                      if (params.row.is_new === true) return "data-grid-single-row";
                    }
                    }
                  />


                </div>


                <DuplicatePeople isOpen={this.props.showDuplicatePeople}
                  //toggleDuplicatePeople={this.toggleDuplicatePeople.bind(this)}                    
                  data={this.props.duplicatesData}
                  togglePreviewImport={this.togglePreviewImport.bind(this)}
                  finishDuplicateCheck={this.finishDuplicateCheck.bind(this)}
                >
                </DuplicatePeople>

                {/* <div className={this.state.selectedFile ? "flexBox-plain padding" : "d-none"}> 
                        <MDBIcon size='1x' icon="check-circle" className="mr-2 text-success"></MDBIcon>
                        <label>{this.state.selectedFile?.name || ''}</label>  
                    </div>  */}
                <br />
                <div className="flexBox-center padding">

                  <Button variant="contained" size="small" color="primary" disabled={this.state.isPosting}
                    onClick={this.importPeople.bind(this)}>
                    {this.state.isPosting ? <CircularProgress size={25} /> : "Import People"}</Button>
                  {/* <MDBBtn onClick={this.importPeople.bind(this)}>Import People</MDBBtn> */}
                </div>
              </MDBModalBody>

              {/* <MDBModalFooter>
                      <MDBBtn color='secondary' onClick={this.props.togglePreviewImport}>
                        Close
                      </MDBBtn>
                      <MDBBtn>Save changes</MDBBtn>
                    </MDBModalFooter> */}
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    );
  }

}

export default PreviewImport;