import Input from './Input'
import Select from './Select'
import Button from './Button'
import AutoDropdown from './AutoComplete'
import AutoDropdownNo from './AutoCompleteNo'


const Controls = {
    Input,
    Select,
    Button,
    AutoDropdown,
    AutoDropdownNo
}

export default Controls;