import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {
    MDBContainer, MDBCol, MDBRow, MDBModal,
    MDBTableHead, MDBIcon, MDBModalBody, MDBModalHeader, MDBModalFooter
    , MDBDataTableV5, MDBInput
}
    from 'mdbreact';
import { BrowserRouter as Router, Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
    MDBTabsPane, MDBTabsContent, MDBDropdown, MDBSelect, MDBBtn
} from 'mdb-react-ui-kit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DateRangePicker } from 'rsuite';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import GifterService from '../services/service.js';
import EventGiftDashboardComponent from '../components/EventGiftDashboardComponent';
import EventGiftDashboardComponentMD from '../components/EventGiftDashboardComponentMD';
import * as Constants from '../constants/constants';
import ConfirmDialog from '../components/Common/ConfirmDialog';
import Tooltip from '@material-ui/core/Tooltip';
import './EventGiftLandingPage.scss';

class EventGiftLandingPage extends React.Component {
    _isMounted = false;
    //mainContent = null;
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: "",
            user_name: localStorage.getItem("user_name"),
            filterEvent: false,
            filterGift: false,
            filterCard: false,
            inviteeRange: [0, 1000],
            minInvitee: 0,
            maxInvitee: 1000,
            eventPage: 0,
            eventSize: 10,
            eventRowCount: 10,
            giftPage: 0,
            giftSize: 10,
            giftRowCount: 10,
            cardPage: 0,
            cardSize: 10,
            cardRowCount: 10,
            confirmSaveChanges: false,
            event_gift_card_id: 0,
            divisions: [
                // {text: 'Warner Television', divisionName: 'Warner Television', value: "1" },
                // {text: 'Warner Animation', divisionName: 'Warner Animation', value: "2", selected: true},
                // {text: 'Warner Horizon', divisionName: 'Warner Horizon', value: "3", selected: true }
            ],
            shows: [
                // {name: 'Arrow',  showName: 'Arrow', showId: 1 },
                // {name: 'Big Bang Theory', showName: 'Big Bang Theory', showId: 2, selected: true },
                // {name: 'Shameless', showName: 'Shameless', showId: 3 }
            ],
            occasions: [],
            invitees: [],
            eventsData: {
                columns: [
                    {
                        label: 'Event Id',
                        headerName: 'Event Id',
                        field: 'id',
                        hide: true,
                        flex: 0
                    },
                    {
                        label: 'Event Name',
                        headerName: 'Event Name',
                        field: 'name',
                        width: 200,
                        flex: 0.4,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.name} >
                                <span className="table-cell-trucate">{params.row.name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Division',
                        headerName: 'Division',
                        field: 'org_unit_name',
                        width: 150,
                        flex: 0.3,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.org_unit_name} >
                                <span className="table-cell-trucate">{params.row.org_unit_name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Show',
                        headerName: 'Show',
                        field: 'show_name',
                        width: 150,
                        flex: 0.2,
                        sortable: true
                    },

                    {
                        label: '#Invitees',
                        headerName: '#Invitees',
                        type: "number",
                        field: 'invitee_cnt',
                        width: 50,
                        flex: 0.2,
                        sortable: true
                    },
                    {
                        label: 'Date',
                        headerName: 'Date',
                        type: "dateTime",
                        field: 'event_date_time',
                        width: 100,
                        flex: 0.3,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.event_date_time} >
                                <span className="table-cell-trucate">{params.row.event_date_time}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Owner',
                        headerName: 'Owner',
                        field: 'created_by_name',
                        width: 70,
                        flex: 0.2,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.created_by_name} >
                                <span className="table-cell-trucate">{params.row.created_by_name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Action',
                        headerName: 'Action',
                        field: 'action',
                        sortable: false,
                        width: 100,
                        flex: 0.3,
                        renderCell: (params) => {
                            var role = this.props.active_tenant_event_role;
                            var is_event_create = false;
                            if (role == "Admin" || params.row.created_by_name == this.props.user_profile?.name)
                                is_event_create = true;

                            if (role == "Read-Only User")
                                is_event_create = false;

                            return <>
                                <div class="flexBox-plain">
                                    <div className={is_event_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/event/${params.row.id}/0`}>
                                            <MDBIcon onClick={this.viewOrEditEvent.bind(this)} icon="pen" component={RouterLink} className="mr-4 text-black padding-hor action-icon"
                                            />
                                        </Link>
                                    </div>
                                    <div className={is_event_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/event/${params.row.id}/1`}>
                                            <MDBIcon onClick={this.viewOrEditEvent.bind(this)} icon="copy" component={RouterLink} className="mr-4 text-black padding-hor action-icon"
                                            />
                                        </Link>
                                    </div>

                                    <div className={is_event_create ? "padding-hor" : "d-none"}>

                                        <MDBIcon onClick={this.deleteEventGiftCard.bind(this, params.row.id)} fas
                                            icon="trash-alt" className="mr-4 text-black padding-hor action-icon" >
                                        </MDBIcon>
                                    </div>
                                    <div className={!is_event_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/viewEvent/${params.row.id}`}>
                                            <MDBIcon onClick={this.viewOrEditEvent.bind(this)} fas
                                                icon="eye" className="mr-4 text-black padding-hor action-icon"
                                                component={RouterLink} >
                                            </MDBIcon>
                                        </Link>
                                    </div>
                                </div>
                            </>;
                        },
                    }
                ],
                rows: []
            },
            giftsData: {
                columns: [
                    {
                        label: 'Gift Id',
                        headerName: 'Gift Id',
                        field: 'id',
                        hide: true,
                        flex: 0
                    },
                    {
                        label: 'Name',
                        headerName: 'Name',
                        field: 'name',
                        width: 200,
                        flex: 0.4,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.name} >
                                <span className="table-cell-trucate">{params.row.name}</span>
                            </Tooltip>
                        )
                    },
                    // {
                    //     label: 'Division',
                    //     headerName: 'Division',
                    //     field: 'org_unit_name',
                    //     width: 150,
                    //     flex: 0.3,
                    //     sortable: true,
                    //     renderCell: (params) => (
                    //         <Tooltip title={params.row.org_unit_name} >
                    //             <span className="table-cell-trucate">{params.row.org_unit_name}</span>
                    //         </Tooltip>
                    //     )
                    // },
                    {
                        label: 'Show',
                        headerName: 'Show',
                        field: 'show_name',
                        width: 150,
                        flex: 0.2,
                        sortable: true
                    },

                    {
                        label: 'Occasion',
                        headerName: 'Occasion',
                        // type: "occasion_name",
                        field: 'occasion_name',
                        width: 50,
                        flex: 0.2,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.occasion_name} >
                                <span className="table-cell-trucate">{params.row.occasion_name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Recipients',
                        headerName: 'Recipients',
                        // type: "number",
                        field: 'recipients_name',
                        width: 200,
                        flex: 0.3,
                        sortable: true,
                        cellClassName: 'data-grid-block-el-to-flex',
                        renderCell: (params) => (
                            <Tooltip title={<div>
                                {
                                    <Typography dangerouslySetInnerHTML={{__html: params.row.recipient_cnt > 1 ? params.row.tooltip_recipients?.split(",").join('<br />') : params.row.tooltip_recipients}}>
                                    </Typography>
                                }
                            </div>} >
                                {
                                    <span className="table-cell-trucate">{params.row.display_recipients}</span>
                                }
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Created On',
                        headerName: 'Created On',
                        type: "dateTime",
                        field: 'gift_created_date',
                        width: 100,
                        flex: 0.2,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.gift_created_date} >
                                <span className="table-cell-trucate">{params.row.gift_created_date}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Owner',
                        headerName: 'Owner',
                        field: 'created_by_name',
                        width: 70,
                        flex: 0.2,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.created_by_name} >
                                <span className="table-cell-trucate">{params.row.created_by_name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Action',
                        headerName: 'Action',
                        field: '',
                        sortable: false,
                        width: 80,
                        flex: 0.2,
                        renderCell: (params) => {
                            var role = this.props.active_tenant_gift_role;
                            var is_gift_create = false;
                            if (role == "Admin" || params.row.created_by_name == this.props.user_profile?.name)
                                is_gift_create = true;

                            if (role == "Read-Only User")
                                is_gift_create = false;

                            return <>
                                <div class="flexBox-plain">
                                    <div className={is_gift_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/gift/${params.row.id}/0`}>
                                            <MDBIcon onClick={this.viewOrEditGift.bind(this)} icon="pen" component={RouterLink} className="mr-4 text-black padding-hor action-icon"
                                            />
                                        </Link>
                                    </div>
                                    <div className={is_gift_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/gift/${params.row.id}/1`}>
                                            <MDBIcon onClick={this.viewOrEditGift.bind(this)} icon="copy"
                                                component={RouterLink} className="mr-4 text-black padding-hor action-icon"
                                            />
                                        </Link>
                                    </div>
                                    <div className={is_gift_create ? "padding-hor" : "d-none"}>
                                        <MDBIcon onClick={this.deleteEventGiftCard.bind(this, params.row.id)} fas
                                            icon="trash-alt" className="mr-4 text-black padding-hor action-icon" >
                                        </MDBIcon>
                                    </div>
                                    <div className={!is_gift_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/viewGift/${params.row.id}`}>
                                            <MDBIcon onClick={this.viewOrEditGift.bind(this)} fas
                                                icon="eye" className="mr-4 text-black padding-hor action-icon"
                                                component={RouterLink} >
                                            </MDBIcon>
                                        </Link>
                                    </div>
                                </div>
                            </>;
                        }
                    }
                ],
                rows: []
            },
            cardsData: {
                columns: [
                    {
                        label: 'Card Id',
                        headerName: 'Card Id',
                        field: 'id',
                        hide: true,
                        flex: 0
                    },
                    {
                        label: 'Name',
                        headerName: 'Name',
                        field: 'name',
                        width: 200,
                        flex: 0.4,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.name} >
                                <span className="table-cell-trucate">{params.row.name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Division',
                        headerName: 'Division',
                        field: 'org_unit_name',
                        width: 150,
                        flex: 0.3,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.org_unit_name} >
                                <span className="table-cell-trucate">{params.row.org_unit_name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Recipients',
                        headerName: 'Recipients',
                        type: "number",
                        field: 'rec_cnt',
                        width: 50,
                        flex: 0.2,
                        sortable: true
                    },
                    {
                        label: 'Created On',
                        headerName: 'Created On',
                        type: "dateTime",
                        field: 'card_created_date',
                        width: 100,
                        flex: 0.3,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.card_created_date} >
                                <span className="table-cell-trucate">{params.row.card_created_date}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Owner',
                        headerName: 'Owner',
                        field: 'created_by_name',
                        width: 70,
                        flex: 0.3,
                        sortable: true,
                        renderCell: (params) => (
                            <Tooltip title={params.row.created_by_name} >
                                <span className="table-cell-trucate">{params.row.created_by_name}</span>
                            </Tooltip>
                        )
                    },
                    {
                        label: 'Action',
                        headerName: 'Action',
                        field: '',
                        sortable: false,
                        width: 90,
                        flex: 0.3,
                        renderCell: (params) => {
                            var role = this.props.active_tenant_card_role;
                            var is_card_create = false;
                            if (role == "Admin" || params.row.created_by_name == this.props.user_profile?.name)
                                is_card_create = true;

                            if (role == "Read-Only User")
                                is_card_create = false;

                            return <>
                                <div class="flexBox-plain">
                                    <div className={is_card_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/card/${params.row.id}/0`}>
                                            <MDBIcon onClick={this.viewOrEditCard.bind(this)} icon="pen" component={RouterLink} className="mr-4 text-black padding-hor action-icon"
                                            />
                                        </Link>
                                    </div>

                                    <div className={is_card_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/card/${params.row.id}/1`}>
                                            <MDBIcon onClick={this.viewOrEditCard.bind(this)} icon="copy" component={RouterLink} className="mr-4 text-black padding-hor action-icon"
                                            />
                                        </Link>
                                    </div>
                                    <div className={is_card_create ? "padding-hor" : "d-none"}>

                                        <MDBIcon onClick={this.deleteEventGiftCard.bind(this, params.row.id)} fas
                                            icon="trash-alt" className="mr-4 text-black padding-hor action-icon" >
                                        </MDBIcon>

                                    </div>
                                    <div className={!is_card_create ? "padding-hor" : "d-none"}>
                                        <Link component={RouterLink} to={`/viewCard/${params.row.id}`}>
                                            <MDBIcon onClick={this.viewOrEditCard.bind(this)} fas
                                                icon="eye" className="mr-4 text-black padding-hor action-icon"
                                                component={RouterLink} >
                                            </MDBIcon>
                                        </Link>
                                    </div>
                                </div>
                            </>;
                        }
                    }
                ],
                rows: []
            },
            eventParams: {
                "tenant_id": localStorage.getItem("active_tenant_id"),
                "division_filters": null,
                "show_filters": null,
                "invitee_cnt_min": 1,
                "invitee_cnt_max": 1000,
                "event_date_from": null,
                "event_date_to": null,
                "page_count": 10,
                "page_no": 1,
                "created_by_me": null,
                "order_by_clause": "event_date_time",
                "order_by": "desc",
                "search_filters": ""
            },
            giftParams: {
                "tenant_id": localStorage.getItem("active_tenant_id"),
                "division_filters": null,
                "show_filters": null,
                "occasion_filters": null,
                "invitee_filters": null,
                "rec_cnt_min": 1,
                "rec_cnt_max": 1000,
                "created_from": null,
                "created_to": null,
                "page_count": 10,
                "page_no": 1,
                "created_by_me": null,
                "order_by_clause": "gift_created_date",
                "order_by": "desc",
                "search_filters": ""
            },
            cardParams:
            //  {
            //     "tenant_id": localStorage.getItem("active_tenant_id"),
            //     "division_filters": null,
            //     "show_filters": null,
            //     "invitee_cnt_min": 1,
            //     "invitee_cnt_max": 1000,
            //     "created_from": null,
            //     "created_to": null,
            //     "page_count": 10,
            //     "page_no": 1,
            //     "created_by_me": null,
            //     "order_by_clause": "Name",
            //     "order_by": null,
            //     "search_filters": ""

            // },
            {
                "tenant_id": localStorage.getItem("active_tenant_id"),
                "division_filters": null,
                "show_filters": null,
                "rec_cnt_min": 1,
                "rec_cnt_max": 1000,
                "created_from": null,
                "created_to": null,
                "page_count": 10,
                "page_no": 1,
                "created_by_me": null,
                "order_by_clause": "card_created_date",
                "order_by": "desc",
                "search_filters": ""
            },
            eventsLoading: false,
            giftsLoading: false,
            cardsLoading: false,
            eventsSortModel: [{
                field: 'event_date_time', sort: 'desc'
            }],
            giftsSortModel: [{
                field: 'gift_created_date', sort: 'desc'
            }],
            cardsSortModel: [{
                field: 'card_created_date', sort: 'desc'
            }],
            eventInviteeRange: [0, 1000],
            giftInviteeRange: [0, 1000],
            cardInviteeRange: [0, 1000],
            eventDateFrom: null,
            eventDateTo: null,
            eventCreatedBy: false,
            giftDateFrom: null,
            giftDateTo: null,
            giftCreatedBy: false,
            cardDateFrom: null,
            cardDateTo: null,
            cardCreatedBy: false,
            eventSelDivisions: [],
            eventSelShows: [],
            giftSelDivisions: [],
            giftSelShows: [],
            giftSelOccasions: [],
            giftSelInvitees: [],
            cardSelDivisions: [],
            cardSelShows: []
            // eventSelDivisions: [],
            // cardSelDivisions: [],
            // cardSelDivisions: []
        }

    }
    componentDidMount() {
        this._isMounted = true;
        // this.loadData("eventsTab", this.state.eventPage, this.state.eventSize);
        // this.loadData("giftsTab", this.state.giftPage, this.state.giftSize);
        // this.loadData("cardsTab", this.state.cardPage, this.state.cardSize);
        //console.log("Event Landing:"+ JSON.stringify(props));
        this.setActiveTab(this.props);
        this.loadData();
        this.loadDivisions();
        this.loadShows();
        this.loadOccasions();
        this.loadInvitees();
    }
    componentWillReceiveProps(props) {
        //console.log("Event Landing:"+ JSON.stringify(props));
        this.setActiveTab(props);

    }

    setActiveTab(props) {
        var selectedTab = "";
        if (props.active_tenant_permissions.isViewEvent)
            selectedTab = "eventsTab";
        else if (props.active_tenant_permissions.isViewGift)
            selectedTab = "giftsTab";
        else if (props.active_tenant_permissions.isViewGift)
            selectedTab = "cardsTab";

        this.setState({ selectedTab: selectedTab });

        var params = { ...this.state.eventParams };
        params.tenant_id = props.active_tenant_id;
        this.setState({
            eventParams: params
        });
        params = { ...this.state.giftParams };
        params.tenant_id = props.active_tenant_id;
        this.setState({
            giftParams: params
        });
        params = { ...this.state.cardParams };
        params.tenant_id = props.active_tenant_id;
        this.setState({
            cardParams: params
        });
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevState.eventParams !== this.state.eventParams || prevState.giftParams !== this.state.giftParams || prevState.cardParams !== this.state.cardParams
            // || prevState.eventsData != this.state.eventsData || prevState.giftsData != this.state.giftsData || prevState.cardsData != this.state.cardsData 
            || prevState.eventPage !== this.state.eventPage || prevState.giftPage !== this.state.giftPage || prevState.cardPage !== this.state.cardPage
            //|| prevState.eventPageSize != this.state.eventPageSize || prevState.giftPageSize != this.state.giftPageSize || prevState.cardPageSize != this.state.cardPageSize 
            || prevState.selectedTab !== this.state.selectedTab
        ) {
            var page = 1, pageSize = 25;
            // if(this.state.selectedTab === "eventsTab")
            //     {           
            //     page = this.state.eventPage;
            //     pageSize = this.state.eventSize;
            //     }
            // else if(this.state.selectedTab === "giftsTab")
            //     {                
            //         page = this.state.giftPage;
            //         pageSize = this.state.giftSize;             
            //     }
            // else if(this.state.selectedTab === "cardsTab")
            //     {                
            //         page = this.state.cardPage;
            //         pageSize = this.state.cardSize;   
            //     }

            this.loadData(); //this.state.selectedTab, page, pageSize); 
        }
    }


    loadDivisions = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/orgUnits?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {

                var divisionsArr = response.data.map((division) => ({ text: division.org_unit_name, value: division.org_unit_id.toString() }));
                console.log("Load Divisions-->" + JSON.stringify(divisionsArr));
                this.setState({ divisions: divisionsArr });
            },
                (err) => {
                    console.log("Load Divisions Error-->" + err);
                })
    }

    loadShows = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/shows?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {

                var showsArr = response.data.map((show) => ({ text: show.show_name, value: show.show_id.toString() }));
                console.log("Load Shows-->" + JSON.stringify(showsArr));
                this.setState({ shows: showsArr });
            },
                (err) => {
                    console.log("Load Shows Error-->" + err);
                })
    }
    loadOccasions = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/occasions?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var occasionArr = response.data.map((occ) => ({ text : occ.name, value: occ.occasion_id.toString() }));
                console.log("Load Occasions-->" + JSON.stringify(occasionArr));
                this.setState({occasions : occasionArr});
            },
            (err) => {
                console.log("Load Occasions Error-->"+err);
            })
    }
    loadInvitees = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenantGiftRecipients?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var inviteesArr = response.data.map((inv) => ({ text : inv.name, value: inv.person_id.toString() }));
                console.log("Load Invitees-->" + JSON.stringify(inviteesArr));
                this.setState({invitees : inviteesArr});
            },
            (err) => {
                console.log("Load Invitees Error-->"+err);
            })
    }
    confirmCancelChanges = (isCancel) => {
        if (isCancel) { //this.props.togglePreviewImport();
            if (this.state.selectedTab === "eventsTab") {
                GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/eventRecordDelete',
                    {
                        gift_event_card_id: this.state.event_gift_card_id
                    }).then(response => {

                        this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                        this.loadData();
                    },
                        (err) => {
                            console.log("Delete Shows Error-->" + err);
                            alert("Unable to delete the event record" + err);
                            this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                        });
            }
            else if (this.state.selectedTab === "giftsTab") {
                GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/giftRecordDelete',
                    {
                        gift_event_card_id: this.state.event_gift_card_id
                    }).then(response => {

                        this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                        this.loadData();
                    },
                        (err) => {
                            console.log("Load Shows Error-->" + err);
                            alert("Unable to delete the gift record" + err);
                            this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                        });
            }
            else if (this.state.selectedTab === "cardsTab") {
                GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/cardRecordDelete',
                    {
                        gift_event_card_id: this.state.event_gift_card_id
                    }).then(response => {

                        this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                        this.loadData();
                    },
                        (err) => {
                            console.log("Load Shows Error-->" + err);
                            alert("Unable to delete the card record" + err);
                            this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                        });
            }
        }
        else {
            this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
        }

    }


    deleteEventGiftCard = (id) => {
        this.setState({
            event_gift_card_id: id,
            confirmSaveChanges: !this.state.confirmSaveChanges
        });

    }

    viewOrEditEvent = () => {
        // this.props.history.push('/event/'+id);
        // this.props.handleSideNavActive('CE', '/event/'+id)
        this.props.setActiveTab("CE");
    }

    viewOrEditGift = (id) => {
        //this.props.history.push('/gift/'+id);
        this.props.setActiveTab("CG");
    }

    viewOrEditCard = (id) => {
        //this.props.history.push('/card/'+id);
        this.props.setActiveTab("CC");
    }


    handlePageChange = (pagination) => {

        if (this.state.selectedTab === "eventsTab") {
            this.setState({ eventPage: pagination.page });
            var params = { ...this.state.eventParams };
            params.page_no = pagination.page + 1;
            this.setState({
                eventParams: params
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            this.setState({ giftPage: pagination.page });
            var params = { ...this.state.giftParams };
            params.page_no = pagination.page + 1;
            this.setState({
                giftParams: params
            });
        }
        else if (this.state.selectedTab === "cardsTab") {
            this.setState({ cardPage: pagination.page });
            var params = { ...this.state.cardParams };
            params.page_no = pagination.page + 1;
            this.setState({
                cardParams: params
            });
        }

        //this.loadData(this.state.selectedTab, pagination.page, pagination.pageSize);  

    }

    handlePageSizeChange = (pagination) => {
        pagination.page = 0;
        if (this.state.selectedTab === "eventsTab") {
            this.setState({ eventSize: pagination.pageSize });
            this.setState({ eventPage: pagination.page });
            var params = { ...this.state.eventParams };
            params.page_no = pagination.page + 1;
            params.page_count = pagination.pageSize
            this.setState({
                eventParams: params
            });
        }
        else if (this.state.selectedTab === "giftsTab") {
            this.setState({ giftSize: pagination.pageSize });
            this.setState({ giftPage: pagination.page });
            var params = { ...this.state.giftParams };
            params.page_no = pagination.page + 1;
            params.page_count = pagination.pageSize
            this.setState({
                giftParams: params
            });
            // this.setState({
            //      giftParams:  {...this.state.giftParams, {page_no : pagination.page, page_count : pagination.pageSize}}                               
            //  });   
        }
        else if (this.state.selectedTab === "cardsTab") {
            this.setState({ cardSize: pagination.pageSize });
            this.setState({ cardPage: pagination.page });
            var params = { ...this.state.cardParams };
            params.page_no = pagination.page + 1;
            params.page_count = pagination.pageSize
            this.setState({
                cardParams: params
            });
            // this.setState({
            //      cardParams:  {...this.state.cardParams, {page_no : pagination.page, page_count : pagination.pageSize}}                               
            //  }); 
        }
        //this.handlePageChange(pagination);
    }

    handleSortModelChange = (sortModelParams) => {
        if (this.state.selectedTab === "eventsTab") {
            var params = { ...this.state.eventParams };
            params.order_by_clause = sortModelParams.sortModel[0].field;
            params.order_by = sortModelParams.sortModel[0].sort;
            this.setState({
                eventParams: params,
                eventsSortModel: sortModelParams.sortModel
            });
        }
        else if (this.state.selectedTab === "giftsTab") {
            var params = { ...this.state.giftParams };
            params.order_by_clause = sortModelParams.sortModel[0].field;
            params.order_by = sortModelParams.sortModel[0].sort;
            this.setState({
                giftParams: params,
                giftsSortModel: sortModelParams.sortModel
            });

        }
        else if (this.state.selectedTab === "cardsTab") {

            var params = { ...this.state.cardParams };
            params.order_by_clause = sortModelParams.sortModel[0].field;
            params.order_by = sortModelParams.sortModel[0].sort;
            this.setState({
                cardParams: params,
                cardsSortModel: sortModelParams.sortModel
            });
            // this.setState({
            //      cardParams:  {...this.state.cardParams, {page_no : pagination.page, page_count : pagination.pageSize}}                               
            //  }); 
        }
    }

    handlePageChangeMD = (event, newPage) => {
        //setPage(newPage);
        var pageSize = 0;
        // if (this.state.selectedTab === "eventsTab") {
        //     this.setState({ eventPage: newPage });
        //     pageSize = this.state.eventSize;
        // }
        // else if (this.state.selectedTab === "giftsTab") {
        //     this.setState({ giftPage: newPage });
        //     pageSize = this.state.giftSize;
        // }
        // else if (this.state.selectedTab === "cardsTab") {
        //     this.setState({ cardPage: newPage });
        //     pageSize = this.state.cardSize;
        // }
        if (this.state.selectedTab === "eventsTab") {
            this.setState({ eventPage: newPage });
            var params = { ...this.state.eventParams };
            params.page_no = newPage + 1;
            this.setState({
                eventParams: params
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            this.setState({ giftPage: newPage });
            var params = { ...this.state.giftParams };
            params.page_no = newPage + 1;
            this.setState({
                giftParams: params
            });
        }
        else if (this.state.selectedTab === "cardsTab") {
            this.setState({ cardPage: newPage });
            var params = { ...this.state.cardParams };
            params.page_no = newPage + 1;
            this.setState({
                cardParams: params
            });
        }

        //this.loadData(this.state.selectedTab, newPage, pageSize);  
    }
    handleSearchNameChange = (event) => {
        var page = 1;
        if (this.state.selectedTab === "eventsTab") {
            var eventParams = { ...this.state.eventParams };
            eventParams.search_filters = event.target.value;
            eventParams.page_no = page;
            this.setState({
                eventParams: eventParams,
                page_no: page,
                eventPage: page - 1
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            var giftParams = { ...this.state.giftParams };
            giftParams.search_filters = event.target.value;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                page_no: page,
                giftPage: page - 1
            });

        }
        else if (this.state.selectedTab === "cardsTab") {
            var cardParams = { ...this.state.cardParams };
            cardParams.search_filters = event.target.value;
            cardParams.page_no = page;
            this.setState({
                cardParams: cardParams,
                page_no: page,
                cardPage: page - 1
            });
        }
    }
    handleChangeDivisions = (selectedDivisions) => {

        var page = 1;
        var selDiv = selectedDivisions.map((division) =>
            division.value).join(',');
        if (selDiv == "")
            selDiv = null;

        if (this.state.selectedTab === "eventsTab") {
            var eventParams = { ...this.state.eventParams };
            eventParams.division_filters = selDiv;
            eventParams.page_no = page;
            this.setState({
                eventParams: eventParams,
                eventSelDivisions: selectedDivisions,
                eventPage: page - 1
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            var giftParams = { ...this.state.giftParams };
            giftParams.division_filters = selDiv;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                giftSelDivisions: selectedDivisions,
                giftPage: page - 1
            });

        }
        else if (this.state.selectedTab === "cardsTab") {
            var cardParams = { ...this.state.cardParams };
            cardParams.division_filters = selDiv;
            cardParams.page_no = page;
            this.setState({
                cardParams: cardParams,
                cardSelDivisions: selectedDivisions,
                cardPage: page - 1
            });
        }

        //this.loadData(this.state.selectedTab, page, pageSize);
    }

    handleChangeOccasions = (selectedOccasions) =>
    {
        var page = 1;
        var selOccasion = selectedOccasions.map((occ) => occ.value).join(',');
        if(selOccasion == "")
            selOccasion = null;
        if (this.state.selectedTab === "giftsTab")
        {
            var giftParams = { ...this.state.giftParams };
            giftParams.occasion_filters = selOccasion;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                giftSelOccasions: selectedOccasions,
                giftPage: page - 1
            });
            console.log("Occasion Gift Params: ",giftParams);
        }
    }

    handleChangeInvitees = (selectedInvitee) => {
        var page = 1;
        var selInvitee = selectedInvitee.map((inv) => inv.value).join(',');
        if (selInvitee == "")
            selInvitee = null;
        if (this.state.selectedTab === "giftsTab")
        {
            var giftParams = {...this.state.giftParams};
            giftParams.invitee_filters = selInvitee;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                giftSelInvitees: selectedInvitee,
                giftPage: page - 1
            });
            console.log("Invitee Gift Params: ",giftParams);
        }
    }

    handleChangeShows = (selectedShows) => {
        var page = 1;
        var selShow = selectedShows.map((show) => show.value).join(',');
        if (selShow == "")
            selShow = null;
        if (this.state.selectedTab === "eventsTab") {
            var eventParams = { ...this.state.eventParams };
            eventParams.show_filters = selShow;
            eventParams.page_no = page;
            this.setState({
                eventParams: eventParams,
                eventSelShows: selectedShows,
                eventPage: page - 1
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            var giftParams = { ...this.state.giftParams };
            giftParams.show_filters = selShow;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                giftSelShows: selectedShows,
                giftPage: page - 1
            });
            
        }
        else if (this.state.selectedTab === "cardsTab") {
            var cardParams = { ...this.state.cardParams };
            cardParams.show_filters = selShow;
            cardParams.page_no = page;
            this.setState({
                cardParams: cardParams,
                cardSelShows: selectedShows,
                cardPage: page - 1
            });
        }

        //this.loadData(this.state.selectedTab, page, pageSize);
    }

    handleInviteeRange = (value) => {
        var page = 1;
        if (this.state.selectedTab === "eventsTab") {

            // this.setState({
            //     eventInviteeRange:  value
            // });
            var params = { ...this.state.eventParams };
            params.invitee_cnt_min = value[0];
            params.invitee_cnt_max = value[1];
            params.page_no = page;
            this.setState({
                eventParams: params,
                eventInviteeRange: value,
                eventPage: page - 1
            });
        }
        else if (this.state.selectedTab === "giftsTab") {
            // this.setState({
            //     giftInviteeRange:  value
            // });  
            var params = { ...this.state.giftParams };
            params.rec_cnt_min = value[0];
            params.rec_cnt_max = value[1];
            params.page_no = page;
            this.setState({
                giftParams: params,
                giftInviteeRange: value,
                giftPage: page - 1
            });

        }
        else if (this.state.selectedTab === "cardsTab") {
            // this.setState({
            //     cardInviteeRange:  value
            // });  
            var params = { ...this.state.cardParams };
            params.rec_cnt_min = value[0];
            params.rec_cnt_max = value[1];
            params.page_no = page;
            this.setState({
                cardParams: params,
                cardInviteeRange: value,
                cardPage: page - 1
            });

        }

    }

    handleChangeDateFrom = (value) => {
        var page = 1;
        if (this.state.selectedTab === "eventsTab") {
            // this.setState({
            //     eventDateFrom:  value
            // });
            var eventParams = { ...this.state.eventParams };
            eventParams.event_date_from = value;
            eventParams.page_no = page;
            this.setState({
                eventParams: eventParams,
                eventDateFrom: value,
                eventPage: page - 1
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            // this.setState({
            //     giftDateFrom:  value
            // });  
            var giftParams = { ...this.state.giftParams };
            giftParams.created_from = value;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                giftDateFrom: value,
                giftPage: page - 1
            });

        }
        else if (this.state.selectedTab === "cardsTab") {
            // this.setState({
            //     cardDateFrom:  value
            // });  
            var cardParams = { ...this.state.cardParams };
            cardParams.created_from = value;
            cardParams.page_no = page;
            this.setState({
                cardParams: cardParams,
                cardDateFrom: value,
                cardPage: page - 1
            });
        }
    }

    handleChangeDateTo = (value) => {
        var page = 1;
        if (this.state.selectedTab === "eventsTab") {
            // this.setState({
            //     eventDateTo:  value
            // });
            var eventParams = { ...this.state.eventParams };
            eventParams.event_date_to = value;
            eventParams.page_no = page;
            this.setState({
                eventParams: eventParams,
                eventDateTo: value,
                eventPage: page - 1
            });
        }
        else if (this.state.selectedTab === "giftsTab") {
            // this.setState({
            //     giftDateTo:  value
            // });  
            var giftParams = { ...this.state.giftParams };
            giftParams.created_to = value;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                giftDateTo: value,
                giftPage: page - 1
            });

        }
        else if (this.state.selectedTab === "cardsTab") {
            // this.setState({
            //     cardDateTo:  value
            // });    
            var cardParams = { ...this.state.cardParams };
            cardParams.created_to = value;
            cardParams.page_no = page;
            this.setState({
                cardParams: cardParams,
                cardDateTo: value,
                cardPage: page - 1
            });
        }
    }

    handleChangeCreated = (value) => {
        var page = 1;
        // var selShow = selectedShows.map((show) => show.value).join(',');
        // if(selShow == "")
        //  selShow = null;      
        if (this.state.selectedTab === "eventsTab") {
            var eventParams = { ...this.state.eventParams };
            eventParams.created_by_me = value;
            eventParams.page_no = page;
            this.setState({
                eventParams: eventParams,
                eventCreatedBy: value,
                eventPage: page - 1
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            var giftParams = { ...this.state.giftParams };
            giftParams.created_by_me = value;
            giftParams.page_no = page;
            this.setState({
                giftParams: giftParams,
                giftCreatedBy: value,
                giftPage: page - 1
            });

        }
        else if (this.state.selectedTab === "cardsTab") {
            var cardParams = { ...this.state.cardParams };
            cardParams.created_by_me = value;
            cardParams.page_no = page;
            this.setState({
                cardParams: cardParams,
                cardCreatedBy: value,
                cardPage: page - 1
            });
        }

    }

    clearFilters = () => {
        if (this.state.selectedTab === "eventsTab") {
            this.setState({
                eventParams: {
                    "tenant_id": localStorage.getItem("active_tenant_id"),
                    "division_filters": null,
                    "show_filters": null,
                    "invitee_cnt_min": 1,
                    "invitee_cnt_max": 1000,
                    "event_date_from": null,
                    "event_date_to": null,
                    "page_count": this.state.eventSize,
                    "page_no": 1,
                    "created_by_me": null,
                    "order_by_clause": "event_date_time",
                    "order_by": 'desc',
                    "search_filters": ""
                },
                eventSelDivisions: [],
                eventSelShows: [],
                eventInviteeRange: [0, 1000],
                eventDateFrom: null,
                eventDateTo: null,
                eventCreatedBy: false,
                eventPage: 0
            });

        }
        else if (this.state.selectedTab === "giftsTab") {
            this.setState({
                giftParams: {
                    "tenant_id": localStorage.getItem("active_tenant_id"),
                    "division_filters": null,
                    "show_filters": null,
                    "occasion_filters": null,
                    "invitee_filters":null,
                    "rec_cnt_min": 1,
                    "rec_cnt_max": 1000,
                    "created_from": null,
                    "created_to": null,
                    "page_count": this.state.giftSize,
                    "page_no": 1,
                    "created_by_me": null,
                    "order_by_clause": "gift_created_date",
                    "order_by": 'desc',
                    "search_filters": ""
                },
                giftSelDivisions: [],
                giftSelShows: [],
                giftSelOccasions: [],
                giftSelInvitees: [],
                giftInviteeRange: [0, 1000],
                giftDateFrom: null,
                giftDateTo: null,
                giftCreatedBy: false,
                giftPage: 0
            });
            
        }
        else if (this.state.selectedTab === "cardsTab") {
            this.setState({
                cardParams: {
                    "tenant_id": localStorage.getItem("active_tenant_id"),
                    "division_filters": null,
                    "show_filters": null,
                    "rec_cnt_min": 1,
                    "rec_cnt_max": 1000,
                    "created_from": null,
                    "created_to": null,
                    "page_count": this.state.cardSize,
                    "page_no": 1,
                    "created_by_me": null,
                    "order_by_clause": "card_created_date",
                    "order_by": 'desc',
                    "search_filters": ""
                },
                cardSelDivisions: [],
                cardSelShows: [],
                cardInviteeRange: [0, 1000],
                cardDateFrom: null,
                cardDateTo: null,
                cardCreatedBy: false,
                cardPage: 0
            });
        }
    }

    handlePageSizeChangeMD = (event) => {
        var pageSize = parseInt(event.target.value, 10);
        if (this.state.selectedTab === "eventsTab") {
            this.setState({ eventSize: pageSize });
            this.setState({ eventPage: 0 });
            var params = { ...this.state.eventParams };
            params.page_no = 1;
            params.page_count = pageSize
            this.setState({
                eventParams: params
            });
        }
        else if (this.state.selectedTab === "giftsTab") {
            this.setState({ giftSize: pageSize });
            this.setState({ giftPage: 0 });
            var params = { ...this.state.giftParams };
            params.page_no = 1;
            params.page_count = pageSize
            this.setState({
                giftParams: params
            });
            // this.setState({
            //      giftParams:  {...this.state.giftParams, {page_no : pagination.page, page_count : pagination.pageSize}}                               
            //  });   
        }
        else if (this.state.selectedTab === "cardsTab") {
            this.setState({ cardSize: pageSize });
            this.setState({ cardPage: 0 });
            var params = { ...this.state.cardParams };
            params.page_no = 1;
            params.page_count = pageSize
            this.setState({
                cardParams: params
            });
            // this.setState({
            //      cardParams:  {...this.state.cardParams, {page_no : pagination.page, page_count : pagination.pageSize}}                               
            //  }); 
        }
        //this.handlePageChangeMD(event, 1);
    }

    handleTabClick = (value) => {
        this.setState({
            selectedTab: value,
            filterEvent: false, filterCard: false, filterGift: false
        });
    }

    loadData() { //selectedTab, page, pageSize){

        if (this.state.selectedTab === "eventsTab") {
            this.setState({ eventsLoading: !this.state.eventsLoading });
            GifterService.getDataParams(Constants.GifterServiceBaseUrl + '/eventRecords',
                this.state.eventParams)//'{page='+page+"&pageSize="+pageSize)
                .then((response) => {
                    console.log("Gift Service started for Events: " + response.data.records);
                    this.setState({
                        eventsData: {
                            ...this.state.eventsData, rows: response.data.records
                        },
                        eventRowCount: response.data.query_record_count
                        , eventsLoading: !this.state.eventsLoading
                    });
                },
                    (err) => {
                        console.log("Gift Service error for Events: " + err);
                        this.setState({ eventsLoading: !this.state.eventsLoading });
                    });
        }
        else if (this.state.selectedTab === "giftsTab") {
            this.setState({ giftsLoading: !this.state.giftsLoading });
            GifterService.getDataParams(Constants.GifterServiceBaseUrl + '/giftRecords',
                this.state.giftParams) // '?page='+page+"&pageSize="+pageSize)
                .then((response) => {
                    console.log("Gift Service started for Gifts: " + response.data.records);
                    let giftsDisplayRecords = response.data.records
                    giftsDisplayRecords = giftsDisplayRecords?.map(element => {
                        element['tooltip_recipients'] = element?.recipients_name
                        element['display_recipients'] = element?.recipients_name?.split(',')?.splice(0,1);
                        return element;
                    });
                    this.setState({
                        giftsData: {
                            ...this.state.giftsData, rows: response.data.records
                        },
                        giftRowCount: response.data.query_record_count
                        , giftsLoading: !this.state.giftsLoading
                    });
                },
                    (err) => {
                        console.log("Gift Service error for Gifts: " + err);
                        this.setState({ giftsLoading: !this.state.giftsLoading });
                    });
        }
        else if (this.state.selectedTab === "cardsTab") {
            this.setState({ cardsLoading: !this.state.cardsLoading });
            GifterService.getDataParams(Constants.GifterServiceBaseUrl + '/cardRecords',
                this.state.cardParams) //+'?page='+page+"&pageSize="+pageSize)
                .then((response) => {
                    console.log("Gift Service started for Cards: " + response.data.records);
                    this.setState({
                        cardsData: {
                            ...this.state.cardsData, rows: response.data.records
                        },
                        cardRowCount: response.data.query_record_count
                        , cardsLoading: !this.state.cardsLoading
                    });
                },
                    (err) => {
                        console.log("Gift Service error for Cards: " + err);
                        this.setState({ cardsLoading: !this.state.cardsLoading });
                    });

        }
    }

    filterDashboard = () => {
        console.log("Filter for tab: " + this.state.selectedTab);
        if (this.state.selectedTab == "eventsTab") {
            this.setState({
                filterEvent: !this.state.filterEvent,
                filterGift: false,
                filterCard: false
            });
            console.log("this.state.filterEvent: " + this.state.filterEvent);
        }
        else if (this.state.selectedTab == "giftsTab") {
            this.setState({
                filterGift: !this.state.filterGift,
                filterEvent: false,
                filterCard: false
            });
            console.log("this.state.filterGift: " + this.state.filterGift);
        }
        else if (this.state.selectedTab == "cardsTab") {
            this.setState({
                filterCard: !this.state.filterCard,
                filterGift: false,
                filterEvent: false
            });
            console.log("this.state.filterCard: " + this.state.filterCard);
        }
    }
    // handleInviteeRange = (event, newValue) => {
    //     this.setState({
    //         inviteeRange : newValue
    //     });
    // };
    render() {
        return (
            <>
                {/* <Router> */}
                <div id="dashboard" className={this.props.isLoggedIn ? "d-block" : "d-none"}>
                    <div className="d-none d-lg-block">
                        <div className="welcomeUser fw-bold">
                            {this.props.user_profile?.name ? "Welcome, " + this.props.user_profile?.name : ""}
                        </div>
                        <MDBCard className='mb-3' style={{ height: '100%' }}>
                            <MDBCardBody>
                                {!this.props.active_tenant_permissions.isViewEvent && !this.props.active_tenant_permissions.isViewGift
                                    && !this.props.active_tenant_permissions.isViewCard ?
                                    <h6>
                                        No Access to Events/Gifts/Cards
                                </h6>
                                    :
                                    <div className="flexBox">
                                        <MDBTabs bgcolor="white" color='black' className='card-header-tabs'>

                                            <MDBTabsItem className={this.props.active_tenant_permissions.isViewEvent ? "d-block" : "d-none"}>
                                                <MDBTabsLink className="nav-link-default" color="white" active={this.state.selectedTab === 'eventsTab'} onClick={this.handleTabClick.bind(this, 'eventsTab')}>
                                                    Events
                                            </MDBTabsLink>
                                            </MDBTabsItem>
                                            <MDBTabsItem className={this.props.active_tenant_permissions.isViewGift ? "d-block" : "d-none"}>
                                                <MDBTabsLink className="nav-link-default" color="white"
                                                    active={this.state.selectedTab === 'giftsTab'} onClick={this.handleTabClick.bind(this, 'giftsTab')}>
                                                    Gift Records
                                            </MDBTabsLink>
                                            </MDBTabsItem>
                                            <MDBTabsItem className={this.props.active_tenant_permissions.isViewCard ? "d-block" : "d-none"}>
                                                <MDBTabsLink className="nav-link-default" color="white" active={this.state.selectedTab === 'cardsTab'} onClick={this.handleTabClick.bind(this, 'cardsTab')}>
                                                    Cards
                                            </MDBTabsLink>
                                            </MDBTabsItem>
                                        </MDBTabs>

                                        <div className="flexAlignRight">
                                            <MDBBtn className="btnSize" outline size="sm"
                                                // color="senary" 
                                                color={this.state.filterEvent || this.state.filterGift || this.state.filterCard ? 'senary' : 'white'}
                                                onClick={this.filterDashboard.bind(this)}>
                                                <MDBIcon fas size='2x' icon="filter" className="mr-2 text-quinary" >
                                                    Filter</MDBIcon>
                                                {/* <span>Filter</span> */}
                                            </MDBBtn>
                                        &nbsp;&nbsp;
                                        <span className={this.state.selectedTab === "eventsTab" ? "show" : "hide"}>
                                                <MDBInput placeholder="Search Event Name.." type="text"
                                                    onChange={this.handleSearchNameChange.bind(this)}></MDBInput>
                                                {/* <input type="text" id="searchEvent" className="form-control form-control-sm" placeholder="Search Event Name.." /> */}
                                            </span>
                                            <span className={this.state.selectedTab === "giftsTab" ? "show" : "hide"}>
                                                <MDBInput placeholder='Search Gift Name..' type="text" onChange={this.handleSearchNameChange.bind(this)}></MDBInput></span>
                                            <span className={this.state.selectedTab === "cardsTab" ? "show" : "hide"}>
                                                <MDBInput placeholder='Search Card Name..' type="text" onChange={this.handleSearchNameChange.bind(this)}></MDBInput>
                                            </span>
                                        </div>

                                    </div>
                                }
                                <ConfirmDialog isOpen={this.state.confirmSaveChanges}
                                    confirmCancelChanges={this.confirmCancelChanges.bind(this)}
                                    displayMessage={"Are you sure you want to delete the record?"}>
                                </ConfirmDialog>
                                <MDBTabsContent>
                                    <MDBTabsPane show={this.state.selectedTab === 'eventsTab'}>
                                        <div>
                                            <EventGiftDashboardComponent
                                                selectedTab={this.state.selectedTab}
                                                data={this.state.eventsData}
                                                filterDashboard={this.filterDashboard}
                                                filterOpen={this.state.filterEvent}
                                                pageSize={this.state.eventSize}
                                                handlePageChange={this.handlePageChange}
                                                page={this.state.eventPage}
                                                handlePageSizeChange={this.handlePageSizeChange}
                                                rowCount={this.state.eventRowCount}
                                                name="Event"
                                                divisions={this.state.divisions}
                                                shows={this.state.shows}
                                                inviteeRange={this.state.eventInviteeRange}
                                                dateFrom={this.state.eventDateFrom}
                                                dateTo={this.state.eventDateTo}
                                                createdBy={this.state.eventCreatedBy}
                                                loading={this.state.eventsLoading}
                                                handleChangeDivisions={this.handleChangeDivisions.bind(this)}
                                                handleChangeShows={this.handleChangeShows.bind(this)}
                                                handleInviteeRange={this.handleInviteeRange.bind(this)}
                                                handleChangeDateFrom={this.handleChangeDateFrom.bind(this)}
                                                handleChangeDateTo={this.handleChangeDateTo.bind(this)}
                                                handleChangeCreated={this.handleChangeCreated.bind(this)}
                                                clearFilters={this.clearFilters.bind(this)}
                                                selDivisions={this.state.eventSelDivisions}
                                                selShows={this.state.eventSelShows}
                                                sortModel={this.state.eventsSortModel}
                                                handleSortModelChange={this.handleSortModelChange.bind(this)}
                                            >
                                            </EventGiftDashboardComponent>
                                        </div>
                                        {/* <div className="d-block d-lg-none">
                            New content here - event
                        </div>                    */}
                                    </MDBTabsPane>
                                    <MDBTabsPane show={this.state.selectedTab === 'giftsTab'}>
                                        <div>
                                            <EventGiftDashboardComponent
                                                selectedTab={this.state.selectedTab}
                                                data={this.state.giftsData}
                                                filterDashboard={this.filterDashboard}
                                                filterOpen={this.state.filterGift}
                                                pageSize={this.state.giftSize}
                                                handlePageChange={this.handlePageChange}
                                                page={this.state.giftPage}
                                                handlePageSizeChange={this.handlePageSizeChange}
                                                rowCount={this.state.giftRowCount}
                                                name="Gift"
                                                divisions={this.state.divisions}
                                                shows={this.state.shows}
                                                occasions={this.state.occasions}
                                                invitees={this.state.invitees}
                                                inviteeRange={this.state.giftInviteeRange}
                                                dateFrom={this.state.giftDateFrom}
                                                dateTo={this.state.giftDateTo}
                                                createdBy={this.state.giftCreatedBy}
                                                loading={this.state.giftsLoading}
                                                handleChangeDivisions={this.handleChangeDivisions.bind(this)}
                                                handleChangeShows={this.handleChangeShows.bind(this)}
                                                handleChangeOccasions={this.handleChangeOccasions.bind(this)}
                                                handleInviteeRange={this.handleInviteeRange.bind(this)}
                                                handleChangeInvitees={this.handleChangeInvitees.bind(this)}
                                                handleChangeDateFrom={this.handleChangeDateFrom.bind(this)}
                                                handleChangeDateTo={this.handleChangeDateTo.bind(this)}
                                                handleChangeCreated={this.handleChangeCreated.bind(this)}
                                                clearFilters={this.clearFilters.bind(this)}
                                                selectedVal={this.state.selectedShows}
                                                selDivisions={this.state.giftSelDivisions}
                                                selShows={this.state.giftSelShows}
                                                selOccasion = {this.state.giftSelOccasions}
                                                selInvitee = {this.state.giftSelInvitees}
                                                sortModel={this.state.giftsSortModel}
                                                handleSortModelChange={this.handleSortModelChange.bind(this)}
                                            >
                                            </EventGiftDashboardComponent>
                                        </div>
                                        {/* <div className="d-block d-lg-none">
                            New content here - gift
                    </div>    */}
                                    </MDBTabsPane>
                                    <MDBTabsPane show={this.state.selectedTab === 'cardsTab'}>
                                        <div>
                                            <EventGiftDashboardComponent
                                                selectedTab={this.state.selectedTab}
                                                data={this.state.cardsData}
                                                filterDashboard={this.filterDashboard}
                                                filterOpen={this.state.filterCard}
                                                pageSize={this.state.cardSize}
                                                handlePageChange={this.handlePageChange}
                                                page={this.state.cardPage}
                                                handlePageSizeChange={this.handlePageSizeChange}
                                                rowCount={this.state.cardRowCount}
                                                name="Card"
                                                divisions={this.state.divisions}
                                                shows={this.state.shows}
                                                inviteeRange={this.state.cardInviteeRange}
                                                dateFrom={this.state.cardDateFrom}
                                                dateTo={this.state.cardDateTo}
                                                createdBy={this.state.cardCreatedBy}
                                                loading={this.state.cardsLoading}
                                                handleChangeDivisions={this.handleChangeDivisions.bind(this)}
                                                handleChangeShows={this.handleChangeShows.bind(this)}
                                                handleInviteeRange={this.handleInviteeRange.bind(this)}
                                                handleChangeDateFrom={this.handleChangeDateFrom.bind(this)}
                                                handleChangeDateTo={this.handleChangeDateTo.bind(this)}
                                                handleChangeCreated={this.handleChangeCreated.bind(this)}
                                                clearFilters={this.clearFilters.bind(this)}
                                                selDivisions={this.state.cardSelDivisions}
                                                selShows={this.state.cardSelShows}
                                                sortModel={this.state.cardsSortModel}
                                                handleSortModelChange={this.handleSortModelChange.bind(this)}
                                            >
                                            </EventGiftDashboardComponent>
                                        </div>
                                        {/* <div className="d-block d-lg-none">
                            New content here - card
                    </div>    */}
                                    </MDBTabsPane>
                                </MDBTabsContent>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                    <div className="d-block d-lg-none">
                        <h4>
                            Attendance Management
                        </h4>
                        <EventGiftDashboardComponentMD
                            selectedTab={this.state.selectedTab}
                            data={this.state.eventsData}
                            filterDashboard={this.filterDashboard}
                            filterOpen={this.state.filterEvent}
                            pageSize={this.state.eventSize}
                            handlePageChange={this.handlePageChangeMD}
                            page={this.state.eventPage}
                            handlePageSizeChange={this.handlePageSizeChangeMD}
                            rowCount={this.state.eventRowCount}
                            name="Event"
                            handleSearchNameChange={this.handleSearchNameChange.bind(this)}
                        >

                        </EventGiftDashboardComponentMD>
                    </div>
                </div>
                {/* </Router> */}
            </>
        );

    }

}

export default withRouter(EventGiftLandingPage);