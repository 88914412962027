import React, { useState, useEffect } from "react";
import { MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal, MDBContainer, MDBIcon, MDBBtn }
    from "mdb-react-ui-kit";
import Divider from '@material-ui/core/Divider';
import { Component } from "react";
import { withRouter } from 'react-router-dom';

class MaterialSideNav extends Component {
    _isMounted = false;
    container = null;
    constructor(props) {
        super(props);
        console.log("SideNav loaded");
        // this.state = {
        //     // isModal: false,
        //     // isManageUserModal: false,
        //     // isManageProjectsModal: false,
        //     // isManageTalents: false,
        //     // sideNavLeft: true,
        //     // isSlim: false,
        //     // toggleStateA: true,
        //    // userProfile: localStorage.getItem("user_name"),//JSON.parse(localStorage.getItem("user_profile")),
        //     // isAdmin: false,
        //     // isCreate: false,
        //     // isFinalApprover: false,
        //     //permissions: localStorage.getItem("permissions"),
        //     // sidenavContent: null,
        //     // activeTab: 'EX',
        //     //isOpen: false

        // }

        // console.log("User Profile : " + JSON.parse(localStorage.getItem("user_profile")));

    }

    handleSideNavActive = (value, routePath) => {
        // this.setState({
        //     activeTab: value
        // }
            //,function () {
            //     console.log("activeTab: "+ this.props.activeTab);
            //    }
        //);
        this.props.handleSideNavActive(value, routePath);
        //this.props.history.push(routePath, this.state);
    }


    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
        }
    }
 

    handleSideNavLeft = () => {
        // this.setState(
        //     {
        //         sideNavLeft: !this.state.sideNavLeft,
        //         slim: !this.state.isSlim
        //     });
        //this.props.toggleSideNav();
    }


    // getParentRef = () => {
    //     return this.props.getParentContent();
    // }

    render() {
        // const navStyle = { paddingLeft: this.state.windowWidth > this.state.breakWidth ? "210px" : "16px" };
        // const ptli = { paddingLeft: '8rem' }
        return (
            <>
                {/* <div className={this.props.isValidUser ? "d-block" : "d-none"}> */}
                <div id="material-sidenav"> 
                    <MDBSideNav //contentRef={this.state.container}                    
                        constant
                        right={false}
                        fixed
                        color="white"
                        bgColor="primary"                       
                        mode="push"
                        backdrop={false}
                        closeOnEsc={false}
                        className="side-nav-modal"
                    >
                        <MDBSideNavMenu className="side-nav-menu">
                            {/* <MDBSideNavItem>

                            </MDBSideNavItem>
                            <MDBSideNavItem >
                                <MDBSideNavLink>
                                    <div onClick={this.handleSideNavLeft.bind(this)}>
                                        <MDBIcon style={{ paddingLeft: !this.state.sideNavLeft ? '0' : '180px' }}
                                            size="2x"
                                            icon={!this.state.sideNavLeft ? "angle-double-right"
                                                : "angle-double-left"} />
                                    </div >
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                             */}
                             <MDBSideNavItem>

                            </MDBSideNavItem>
                            <MDBSideNavItem className={this.props.activeTab === "EX" ? "sidenav-item-active" : ""}
                                onClick={this.handleSideNavActive.bind(this, 'EX', "/query")}
                            >

                                <MDBSideNavLink topLevel to="/query">
                                    <MDBIcon fas size='2x' icon="file-export" className="mr-2" />
                                  
                                    <span className='sidenav-non-slim ml-6'>Export {this.props.fromPage} Details</span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                           
                            <MDBSideNavItem className={this.props.activeTab === "QD" ? "sidenav-item-active" : ""}
                                onClick={this.handleSideNavActive.bind(this, 'QD', "/query")}
                            >
                                <MDBSideNavLink to="/query" topLevel >
                                    <MDBIcon fas size='2x' icon="database" className="mr-2" />
                                  
                                    <span className='sidenav-non-slim ml-6'>Query {this.props.fromPage} Data</span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>

                          
                            <MDBSideNavItem className={this.props.activeTab === "MC" ? "sidenav-item-active" : ""}
                                onClick={() => this.handleSideNavActive('MC', '/query')}>
                                <MDBSideNavLink>
                                    <MDBIcon size='2x' icon="envelope-open-text" className="mr-2" />
                                    <span className="sidenav-non-slim ml-6">
                                        Message Cards
                                </span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                          
                            <MDBSideNavItem className={this.props.activeTab === "EV" ? "sidenav-item-active" : ""}
                                onClick={() => this.handleSideNavActive('EV', '/query')}>
                                <MDBSideNavLink>
                                    <MDBIcon size='2x' icon="envelope" className="mr-2" />
                                    <span className="sidenav-non-slim ml-6">
                                        Envelopes
                                    </span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                            <MDBSideNavItem className={this.props.activeTab === "AL" ? "sidenav-item-active" : ""}
                                onClick={() => this.handleSideNavActive('AL', '/query')}>
                                <MDBSideNavLink>
                                    <MDBIcon size='2x' icon="table" className="mr-2" />
                                    <span className="sidenav-non-slim ml-6">
                                        Avery Labels
                                    </span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                        </MDBSideNavMenu>
                    </MDBSideNav>
                    </div>
                {/* </div> */}
            </>
        );
    }
}

export default withRouter(MaterialSideNav);
