import React from 'react';
import { userList, configObject } from './MockData/userList';
import { filterList } from './MockData/filterList';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableComponent from '../Common/Table';
import { MDBIcon } from "mdbreact";
import { MDBBtn } from 'mdb-react-ui-kit';
import GifterService from '../../services/service';
import * as Constants from '../../constants/constants';
import Filters from './PMFilter';
import './PeopleManagement.scss';
import axios from 'axios';

class PeopleManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            filterList: {
                company: [],
                division: [],
                category: [],
                title: [],
                status: [{ value: 0, text: "Inactive" }, { value: 1, text: "Active" }],
                customGroup: []
            },
            selectedFilterOptions: {
                company: null,
                division: null,
                category: null,
                title: null,
                status: null,
                customGroup: null
            },
            selectedFilters: {
                company: [],
                division: [],
                category: [],
                title: [],
                status: [],
                customGroup: []
            },
            showFilter: false,
            userList: userList,
            peopleList: [],
            formattedPeopleList: [],
            isPeopleListFetchOnProgress: false,
            sortBy: "Name",
            searchTerm: null,
            isSortByAsc: true,
            sortCount: 0,
            configObject: configObject,
            searchCancelToken: null
        };
    }

    componentDidMount() {
        this.fetchPeopleList();
        this.fetchDivisions();
        this.fetchTitles();
        this.fetchCompanies();
        this.fetchCategories();
        this.fetchCustomGroups();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => {
            this.fetchPeopleList();
        });
    }

    fetchDivisions = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/divisions?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((division) => ({ value: division.Division_id, text: division.Division_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        division: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Divisions Error-->" + err);
                })
    }

    fetchTitles = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/titles?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((title) => ({ value: title.title_id, text: title.title_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        title: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Titles Error-->" + err);
                })
    }

    fetchCompanies = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/companies?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((company) => ({ value: company.company_id, text: company.company_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        company: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Companies Error-->" + err);
                })
    }

    fetchCategories = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/categories?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((category) => ({ value: category.category_id, text: category.category_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        category: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load Categories Error-->" + err);
                })
    }

    fetchCustomGroups = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/customGroups?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((cgItem) => ({ value: cgItem.custom_group_id, text: cgItem.custom_group_name.toString() }));
                this.setState(prevState => ({
                    filterList: {
                        ...prevState.filterList,
                        customGroup: formattedArray
                    }
                }))
            },
                (err) => {
                    console.log("Load CustomGroup Error-->" + err);
                })
    }

    fetchPeopleList = async value => {
        this.setState({ isPeopleListFetchOnProgress: true });
        let option = {
            "tenant_id": 1,
            "division_filters": this.state.showFilter ? this.state.selectedFilterOptions.division : null,
            "company_filters": this.state.showFilter ? this.state.selectedFilterOptions.company : null,
            "category_filters": this.state.showFilter ? this.state.selectedFilterOptions.category : null,
            "title_filters": this.state.showFilter ? this.state.selectedFilterOptions.title : null,
            "custom_group_filters": this.state.showFilter ? this.state.selectedFilterOptions.customGroup : null,
            "status_filters": this.state.showFilter ? this.state.selectedFilterOptions.status : null,
            "page_count": this.state.rowsPerPage,
            "page_no": this.state.page,
            "order_by_clause": this.state.sortBy,
            "order_by": this.state.isSortByAsc ? "asc" : "desc",
            "search_filters": this.state.searchTerm ? this.state.searchTerm : null,
        }
        console.log("option", option)
        if(this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({searchCancelToken: cancelToken});
        GifterService.getDataParamsWithCancel(Constants.GifterServiceBaseUrl + '/personsAll', option, cancelToken)
            .then((response) => {
                this.setState({ peopleList: response.data, isPeopleListFetchOnProgress: false }, () => {
                    this.formatPeopleList();
                });
            },
                (err) => {
                    this.setState({ isPeopleListFetchOnProgress: false });
                    console.log("People Management grid service error: " + err);
                });

    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
            this.fetchPeopleList();
        });
    }

    handleSearch = (event) => {
        this.setState({ searchTerm: event.target.value, page: 0 }, () => {
            this.fetchPeopleList();
        });
    }

    handleFilterChange = (event, value, field) => {
                let options = value?.length !== 0 ? value.map(item => item.value).join(',').toString() : null;
                this.setState(prevState => ({
                    selectedFilterOptions: {
                        ...prevState.selectedFilterOptions,
                        [field]: options
                    },
                    selectedFilters: {
                        ...prevState.selectedFilters,
                        [field]: value
                    }
                }), () => {
                    this.fetchPeopleList();
                })
            }

    handleTableColumnClick = (column) => {
                if (column === this.state.sortBy) {
                    this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                        this.handleTableSort(column);
                    })
                }
                else this.setState({ sortCount: 0 }, () => {
                    this.handleTableSort(column);
                })
            }

    handleTableSort = (sortBy) => {
                let configObject = this.state.configObject;
                this.setState({ isSortByAsc: this.state.sortCount % 2 === 0 ? true : false }, () => {
                    let newHeadingArray = configObject.headings.map(item => {
                        if (item.headingLabel === sortBy) {
                            item.icon = this.state.isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
                        } else if (sortBy !== this.state.sortBy && item.headingLabel === this.state.sortBy) {
                            item.icon = "";
                        }
                        return item;
                    });
                    delete configObject.headings
                    configObject.headings = newHeadingArray;
                    this.setState({ sortBy: sortBy, page: 0, configObject: configObject }, () => {
                        this.fetchPeopleList();
                    })
                })
            }

    handleClearFilter = () => {
                let selectedFilterOptions = {
                    company: null,
                    division: null,
                    category: null,
                    title: null,
                    status: null,
                    customGroup: null
                };
                let selectedFilters = {
                    company: [],
                    division: [],
                    category: [],
                    title: [],
                    status: [],
                    customGroup: []
                }
                this.setState({ selectedFilterOptions: selectedFilterOptions, selectedFilters: selectedFilters }, () => {
                    this.fetchPeopleList();
                });
            }

    handleShowFilter = () => {
                this.setState({ showFilter: !this.state.showFilter });
            }

    handleOnEditClick = (personId) => {
                this.handleNavigation('/editPerson/' + personId, 'EP');
            }

    handleNavigation = (path, value) => {
                this.props.history.push(path, value);
            }

    formatPeopleList = () => {
                let formattedList = this.state.peopleList.records.map(list => {
                    return {
                        "person_id": list.person_id,
                        "name": list.first_name + " " + list.last_name,
                        "email": list.email,
                        "company_name": list.company_name,
                        "division_name": list.division_name,
                        "category_name": list.category_name,
                        "title_name": list.title_name,
                        "is_active": list.is_active ? "Active" : "Inactive"
                    }
                })
                this.setState({ formattedPeopleList: formattedList });
            }

    render() {
            let list = this.state.userList?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
            return(
            <div className = "peopleManagement" >
                <div><h5>People Maintenance</h5></div>
                <div className="headingNote">{`You are viewing People included in the ${localStorage.getItem("active_tenant_name")} - Marketing Tenant.`}</div>
                <div className="userTableHeaderContainer">
                    <div id="userTableHeader">
                        <div className="row">
                            <div className="col-2" id="newPersonButton">
                                <MDBBtn color="primary" onClick={() => this.handleNavigation('/addPerson', 'AP')}>
                                    <MDBIcon icon="user-plus" className="mr-1" />&nbsp;&nbsp;New Person</MDBBtn>
                            </div>
                            <div className="col-1" id="filterIcon" onClick={this.handleShowFilter}>
                                <button class={!this.state.showFilter ? "btn btn-outline-senary btn-sm btnSize remove-border" : "btn btn-outline-senary btn-sm btnSize"} type="button" id="btn-lftmrgn" role="button"><i data-test="fa" class="fas fa-filter fa-2x mr-2 text-quinary"></i></button>
                            </div>
                            {/* <div className="col-1" id="filterIcon" onClick={this.handleShowFilter}><MDBIcon icon="filter" className="mr-1" /></div> */}
                            <div className="col-7"></div>
                            <div className="col-2" id="search"><TextField label="Search Name:" variant="outlined" onChange={(event) => this.handleSearch(event)} /></div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.state.showFilter ?
                                        <Filters filterList={this.state.filterList}
                                            selectedFilters={this.state.selectedFilters}
                                            handleFilterChange={this.handleFilterChange}
                                            handleClearFilter={this.handleClearFilter} /> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="userTable">
                        <TableComponent
                            list={this.state.formattedPeopleList}
                            config={this.state.configObject}
                            isLoading={this.state.isPeopleListFetchOnProgress}
                            sortCallback={this.handleTableColumnClick}
                            editCallback={this.handleOnEditClick} />
                        <TablePagination
                            component="div"
                            count={this.state.peopleList?.query_record_count ? this.state.peopleList.query_record_count : 0}
                            rowsPerPageOptions = {[2, 4, 10, 25, 50, 75, 100]}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default PeopleManagement;