import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid grey',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
    success: {
        textAlign: 'center',
        color: 'green'
    },
    error: {
        textAlign: 'center',
        color: 'red'
    },
    manadatory: {
        textAlign: 'center',
        color: 'red'
    }
}));

export default function SaveModal(props) {
    const classes = useStyles()
    return (
        <div className="saveModalContainer">
            <Modal
                open={props?.open}
                onClose={props?.handleClose}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <p id="Message" className={classes[props?.className]}>
                        {props?.message}
                    </p>
                </div>
            </Modal>
        </div>
    );
}
