import React from 'react';
import { customGroupPersonsConfigObject } from '../PeopleManagement/MockData/userList';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBFormInline, MDBInput } from "mdbreact";
import { MDBBtn } from 'mdb-react-ui-kit';
import GifterService from '../../services/service';
import * as Constants from '../../constants/constants';
import '../PeopleManagement/PeopleManagement.scss';
import { SignalCellularNullRounded } from '@material-ui/icons';
import Modal from '../UserRoleManagement/AddorEditUserRoles';
import TableComponent from '../Common/Table';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Import from '../Import/Import';
import LookupModal from '../LookupPeople/LookupModal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

class CustomGroupPersons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalActionType: null,
            openUserModal: false,
            fetchUserWithEmail: null,
            page: 0,
            rowsPerPage: 10,
            customGroupPersonsList: [],
            listBackUp: [],
            isPeopleListFetchOnProgress: false,
            sortBy: "Name",
            isSortByAsc: true,
            sortCount: 0,
            configObject: customGroupPersonsConfigObject,
            customGroupId: props?.match?.params?.customGroupId,
            activePersons: [],
            showImportPeople: false,
            showLookupPeople: false,
            isCustomGroupPersons: true,
            formErrors: { groupName: '' },
            formValid: false,
            groupNameValid: false,
            customGroupName: '',
            customGroupListNames: props?.history?.location?.state,
            loaderOpen: false,
            searchTerm: null,
            totalCount: 0
        };
    }

    componentDidMount() {
        this.setState({
            loaderOpen: true
        })
        this.fetchData();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => {
            this.handlePagination();
        });
    }

    fetchData = async () => {
        let customGroupRecordId = this.state.customGroupId;
        let tenantId = localStorage.getItem("active_tenant_id");
        let GetCustomGroups = Constants.GifterServiceBaseUrl + '/customGroups?tenantId=' + tenantId;
        let GetCustomGroupPeople = Constants.GifterServiceBaseUrl + '/customGroupPersons?customGroupId=' + customGroupRecordId;
        let urls = [];
        let groupName = '';
        let groupNameValid = false;
        urls = [GetCustomGroups, GetCustomGroupPeople];
        this.setState({ isPeopleListFetchOnProgress: true });
        try {
            const res = await Promise.all(urls.map(url =>
                GifterService.getData(url)));
            const data = res.map((res) => res.data);
            if (data && data.length > 0) {
                groupName = customGroupRecordId > 0 && data[0].length > 0 ? data[0].filter(item => item.custom_group_id == customGroupRecordId)[0]?.custom_group_name : '';
                groupNameValid = groupName != '';
                let peoples = customGroupRecordId > 0 ? data[1] : []
                peoples.map(obj => {
                    obj['name'] = obj.first_name + ' ' + obj.last_name
                })
                this.setState({
                    customGroupPersonsList: peoples, listBackUp: peoples, customGroupName: groupName,
                    activePersons: [], isPeopleListFetchOnProgress: false, groupNameValid: groupNameValid, page: 0, rowsPerPage: 10
                }, () => {
                    this.setState({
                        loaderOpen: false
                    }, () => { this.validateForm(); })
                });
                this.sortList();
                this.handlePagination();
            }
        } catch {
            throw Error("Promise failed");
        }
    };

    toggleImportPeople = () => {
        this.setState({ showImportPeople: !this.state.showImportPeople });
    }

    toggleLookupPeople = () => {
        this.setState({ showLookupPeople: !this.state.showLookupPeople });
    }

    handlePagination = () => {
        let startIndex = this.state.page * this.state.rowsPerPage;
        let lastIndex = startIndex + this.state.rowsPerPage;
        let list = this.state.listBackUp?.slice(startIndex, lastIndex);
        this.setState({ customGroupPersonsList: list });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
            this.handlePagination();
        });
    }


    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (sortBy) => {
        let configObject = this.state.configObject;
        this.setState({ isSortByAsc: this.state.sortCount % 2 === 0 ? true : false }, () => {
            let newHeadingArray = configObject.headings.map(item => {
                if (item.headingLabel === sortBy) {
                    item.icon = this.state.isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
                } else if (sortBy !== this.state.sortBy && item.headingLabel === this.state.sortBy) {
                    item.icon = "";
                }
                return item;
            });
            delete configObject.headings
            configObject.headings = newHeadingArray;
            this.setState({ sortBy: sortBy, page: 0, configObject: configObject }, () => {
                this.sortList();
            })
        })
    }

    sortList = () => {
        let sortedList;
        switch (this.state.sortBy) {
            case "Name":
                if (this.state.isSortByAsc) {
                    sortedList = this.state.customGroupPersonsList?.sort((a, b) => (a.name.toString() > b.name.toString()) ? 1 : -1);
                } else {
                    sortedList = this.state.customGroupPersonsList?.sort((a, b) => (b.name.toString() > a.name.toString()) ? 1 : -1);
                }
                this.setState({ customGroupPersonsList: sortedList, page: 0, rowsPerPage: 10 }, () => {
                    this.handlePagination();
                });
                break;
        }
    }
    addInviteeFromSearch = (newValue) => {
        let customGroup = this.state.listBackUp;
        customGroup.push(newValue);
        let filteredList = this.mergeDuplicates(customGroup);
        this.setState({
            customGroupPersonsList: filteredList,
            listBackUp: filteredList
        }, () => {
            this.validateForm();
            this.sortList();
        });

    }
    handleDelCallBack = (newValue) => {
        let r = this.state.listBackUp;
        r.splice(r.findIndex(el => el.person_id === newValue), 1);
        this.setState({
            customGroupPersonsList: r,
            listBackUp: r
        }, () => {
            this.validateForm();
            this.sortList();
        });

    }
    mergeDuplicates = (data) => {
        let filteredArr = data.reduce((acc, current) => {
            const x = acc.find(item => item.person_id === current.person_id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        filteredArr.map(obj => {
            obj['name'] = obj.first_name + ' ' + obj.last_name
        })
        return filteredArr
    }


    handleSearchByName = (value) => {
        this.setState({ searchTerm: value })
        if (value && value !== null) {
            let filteredList = this.state.listBackUp?.filter(item => item.name?.toLowerCase().includes(value.toLowerCase()));
            this.setState({ customGroupPersonsList: filteredList, page: 0, rowsPerPage: 10 }, () => {
                //this.handlePagination();
            });
        } else {
            this.setState({ customGroupPersonsList: this.state.listBackUp, page: 0, rowsPerPage: 10 }, () => {
                this.handlePagination();
            });
        }
    }

    updateData = (finalResult) => {
        // let records = this.state.listBackUp;
        // var r = finalResult.concat(records.filter(({ person_id }) => !records.find(p => p.person_id == person_id)));
        let filteredList = this.mergeDuplicates(finalResult);
        this.setState({
            listBackUp: filteredList, customGroupPersonsList: filteredList,
        }, () => {
            this.validateForm();
            this.sortList();
        });
    }

    handleChange = (value) => {
        this.setState({
            customGroupName: value
        }, () => { this.validateField('groupName', value) })
    }

    addPeople = (people) => {
        let customGroup = this.state.listBackUp;
        let finalResult = customGroup.concat(people.filter(({ person_id }) => !customGroup.find(p => p.person_id == person_id)));
        let filteredList = this.mergeDuplicates(finalResult);
        this.setState({
            customGroupPersonsList: filteredList,
            listBackUp: filteredList,
            page: 0, rowsPerPage: 10
        }, () => {
            this.validateForm();
            this.sortList();
        });
        this.toggleLookupPeople();
    }


    postCustomGroup = () => {
        let personId = [];
        this.state.listBackUp.forEach(item => {
            personId.push({ person_id: item.person_id })
        })
        let postJson = {
            "custom_group_id": this.state.customGroupId > 0 ? this.state.customGroupId : null,
            "custom_group_name": this.state.customGroupName,
            "persons": personId
        }
        if (postJson.custom_group_name) {
            if (this.state.customGroupListNames && this.state.customGroupListNames.some(item => item.name.toString() === postJson.custom_group_name.toString()
                && postJson.custom_group_id === 0)) {
                NotificationManager.warning('Please enter a unique Custom Group Name', 'Custom Group');
            } else {
                GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/customGroupPersonsAdd', postJson)
                    .then((response) => {
                        let orgId = this.state.customGroupId;
                        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
                        if (response.data.length > 0) {
                            let res = response?.data?.[0].custom_group_id;
                            if (orgId > 0) {
                                NotificationManager.info('Updated Successfully', 'Custom Group');
                                setTimeout(() => {
                                    this.setState({ customGroupId: res, isPeopleListFetchOnProgress: true, loaderOpen: true, page: 0, rowsPerPage: 10 });
                                    this.fetchData();
                                }, 1500);

                            } else {
                                NotificationManager.info('Created Successfully', 'Custom Group');
                                setTimeout(() => {
                                    this.setState({ customGroupId: res, isPeopleListFetchOnProgress: true, loaderOpen: true, page: 0, rowsPerPage: 10 });
                                    this.props.history.push('/customGroup/' + res);
                                    this.fetchData();
                                }, 1500);
                            }
                        } else {
                            NotificationManager.error(response?.data?.message ? response?.data?.message : 'Error', 'Custom Group');
                        }
                    },
                        (err) => {
                            NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
                            this.setState({ formValid: true })
                            NotificationManager.error('Save Failed', 'Event');
                            console.log("People Management grid service error: " + err);
                        });
            }
        }
    }

    goBack = () => {
        this.props?.history?.push('/manageData', 'CG');
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let groupNameValid = this.state.groupNameValid;
        switch (fieldName) {
            case 'groupName':
                groupNameValid = value != '';
                fieldValidationErrors.groupName = groupNameValid ? '' : ' is invalid';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            groupNameValid: groupNameValid,
        }, this.validateForm);
    }
    validateForm() {
        this.setState({
            formValid: (this.state.groupNameValid) && this.state.listBackUp.length > 0
        });
    }
    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }
    onChangeHandle = async value => {
        let option = {
            "searchString": value,
        }
        console.log("option", option)
        GifterService.getData(Constants.GifterServiceBaseUrl + '/activePersons?searchString=' + value)
            .then((response) => {
                this.setState({ activePersons: response.data });
            },
                (err) => {
                    this.setState({ activePersons: [] });
                });
    };

    render() {
        //   let list = this.state.userList?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return (<>
            {this.state.loaderOpen ? (<>
                <div className="tableLoader">
                    <Backdrop className='backdrop' open={this.state.loaderOpen}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            </>) : (<>
                <div className="customGroupPersons" >
                    <form className="demoForm" onSubmit={this.submitHandler}>
                        <div id="customGroupHeader">
                            <div className="row">
                                <div id="cutomsearch" className={`col-5 customGroupName F25 form-group ${this.errorClass(this.state.formErrors.groupName)}`}>
                                    <ArrowBackIcon className='FL' onClick={() => this.goBack()} />
                                    <MDBInput className='FL' id="eventName" placeholder="Custom Group Name.." type="text"
                                        className="form-control"
                                        value={this.state.customGroupName} onChange={(e) => this.handleChange(e.target.value)} >
                                    </MDBInput>
                                </div>
                                <div className="col-7">
                                    <MDBBtn color="primary" className="Save" disabled={!this.state.formValid}
                                        onClick={this.postCustomGroup.bind(this)}>
                                        <MDBIcon icon="save" className="mr-1" />&nbsp;&nbsp;Save
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="userTableHeaderContainer">
                        <div id="customGroupHeader">
                            <div className="row">
                                <div className="col-5 customNameSearch" id="cutomsearch">
                                    <MDBInput id="eventName" placeholder="Search Name.."
                                        value={this.state.searchTerm ? this.state.searchTerm : ""}
                                        onChange={(e) => this.handleSearchByName(e.target.value)}
                                        type="text">
                                    </MDBInput>
                                </div>
                                <div className="col-7">
                                    <MDBBtn color="primary" className="F10"
                                        onClick={this.toggleImportPeople.bind(this)}>
                                        {/* onClick = {this.postCustomGroup.bind(this)}> */}
                                        <ArrowDownwardRoundedIcon style={{ fontSize: 20 }} /> Import
                                    </MDBBtn>
                                    &nbsp;
                                    <MDBBtn color="primary" className="F10"
                                        onClick={this.toggleLookupPeople.bind(this)}
                                    >
                                        <AddCircleRoundedIcon style={{ fontSize: 20 }} />
                                        &nbsp;&nbsp;Lookup People
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>
                        <div className="userTable">
                            <TableComponent
                                list={this.state.customGroupPersonsList ? this.state.customGroupPersonsList : []}
                                config={this.state.configObject}
                                isLoading={this.state.isPeopleListFetchOnProgress}
                                sortCallback={this.handleTableColumnClick}
                                //  editCallback={this.handleOnEditClick('Edit')}
                                isCheck={this.state.isCustomGroupPersons}
                                listNew={this.state.activePersons}
                                addInviteeFromSearch={this.addInviteeFromSearch}
                                handleDelCallBack={this.handleDelCallBack}
                                onChangeHandle={this.onChangeHandle}
                            />
                            <TablePagination
                                component="div"
                                count={this.state.listBackUp.length > 0 ? this.state.listBackUp.length : 0}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                    <Import showImportPeople={this.state.showImportPeople}
                        toggleImportPeople={this.toggleImportPeople.bind(this)}
                        data={this.state.listBackUp}
                        updateData={this.updateData.bind(this)}
                    />
                    <LookupModal id="lookup-people" isOpen={this.state.showLookupPeople} addPeople={this.addPeople}
                        pageName='Lookup Recipients'
                        toggleLookupPeople={this.toggleLookupPeople.bind(this)}>
                    </LookupModal >
                </div>
                <NotificationContainer />
            </>)}
        </>
        );
    }
}

export default CustomGroupPersons;