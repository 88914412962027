import React from 'react';
import { MDBInput } from "mdbreact";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { validatePhone } from '../../Common/helper';

export default class BasicInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicInfo: {}
        }
    }

    componentDidMount() {
        this.setState({ basicInfo: this.props.list });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ basicInfo: nextProps.list });
    }

    render() {
        return (
            <div className="basicInfoContainer">
                <h6>Basic Info</h6>
                <div className="row">
                    <div className="col-4"><label className="inputLabel"><span className="required">*</span>Vendor Name:</label></div>
                    <div className="col-8">
                        <MDBInput className="col"
                            id="vendor_name"
                            value={this.state.basicInfo?.name}
                            onChange={(e) => this.props.handleValueChange(e.target.value, "name")}
                            type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><label className="inputLabel">Address:</label></div>
                    <div className="col-8">
                        <MDBInput id="address"
                            type="textarea"
                            onChange={(e) => this.props.handleValueChange(e.target.value, "address")}
                            value={this.state.basicInfo?.address}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><label className="inputLabel">Phone:</label></div>
                    <div className="col-8">
                        <MDBInput id="phone"
                            onChange={(e) => (validatePhone(e.target.value) || !e.target.value) ? this.props.handleValueChange(e.target.value, "phone") : null}
                            value={this.state.basicInfo?.phone ? this.state.basicInfo?.phone : ""}
                            type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><label className="inputLabel">Email:</label></div>
                    <div className="col-8">
                        <MDBInput id="email"
                            onChange={(e) => this.props.handleValueChange(e.target.value, "email")}
                            value={this.state.basicInfo?.email}
                            type="text" />
                            {!this.props.isEmailValid ? <div className="row"><div className="col-8 error">Invalid Email!</div></div> : null }
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><label className="inputLabel">Notes:</label></div>
                    <div className="col-8">
                        <MDBInput id="notes"
                            value={this.state.basicInfo?.notes}
                            onChange={(e) => this.props.handleValueChange(e.target.value, "notes")}
                            type="textarea"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4"><label className="inputLabel">Status:</label></div>
                    <div className="col-8">
                        <Select
                            id="is_active"
                            value={ this.state.basicInfo?.is_active? this.state.basicInfo?.is_active : 0}
                            onChange={(event) => this.props.handleValueChange(event.target.value, "is_active")}
                            disableUnderline
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
        );
    }

}