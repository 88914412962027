import React, { useState, useEffect } from "react";
import { MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal, MDBContainer, MDBIcon, MDBBtn }
    from "mdb-react-ui-kit";
import Divider from '@material-ui/core/Divider';
import { Component } from "react";
import { withRouter } from 'react-router-dom';

class SideNav extends Component {
    _isMounted = false;
    container = null;
    constructor(props) {
        super(props);
        console.log("SideNav loaded");
        this.state = {
            //isModal: false,
            // isManageUserModal: false,
            // isManageProjectsModal: false,
            // isManageTalents: false,          
            //toggleStateA: true,
            //userProfile: localStorage.getItem("user_name"),//JSON.parse(localStorage.getItem("user_profile")),
            // isAdmin: false,
            // isCreate: false,
            // isFinalApprover: false,
            //permissions: localStorage.getItem("permissions"),
            //sidenavContent: null,
            //isOpen: false,
            sideNavLeft: true,
            isSlim: false,
            //activeTab: ''
        }

        // console.log("User Profile : " + JSON.parse(localStorage.getItem("user_profile")));

    }

    handleSideNavActive = (value, routePath) => {
        // this.setState({
        //     activeTab: value
        // }            
        //);
        this.props.setActiveTab(value);
        var prevLoc = this.props.location.pathname.split('/');
        var currLoc = routePath.split('/');
        var prevPath = prevLoc[1];
        var currPath = currLoc[1];
        var prevParam = prevLoc.length > 2 ? prevLoc[2] : "";
        var currParam = prevLoc.length > 2 ? currLoc[2] : "";
        if (prevPath == currPath && prevParam != currParam) {
            this.props.history.push('/empty');
            this.props.history.push(routePath);
        }
        else
            this.props.history.push(routePath);

        //if(prevParam != currParam || (prevParam == "" && currParam == ""))

        //this.props.handleSideNavActive(value, routePath);
    }


    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        // if (this._isMounted) {
        //     this.setState({
        //         activeTab: nextProps.activeTab
        //     });
        // }
    }
    // toggle = () => {
    //     //this.setModal(!this.isModal);
    //     this.setState({
    //         isModal: !this.state.isModal
    //     });
    // };
    // toggleManageUser = () => {
    //     debugger
    //     this.setState({
    //         isManageUserModal: !this.state.isManageUserModal
    //     });
    // }

    // toggleManageProjects = () => {
    //     debugger
    //     this.setState({
    //         isManageProjectsModal: !this.state.isManageProjectsModal
    //     });
    // }
    // toggleManageTalent = () => {
    //     debugger
    //     this.setState({
    //         isManageTalents: !this.state.isManageTalents
    //     });
    // }

    // setModal(isModal) {
    //     this.setState({ isModal: isModal });
    //     this.isModal = isModal;
    // }
    handleSideNavLeft = () => {
        this.setState(
            {
                sideNavLeft: !this.state.sideNavLeft,
                slim: !this.state.isSlim
            });
        this.props.toggleSideNav();
    }


    getParentRef = () => {
        return this.props.getParentContent();
    }

    render() {
        const navStyle = { paddingLeft: this.state.windowWidth > this.state.breakWidth ? "210px" : "16px" };
        const ptli = { paddingLeft: '8rem' }
        return (
            <>
                <div className={this.props.isValidUser ? "d-block" : "d-none"}>
                    <MDBSideNav //contentRef={this.state.container}                    
                        constant
                        right={false}
                        fixed
                        color="white"
                        bgColor="primary"
                        slim={this.state.slim}
                        slimCollapsed={!this.state.sideNavLeft}
                        mode="push"
                        backdrop={false}
                        closeOnEsc={false}

                    >
                        <MDBSideNavMenu className="side-nav-menu">
                            <MDBSideNavItem>

                            </MDBSideNavItem>
                            <MDBSideNavItem >
                                <MDBSideNavLink>
                                    <div onClick={this.handleSideNavLeft.bind(this)}>
                                        <MDBIcon style={{ paddingLeft: !this.state.sideNavLeft ? '0' : '180px' }}
                                            size="2x"
                                            icon={!this.state.sideNavLeft ? "angle-double-right"
                                                : "angle-double-left"} />
                                    </div >
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                            {/* <span className='mnuDividers'>Gift Records</span> */}
                            {/* <MDBSideNavItem className={this.state.isExpanded ? "d-block" : "d-none"}>
                            <span className = "side-nav-menu-header">
                                Events
                            </span>
                        </MDBSideNavItem> */}
                            <MDBSideNavItem className={this.props.active_tenant_permissions.isCreateEvent ? (this.props.activeTab === "CE" ? "sidenav-item-active" : "") : "d-none"}
                                onClick={this.handleSideNavActive.bind(this, 'CE', "/event/0/0")}
                            >

                                <MDBSideNavLink topLevel to="/event/0/0">
                                    <MDBIcon fas size='fw' icon="calendar-plus" className="mr-2" />
                                    {/* <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                Create Gift Record
                                </span> */}
                                    <span className='sidenav-non-slim ml-6'>Create Event</span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                            {/* <MDBSideNavItem className={this.state.isExpanded ? "d-block" : "d-none"}>
                            <span className = "side-nav-menu-header">
                                Gift Records
                            </span>
                        </MDBSideNavItem> */}
                            <MDBSideNavItem className={this.props.active_tenant_permissions.isCreateGift ? (this.props.activeTab === "CG" ? "sidenav-item-active" : "") : "d-none"}
                                onClick={this.handleSideNavActive.bind(this, 'CG', "/gift/0/0")}
                            >
                                <MDBSideNavLink to="/gift/0" topLevel >
                                    <MDBIcon fas size='fw' icon="gift" className="mr-2" />
                                    {/* <span className={!this.state.sideNavLeft ? "sv-slim-override" : ""}>
                                Create Gift Record
                                </span> */}
                                    <span className='sidenav-non-slim ml-6'>Create Gift Record</span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>

                            {/* <hr className="solid" /> */}
                            {/* <span className='mnuDividers'>Cards</span> */}
                            {/* <MDBSideNavItem className={this.state.isExpanded ? "d-block" : "d-none"}>
                            <span className = "side-nav-menu-header">
                                Cards
                            </span>
                        </MDBSideNavItem> */}
                            <MDBSideNavItem className={this.props.active_tenant_permissions.isCreateCard ? (this.props.activeTab === "CC" ? "sidenav-item-active" : "") : "d-none"}
                                onClick={() => this.handleSideNavActive('CC', '/card/0/0')}>
                                <MDBSideNavLink>
                                    <MDBIcon size='fw' icon="address-card" className="mr-2" />
                                    <span className="sidenav-non-slim ml-6">
                                        Create Cards
                                </span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                            {/* <MDBSideNavItem>
                        <MDBSideNavLink>
                        <MDBIcon size='3x' icon="lightbulb" className="mr-2" />
                            <span className="sidenav-non-slim">
                              Insights
                            </span>

                        </MDBSideNavLink>
                        </MDBSideNavItem> */}

                            {/* <hr className="solid" /> */}
                            {/* <span className='mnuDividers'>Data Management</span> */}
                            {/* <MDBSideNavItem className={this.state.isExpanded ? "d-block" : "d-none"}>
                            <span className = "side-nav-menu-header">
                                Data Management
                            </span>
                        </MDBSideNavItem> */}
                            <MDBSideNavItem className={this.props.active_tenant_permissions.isCreatePeople ? (this.props.activeTab === "MP" ? "sidenav-item-active" : "") : "d-none"}
                                onClick={() => this.handleSideNavActive('MP', '/manageUsers')}>
                                <MDBSideNavLink>
                                    <MDBIcon size='fw' icon="users" className="mr-2" />
                                    <span className="sidenav-non-slim ml-6">
                                        Manage People
                            </span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                            <MDBSideNavItem className={this.props.active_tenant_permissions.isCreateData ? (this.props.activeTab === "MD" ? "sidenav-item-active" : "") : "d-none"}
                                onClick={() => this.handleSideNavActive('MD', '/manageData')}>
                                <MDBSideNavLink>
                                    <MDBIcon size="fw" icon="database" className="mr-2" />
                                    <span className="sidenav-non-slim ml-6">
                                        Manage Data
                            </span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                            <MDBSideNavItem className={this.props.active_tenant_permissions.isCreateUser ? (this.props.activeTab === "UM" ? "sidenav-item-active" : "") : "d-none"}
                                onClick={() => this.handleSideNavActive('UM', '/userRoleManagers')}>
                                <MDBSideNavLink>
                                    <MDBIcon size="fw" icon="user-shield" className="mr-2" />
                                    <span className="sidenav-non-slim ml-6">
                                        Manage Users
                                    </span>
                                </MDBSideNavLink>
                            </MDBSideNavItem>
                        </MDBSideNavMenu>
                    </MDBSideNav>
                </div>
            </>
        );
    }
}

export default withRouter(SideNav);











