import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { MDBInput } from "mdbreact";
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid grey',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    label: {
        fontSize: '0.9rem'
    }
}));

const filter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

export default function AddOrEditModal(props) {
    const classes = useStyles();
    return (
        <div className="addEditContainer">
            <Modal
                open={props?.open}
                onClose={props?.handleClose}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <h6><b>{props.modalMode + " Gift Item"}</b></h6>
                    <div className="row">
                        <div className="col-4">
                            <label className={classes.label}>Item Name:</label>
                        </div>
                        <div className="col-8">
                            <Autocomplete
                                id={"giftDropDown"}
                                options={props?.giftDropDown}
                                value={props?.giftObj}
                                freeSolo
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option ? option : "";
                                    } else
                                        if (option?.inputValue) {
                                            return option.inputValue;
                                        } else {
                                            if (option.text?.includes("Add '")) {
                                                option.text = option.text?.match(/'([^']+)'/)[1];
                                            }
                                            return option.text ? option.text : "";
                                        }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            value: params.inputValue,
                                            text: `Add '${params.inputValue}'`,
                                        });
                                    }

                                    return filtered;
                                }}
                                style={{ width: '100%' }}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        props.handleEdit(props?.giftObj, newValue, "autocomplete")
                                    } else if (newValue && newValue.inputValue) {
                                        props.handleEdit(props?.giftObj, newValue.inputValue, "autocomplete")
                                    } else {
                                        if (newValue?.text?.includes("Add '")) {
                                            newValue.text = newValue.text.match(/'([^']+)'/)[1];
                                        }
                                        props.handleEdit(props?.giftObj, newValue, "autocomplete")
                                    }
                                }}
                                renderOption={(option) => option.text}
                                renderInput={(params) => <TextField {...params} variant="outlined" onChange={(e) => props.handleEdit(props?.giftObj, e.target.value, "gift_name")} />}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label className={classes.label}>Price</label>
                        </div>
                        <div className="col-5">
                            <TextField id="price"
                                variant="outlined"
                                value={props?.giftObj?.price}
                                type="number"
                                onChange={(e) => props.handleEdit(props?.giftObj, parseFloat(e.target.value), "price")}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyTwoToneIcon fontSize={"small"} color={"primary"} />
                                        </InputAdornment>
                                    ),
                                }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label className={classes.label}>Status</label>
                        </div>
                        <div className="col-5">
                            <Select
                                id="is_active"
                                value={(props?.giftObj?.is_active === "Active" || props?.giftObj?.is_active === 1) ? 1 : 0}
                                onChange={(e) => props.handleEdit(props?.giftObj, e.target.value, "is_active")}
                                disableUnderline
                            >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={0}>Inactive</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="row" id="buttonRow">
                        <div className="col-6">
                            <Button variant="contained" color="primary" disabled={props?.isSaveGiftDisabled} onClick={() => props?.handleSave()}>Save</Button>
                        </div>
                        <div className="col-6">
                            <Button variant="contained" color="primary" onClick={() => props?.handleClose()}>Cancel</Button>
                    </div>
                </div>
                </div>
            </Modal>
        </div >
    );
}
