import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableComponent from '../Common/Table';
import TablePagination from '@material-ui/core/TablePagination';
import { MDBIcon } from "mdbreact";
import { MDBBtn } from 'mdb-react-ui-kit';
import Button from '@material-ui/core/Button';
import TabPanel from './TabPanel';
import TextField from '@material-ui/core/TextField';

const configObject = {
    headings: [{
        headingLabel: "Name",
        icon: "sort-alpha-down"
    },
    {
        headingLabel: "Last Modified",
        icon: ""
    },
    {
        headingLabel: "Status",
        icon: ""
    },
    {
        headingLabel: "Actions",
        icon: ""
    }],
    dataNodes: ["name", "updated", "is_active"],
    primaryKey: "id",
    actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};

const alternateConfigObject = {
    headings: [{
        headingLabel: "Name",
        icon: "sort-alpha-down"
    },
    {
        headingLabel: "Last Modified",
        icon: ""
    },
    {
        headingLabel: "Actions",
        icon: ""
    }],
    dataNodes: ["name", "updated"],
    primaryKey: "id",
    actions: ["pen"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};


export default class TabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            sortCount: 0,
            sortBy: "Name",
            searchTerm: null,
            isSortByAsc: true,
            configObject: {},
            list: [],
            listBackUp: [],
            listToRender: []
        }
    }

    componentDidMount() {
        this.setState({ list: this.props.list, listBackUp: this.props.list }, () => {
            let config = this.props.tabList?.find(item => item.index === this.props.tabValue);
            let currentConfig = (config.label === "Occasions" || config.label === "Custom Groups" || config.label === "Shows") ?
                alternateConfigObject : configObject;
            // if (config.label === 'Custom Groups') {
            //     currentConfig.actions = ["plus", "pen", "trash-alt"]
            // }
            this.setState({ configObject: currentConfig });
            // this.handlePagination();
            this.sortList();
        });
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (sortBy) => {
        let configObject = this.state.configObject;
        this.setState({ isSortByAsc: this.state.sortCount % 2 === 0 ? true : false }, () => {
            let newHeadingArray = configObject.headings.map(item => {
                if (item.headingLabel === sortBy && sortBy !== "Actions") {
                    item.icon = this.state.isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
                } else if (sortBy !== this.state.sortBy && item.headingLabel === this.state.sortBy && sortBy !== "Actions") {
                    item.icon = "";
                }
                return item;
            });
            delete configObject.headings
            configObject.headings = newHeadingArray;
            this.setState({ sortBy: sortBy, page: 0, configObject: configObject }, () => {
                this.sortList();
            })
        })
    }

    sortList = () => {
        let sortedList;
        switch (this.state.sortBy) {
            case "Name":
                if (this.state.isSortByAsc) {
                    sortedList = this.state.list?.sort((a, b) => (a.name.toString().split(' ').join('').toLowerCase() > b.name.toString().split(' ').join('').toLowerCase()) ? 1 : -1);
                } else {
                    sortedList = this.state.list?.sort((a, b) => (b.name.toString().split(' ').join('').toLowerCase() > a.name.toString().split(' ').join('').toLowerCase()) ? 1 : -1);
                }
                this.setState({ list: sortedList, page: 0 }, () => {
                    this.handlePagination();
                });
                break;

            case "Last Modified":
                if (this.state.isSortByAsc) { 
                    sortedList = this.state.list?.sort((a, b) => (Date.parse(a.updated_at?.toString().trim()) > Date.parse(b.updated_at?.toString().trim())) ? 1 : -1);
                } else {
                    sortedList = this.state.list?.sort((a, b) => (Date.parse(b.updated_at?.toString().trim()) > Date.parse(a.updated_at?.toString().trim())) ? 1 : -1);
                }
                this.setState({ list: sortedList, page: 0 }, () => {
                    this.handlePagination();
                });
                break;

            case "Status":
                if (this.state.isSortByAsc) {
                    sortedList = this.state.list?.sort((a, b) => (a.is_active.toString() > b.is_active.toString()) ? 1 : -1);
                } else {
                    sortedList = this.state.list?.sort((a, b) => (b.is_active.toString() > a.is_active.toString()) ? 1 : -1);
                }
                this.setState({ list: sortedList, page: 0 }, () => {
                    this.handlePagination();
                });
                break;
        }
    }

    handleSearchByName = (value) => {
        this.setState({ searchTerm: value })
        if (value && value !== null) {
            let filteredList = this.state.listBackUp?.filter(item => item.name?.toLowerCase().includes(value.toLowerCase()));
            this.setState({ list: filteredList }, () => {
                this.sortList();
            });
        } else {
            this.setState({ list: this.state.listBackUp }, () => {
                this.sortList();
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.list) !== JSON.stringify(this.props.list)) {
            this.setState({ list: nextProps.list, listBackUp: nextProps.list, page: 0, rowsPerPage: 10, searchTerm: null }, () => {
                let config = this.props.tabList?.find(item => item.index === this.props.tabValue);
                let currentConfig = (config.label === "Occasions" || config.label === "Custom Groups" || config.label === "Shows") ? alternateConfigObject : configObject
                    // if (config.label === 'Custom Groups') {
                    //     currentConfig.actions = ["plus", "pen", "trash-alt"]
                    // }
                this.setState({ isSortByAsc: true, sortCount: 0, configObject: currentConfig }, () => {
                    this.handleTableSort("Name");
                })
                //this.handlePagination();
            });
        }
    }

    a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`
        };
    }

    handlePagination = () => {
        let startIndex = this.state.page * this.state.rowsPerPage;
        let lastIndex = startIndex + this.state.rowsPerPage;
        let list = this.state.list?.slice(startIndex, lastIndex);
        this.setState({ listToRender: list });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => {
            this.handlePagination();
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
            this.handlePagination();
        });
    }

    handleEditCallback = (id) => {
        this.props.editCallback(id,"Edit");
        if(this.props.activeTabName === "Vendors") {
            this.props.vendorNavigation(id, "Edit");
        }if(this.props.activeTabName === "Custom Groups") {
            this.props.customGroupNavigation(id, "Edit");
        }
    }

    handleAddCallback = (id) => {
        this.props.editCallback(null,"Add");
        this.props.addCallback(null);
        if(this.props.activeTabName === "Vendors") {
            this.props.vendorNavigation(null, "Add");
        }
        if(this.props.activeTabName === "Custom Groups") {
            if(id != null)
            this.props.vendorNavigation(id, "Plus");
            else
            this.props.vendorNavigation(0, "Plus");
        }
        
    }

    render() {
        return (
            <div className="row">
                <div className="col-2" id="tabContainer">
                    <Tabs
                        orientation="vertical"
                        variant="standard"
                        value={this.props.tabValue}
                        onChange={this.props.handleTabChange}
                    >
                        {
                            this.props.tabList?.map(item => <Tab label={item.label} {...this.a11yProps(item.index)} />)
                        }
                    </Tabs>
                </div>
                <div className="col-10" id="tabPanelContainer">
                    <div className="row">
                        <div className="col-2" id="addButton">
                            <MDBBtn color="primary" onClick={() => this.handleAddCallback()}>
                                <MDBIcon icon="plus-circle" className="mr-1" />&nbsp;&nbsp;Add</MDBBtn>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-4" id="search"><TextField placeholder={`Search ${this.props.activeTabName} by Name:`} value={this.state.searchTerm ? this.state.searchTerm : ""} variant="outlined" onChange={(e) => this.handleSearchByName(e.target.value)} /></div>
                        <div className="row" id="dataTable">
                            {
                                this.props.tabList?.map(item => <TabPanel value={this.props.tabValue} index={item.index}>
                                    <TableComponent
                                        list={this.state.listToRender ? this.state.listToRender : []}
                                        config={this.state.configObject}
                                        isLoading={this.props.isLoading}
                                        sortCallback={this.handleTableColumnClick}
                                        editCallback={this.handleEditCallback}
                                        handleAddCallback={this.handleAddCallback} />
                                    <TablePagination
                                        component="div"
                                        count={this.state.list ? this.state.list?.length : 0}
                                        rowsPerPageOptions = {[2, 4, 10, 25, 50, 75, 100]}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </TabPanel>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}