import React, { useState, useEffect } from "react";
import { MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal, MDBContainer, MDBIcon, MDBBtn }
    from "mdb-react-ui-kit";
import Divider from '@material-ui/core/Divider';
import { Component } from "react";
import { withRouter } from 'react-router-dom';
import ExportEventDetailsJSON from '../../json/export_event_details.json';
import ExportGiftDetailsJSON from '../../json/export_gift_details.json';
import ExportCardDetailsJSON from '../../json/export_card_details.json';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';

const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

class ExportDetails extends Component {
    _isMounted = false;
    container = null;
    export_details_json = "";
    constructor(props) {
        super(props);   
        if(this.props.fromPage === "Event")
            this.export_details_json = ExportEventDetailsJSON;
        else if (this.props.fromPage === "Gift")
        this.export_details_json = ExportGiftDetailsJSON;
        else if (this.props.fromPage === "Card")
        this.export_details_json = ExportCardDetailsJSON;

        this.state = {
            selectedFields: {},
            exportDetailsStatus: false
        }
    }

    handleSideNavActive = (value, routePath) => {
        this.setState({
            activeTab: value
        } );
        this.props.history.push(routePath, this.state);
    }


    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
        
        var finalJson = this.state.selectedFields;
        this.export_details_json.sections.map((section) => {
            var finalArr = finalJson[section.type] || {};
            section.subSections.map((subSection) => {
                var checkedItems = {};
                subSection.values.map((fields) => {
                    checkedItems[fields.fieldId] = fields.default;
                });
                finalArr[subSection.groupName] = checkedItems;
            });
                finalJson[section.type] = finalArr;
        });
        this.setState({selectedFields : finalJson });

    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
        }
    }

    exportDetails = () =>{
        //alert("Exported");
        this.setState({exportDetailsStatus : true});
        var input = this.state.selectedFields;
        var filterJson = {}; 
        filterJson["gift_event_card_id"] = this.props.id;
        input["Where"] = filterJson;
        console.log("Export Details -->" + JSON.stringify(input));
        var url_path = "";
        if(this.props.fromPage === 'Event')
          url_path =  "/exportEvent";
        else if(this.props.fromPage === 'Gift')
          url_path =  "/exportGift"; 
        else if(this.props.fromPage === 'Card')
          url_path =  "/exportCard";
        GifterService.getDataParams(Constants.GifterServiceBaseUrl + url_path //+ '?tenantId=' + localStorage.getItem("active_tenant_id"))
        , input )
        .then(response => {
            var buffer = response.data;
            var blob = new Blob([s2ab(atob(response.data))], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            });
            
            // href = URL.createObjectURL(blob);
            // const blob = new Blob([myJsonString], {
            //     type: "application/vnd.ms-excel;charset=utf-8"
            //   });
            saveAs(blob, "export-details-"+this.props.fromPage+"-"+ this.props.name +"-"+this.props.id.toString()+".xlsx");
            this.setState({exportDetailsStatus : false});
        },
        (err) => {
            console.log("Export Details Error-->" + err);
            this.setState({exportDetailsStatus : false});
        });
    }

    handleFieldsChecked = (sectionType, subSectionGroupName, fieldId, event) => {
        var currState = this.state.selectedFields;
        currState[sectionType][subSectionGroupName][fieldId] = event.target.checked;
        this.setState({selectedFields : currState});
    }
    

    render(){
        return (
            <>
            <div>
                <div className="fst-italic mat-sub-section col-12">
                     Select {this.props.fromPage} data to be exported to an Excel spreadsheet. Sections with no selected field(s) will not be generated.
                </div>
                <div className="h6 fw-bold margin-ver-none padding-ver"> 
                    {this.export_details_json.name}
                </div>
                <div className="mat-sub-section fst-italic"> 
                    {this.export_details_json.value}
                </div>
                {
                    this.export_details_json.sections.map((section) => {
                        return <div > 
                            {/* className={section.fullPage ? "row" : ""} */}
                                    <div className="h6 fw-bold margin-ver-none padding-ver"> 
                                        {section.name}
                                    </div>
                                    <div className="row">
                                    
                                    {/* {section.values.map((value) => {
                                        <> */}
                                        {section.subSections.map((subSection) => {
                                            return <>                                                
                                                    <div className={subSection.fullPage ? "col-12 padding-hor-25" : "col-6 padding-hor-25"}>
                                                    <div className={subSection.newSection ? "padding-ver margin-top" : "padding-ver"}>
                                                        <div className={subSection.section ? "h6 fw-bold margin-hor-minus-12 padding-ver" : "mat-sub-section-header fst-italic padding-hor-15"}>
                                                            {subSection.name}
                                                        </div>
                                                        {subSection.values.map((fields) => {
                                                            
                                                            return <> 
                                                            <div className="mat-sub-section">
                                                                <div className="flexBox-plain align-center-vertical">
                                                                    <input type ="checkbox" value={section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                        checked={this.state.selectedFields[section.type]?.[subSection.groupName]?.[fields.fieldId] || false} 
                                                                        onChange={this.handleFieldsChecked.bind(this, section.type, subSection.groupName, fields.fieldId )}/>
                                                                    <label className="padding-left-3">{fields.fieldName}</label>
                                                                </div>

                                                            </div>
                                                            </>})
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            })
                                        }                                        
                                    </div>
                               </div> 
                            })
                }        
                          
                <div className="padding-hor flexAlignRight">
                    <div className="padding-hor">
                        <MDBBtn onClick={this.exportDetails.bind(this)}>
                        <MDBIcon className={this.state.exportDetailsStatus ? "" : "d-none"} icon="spinner" spin/> Export
                        </MDBBtn>
                    </div>
                    <div className="padding-hor">
                        <MDBBtn onClick={this.props.toggleMaterials}>
                            Cancel
                        </MDBBtn>
                    </div>
                </div>
            </div>
        </>
        );
    }

}

export default ExportDetails;