import React from 'react';
import { MDBInput, MDBIcon, MDBRow } from "mdbreact";
import '../AttendenceManagement/AttendanceManagement.scss';
import {
    MDBCard, MDBCardBody, MDBBtn
} from 'mdb-react-ui-kit';
import TablePagination from '@material-ui/core/TablePagination';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants.js';
import moment from 'moment'

class AttendenceManagementMD extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            data: props.rows,
            filterData: props.rows,
            filterString: '',
            countDetails: props.countDetails,
            page: 0,
            rowsPerPage: 10,
            saveProgress: props.saveProgress,
            activePersons: props.activePersons ? props.activePersons : [],
            newInvitee: null,
            selectedTitle: null,
            person_phone_id: null,
            titles: props.titles,
            eventId: props.eventId,
            eventHeader: props.eventHeader,
            existingUser: false,
            newPhone: '',
            disabled: props.disabled ? props.disabled : false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rows !== this.props?.rows) {
            this.setState({data:this.props?.rows},()=>{
                this.searchInviteewithPreSelectedValue();
            })
           
        }
    }
    rsvpStatusChange = (event, row, flag = 0) => {
        this.props.rsvpStatusChange(event, row, flag)
    }
    checkinStatusChange = (event, row, flag = 0) => {
        this.props.checkinStatusChange(event, row, flag)
    }
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.rows,
            filterData: nextProps.rows,
            countDetails: nextProps.countDetails,
            saveProgress: nextProps.saveProgress
        });
    }
    newInviteeInputChange = (newValue, tab = '') => {
        if (newValue) {
            let r = this.state.titles;
            let title = r.filter(item => item.title_id == newValue.title_id);
            let selectedTilte = title && title.length > 0 ? title[0] : null
            let phone = newValue.phone ? newValue.phone : '';
            this.setState({ newInvitee: newValue, selectedTitle: selectedTilte, existingUser: true, newPhone: phone })
        } else {
            this.setState({ newInvitee: null, selectedTitle: null, existingUser: false, newPhone: '' })
        }
    }
    newTitleChange = (newValue) => {
        if (newValue) {
            this.setState({ selectedTitle: newValue })
        } else {
            this.setState({ selectedTitle: null })
        }
    }
    expKeypress = (event, value) => {
        if (event.charCode == 13) {
            let newInviteeObj = {
                "gift_event_card_id": this.state.eventId > 0 ? this.state.eventId : null,
                "person_id": null,
                "first_name": event.target.value,
                "last_name": '',
                "p_comp_div_title_id": null,
                "person_phone_id": null,
                "phone": this.state.newPhone
            }
            // let invitees = this.state.activePersons;
            // invitees.push(newInviteeObj);
            this.setState({ newInvitee: newInviteeObj, existingUser: false })
        }
    }
    addGuest = (event, item) => {
        if (event.charCode == 13 && event.target.value != '') {
            let guestObje = {
                "gift_event_card_id": this.state.eventId > 0 ? this.state.eventId : null,
                "event_invitee_id": item.event_invitee_id,
                "guest_name": event.target.value
            }
            console.log("newInviteeObj", guestObje)
            this.setState({ saveProgress: true })
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/eventInviteeGuestAdd', guestObje)
                .then((response) => {
                    this.setState({ selectedTitle: null, newInvitee: null, existingUser: false, saveProgress: true })
                    this.props.reload(this.state.filterString);
                   // this.state.filterString && this.searchInviteewithPreSelectedValue();
                },
                    (err) => {
                        console.log("Guest Add Error: " + err);
                    });
        }
    }
    deleteGuest = (event, item, event_invitee_id) => {
        let guestObje = {
            "gift_event_card_id": this.state.eventId > 0 ? this.state.eventId : null,
            "event_invitee_id": event_invitee_id,
            "guest_name": event.target.value,
            "event_guest_id": item.event_guest_id,
        }
        console.log("newInviteeObj", guestObje)
        this.setState({ saveProgress: true })
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/eventInviteeGuestDelete', guestObje)
            .then((response) => {
                this.setState({ selectedTitle: null, newInvitee: null, existingUser: false, saveProgress: true })
                this.props.reload();
            },
                (err) => {
                    console.log("Guest Delete Error: " + err);
                });
    }
    titleKeypress = (event, value) => {
        if (event.charCode == 13) {
            let titleObj = {
                "title_id": null,
                "title_name": event.target.value,
            }
            // let titles = this.state.titles;
            // titles.push(titleObj);
            this.setState({ selectedTitle: titleObj })
        }
    }

    searchFromFilter = (event) => {       
        if (event?.target?.value != '') {
            let records = this.state.filterData;
            let query = event.target.value.toLowerCase();
           
            let searcResults = records.filter(e => e.first_name?.toLowerCase().includes(query) || e.last_name?.toLowerCase().includes(query))
                .sort((a, b) => a.first_name?.toLowerCase().includes(query) && !b.first_name?.toLowerCase().includes(query) ? -1 : b.last_name?.toLowerCase().includes(query) && !a.last_name?.toLowerCase().includes(query) ? 1 : 0);
            this.setState({ data: searcResults, filterString : event?.target?.value })
        } else{
            this.setState({ data: this.state.filterData,filterString : '' })
        }
    }

    searchInviteewithPreSelectedValue() {
        if (this.state?.filterString != '' && this.state?.data) {
            let records = this.state?.data;
            let query = this.state?.filterString?.toLowerCase();
            let searcResults = records.filter(e => e.first_name?.toLowerCase().includes(query) || e.last_name?.toLowerCase().includes(query))
                .sort((a, b) => a.first_name?.toLowerCase().includes(query) && !b.first_name?.toLowerCase().includes(query) ? -1 : b.last_name?.toLowerCase().includes(query) && !a.last_name?.toLowerCase().includes(query) ? 1 : 0);
            this.setState({ data: searcResults })
        }
    }

    addInviteeFromSearch = () => {
        let newInviteeObj = {
            "gift_event_card_id": this.state.eventId > 0 ? this.state.eventId : null,
            "person_id": this.state.newInvitee.person_id,
            "person_name": this.state.newInvitee.first_name + ' ' + this.state.newInvitee.last_name,
            "p_comp_div_title_id": this.state.newInvitee.p_comp_div_title_id,
            "title_id": this.state.selectedTitle ? this.state.selectedTitle.title_id : null,
            "title_name": this.state.selectedTitle ? this.state.selectedTitle.title_name : null,
            "person_phone_id": this.state.newInvitee?.person_phone_id && this.state.newInvitee?.person_phone_id != '' ? this.state.newInvitee?.person_phone_id : null,
            "phone": this.state.newPhone != '' ? this.state.newPhone : null,
            "person_category_id": this.state.newInvitee?.person_category_id,
            "person_email_id": this.state.newInvitee?.person_email_id,
            "person_extension_id": this.state.newInvitee?.person_extension_id,
        }
        this.setState({ newPhone: '' })
        console.log("newInviteeObj", newInviteeObj)
        this.setState({ saveProgress: true })
        GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/eventInviteeQuickAdd', newInviteeObj)
            .then((response) => {
                this.setState({
                    selectedTitle: null, newInvitee: null, newPhone: '', existingUser: false, saveProgress: true
                })
                this.props.reload();
            },
                (err) => {
                    console.log("People Management grid service error: " + err);
                });
    }
    handlePhoneChange = (event) => {
        this.setState({ newPhone: event.target.value })
    }
    onChangeHandle = async value => {
        let option = {
            "searchString": value,
        }
        console.log("option", option)
        GifterService.getData(Constants.GifterServiceBaseUrl + '/activePersons?searchString=' + value)
            .then((response) => {
                this.setState({ activePersons: response.data });
            },
                (err) => {
                    this.setState({ activePersons: [] });
                });
    };

    render() {
        let r = this.state.data ? this.state.data : [];
        let records = r?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return (
            <>
                <div>
                    <div class="row">
                        <div class="col-md" id='atdeventDetails'>
                            <span class='mdTitle'>{this.state.eventHeader?.[0]?.name}</span>
                            <br />
                            <span class='colorGray'>{this.state.eventHeader?.[0]?.event_date != null ?
                                moment(this.state.eventHeader?.[0]?.event_date).format('MMM. DD, YYYY') + '-' +
                                this.state.eventHeader?.[0]?.event_time + ' ' + 'PDT' : ''}</span>
                            <br />
                            <span class='colorGray'>{this.state.eventHeader?.[0]?.event_address}</span>
                            <br />
                        </div>
                    </div>
                </div>
                <div className='M2pt5'>
                    <div class="row">

                        <div class="col-4">
                            <lable className='blueNumberLable'>{this.state.countDetails.invitee_count ? this.state.countDetails.invitee_count : 0}</lable>
                            <br />
                            <label className='blueTextLable'>Invitees</label>
                        </div>
                        <div class="col-4">
                            <lable className='greenNumberLable'>{this.state.countDetails.rsvp_accepted_count ? this.state.countDetails.rsvp_accepted_count : 0}</lable>
                            <br />
                            <label className='greenTextLable'>Confirmed</label>
                        </div>
                        <div class="col-4">
                            <lable className='redNumberLable'>{this.state.countDetails.rsvp_declined_count ? this.state.countDetails.rsvp_declined_count : 0}</lable>
                            <br />
                            <label className='redTextLable'>Declined</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <lable className='grayNumberLable'>{this.state.countDetails.rsvp_no_response_count ? this.state.countDetails.rsvp_no_response_count : 0}</lable>
                            <br />
                            <label className='grayTextLable'>No Reply</label>
                        </div>
                        <div class="col-4">
                            <lable className='blueNumberLable'>{this.state.countDetails.checked_in_count ? this.state.countDetails.checked_in_count : 0}</lable>
                            <br />
                            <label className='blueTextLable'>Checked-In</label>
                        </div>
                        <div class="col-4">
                            <lable className='blueNumberLable'>{this.state.countDetails.guest_count ? this.state.countDetails.guest_count : 0}</lable>
                            <br />
                            <label className='blueTextLable'>Total Guests</label>
                        </div>
                    </div>

                </div>
                <div disabled={this.state.disabled}>
                    <span className='addInlabel'>Quick-Add an Invitee:</span>
                    <div class='row' id='addin'>
                        <div class='col-7'>
                            <Autocomplete size="medium"
                                className="tags-standard"
                                options={this.state.activePersons ? this.state.activePersons : []}
                                getOptionLabel={(option) => (option.first_name + ' ' + option.last_name)}
                                value={this.state.newInvitee}
                                fullWidth={true}
                                onKeyPress={(event, newValue) => {
                                    this.expKeypress(event, newValue)
                                }}
                                onChange={(event, newValue) => {
                                    this.newInviteeInputChange(newValue)
                                }}
                                style={{ width: '100%' }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        placeholder="Invitee Name"
                                        margin="normal"
                                        onChange={ev => {
                                            // dont fire API if the user delete or not entered anything
                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                this.onChangeHandle(ev.target.value);
                                            }
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div class='col-5'>
                            <MDBInput disabled={this.state.existingUser}
                                onChange={(event) => {
                                    this.handlePhoneChange(event)
                                }}
                                placeholder='Phone:' type="text" value={this.state.newPhone}></MDBInput>
                        </div>
                    </div>
                    <div class='row' id='addin'>
                        <div class='col-9'>
                            <Autocomplete size="small"
                                className="tags-standard" fullWidth={true}
                                options={this.state.titles ? this.state.titles : []}
                                getOptionLabel={(option) => (option.title_name)}
                                value={this.state.selectedTitle ? this.state.selectedTitle : null}
                                onChange={(event, newValue) => {
                                    this.newTitleChange(newValue)
                                }}
                                onKeyPress={(event, newValue) => {
                                    this.titleKeypress(event, newValue)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Title" disabled={this.state.existingUser}
                                        margin="normal" />
                                )}
                            />
                        </div>
                        <div class='col-3'>
                            <MDBBtn color="primary FR" onClick={(event) => {
                                this.addInviteeFromSearch()
                            }}
                                //  disabled={!this.state.selectedTitle || !this.state.newInvitee}>
                                disabled={!this.state.newInvitee}>
                                Add
                            </MDBBtn>
                        </div>
                    </div>

                </div>
                <div>
                    <span className='addInlabel'>Manage Attendees:</span>
                    <div class='row' id='addin'>
                        <div class='col-12'>
                            <MDBInput placeholder='Search Invitees:' 
                            value={this.state.filterString}
                            onChange={(event) => {
                                this.searchFromFilter(event)
                            }} type="text"></MDBInput>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    {this.state.saveProgress ? (<>
                        <LinearProgress /></>) : null}
                </div>
                <div>
                    {records.map((item) => {
                        return <>
                            <MDBCard disabled={this.state.disabled}>
                                <MDBCardBody>
                                    <div class='row'>
                                        <div class='col-5'>
                                            <label className='inviteeName'>{item.first_name + ' ' + item.last_name}</label>
                                            <br />
                                            <span className='inviteeDetails'>{item.title_name}</span>
                                        </div>
                                        <div class='col-7'>
                                            <span className='inviteeDetails FR'>{item.email}</span>
                                            <br />
                                            <span className='inviteeDetails FR'>{item.phone}</span>
                                        </div>
                                    </div>
                                    <div class='row guestRow'>
                                        {item.guests && item.guests.length > 0 ? (item.guests.map((row, index) =>
                                            <>
                                                {row.event_guest_id != null ?
                                                    <div>
                                                        <label className='F13 clg tiles'>{row?.guest_name}{row?.guest_checked_in_status === 1 ? <span
                                                            onClick={(event, newValue) => {
                                                                event.preventDefault();
                                                                this.props?.checkinStatusChange(event, item, row?.checked_in_status, row?.event_guest_id, 0, 1)
                                                            }}
                                                        >" - Checked-in"</span> :
                                                            <MDBBtn className='L05' color="primary" size='sm' onClick={(event, newValue) => {
                                                                event.preventDefault();
                                                                this.props?.checkinStatusChange(event, item, row?.checked_in_status, row?.event_guest_id, 1, 1)
                                                            }}>Check-In</MDBBtn>
                                                        }

                                                        </label>

                                                        <MDBIcon icon="times-circle" className="ml-1 PL04"
                                                                 onClick={(event) => {
                                                                    event.preventDefault();
                                                                    this.deleteGuest(event, row, item.event_invitee_id)
                                                                }} />
                                                    </div> : null
                                                }
                                                {index === item.guests.length - 1 ?
                                                    (<><div>
                                                        <MDBInput placeholder='Add Guest:' onKeyPress={(event) => {
                                                            this.addGuest(event, item)
                                                        }} type="text" className='guest'>
                                                        </MDBInput></div></>) : null
                                                }
                                            </>)) : null}
                                        {(item.guests.length == 0) ?
                                            (<><MDBInput placeholder='Add Guest:'
                                                onKeyPress={(event) => {
                                                    this.addGuest(event, item)
                                                }} type="text" className='guest'></MDBInput></>) : null
                                        }
                                    </div>
                                    <div class='row'>
                                        <div class='col-6'>
                                            <span className='CB FWB'>RSVP:&nbsp;&nbsp;</span>
                                            <MDBIcon icon="check-circle" className="ml-1" style={{
                                                color: item.RSVP_status != null && item.RSVP_status == true ? "green" : "gray",
                                                fontSize: 24
                                            }} onClick={(event, newValue) => {
                                                event.preventDefault();
                                                this.rsvpStatusChange(event, item, item.RSVP_status == true ? null : 1)
                                            }} />
                                        &nbsp;&nbsp;
                                        <MDBIcon icon="times-circle" className="ml-1" onClick={(event, newValue) => {
                                                event.preventDefault();
                                                this.rsvpStatusChange(event, item, item.RSVP_status == false ? null : 0)
                                            }}
                                                style={{
                                                    color: item.RSVP_status != null && item.RSVP_status == false ? "red" : "gray",
                                                    fontSize: 24
                                                }} />
                                        </div>
                                        <div class='col-6'>
                                            {item.checked_in_status ? <label className='lblcheckinMd FR' onClick={(event, newValue) => {
                                                event.preventDefault();
                                                this.checkinStatusChange(event, item, 0)
                                            }}>Checked-In</label>
                                                : <MDBBtn className='L1pt5 FR' onClick={(event, newValue) => {
                                                    event.preventDefault();
                                                    this.checkinStatusChange(event, item, 1)
                                                }} color="primary" size='sm'>Check-In</MDBBtn>}
                                        </div>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </>
                    })}
                    <TablePagination
                        component="div"
                        count={this.state.data.length}
                        rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />

                </div>
            </>
        );
    }
}

export default AttendenceManagementMD;
