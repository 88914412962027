export const validateEmail = (email) => {
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
}

export const validatePhone = (phone) => {
    let phoneRegex = /^[0-9\-\(\)+]+$/;
    return phoneRegex.test(phone);
} 

export const validatePriorityLevel = (pl) => {
    let priorityLevelRegex = /^[1-5]$/;
    return priorityLevelRegex.test(pl);
}