import React, { useState, useEffect } from "react";
import { MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal, MDBContainer, MDBIcon, MDBBtn }
    from "mdb-react-ui-kit";
import Divider from '@material-ui/core/Divider';
import { Component } from "react";
import { withRouter } from 'react-router-dom';
import QueryEventDataJSON from '../../json/query_event_data.json';
import QueryGiftDataJSON from '../../json/query_gift_data.json';
import QueryCardDataJSON from '../../json/query_card_data.json';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';
import QueryDataEvents from './QueryDataEvents';
import QueryDataGifts from './QueryDataGifts';
import QueryDataCards from './QueryDataCards';
import TextField from '@material-ui/core/TextField';

const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

class QueryData extends Component {
    _isMounted = false;
    container = null;
    query_data_json = "";
    constructor(props) {
        super(props);   
        if(this.props.fromPage === "Event")
            this.query_data_json = QueryEventDataJSON;
        else if (this.props.fromPage === "Gift")
            this.query_data_json = QueryGiftDataJSON;
        else if (this.props.fromPage === "Card")
            this.query_data_json = QueryCardDataJSON;

        this.state = {
            divisions: [],
            shows: [],
            companies: [],            
            events: [],
            eventInvitees: [],
            gifts: [],
            giftRecipients: [],
            giftVendors: [],
            giftSenders: [],
            giftItems : [],
            cards:[],
            cardRecipients: [],
            cardSigners: [],
            eventsSelDivisions: [],
            giftsSelDivisions: [],
            cardsSelDivisions: [],
            eventsSelShows: [],
            giftsSelShows: [],
            cardsSelShows: [],
            eventsSelCompanies: [],
            giftsSelCompanies: [],
            cardsSelCompanies: [],
            selEvents: [],
            selEventInvitees: [],
            selGifts: [],            
            selGiftRecipients: [],
            selGiftVendors: [],
            selGiftSenders: [],
            selGiftItems : [],
            selCards: [],
            selCardRecipients: [],
            selCardSigners: [],
            changeDateFrom: null,
            changeDateTo: null,
            selectedFields: {},
            queryDataStatus: false
        }

    }

    handleSideNavActive = (value, routePath) => {
        this.setState({
            activeTab: value
        } );
        this.props.history.push(routePath, this.state);
    }


    componentDidMount() {
        this._isMounted = true;
        // this.childRef = React.createRef();
        // this.state.container = this.childRef.current;
        // this.container = this.props.mainContent();
        this.loadShows();
        this.loadDivisions();
        this.loadCompanies();
        this.loadEvents();
        this.loadEventInvitees();
        this.loadGifts();
        this.loadGiftVendors();
        this.loadGiftItems();
        this.loadGiftRecipients();
        this.loadGiftSenders();
        this.loadCards();
        this.loadCardRecipients();
        this.loadCardSigners();
        //var finalJson = this.state.selectedFields;
        var finalArr = this.state.selectedFields;
        this.query_data_json.sections.map((section) => {
            //var finalArr = finalJson[section.type] || {};
            section.subSections.map((subSection) => {
                var checkedItems = {};
                subSection.values.map((fields) => {
                    if(fields.radio)
                    {
                        var radioGroupItems = finalArr[fields.radioGroupName] || {};
                        radioGroupItems[fields.fieldId] = fields.default;
                        finalArr[fields.radioGroupName] = radioGroupItems;                       
                    }
                    else
                    checkedItems[fields.fieldId] = fields.default;
                });
                //finalArr[subSection.groupName] = checkedItems;
            });
               // finalJson[section.type] = finalArr;
        });
        this.setState({selectedFields : finalArr });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this._isMounted) {

            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
        }
    }

    queryData = () =>{
        //alert("Exported");
        this.setState({queryDataStatus : true});
        var input = this.state.selectedFields;
        var filterJson = {};        

        if(this.props.fromPage === "Event"){          
            filterJson["divisions"] = this.state.eventsSelDivisions.map((division) =>
                                                                         division.value).join(',');
            filterJson["companies"] = this.state.eventsSelCompanies.map((company) =>
                                                                         company.value).join(',');           
            filterJson["shows"] = this.state.eventsSelShows.map((show) =>
                                                                 show.value).join(',');
            filterJson["events"] = this.state.selEvents.map((event) =>
                                                            event.value).join(',');
            filterJson["eventInvitees"] = this.state.selEventInvitees.map((eventInvitee) =>
                                                            eventInvitee.value).join(','); 
            filterJson["dateFrom"] = this.state.changeDateFrom;
            filterJson["dateTo"] = this.state.changeDateTo;                                                                                                                                              
            
        }
        else if(this.props.fromPage === "Gift"){
            filterJson["divisions"] = this.state.giftsSelDivisions.map((division) =>
                                                                         division.value).join(',');
            filterJson["companies"] = this.state.giftsSelCompanies.map((company) =>
                                                                         company.value).join(',');           
            filterJson["shows"] = this.state.giftsSelShows.map((show) =>
                                                                 show.value).join(',');            
            // filterJson["gifts"] = this.state.selGifts.map((gift) =>
            //                                                 gift.value).join(',');
            filterJson["giftRecipients"] = this.state.selGiftRecipients.map((giftRec) =>
                                                                            giftRec.value).join(',');
            filterJson["giftVendors"] = this.state.selGiftVendors.map((giftVendor) =>
                                                                            giftVendor.value).join(',');
            filterJson["giftSenders"] = this.state.selGiftSenders.map((giftSender) =>
                                                                            giftSender.value).join(',');
            filterJson["giftItems"] = this.state.selGiftItems.map((giftItem) =>
                                                                            giftItem.value).join(',');   
            filterJson["dateFrom"] = this.state.changeDateFrom;
            filterJson["dateTo"] = this.state.changeDateTo; 
        }
        else if(this.props.fromPage === "Card"){   
            filterJson["divisions"] = this.state.cardsSelDivisions.map((division) =>
                                                                         division.value).join(',');
            filterJson["companies"] = this.state.cardsSelCompanies.map((company) =>
                                                                         company.value).join(',');           
            filterJson["shows"] = this.state.cardsSelShows.map((show) =>
                                                                 show.value).join(',');                    
            filterJson["cards"] = this.state.selCards.map((card) =>
                                                            card.value).join(',');
            filterJson["cardRecipients"] = this.state.selCardRecipients.map((cardRecipient) =>
                                                                                cardRecipient.value).join(',');
            filterJson["cardSigners"] = this.state.selCardSigners.map((cardSigner) =>
                                                                            cardSigner.value).join(',');
            filterJson["dateFrom"] = this.state.changeDateFrom;
            filterJson["dateTo"] = this.state.changeDateTo; 
        }

        input["Where"] = filterJson;

        console.log("Query Data -->" + JSON.stringify(input));
        var url_path = "";
        if(this.props.fromPage === 'Event')
        url_path =  "/queryEvents";
        else if(this.props.fromPage === 'Gift')
        url_path =  "/queryGifts"; 
        else if(this.props.fromPage === 'Card')
        url_path =  "/queryCards";
        GifterService.getDataParams(Constants.GifterServiceBaseUrl + url_path //+ '?tenantId=' + localStorage.getItem("active_tenant_id"))
        , input )
        .then(response => {
            var buffer = response.data;
            var blob = new Blob([s2ab(atob(response.data))], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            });            
            
            saveAs(blob, "query-data-"+this.props.fromPage+"-"+ this.props.name +"-"+this.props.id.toString()+".xlsx");
            this.setState({queryDataStatus : false});
        },
        (err) => {
            console.log("Query Data Error-->" + err);
            this.setState({queryDataStatus : false});
        });
    }

    loadDivisions = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/orgUnits?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var divisionsArr = response.data.map((division) => ( {text : division.org_unit_name, value: division.org_unit_id.toString()}));
                console.log("Load Divisions-->" + JSON.stringify(divisionsArr));
                if(this._isMounted)
                this.setState({divisions : divisionsArr});
            },
            (err) => {
                console.log("Load Divisions Error-->"+err);
            })
    }

    loadShows = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/shows?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var showsArr = response.data.map((show) => ( {text : show.show_name, value: show.show_id.toString()}));
                console.log("Load Shows-->" + JSON.stringify(showsArr));
                if(this._isMounted)
                this.setState({shows : showsArr});
            },
            (err) => {
                console.log("Load Shows Error-->"+err);
            })
    }

    loadCompanies = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/companiesAll?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var companiesArr = response.data.map((company) => ( {text : company.company_name, value: company.company_id.toString()}));
                console.log("Load Companies-->" + JSON.stringify(companiesArr));
                if(this._isMounted)
                this.setState({companies : companiesArr});
            },
            (err) => {
                console.log("Load Companies Error-->"+err);
            })
    }

    loadEvents = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/eventNames?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var eventsArr = response.data.map((event) => ( {text : event.name, value: event.gift_event_card_id.toString()}));
                console.log("Load Events-->" + JSON.stringify(eventsArr));
                if(this._isMounted)
                this.setState({events : eventsArr});
            },
            (err) => {
                console.log("Load Events Error-->"+err);
            })
    }

    loadEventInvitees = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenantEventInvitees?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var eventInviteesArr = response.data.map((eventInvitee) => ( {text : eventInvitee.name, value: eventInvitee.person_id.toString()}));
                console.log("Load Event Invitees-->" + JSON.stringify(eventInviteesArr));
                 if(this._isMounted)
                this.setState({eventInvitees : eventInviteesArr});
            },
            (err) => {
                console.log("Load Event Invitees Error-->"+err);
        });
    }

    loadGifts = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/giftNames?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var giftsArr = response.data.map((gift) => ( {text : gift.name, value: gift.gift_event_card_id.toString()}));
                console.log("Load Gifts-->" + JSON.stringify(giftsArr));
                if(this._isMounted)
                this.setState({gifts : giftsArr});
            },
            (err) => {
                console.log("Load Gifts Error-->"+err);
            });
    }

    loadGiftSenders = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenantGiftSenders?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var giftSendersArr = response.data.map((giftSender) => ( {text : giftSender.name, value: giftSender.person_id.toString()}));
                console.log("Load Gift Senders-->" + JSON.stringify(giftSendersArr));
                if(this._isMounted)
                this.setState({giftSenders : giftSendersArr});
            },
            (err) => {
                console.log("Load Gift Senders Error-->"+err);
            });
    }

    loadGiftRecipients = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenantGiftRecipients?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var giftRecsArr = response.data.map((giftRecipient) => ( {text : giftRecipient.name, value: giftRecipient.person_id.toString()}));
                console.log("Load Gift Recipients-->" + JSON.stringify(giftRecsArr));
                if(this._isMounted)
                this.setState({giftRecipients : giftRecsArr});
            },
            (err) => {
                console.log("Load Gift Recipients Error-->"+err);
            });
    }

    loadGiftVendors = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/vendors?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var giftVendorsArr = response.data.map((giftVendors) => ( {text : giftVendors.name, value: giftVendors.vendor_id.toString()}));
                console.log("Load Gift Vendors-->" + JSON.stringify(giftVendorsArr));
                if(this._isMounted)
                this.setState({giftVendors : giftVendorsArr});
            },
            (err) => {
                console.log("Load Gift Vendors Error-->"+err);
            });
    }

    loadGiftItems = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenantGifts?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var giftItemsArr = response.data.map((giftItem) => ( {text : giftItem.gift_name, value: giftItem.gift_id.toString()}));
                console.log("Load Gift Items-->" + JSON.stringify(giftItemsArr));
                if(this._isMounted)
                this.setState({giftItems : giftItemsArr});
            },
            (err) => {
                console.log("Load Gift Items Error-->"+err);
            });
    }

    loadCards = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/cardRecordNames?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var cardsArr = response.data.map((card) => ( {text : card.name, value: card.gift_event_card_id.toString()}));
                console.log("Load Cards-->" + JSON.stringify(cardsArr));
                if(this._isMounted)
                this.setState({cards : cardsArr});
            },
            (err) => {
                console.log("Load Cards Error-->"+err);
            });
    }

    loadCardRecipients = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenantCardRecipients?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var cardRecipientsArr = response.data.map((cardRecipient) => ( {text : cardRecipient.name, value: cardRecipient.person_id.toString()}));
                console.log("Load Card Recipients-->" + JSON.stringify(cardRecipientsArr));
                if(this._isMounted)
                this.setState({cardRecipients : cardRecipientsArr});
            },
            (err) => {
                console.log("Load Card Recipients Error-->"+err);
            });
    }

    loadCardSigners = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/cardSignerList?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var cardSignersArr = response.data.map((cardSigner) => ( {text : cardSigner.first_name+" "+cardSigner.last_name, value: cardSigner.person_id.toString()}));
                console.log("Load Card Signers-->" + JSON.stringify(cardSignersArr));
                if(this._isMounted)
                this.setState({cardSigners : cardSignersArr});
            },
            (err) => {
                console.log("Load Card Signers Error-->"+err);
            });
    }

    handleFieldsChecked = (sectionType, subSectionGroupName, fieldId, event) => {
        var currState = this.state.selectedFields;
        currState[subSectionGroupName][fieldId] = event.target.checked;
        this.setState({selectedFields : currState});
    }
    handleRadioFieldsChecked = (sectionType, subSectionGroupName, fieldId, radioGroupName, event) => {
        var currState = this.state.selectedFields;
        var groupFields = currState[radioGroupName];
        for (var key in groupFields) {
                if (key === fieldId) {                  
                    groupFields[key] = true;
                }
                else
                groupFields[key] = false;
            }
        currState[radioGroupName] = groupFields;
        //currState[subSectionGroupName][fieldId] = event.target.checked;
        this.setState({selectedFields : currState});
    }

    handleChangeEvents = (selectedEvents) => {
        this.setState({
                selEvents: selectedEvents                
            });
         
    }

    handleChangeEventInvitees = (selectedEventInvitees) => {
        this.setState({
            selEventInvitees: selectedEventInvitees                
        });
    }

    handleChangeGifts = (selectedGifts) => {
    
        this.setState({
            selGifts: selectedGifts                 
        });  
                
    }

    handleChangeGiftVendors = (selectedGiftVendors) => {
    
        this.setState({
            selGiftVendors: selectedGiftVendors                 
        });  
                
    }

    handleChangeGiftItems = (selectedGiftItems) => {
    
        this.setState({
            selGiftItems: selectedGiftItems                 
        });  
                
    }

    handleChangeGiftSenders = (selectedGiftSenders) => {
    
        this.setState({
            selGiftSenders: selectedGiftSenders                 
        });  
                
    }    

    handleChangeGiftRecipients = (selectedGiftRecipients) => {
    
        this.setState({
            selGiftRecipients: selectedGiftRecipients                 
        });  
                
    }   

    handleChangeCards = (selectedCards) => {
            
        this.setState({
            selCards: selectedCards             
        });                   
    }
    handleChangeCardSigners = (selectedCardSigners) => {
    
        this.setState({
            selCardSigners: selectedCardSigners                 
        });  
                
    }    

    handleChangeCardRecipients = (selectedCardRecipients) => {
    
        this.setState({
            selCardRecipients: selectedCardRecipients                 
        });  
                
    }     

    handleChangeDivisions = (selectedDivisions) => {
        var page = 1, pageSize = 25;
        var selDiv = selectedDivisions.map((division) =>
                         division.value).join(',');
        if(selDiv == "")
        selDiv = null;
        if(this.props.fromPage === "Event")
            {
            this.setState({
                eventsSelDivisions: selectedDivisions                
            });
         
            }
        else if(this.props.fromPage === "Gift")
            {
                this.setState({
                    giftsSelDivisions: selectedDivisions                 
                });  
                       
            }
        else if(this.props.fromPage === "Card")
            {
                this.setState({
                   cardsSelDivisions: selectedDivisions             
                });                   
            }
    }

    handleChangeShows = (selectedShows) => {

        if(this.props.fromPage === "Event")
        {
        this.setState({
            eventsSelShows: selectedShows                
        });
     
        }
    else if(this.props.fromPage === "Gift")
        {
            this.setState({
                giftsSelShows: selectedShows                 
            });  
                   
        }
    else if(this.props.fromPage === "Card")
        {
            this.setState({
               cardsSelShows: selectedShows             
            });                   
        }
    }

    handleChangeCompanies = (selectedCompanies) => {

        if(this.props.fromPage === "Event")
        {
        this.setState({
            eventsSelCompanies: selectedCompanies                
        });
     
        }
    else if(this.props.fromPage === "Gift")
        {
            this.setState({
                giftsSelCompanies: selectedCompanies                 
            });  
                   
        }
    else if(this.props.fromPage === "Card")
        {
            this.setState({
               cardsSelCompanies: selectedCompanies             
            });                   
        }
    }

    handleChangeDateFrom = (event, newValue) => {
        //this.props.handleChangeDateFrom(event.target.value);
        this.setState({
            changeDateFrom: event.target.value              
        });
        
    }

    handleChangeDateTo = (event, newValue) => {
       // this.props.handleChangeDateTo(event.target.value);
       this.setState({
            changeDateTo: event.target.value              
        });
    }
    

    render(){
        return (
            <>
            <div>
                <div className="fst-italic mat-sub-section col-12">
                    Generate a sorted Data Table grouped by a single Data Point. Filter the results by providing other data points. Exported to Excel spreadsheet.
                </div>
                <div className="h6 fw-bold margin-ver-none padding-ver"> 
                    {this.query_data_json.name}
                </div>
                <div className="mat-sub-section fst-italic"> 
                    {this.query_data_json.value}
                </div>
                {
                    this.query_data_json.sections.map((section) => {
                        return <div > 
                            {/* className={section.fullPage ? "row" : ""} */}
                                    <div className="h6 fw-bold margin-ver-none padding-ver"> 
                                        {section.name}
                                    </div>
                                    <div className="row">
                                    
                                    {/* {section.values.map((value) => {
                                        <> */}
                                        {section.subSections.map((subSection) => {
                                            return <>                                                
                                                    <div className={"padding-hor-25 col-"+ 12/subSection.col}>
                                                    <div className={subSection.newSection ? "padding-ver margin-top" : "padding-ver"}>
                                                        <div className={subSection.section ? "h6 fw-bold margin-hor-minus-12 padding-ver" : "mat-sub-section-header fst-italic padding-hor-15"}>
                                                            {subSection.name}
                                                        </div>
                                                        {subSection.values.map((fields) => {
                                                            return <> 
                                                            {fields.radio !== true ?
                                                            <div className="mat-sub-section">
                                                                <div className="flexBox-plain align-center-vertical">
                                                                    <input type ="checkbox" value={section.type+ "-" +subSection.groupName+"-" + fields.fieldId} 
                                                                        checked={this.state.selectedFields[subSection.groupName]?.[fields.fieldId] || false} 
                                                                        onChange={this.handleFieldsChecked.bind(this, section.type, subSection.groupName, fields.fieldId )}/>
                                                                    <label className="padding-left-3">{fields.fieldName}</label>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="mat-sub-section">    
                                                                <div className="flexBox-plain align-center-vertical">
                                                                    <input type ="radio" value={section.type+ "-" +fields.radioGroupName+"-" + fields.fieldId} 
                                                                        checked={this.state.selectedFields[fields.radioGroupName]?.[fields.fieldId] || false} 
                                                                        name={"radio-button-group-"+ fields.radioGroupName}
                                                                        onChange={this.handleRadioFieldsChecked.bind(this, section.type, subSection.groupName, fields.fieldId, fields.radioGroupName )}/>
                                                                    <label className="padding-left-3">{fields.fieldName}</label> 
                                                                    </div>
                                                            </div>
                                                                }
                                                            </>})
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            })
                                        }                                        
                                    </div>
                               </div> 
                            })
                        }

                    <div className="row padding-ver fs-italic">
                        <div className="col-3">{this.props.fromPage}s between: </div>  
                        <div className="col-3 padding-hor">
                        <TextField
                            value={this.state.changeDateFrom} 
                            id="fromDate"
                            label=""                           
                            type="date"                            
                            className="date-picker"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange= {this.handleChangeDateFrom.bind(this)}
                            /> 
                        </div>
                        <div className="col-half padding-hor">and</div>
                        <div className="col-3 padding-hor">
                            {/* <span className="padding-hor"> and </span> */}
                            <TextField
                            value={this.state.changeDateTo} 
                            id="toDate"
                            label=""                            
                            type="date"                            
                            className="date-picker"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange= {this.handleChangeDateTo.bind(this)}
                        />
                        </div>
                                            
                    </div>

                    {this.props.fromPage === "Event" ? 
                        <QueryDataEvents fromPage={this.props.fromPage}
                            divisions = {this.state.divisions}
                            shows = {this.state.shows}
                            companies = {this.state.companies}
                            events = {this.state.events}
                            eventInvitees= {this.state.eventInvitees}     
                            selDivisions = {this.state.eventsSelDivisions}
                            selShows = {this.state.eventsSelShows}                                       
                            selCompanies = {this.state.eventsSelCompanies}  
                            selEvents= {this.state.selEvents} 
                            selEventInvitees= {this.state.selEventInvitees}                                     
                            queryData = {this.queryData.bind(this)}
                            handleChangeEvents = {this.handleChangeEvents.bind(this)}
                            handleChangeEventInvitees = {this.handleChangeEventInvitees.bind(this)}
                            handleChangeDivisions = {this.handleChangeDivisions.bind(this)}
                            handleChangeShows = {this.handleChangeShows.bind(this)}
                            handleChangeCompanies = {this.handleChangeCompanies.bind(this)}
                            /> : 
                        (this.props.fromPage === "Gift" ? 
                            <QueryDataGifts fromPage={this.props.fromPage}
                                divisions = {this.state.divisions}
                                shows = {this.state.shows}
                                companies = {this.state.companies} 
                                // events = {this.state.events}
                                // eventInvitees= {this.state.eventInvitees} 
                                gifts = {this.state.gifts}
                                giftVendors = {this.state.giftVendors}
                                giftItems={this.state.giftItems}
                                giftRecipients={this.state.giftRecipients}
                                giftSenders={this.state.giftSenders}
                                selDivisions = {this.state.giftsSelDivisions}
                                selShows = {this.state.giftsSelShows}                                       
                                selCompanies = {this.state.giftsSelCompanies}  
                                selGifts= {this.state.selGifts} 
                                selGiftItems= {this.state.selGiftItems}   
                                selGiftRecipients= {this.state.selGiftRecipients}   
                                selGiftSenders= {this.state.selGiftSenders}  
                                selGiftVendors = {this.state.selGiftVendors} 
                                queryData = {this.queryData.bind(this)}
                                handleChangeGifts = {this.handleChangeGifts.bind(this)}
                                handleChangeGiftVendors = {this.handleChangeGiftVendors.bind(this)}
                                handleChangeGiftSenders = {this.handleChangeGiftSenders.bind(this)}
                                handleChangeGiftItems = {this.handleChangeGiftItems.bind(this)}
                                handleChangeGiftRecipients = {this.handleChangeGiftRecipients.bind(this)}                                
                                handleChangeDivisions = {this.handleChangeDivisions.bind(this)}
                                handleChangeShows = {this.handleChangeShows.bind(this)}
                                handleChangeCompanies = {this.handleChangeCompanies.bind(this)}                                    
                                /> :
                            <QueryDataCards fromPage={this.props.fromPage}
                                divisions = {this.state.divisions}
                                shows = {this.state.shows}
                                companies = {this.state.companies} 
                                cards = {this.state.cards}
                                cardRecipients = {this.state.cardRecipients}
                                cardSigners = {this.state.cardSigners}                                
                                selDivisions = {this.state.cardsSelDivisions}
                                selShows = {this.state.cardsSelShows}                                       
                                selCompanies = {this.state.cardsSelCompanies}  
                                selCards= {this.state.selCards} 
                                selCardRecipents= {this.state.selCardRecipents}   
                                selCardSigners= {this.state.selCardSigners}   
                                queryData = {this.queryData.bind(this)}
                                handleChangeCards = {this.handleChangeCards.bind(this)}
                                handleChangeCardSigners = {this.handleChangeCardSigners.bind(this)}                                     
                                handleChangeCardRecipients = {this.handleChangeCardRecipients.bind(this)} 
                                handleChangeDivisions = {this.handleChangeDivisions.bind(this)}
                                handleChangeShows = {this.handleChangeShows.bind(this)}
                                handleChangeCompanies = {this.handleChangeCompanies.bind(this)} 
                                                                   
                            />)
                    }  

                    <div className="padding-hor flexAlignRight">
                        <div className="padding-hor">
                            <MDBBtn onClick={this.queryData.bind(this)}>
                                <MDBIcon className={this.state.queryDataStatus ? "" : "d-none"} icon="spinner" spin/>  Query Data
                            </MDBBtn>
                        </div>
                        <div className="padding-hor">
                            <MDBBtn onClick={this.props.toggleMaterials}>
                                Cancel
                            </MDBBtn>
                        </div>
                    </div>
            </div>
         </>
        );
    }

}

export default QueryData;