import React, { useState } from 'react'
import SampleForm from './SampleForm'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({

    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    }
}))

export default function FormElement(props) {

    const { row, toggle } = props;
    const classes = useStyles();

    return (
        <>
            {row ? <div>
                {!row.newUser ? <h3 className='MLN1'>Edit {row.name ? row.name : row.first_name + ' ' + row.last_name} : </h3>
                    : <h3 className='MLN1'>Add</h3>}
                <h6 className="pb-3 MLN1">Adjustments made here certain only to this  record </h6>
                <SampleForm formData={row} editCallback={props.editCallback} toggle1={toggle} />
            </div> : null}</>


    )
}