import React from 'react';
import BasicInfo from './BasicInfo';
import Gifting from './Gifting';
import CardSigner from './CardSigner';
import Contact from './Contact';
import Company from './Company';
import * as Constants from '../../../constants/constants';
import GifterService from '../../../services/service';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import SaveModal from './SaveModal';
import { Prompt } from 'react-router-dom';
import './NewPerson.scss';

class NewPerson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personDetails: {
                person_id: null,
                is_active: true,
                is_card_signer: false,
                priority_level: 1
            },
            cardSignerList: [],
            newCardSignerList: [],
            personCardSignerList: {
                card_signers: []
            },
            dropDownList: {
                company: [],
                division: [],
                category: [],
                title: [],
                addressType: []
            },
            isFetchingDivisions: false,
            isFetchingTitles: false,
            isFetchingCompanies: false,
            isBlocking: false,
            isPosting: false,
            isFetchingCategories: false,
            isFetchingCardSignerList: false,
            isFetchingPersonCardSignerList: false,
            isPersonDetailsLoading: false,
            isFetchingAddressTypes: false,
            isPostSaveModalOpen: false,
            isPosted: false,
            saveState: "",
            saveSuccessMessage: "The changes have been saved successfully!",
            saveErrorMessage: "We couldn't save your changes!",
            saveMandatoryFields: "Please fill the mandatory fields!",
            saveUniqueCompanySet: "Company Entries should be unique!"
        }
    }

    formatPersonDetails = () => {
        let personDetails = this.state.personDetails;
        let phone = {
            "person_phone_id": null,
            "phone": "",
            "is_primary": 0,
            "is_active": 0
        };

        if (personDetails?.phone_list?.length === 0) {
            personDetails?.phone_list?.push(phone);
        } else if (!personDetails.phone_list) {
            personDetails.phone_list = [phone];
        }

        let category = {
            "person_category_id": null,
            "category_id": null,
            "category_name": "",
            "is_primary": 0,
            "is_active": 0
        }

        if (personDetails?.category_list?.length === 0) {
            personDetails?.category_list?.push(category);
        } else if (!personDetails.category_list) {
            personDetails.category_list = [category];
        }

        let email = {
            "person_email_id": null,
            "isValid": true,
            "email": "",
            "is_primary": 0,
            "is_active": 0
        };

        if (personDetails?.email_list?.length === 0) {
            personDetails?.email_list?.push(email);
        } else if (!personDetails.email_list) {
            personDetails.email_list = [email];
        } else if (personDetails.email_list){
           let personEmails = [...personDetails.email_list];
           personDetails.email_list = personEmails.map( email => {
               email.isValid = true;
               return email;
           })
        }

        let address = {
            "person_extension_id": null,
            "address": "",
            "address_type": "",
            "address_type_name": "",
            "is_primary": 0,
            "is_active": 0
        };

        if (personDetails?.address_list?.length === 0) {
            personDetails?.address_list?.push(address);
        } else if (!personDetails.address_list) {
            personDetails.address_list = [address];
        }

        let company = {
            "p_comp_div_title_id": null,
            "company_id": null,
            "company_name": null,
            "division_id": null,
            "division_name": null,
            "title_id": null,
            "title_name": null,
            "is_primary": 0,
            "is_active": 0
        };

        if (personDetails?.comp_div_title_list?.length === 0) {
            personDetails?.comp_div_title_list?.push(company);
        } else if (!personDetails.comp_div_title_list) {
            personDetails.comp_div_title_list = [company];
        }

        personDetails.is_active = (personDetails?.is_active === 1 || personDetails?.is_active) ? true : false;
        personDetails.is_card_signer = personDetails?.is_card_signer === 1 ? true : false;
        this.setState({ personDetails: personDetails }, () => {
            this.updateCategoryDropDownList();
        });
    }

    formatPersonDetailsBeforeSave = () => {
        let personDetails = this.state.personDetails;
        let comp_div_title_list = personDetails?.comp_div_title_list?.filter(item => item.company_id || item.division_id || item.title_id);
        personDetails.comp_div_title_list = comp_div_title_list ? comp_div_title_list : [];
        if (personDetails.comp_div_title_list.length !== 0 &&
            (personDetails.comp_div_title_list.length === 1 || !personDetails.comp_div_title_list.some(item => item.is_primary === 1))) {
            personDetails.comp_div_title_list[0].is_active = 1;
            personDetails.comp_div_title_list[0].is_primary = 1;
        }
        let address_list = personDetails?.address_list?.filter(item => item?.address_type && item?.address);
        personDetails.address_list = address_list ? address_list : [];
        if (personDetails.address_list.length !== 0 &&
            (personDetails.address_list.length === 1 || !personDetails.address_list.some(item => item.is_primary === 1))) {
            personDetails.address_list[0].is_active = 1;
            personDetails.address_list[0].is_primary = 1;
        }
        let email_list = personDetails?.email_list?.filter(item => item?.email);
        personDetails.email_list = email_list ? email_list : [];
        if (personDetails.email_list.length !== 0 &&
            (personDetails.email_list.length === 1 || !personDetails.email_list.some(item => item.is_primary === 1))) {
            personDetails.email_list[0].is_active = 1;
            personDetails.email_list[0].is_primary = 1;
        }
        let category_list = personDetails?.category_list?.filter(item => item?.category_id);
        personDetails.category_list = category_list ? category_list : [];
        if (personDetails.category_list.length !== 0 &&
            (personDetails.category_list.length === 1 || !personDetails.category_list.some(item => item.is_primary === 1))) {
            personDetails.category_list[0].is_active = 1;
            personDetails.category_list[0].is_primary = 1;
        }
        let phone_list = personDetails?.phone_list?.filter(item => item?.phone);
        personDetails.phone_list = phone_list ? phone_list : [];
        if (personDetails.phone_list.length !== 0 && (
            personDetails.phone_list.length === 1 || !personDetails.phone_list.some(item => item.is_primary === 1))) {
            personDetails.phone_list[0].is_active = 1;
            personDetails.phone_list[0].is_primary = 1;
        }
        personDetails.priority_level = personDetails.priority_level ? (parseInt(personDetails.priority_level) ? parseInt(personDetails.priority_level) : 1) : 1;
        return personDetails;
    }

    handleOnSave = (e) => {
        e.preventDefault();
        let personDetails = this.formatPersonDetailsBeforeSave();
        personDetails.card_signers_list = this.state.personCardSignerList?.card_signers;
        console.log("param", personDetails);
        this.setState({ isBlocking: false });
        if (personDetails.first_name && personDetails.last_name) {
            if (this.isCompanySetAlreadyAvailable()) {
                this.setState({ isPostSaveModalOpen: true, saveState: "companyUnique" });
            } else if(!personDetails.email_list.some(item => !item.isValid)) {
                this.setState({ isPosting: true });
                GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/person', personDetails)
                    .then((response) => {
                        console.log("post response", response);
                        this.setState({ isPostSaveModalOpen: true, saveState: "success", isPosted: true, isPosting: false })
                        this.fetchPersonDetails(response.data[0].person_id);
                        this.fetchPersonCardSignerList(response.data[0].person_id);
                    },
                        (err) => {
                            this.setState({ isPostSaveModalOpen: true, saveState: "error", isPosting: false })
                            console.log("People Management add people error: " + err);
                        });
            }
        } else {
            this.setState({ isPostSaveModalOpen: true, saveState: "mandatory" })
        }
    }

    handlePostSaveClose = () => {
        this.setState({ isPostSaveModalOpen: false });
    }

    handleOnChange = (event, field) => {
        this.setState({ isBlocking: true });
        const { value, checked } = event.target;
        const assignValue = (field === "is_card_signer") ? checked : value;
        this.setState(prevState => ({
            personDetails: {
                ...prevState.personDetails,
                [field]: assignValue
            }
        }), () => {
            console.log("personDetails", this.state.personDetails);
        })
    }

    handleNewSigner = (value, action) => {
        this.setState({ isBlocking: true });
        if (action === "add" && value) {
            let newSigner = {
                "person_card_signer_id": null,
                "card_signer_id": value?.value,
                "card_signer_name": value?.text,
                "signer_is_active": 1,
                "canDelete": true
            }
            let newArray = this.state.personCardSignerList;
            newArray?.card_signers.push(newSigner);
            this.setState({ personCardSignerList: newArray });
        } else if (action === "delete" && value) {
            if (value?.canDelete) {
                let array = this.state.personCardSignerList;
                let newCard_signers = array?.card_signers.filter(item => item.card_signer_id !== value.card_signer_id);
                delete array?.card_signers;
                array.card_signers = newCard_signers;
                this.setState({ personCardSignerList: array });
            } else {
                let array = this.state.personCardSignerList;
                let newCard_signers = this.state.personCardSignerList?.card_signers?.map(item => {
                    if (item.card_signer_id === value.card_signer_id) {
                        item.signer_is_active = 0;
                        return item;
                    } return item;
                });
                delete array?.card_signers;
                array.card_signers = newCard_signers;
                this.setState({ personCardSignerList: array });
            }
        } else if (action === "redo" && value) {
            let array = this.state.personCardSignerList;
            let newCard_signers = this.state.personCardSignerList?.card_signers?.map(item => {
                if (item.card_signer_id === value.card_signer_id) {
                    item.signer_is_active = 1;
                    return item;
                } return item;
            });
            delete array?.card_signers;
            array.card_signers = newCard_signers;
            this.setState({ personCardSignerList: array });
        }
        this.removeExistingCardSigner();
    }

    handleContactInfo = (value, field, action) => {
        this.setState({ isBlocking: true });
        if (action === "add") {
            let newArray = this.state.personDetails;
            newArray[field].push(value);
            this.setState({ personDetails: newArray });
        } else if (action === "delete") {
            let array = this.state.personDetails;
            let newArray = array[field].filter(item => item !== value);
            delete array[field];
            array[field] = newArray;
            this.setState({ personDetails: array });
        }
    }

    isCompanySetAlreadyAvailable = () => {
        if (this.state.personDetails?.comp_div_title_list && this.state.personDetails?.comp_div_title_list?.length !== 0) {
            return this.state.personDetails?.comp_div_title_list.some(list => {
                return this.state.personDetails?.comp_div_title_list.some(item => {
                    let isAvailable = (item !== list && item.company_id === list.company_id &&
                        item.division_id === list.division_id && item.title_id === list.title_id) ? true : false;
                    return isAvailable;
                })
            })
        }
    }

    handleCompanyInputChange = (field, value, object) => {
        this.setState({ isBlocking: true });
        let array = this.state.personDetails;
        let newArray = [];
        if (array?.comp_div_title_list && array?.comp_div_title_list?.length !== 0) {
            newArray = array?.comp_div_title_list?.map(item => {
                if (item === object) {
                    switch (field) {
                        case "company":
                            item.company_id = value?.value;
                            item.company_name = value?.text;
                            break;
                        case "division":
                            item.division_id = value?.value;
                            item.division_name = value?.text;
                            break;
                        case "title":
                            item.title_id = value?.value;
                            item.title_name = value?.text;
                            break;
                    }
                    return item;
                } else return item;
            })
        } else {
            newArray?.push({
                "p_comp_div_title_id": null,
                "company_id": null,
                "company_name": null,
                "division_id": null,
                "division_name": null,
                "title_id": null,
                "title_name": null,
                "is_primary": 0,
                "is_active": 0
            });
            switch (field) {
                case "company":
                    newArray[0].company_id = value.value;
                    newArray[0].company_name = value.text;
                    break;
                case "division":
                    newArray[0].division_id = value.value;
                    newArray[0].division_name = value.text;
                    break;

                case "title":
                    newArray[0].title_id = value.value;
                    newArray[0].title_name = value.text;
                    break;
            }
        }
        this.setState(prevState => ({
            personDetails: {
                ...prevState.personDetails,
                comp_div_title_list: newArray
            }
        }));
    }

    handleContactInputChange = (value, field, obj, sub = null) => {
        this.setState({ isBlocking: true });
        switch (field) {
            case "phone_list":
                let newPhoneList = this.state.personDetails?.phone_list ? this.state.personDetails?.phone_list?.map(phone => {
                    if (phone === obj) {
                        phone.phone = value;
                        return phone;
                    } else return phone;
                }) : [{
                    "is_active": 0,
                    "is_primary": 0,
                    "person_phone_id": null,
                    "phone": value
                }]
                this.setState(prevState => ({
                    personDetails: {
                        ...prevState.personDetails,
                        [field]: newPhoneList
                    }
                }));
                break;

            case "category_list":
                let newCategoryList = this.state.personDetails?.category_list ? this.state.personDetails?.category_list?.map(category => {
                    if (category === obj) {
                        category.category_id = value?.value;
                        category.category_name = value?.text;
                        return category;
                    } else return category;
                }) : [{
                    "category_id": value.value,
                    "category_name": value.text,
                    "is_active": 0,
                    "is_primary": 0,
                    "person_category_id": null
                }];
                this.setState(prevState => ({
                    personDetails: {
                        ...prevState.personDetails,
                        [field]: newCategoryList
                    }
                }), () => {
                    this.updateCategoryDropDownList();
                });
                break;

            case "email_list":
                let newEmailList = this.state.personDetails?.email_list ? this.state.personDetails?.email_list?.map(email => {
                    if (email === obj) {
                        email.email = value;
                        email.isValid = sub;
                        return email;
                    } else return email;
                }) : [{
                    "email": value,
                    "is_active": 0,
                    "is_primary": 0,
                    "person_email_id": null,
                    "isValid": true,
                }]
                this.setState(prevState => ({
                    personDetails: {
                        ...prevState.personDetails,
                        [field]: newEmailList
                    }
                }));
                break;

            case "address_list":
                let newAddressList = this.state.personDetails?.address_list ? this.state.personDetails?.address_list?.map(address => {
                    if (address === obj) {
                        if (sub === "address_type") {
                            address.address_type = value?.value;
                            address.address_type_name = value?.text;
                        } else {
                            address[sub] = value;
                        }
                        return address;
                    } else return address;
                }) : [{
                    "address_type_name": sub === "address_type" ? value.text : null,
                    "address_type": sub === "address_type" ? value.value : null,
                    "address": sub === "address" ? value : null,
                    "is_active": 0,
                    "is_primary": 0,
                    "person_extension_id": 0
                }]
                this.setState(prevState => ({
                    personDetails: {
                        ...prevState.personDetails,
                        [field]: newAddressList
                    }
                }));
                break;
        }

    }

    handleContactListState = (field, array) => {
        this.setState({ isBlocking: true });
        let list = this.state.personDetails;
        delete list[field];
        list[field] = array;
        this.setState({ personDetails: list });
    }

    componentDidMount() {
        if (this.props?.history?.location?.pathname?.includes('editPerson')) {
            this.fetchPersonDetails(this.props.match.params.id);
            this.fetchPersonCardSignerList(this.props.match.params.id);
        }
        this.formatPersonDetails();
        this.fetchDivisions();
        this.fetchTitles();
        this.fetchCompanies();
        this.fetchCategories();
        this.fetchCardSignerList();
        this.fetchAddressTypes()
    }

    fetchAddressTypes = () => {
        this.setState({ isFetchingAddressTypes: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/addressTypes?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isFetchingAddressTypes: false });
                var formattedArray = response.data.map((addressType) => ({ value: addressType.address_type_id, text: addressType.address_type.toString() }));
                this.setState(prevState => ({
                    dropDownList: {
                        ...prevState.dropDownList,
                        addressType: formattedArray
                    }
                }))
            },
                (err) => {
                    this.setState({ isFetchingAddressTypes: false });
                    console.log("Load Address Types Error-->" + err);
                })
    }

    fetchPersonDetails = (id) => {
        if (!this.state.isPosted) {
            this.setState({ isPersonDetailsLoading: true });
        }
        GifterService.getData(Constants.GifterServiceBaseUrl + '/personDetails?personId=' + id)
            .then((response) => {
                this.setState({ personDetails: response.data[0], isPersonDetailsLoading: false }, () => {
                    this.formatPersonDetails();
                });
            },
                (err) => {
                    this.setState({ isPersonDetailsLoading: false });
                    console.log("People Management get person details error: " + err);
                });
    }

    fetchCategories = () => {
        this.setState({ isFetchingCategories: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/categories?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isFetchingCategories: false });
                var formattedArray = response.data.map((category) => ({ value: category.category_id, text: category.category_name.toString() }));
                this.setState(prevState => ({
                    dropDownList: {
                        ...prevState.dropDownList,
                        category: formattedArray
                    }
                }))
            },
                (err) => {
                    this.setState({ isFetchingCategories: false });
                    console.log("Load Categories Error-->" + err);
                })
    }

    fetchCompanies = () => {
        this.setState({ isFetchingCompanies: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/companies?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isFetchingCompanies: false });
                var formattedArray = response.data.map((company) => ({ value: company.company_id, text: company.company_name.toString() }));
                this.setState(prevState => ({
                    dropDownList: {
                        ...prevState.dropDownList,
                        company: formattedArray
                    }
                }))
            },
                (err) => {
                    this.setState({ isFetchingCompanies: false });
                    console.log("Load Companies Error-->" + err);
                })
    }

    fetchDivisions = () => {
        this.setState({ isFetchingDivisions: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/divisions?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isFetchingDivisions: false });
                var formattedArray = response.data.map((division) => ({ value: division.Division_id, text: division.Division_name.toString() }));
                this.setState(prevState => ({
                    dropDownList: {
                        ...prevState.dropDownList,
                        division: formattedArray
                    }
                }))
            },
                (err) => {
                    this.setState({ isFetchingDivisions: false });
                    console.log("Load Divisions Error-->" + err);
                })
    }

    fetchTitles = () => {
        this.setState({ isFetchingTitles: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/titles?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                this.setState({ isFetchingTitles: false });
                var formattedArray = response.data.map((title) => ({ value: title.title_id, text: title.title_name.toString() }));
                this.setState(prevState => ({
                    dropDownList: {
                        ...prevState.dropDownList,
                        title: formattedArray
                    }
                }))
            },
                (err) => {
                    this.setState({ isFetchingTitles: false });
                    console.log("Load Titles Error-->" + err);
                })
    }

    fetchCardSignerList = () => {
        this.setState({ isFetchingCardSignerList: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/cardSignerList?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((list) => ({ value: list.person_id, text: list.first_name + " " + list.last_name }));
                this.setState({ cardSignerList: formattedArray, isFetchingCardSignerList: false, newCardSignerList: formattedArray });
            },
                (err) => {
                    this.setState({ isFetchingCardSignerList: false });
                    console.log("Load card signer list Error-->" + err);
                })
    }

    fetchPersonCardSignerList = (id) => {
        if (this.state.isPosted) {
            this.setState({ isPosted: false });
        } else {
            this.setState({ isFetchingPersonCardSignerList: true });
        }
        GifterService.getData(Constants.GifterServiceBaseUrl + '/personCardSigners?personId=' + id)
            .then(response => {
                this.setState({ personCardSignerList: response.data[0] ? response.data[0] : { card_signers: [] }, isFetchingPersonCardSignerList: false });
                this.removeExistingCardSigner();
            },
                (err) => {
                    this.setState({ isFetchingPersonCardSignerList: false });
                    console.log("Load person card signer list Error-->" + err);
                })
    }

    goBack = () => {
        this.props?.history?.push('/manageUsers', 'MP');
    }

    removeExistingCardSigner = () => {
        const { personCardSignerList } = this.state;
        let cardSignerList = this.state.cardSignerList;
        personCardSignerList?.card_signers.map(cardSigner => {
            cardSignerList = cardSignerList.filter(list => {
                return list['value'] !== cardSigner['card_signer_id']
            });
        });
        this.setState({ newCardSignerList: cardSignerList });
    }

    updateCategoryDropDownList = () => {
        let newDropDown = this.state.dropDownList.category.filter(item => {
            if (!this.state.personDetails.category_list.some(list => list.category_id === item.value)) {
                return true;
            } else {
                return false;
            }
        });
        this.setState(prevState => ({
            dropDownList: {
                ...prevState.dropDownList,
                category: newDropDown
            }
        }))
    }

    render() {
        let renderFlag = this.state.isPersonDetailsLoading || this.state.isFetchingCategories ||
            this.state.isFetchingTitles || this.state.isFetchingDivisions || this.state.isFetchingCompanies ||
            this.state.isFetchingCardSignerList || this.state.isFetchingPersonCardSignerList ||
            this.state.isFetchingAddressTypes;
        let modalMessage = this.state.saveState === 'success' ? this.state.saveSuccessMessage : this.state.saveState === 'error' ?
            this.state.saveErrorMessage :  this.state.saveState === 'mandatory' ? this.state.saveMandatoryFields :  this.state.saveState === 'companyUnique' ? 
            this.state.saveUniqueCompanySet : "";
        return (
            <div>
                <Prompt when={this.state.isBlocking} message={"You have unsaved changes. Are you sure you want to leave this page?"} />
                {
                    renderFlag ? <div className="personScreenLoader">
                        <CircularProgress />
                    </div> :
                        <form autocomplete="off" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onSubmit={(e) => this.handleOnSave(e)}>
                            <div className="peopleAddContainer">
                                <SaveModal open={this.state.isPostSaveModalOpen} handleClose={this.handlePostSaveClose} className={this.state.saveState} message={modalMessage} />
                                <div className="personHeader">
                                    <div onClick={() => this.goBack()}> <ArrowBackIcon /> </div>
                                    <h5 className="margin-tp-lt">Person Details</h5>
                                    <Button variant="contained" size="small" color="primary" disabled={this.state.isPosting} type="submit">{this.state.isPosting ? <CircularProgress size={25} /> : "Save"}</Button>
                                </div>
                                <div className="row" id="newPersonContainer">
                                    <div className="col-4" id="leftContainer">
                                        <BasicInfo personDetails={this.state.personDetails} handleOnChange={this.handleOnChange} />
                                        <Gifting giftAmount={this.state.personDetails?.limit_spent} />
                                        <CardSigner personDetails={this.state.personDetails}
                                            list={this.state.newCardSignerList}
                                            personCardSignerList={this.state.personCardSignerList}
                                            handleNewSigner={this.handleNewSigner}
                                            handleOnChange={this.handleOnChange} />
                                    </div>
                                    <div className="col-8" id="rightContainer">
                                        <Contact personDetails={this.state.personDetails}
                                            list={this.state.dropDownList.category}
                                            addressType={this.state.dropDownList.addressType}
                                            handleContactInfo={this.handleContactInfo}
                                            handleContactInputChange={this.handleContactInputChange}
                                            handleContactListState={this.handleContactListState} />
                                        <Company personDetails={this.state.personDetails}
                                            list={this.state.dropDownList}
                                            handleCompanyInputChange={this.handleCompanyInputChange}
                                            handleContactListState={this.handleContactListState}
                                            handleContactInfo={this.handleContactInfo} />
                                    </div>
                                </div>
                            </div>
                        </form>
                }
            </div>
        );
    }
}

export default NewPerson;