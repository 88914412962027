import * as Constants  from './constants/constants';
import { AuthService } from '@okta/okta-react';
import { createBrowserHistory } from 'history'
import { __esModule } from '@okta/okta-react/dist/Security';
 
const history = createBrowserHistory();
const authService = new AuthService({
  history,
  issuer: Constants.OKTA_ISSUER_URL,
  clientId: Constants.OKTA_CLIENT_ID,
  redirectUri: Constants.OKTA_REDIRECT_URL,
  url: Constants.OKTA_URL
});

export default authService;