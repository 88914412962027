import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import GifterService from '../../services/service';
import * as Constants from '../../constants/constants';
import Alert from '@material-ui/lab/Alert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import '../../index.scss';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 60 + rand();
    const left = 50 + rand();

    return {
        top: `50%`,
        left: `54%`,
        transform: `translate(-${top}%, -${left}%)`,
        height: "35rem"
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 675,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #131313',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "32rem",
        overflowY: "auto",
        fontSize: "13px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    text_input: {
        width: "18rem",
        top: "15px",
        color: "#000000",
        fontSize: "13px"
    },
    bg_color_blue: {
        backgroundColor: "#164f9f"
    },
    text_color: {
        color: "#164f9f"
    },
    drpdwm_wdth_8rem: {
        width: "9rem",
        left: "3rem"
    },
    drpdwm_wdth_10rem: {
        width: "10rem",
        left: "3rem"
    },
    text_color_red: {
        color: "#FF0000"
    },
    drpdwm_top: {
        top: "5px"
    },
    action_btns: {
        textAlign: "end",
        marginTop: "30px"
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function SimpleModal(props) {
    const classes = useStyles();

    const [modalStyle] = React.useState(getModalStyle);
    const [userRoles, setUserRoles] = useState([{
        "name": null,
        "user_id": null,
        "notification_email_id": null,
        "wb_email_id": null,
        "phone": null,
        // "is_active": null,
        "is_active": 1,
        "user_id": null,
        "default_tenat_id": null,
        "tenants": [
            {
                "tenant_id": null,
                "tenant_name": null,
                "permissions": {},
                "access_roles": [
                    {
                        "module_id": null,
                        "module_name": null,
                        "role_id": null,
                        "role_name": null,
                        "shows": [],
                        "divisions": []
                    }
                ]
            }
        ]
    }]);
    const [selectedOU, setSelectedOU] = useState([]);
    const [existingTenant, setExistingTenant] = useState([]);
    const [selectedDefaultTenant, setSelectedDefaultTenant] = useState(null);
    const [shows, setShows] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [roles, setRoles] = useState([]);
    const [modules, setModules] = useState([]);
    const [orgUnits, setOrgUnits] = useState([]);
    const [disableFields, setDisableFields] = useState(false);
    const [userSpecificRoles, setUserSpecificRoles] = useState(false);
    const [errorText, setErrorText] = useState({ "details": "", "access": "" });
    const [savebtnDisabled, setsavebtnDisabled] = useState(false);

    useEffect((props) => {
        loadShowsData();
        loadTenantData();
        loadOrgUnitsData();
        loadRolesData();
        loadModulesData();
        loadUserDetailsForEdit();
    }, [props.type]);

    const submitUserRoles = () => {
        let tempUser = { 'access_roles': [] };
        let validationError = false;
        userRoles.map((user, index) => {
            if (user['name'] === null || user['wb_email_id'] === null || user['notification_email_id'] === null ||
                user['is_active'] === null) {
                setErrorText((error) => ({ ...error, details: "Please fill all the details." }));
                validationError = true;
            } else {
                tempUser['user_id'] = user['user_id'];
                tempUser['user_name'] = user['name'];
                tempUser['wb_email_id'] = user['wb_email_id'];
                tempUser['phone'] = user['phone'];
                tempUser['notification_email_id'] = user['notification_email_id'];
                tempUser['is_active'] = user['is_active'] == 1 ? true : false;
                tempUser['default_tenant_id'] = selectedDefaultTenant;
            }
            if (userSpecificRoles === false && selectedDefaultTenant === null) {
                validationError = true;
                setErrorText({ ...errorText, access: "Please fill all the details." });
            }
            tempUser['is_user_provisioner'] = userSpecificRoles;
            user['tenants'].map(tenant => {
                tenant['access_roles'].map(access => {
                    let accessRoles = {};
                    if (userSpecificRoles === false) {
                        accessRoles['org_units'] = [];
                        accessRoles['shows'] = [];
                        if (tenant['tenant_id'] !== null && access['role_id'] !== null && access['module_id'] !== null && access['divisions'].length > 0) {
                            accessRoles['tenant_id'] = parseInt(tenant['tenant_id']);
                            accessRoles['user_role_id'] = null;
                            accessRoles['module_id'] = parseInt(access['module_id']);
                            // orgUnits.map(ou => {
                            //     access['divisions'].filter(org => {
                            //         if (org === ou['text']) {
                            //             accessRoles['org_units'].push({ org_unit_id: parseInt(ou['value']) });
                            //         }
                            //     });
                            // });
                            accessRoles['org_units'] = access['divisions'].map(div => { return { org_unit_id: parseInt(div.value) } });
                            accessRoles['role_id'] = parseInt(access['role_id']);
                            accessRoles['shows'] = access['shows'].map(show => { return { show_id: parseInt(show.value) } });
                            // shows.map(mShow => {
                            //     access['shows'].filter(show => {
                            //         if (show === mShow['text']) {
                            //             accessRoles['shows'].push({ show_id: parseInt(mShow['value']) });
                            //         }
                            //     });
                            // });
                            tempUser['access_roles'].push(accessRoles);
                        } else {
                            validationError = true;
                            setErrorText({ ...errorText, access: "Please fill all the details." });
                        }
                    }
                });
            });
        });
        if (!validationError) {
            setsavebtnDisabled(true);
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/userAdd', tempUser)
                .then((response) => {
                    clearFormDetails();
                    props.showErrorMessage('success', null);
                    setsavebtnDisabled(false);
                    props.handleClose();
                },
                    (err) => {
                        props.showErrorMessage('failed', err);
                        setsavebtnDisabled(false);
                        props.handleClose();
                        // alert("User update failed." + err);
                    });
        }
    }
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 100,
    });
    const getUserShow = (optionsData, type) => {
        let optionItems = null;
        if (optionsData.length > 0) {
            optionItems = optionsData.map(data => {
                return (
                    <MenuItem value={data.value} key={data.text}>{data.text}</MenuItem>
                )
            });
        }
        return optionItems;
    }
    const getUserDropdown = (optionsData, type) => {
        let optionItems = null;
        if (optionsData.length > 0) {
            optionItems = optionsData.map(data => {
                return (
                    <MenuItem key={data.text} value={data.text}>
                        <Checkbox checked={selectedOU.indexOf(data.text) > -1} />
                        <ListItemText primary={data.text} />
                    </MenuItem>
                )
            });
        }
        return optionItems;
    }
    const getDefaultTenants = (defaultTenants) => {
        let optionItems = null;
        if (defaultTenants.length > 0) {
            optionItems = defaultTenants.map(data => {
                if (data['tenant_id'] !== null) {
                    return (
                        <MenuItem value={data['tenant_id']} key={data['tenant_name']}>{data['tenant_name']}</MenuItem>
                    )
                }
            });
        }
        return optionItems;
    }

    const getAccessRoles = (userObj) => {
        let optionItems = null;
        optionItems = userObj.tenants.map((tenantsObj, tIndex) => {
            return tenantsObj.access_roles.map((accessRoles, aIndex) => {
                return (
                    <Grid item xs={6}>
                        <div style={{ marginTop: "1rem" }}>
                            <div>
                                <label className={classes.text_color_red}>*</label>
                                <label className={classes.text_color}>Tenant</label>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tenantsObj['tenant_id'] || 'selectTenant'}
                                    onChange={handleOnchange('tenant', tIndex, aIndex)}
                                    className={classes.drpdwm_wdth_10rem}
                                    style={{ marginLeft: "4px" }}
                                    disabled={disableFields}
                                >
                                    <MenuItem value={"selectTenant"} disabled>Select Tenant</MenuItem>
                                    {getUserShow(tenants)}
                                </Select>
                            </div>
                            <div>
                                <label className={classes.text_color_red}>*</label>
                                <label className={classes.text_color}>Module</label>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={accessRoles['module_id'] || 'selectModule'}
                                    onChange={handleOnchange('module', tIndex, aIndex)}
                                    className={classes.drpdwm_wdth_10rem}
                                    disabled={disableFields}
                                >
                                    <MenuItem value={"selectModule"} disabled>Select Module</MenuItem>
                                    {getUserShow(modules)}
                                </Select>
                            </div><div>
                                <label className={classes.text_color_red}>*</label>
                                <label className={classes.text_color}>Role</label>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={accessRoles['role_id'] || 'selectRole'}
                                    onChange={handleOnchange('role', tIndex, aIndex)}
                                    className={classes.drpdwm_wdth_10rem}
                                    style={{ marginLeft: "19px" }}
                                    disabled={disableFields}
                                >
                                    <MenuItem value={"selectRole"} disabled>Select Role</MenuItem>
                                    {getUserShow(roles)}
                                </Select>
                            </div><div>
                                <label className={classes.text_color_red}>*</label>
                                <label id="demo-mutiple-checkbox-label" className={classes.text_color}>OU</label>
                                <FormControl>
                                    <Autocomplete
                                        multiple
                                        value={accessRoles['divisions']}
                                        variant="body1"
                                        options={orgUnits}
                                        disableCloseOnSelect
                                        id="tags-standard"
                                        className={classes.drpdwm_wdth_10rem}
                                        style={{ marginLeft: "74px" }}
                                        getOptionLabel={(option) => option.text}
                                        filterOptions={filterOptions}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox checked={accessRoles['divisions'].filter(div => div.text === option.text).length > 0 ? true : false} />
                                                {option.text}
                                            </React.Fragment>
                                        )}
                                        onChange={handleOnchange('orgUnit', tIndex, aIndex)}
                                        renderInput={(params) => (
                                            <TextField {...params} onFocus={(e) => e.target.placeholder = ''} id="standard-required" variant="standard" placeholder={"Select OU"}
                                                InputProps={{ ...params.InputProps }} />
                                        )}
                                        renderTags={(value, getTagProps) => {
                                            const numTags = value.length;
                                            return (
                                                <Typography variant="body1">
                                                    {value
                                                        .slice(0, 1)
                                                        .map((option) => option.text)
                                                        .join(", ")}
                                                    {numTags > 1 && ` +${numTags - 1} more`}
                                                </Typography>
                                            );
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    />
                                </FormControl>
                                {((userObj.tenants.length === tIndex + 1 && userObj['tenants'][userObj.tenants.length - 1]['access_roles'].length === aIndex + 1) && !(userObj.tenants.length === 1 && userObj['tenants'][userObj.tenants.length - 1]['access_roles'].length === 1)) &&
                                    <RemoveCircleIcon style={{ fill: '#000000', fontSize: "1rem", marginLeft: "8px" }} onClick={() => addOrDeleteAccess('remove', tIndex, aIndex)} />
                                }
                            </div><div>
                                <label className={classes.text_color}>Show</label>
                                <FormControl>
                                    <Autocomplete
                                        multiple
                                        value={accessRoles['shows']}
                                        variant="body1"
                                        options={shows}
                                        disableCloseOnSelect
                                        id="tags-standard"
                                        getOptionLabel={(option) => option.text}
                                        className={classes.drpdwm_wdth_10rem}
                                        style={{ marginLeft: "66px" }}
                                        getOptionLabel={(option) => option.text}
                                        filterOptions={filterOptions}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox checked={accessRoles['shows'].filter(div => div.text === option.text).length > 0 ? true : false} />
                                                {option.text}
                                            </React.Fragment>
                                        )}
                                        onChange={handleOnchange('show', tIndex, aIndex)}
                                        renderInput={(params) => (
                                            <TextField {...params} onFocus={(e) => e.target.placeholder = ''} id="standard-required" variant="standard" placeholder={"Select Show"}
                                                InputProps={{ ...params.InputProps }} />
                                        )}
                                        renderTags={(value, getTagProps) => {
                                            const numTags = value.length;
                                            return (
                                                <Typography variant="body1">
                                                    {value && value[0].text}
                                                    {numTags > 1 && ` +${numTags - 1} more`}
                                                </Typography>
                                            );
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    />
                                </FormControl>
                                {(userObj.tenants.length !== tIndex + 1 || userObj['tenants'][userObj.tenants.length - 1]['access_roles'].length !== aIndex + 1) &&
                                    <RemoveCircleIcon style={{ fill: '#000000', fontSize: "1rem", marginLeft: "9px" }} onClick={() => addOrDeleteAccess('remove', tIndex, aIndex)} />
                                }
                                {userObj.tenants.length === tIndex + 1 && userObj['tenants'][userObj.tenants.length - 1]['access_roles'].length === aIndex + 1 &&
                                    <AddCircleIcon style={{ fill: '#000000', fontSize: "1rem", marginLeft: "9px" }} onClick={() => addOrDeleteAccess('add', tIndex, aIndex)} />
                                }
                            </div>
                        </div>
                    </Grid>
                )
            })
        })
        return optionItems;
    }

    const handleOnchange = (type, tIndex, aIndex) => (event, values) => {
        switch (type) {
            case "tenant": {
                let tenantError = false;
                userRoles[0]['tenants'].map(tenant => {
                    if (tenant['tenant_id'] == event.target.value) {
                        tenant['access_roles'].map((access, i) => {
                            if (access['module_id'] == userRoles[0]['tenants'][tIndex]['access_roles'][aIndex]['module_id']) {
                                setErrorText({ ...errorText, access: 'Duplicate access is not allowed.' });
                                tenantError = true;
                            }
                        });
                    }
                });
                if (!tenantError) {
                    const tName = tenants.filter(tenant => tenant['value'] == event.target.value);
                    const isExistingTenant = userRoles[0]['tenants'].filter(tenant => tenant['tenant_name'] == tName[0].text);
                    setExistingTenant(isExistingTenant);
                    userRoles[0]['tenants'][tIndex]["tenant_id"] = event.target.value;
                    userRoles[0]['tenants'][tIndex]["tenant_name"] = tName[0].text;
                    setUserRoles([...userRoles]);
                    setErrorText({ ...errorText, access: '' });
                }
                break;
            }
            case 'module': {
                let tenantError = false;
                userRoles[0]['tenants'].map((tenant, i) => {
                    if (tenant['tenant_id'] == userRoles[0]['tenants'][tIndex]["tenant_id"]) {
                        tenant['access_roles'].map(access => {
                            if (access['module_id'] == event.target.value) {
                                setErrorText({ ...errorText, access: 'Duplicate access is not allowed.' });
                                tenantError = true;
                            }
                        });
                    }
                });
                if (!tenantError) {
                    const mName = modules.filter(module => module['value'] == event.target.value);
                    userRoles[0]['tenants'][tIndex]['access_roles'][aIndex]['module_id'] = event.target.value;
                    userRoles[0]['tenants'][tIndex]['access_roles'][aIndex]['module_name'] = mName[0].text;
                    setErrorText({ ...errorText, access: '' });
                    setUserRoles([...userRoles]);
                }
                break;
            }
            case 'role': {
                const rName = roles.filter(role => role['value'] == event.target.value);
                userRoles[0]['tenants'][tIndex]['access_roles'][aIndex]['role_id'] = event.target.value;
                userRoles[0]['tenants'][tIndex]['access_roles'][aIndex]['role_name'] = rName[0].text;
                setErrorText({ ...errorText, access: '' });
                setUserRoles([...userRoles]);
                break;
            }
            case 'orgUnit': {
                let tempArray = values;
                if (values.filter(value => value.text === 'Warner Media').length > 0) {
                    tempArray = values.filter(value => value.text === 'Warner Media');
                }
                userRoles[0]['tenants'][tIndex]['access_roles'][aIndex]['divisions'] = tempArray;
                setUserRoles([...userRoles]);
                setErrorText({ ...errorText, access: '' });
                break;
            }
            case 'show': {
                userRoles[0]['tenants'][tIndex]['access_roles'][aIndex]['shows'] = values;
                setErrorText({ ...errorText, access: '' });
                setUserRoles([...userRoles]);
                break;
            }
            default: {
                setUserRoles([...userRoles]);
                break;
            }
        }

    }
    const handleModalClose = () => {
        clearFormDetails();
        props.handleClose();
    }
    const loadShowsData = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/shows?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                var showsArr = response.data.map((show) => ({ text: show.show_name, value: show.show_id.toString() }));
                setShows(showsArr);
            },
                (err) => {
                    console.log("Load Shows Error-->" + err);
                })
    }
    const loadUserDetailsForEdit = () => {
        if (props?.type === "Edit") {
            let email = props?.email;
            GifterService.getData(Constants.GifterServiceBaseUrl + '/user?email=' + email)
                .then(response => {
                    var responseData = response.data;
                    let defaultTenant = responseData && responseData.length > 0 ? responseData[0]?.default_tenant_id : 0;
                    setSelectedDefaultTenant(defaultTenant);
                    responseData.map((user, index) => {
                        user['is_user_provisioner'] == 1 ? setDisableFields(true) : setDisableFields(false);
                        user['tenants'].map(tenant => {
                            tenant['access_roles'].map(access => {
                                access['divisions'] = access['divisions'].map(divi => {
                                    return { text: divi.org_unit_name, value: divi.org_unit_id };
                                });
                                let tempShows = [];
                                access['shows'].map(show => {
                                    if (show.show_id !== null) {
                                        tempShows.push({ text: show.show_name, value: show.show_id });
                                    }
                                });
                                access['shows'] = tempShows;
                            });
                        });
                    });
                    setUserRoles(response.data);
                },
                    (err) => {
                        console.log("Load Shows Error-->" + err);
                    })
        }
    }
    const loadTenantData = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenants')
            .then(response => {
                var tenantArr = response.data.map((tenant) => ({ text: tenant.tenant_name, name: tenant.tenant_name, value: tenant.tenant_id.toString() }));
                setTenants(tenantArr);
            },
                (err) => {
                    console.log("Load Shows Error-->" + err);
                })
    }
    const loadModulesData = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/modules')
            .then(response => {
                var modulesArr = response.data.map((modules) => ({ text: modules.module_name, value: modules.module_id.toString() }));
                setModules(modulesArr);
            },
                (err) => {
                    console.log("Load Shows Error-->" + err);
                })
    }
    const loadRolesData = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/roles')
            .then(response => {
                var rolesArr = response.data.map((roles) => ({ text: roles.role_name, value: roles.role_id.toString() }));
                setRoles(rolesArr);
            },
                (err) => {
                    console.log("Load Shows Error-->" + err);
                })
    }
    const loadOrgUnitsData = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/orgUnits')
            .then(response => {
                var tenantArr = response.data.map((orgUnits) => ({ text: orgUnits.org_unit_name, value: orgUnits.org_unit_id.toString() }));
                setOrgUnits(tenantArr);
            },
                (err) => {
                    console.log("Load Shows Error-->" + err);
                })
    }
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div>
                <div>
                    <h4 id="simple-modal-title" >{props.type === "Add" ? "New User" : "Edit User"}</h4>
                </div>
            </div>
            {userRoles.map(userObj => {
                return (
                    <div>

                        <div>
                            <Grid container spacing={12}>
                                <Grid item xs={4}>
                                    <h5 id="simple-modal-title" className={classes.text_color}>Details</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    {errorText.details.length > 0 &&
                                        <Alert severity="warning" style={{ height: "37px" }}>{errorText.details}</Alert>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <label className={classes.text_color}>Status</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={1}
                                value={(userObj['is_active'] == 0 || userObj['is_active'] == 2) ? 2 : 1}
                                onChange={(e) => { setUserRoles([{ ...userObj, 'is_active': parseInt(e.target.value) }]) }}
                                className={classes.drpdwm_wdth_8rem}
                            >
                                <MenuItem value={"selectStatus"} disabled>Select Status</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>In Active</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <Grid container spacing={12}>
                                <Grid item xs={6}>
                                    <div >
                                        <TextField id="outlined-basic" label="Name (required)" value={userObj['name'] || ''} variant="outlined" onChange={(e) => {
                                            var regName = new RegExp("^[a-zA-Z ]{2,30}$");
                                            regName.test(e.target.value) ? setErrorText({ ...errorText, details: '' }) : setErrorText({ ...errorText, details: 'Please enter a valid Input.' });
                                            setUserRoles([{ ...userObj, 'name': e.target.value }])
                                        }
                                        } className={classes.text_input} />
                                    </div>
                                    <div >
                                        <TextField id="outlined-basic" label="Phone Number" value={userObj['phone'] || ''} variant="outlined" onChange={(e) => {
                                            var regName = /^[0-9\.\-\(\)+]+$/;
                                            regName.test(e.target.value) ? setErrorText({ ...errorText, details: '' }) : setErrorText({ ...errorText, details: 'Please enter a valid Input.' });
                                            setUserRoles([{ ...userObj, 'phone': e.target.value }])
                                        }} style={{ marginTop: "10px" }} className={classes.text_input} />
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div >
                                        <TextField id="outlined-basic" label="Login Email Id (required)" value={userObj['wb_email_id'] || ''} variant="outlined" onChange={(e) => {
                                            var regName = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$");
                                            regName.test(e.target.value) ? setErrorText({ ...errorText, details: '' }) : setErrorText({ ...errorText, details: 'Please enter a valid Input.' });
                                            setUserRoles([{ ...userObj, 'wb_email_id': e.target.value, 'notification_email_id': e.target.value }])
                                        }} className={classes.text_input} />
                                    </div>
                                    <div>
                                        <TextField id="outlined-basic" label="Notification Email Id (required)" value={userObj['notification_email_id'] || ''} variant="outlined" onChange={(e) => {
                                            var regName = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$");
                                            regName.test(e.target.value) ? setErrorText({ ...errorText, details: '' }) : setErrorText({ ...errorText, details: 'Please enter a valid Input.' });
                                            setUserRoles([{ ...userObj, 'notification_email_id': e.target.value }])
                                        }} style={{ marginTop: "10px" }} className={classes.text_input} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <Grid container spacing={12}>
                                <Grid item xs={4}>
                                    <h5 id="simple-modal-title" className={classes.text_color}>Access</h5>
                                </Grid>
                                <Grid item xs={8}>
                                    {errorText.access.length > 0 &&
                                        <Alert severity="warning" style={{ height: "37px" }}>{errorText.access}</Alert>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container spacing={12}>
                                <Grid item xs={6}>
                                    {!disableFields &&
                                        <div>
                                            <label className={classes.text_color}>Default Tenant</label>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue="selectDefaultTenant"
                                                value={selectedDefaultTenant}
                                                onChange={(e) => { setSelectedDefaultTenant(e.target.value); setErrorText({ ...errorText, access: '' }); }}
                                                className={classes.drpdwm_wdth_8rem}
                                                disabled={disableFields}
                                            >
                                                <MenuItem value={"selectDefaultTenant"} disabled >Select Default Tenant</MenuItem>
                                                {getDefaultTenants(userObj.tenants)}
                                            </Select>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Checkbox
                                        color="primary"
                                        value={userObj['is_user_provisioner'] == 1 ? true : false || ''}
                                        checked={disableFields}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        onChange={(e) => { setDisableFields(e.target.checked); setUserSpecificRoles(e.target.checked); setErrorText({ ...errorText, access: '' }); }}
                                    />
                                    <label className={classes.text_color}>User Provisioner</label>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            {!disableFields &&
                                <Grid container spacing={12}>
                                    {getAccessRoles(userObj)}
                                </Grid>
                            }
                        </div>
                    </div>
                )
            })}
            <div>
                <div className={classes.action_btns}>
                    <Grid container spacing={12}>
                        <Grid item xs={10}>
                            <Button variant="contained" color="primary" onClick={submitUserRoles} disabled={savebtnDisabled}>
                                {props.type === "Add" ? "Create User" : "Save"}
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" onClick={handleModalClose}>Cancel</Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );

    const clearFormDetails = () => {
        setUserRoles([{
            "name": null,
            "notification_email_id": null,
            "wb_email_id": null,
            "phone": null,
            "is_active": 1,
            "tenants": [
                {
                    "tenant_id": null,
                    "tenant_name": null,
                    "permissions": {},
                    "access_roles": [
                        {
                            "module_id": null,
                            "module_name": null,
                            "role_id": null,
                            "role_name": null,
                            "shows": [],
                            "divisions": []
                        }
                    ]
                }
            ]
        }]);
        setExistingTenant([]);
        setSelectedDefaultTenant(null);
        setShows([]);
        setTenants([]);
        setRoles([]);
        setModules([]);
        setOrgUnits([]);
        setDisableFields(false);
        setUserSpecificRoles(false);
        setErrorText({ "details": "", "access": "" });
    }

    const addOrDeleteAccess = (type, tIndex, aIndex) => {
        const tempUserRoles = userRoles;
        let validations = false;
        if (type === 'add') {
            const accessRoles = tempUserRoles[0]['tenants'][tIndex]['access_roles'][aIndex];
            if (accessRoles['role_name'] !== 'User Provisioner') {
                validations = tempUserRoles[0]['tenants'][tIndex]['tenant_id'] !== null && accessRoles['module_id'] !== null && accessRoles['role_id'] !== null && accessRoles['divisions'].length > 0;
            } else {
                validations = true;
            }
            if (validations) {
                if (existingTenant.length > 0) {
                    tempUserRoles[0]['tenants'].map((tenant, index) => {
                        if (existingTenant[0]['tenant_id'] === tenant['tenant_id']) {
                            tempUserRoles[0]['tenants'][index]['access_roles'].push(accessRoles);
                        }
                    });
                    tempUserRoles[0]['tenants'].splice(tIndex, 1);
                }
                tempUserRoles[0]['tenants'].push({
                    "tenant_id": null,
                    "tenant_name": null,
                    "permissions": {},
                    "access_roles": [
                        {
                            "module_id": null,
                            "module_name": null,
                            "role_id": null,
                            "role_name": null,
                            "shows": [],
                            "divisions": []
                        }
                    ]
                });
            } else {
                setErrorText({ ...errorText, access: "Please fill all the details." });
            }
        } else {
            let checkAccessRole = tempUserRoles[0]['tenants'][tIndex]['access_roles'].length === 1;
            if (checkAccessRole) {
                tempUserRoles[0]['tenants'].splice(tIndex, 1);
            } else {
                tempUserRoles[0]['tenants'][tIndex]['access_roles'].splice(aIndex, 1);
            }
        }
        setUserRoles([...tempUserRoles])
    }
    return (
        <div>
            <Modal
                open={props.open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
            <NotificationContainer />
        </div>
    );
}