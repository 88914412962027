import React from 'react'; //, { useState }
//import '../../styles/SideNav.css';
import SideNav from './SideNav'
import { MDBCol, MDBRow } from 'mdbreact';
import { MDBSideNavCat, MDBSideNavMenu, MDBSideNav, MDBSideNavLink, MDBSideNavItem, MDBModal,
MDBContainer, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import {
    BrowserRouter as Router
} from "react-router-dom";
import Routes from '../../Routes';
import ErrorBoundary from "../../pages/ErrorBoundary";
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import HeaderContent from './HeaderContent';

class MainContent extends React.Component {
    // const [isexpanded, setWidth] = useState(false);
    _isMounted = false;
    //history = useHistory();
    //mainContent = null;
    constructor(props) {
        super(props);
        //console.log("Main Content -->"+ this.props.authState.isAuthenticated);
        //this.inputRef = React.createRef();
        //this.history = useHistory();
        this.state = {
            isExpanded: true,
            isAdmin: false,
            isCreate: false,
            isFinalApprover: false,
            sideNavLeft: true,
            isSlim: false,
            //container: null,
            user_name: localStorage.getItem("user_name"),
            // JSON.parse(localStorage.getItem("user_profile")).Name,
            activeTab : "",
            isOpen: false,
            active_route: ""
        }
        this.mainContent = React.createRef();
    }
    toggleSideNav = () => {

        this.setState({
            isExpanded: !this.state.isExpanded
        });

    }
    handleSideNavLeft = () => {
        this.setState(
            {
                sideNavLeft: !this.state.sideNavLeft,
                slim: !this.state.isSlim,
                //container: this.mainContent.current,
                isExpanded : !this.state.isExpanded
            });
        //this.props.toggleSideNav();
    }

    setActiveTab = (value) => {
        this.setState({ activeTab : value });
    }

    handleSideNavActive = (value, routePath) => {
        this.setActiveTab(value);
        // //,function () {
        // //     console.log("activeTab: "+ this.state.activeTab);
        // //    }
         
        this.props.history.push(routePath, this.state);
        //this.props.handleSideNavActive(value, routePath);
    }
/*
    handleSideNavActive1 = (value, routePath) => {
        this.setState({
            activeTab : value
         });
        this.props.history.push(routePath, this.state);
    }
*/
    switchTenant = (tenant_id) => {
       this.props.switchTenant(tenant_id);
    }



    componentDidMount() {
        this._isMounted = true;
        // this.setState(
        // {
        //     container : this.mainContent.current
        // });
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.active_route !== this.props.active_route){
            this.props.history.push(this.props.active_route, this.state);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {

        if (this._isMounted) {
            this.setState({
                active_route: nextProps.active_route               
            });
            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreate: nextProps.isCreate,
            //     isFinalApprover: nextProps.isFinalApprover,
            //     isOpen: nextProps.isOpen
            // });
            // this.setState({
            //     isAdmin: nextProps.isAdmin,
            //     isCreateEvent: nextProps.isCreateEvent,
            //     isCreateGift: nextProps.isCreateGift,
            //     isCreateCard: nextProps.isCreateCard,
            //     isCreatePeople: nextProps.isCreatePeople,
            //     isViewEvent: nextProps.isViewEvent,
            //     isViewGift: nextProps.isViewGift,
            //     isViewCard: nextProps.isViewCard,
            //     isViewPeople: nextProps.isViewPeople,
            //     active_tenant_id : nextProps.active_tenant_id,
            //     active_tenant_name: nextProps.active_tenant_name,
            //     active_tenant_role: nextProps.active_tenant_role,
            //     isOpen: nextProps.isOpen     
            //   });
        }
    }
    // getParentContent = () => {
    //     return this.mainContent;
    // }
    render() {
        return (

            <Router >
                <div className="h-100">
                <HeaderContent {...this.props} {...this.state} 
                  authState={this.props.authState} 
                  switchTenant = {this.switchTenant.bind(this)}
                  setActiveTab = {this.setActiveTab.bind(this)} />
                <MDBContainer fluid >
                <MDBRow className = "mainContent"> 
                {/* <MDBRow className="containerBorder"> */}
                    {/* <MDBCol id="sideNav"  > */}
                        {/* <SideNav isAdmin={this.state.isAdmin} isExpanded={this.state.isExpanded}
                            isCreate={this.state.isCreate} isFinalApprover={this.state.isFinalApprover} 
                            toggleSideNav={this.toggleSideNav} color="warning"
                            mainContent = {() => this.mainContent}
                            /> */}
                    {/* </MDBCol >
                    <MDBCol size={this.state.isExpanded ? "8" : "10"}  id="main-content"> */}
                <MDBCol  id="sidenav-content" className={this.state.isExpanded ? "d-none d-lg-block col-sidenav " : "d-none d-lg-block col-slim-sidenav"}>
                <SideNav {...this.props} activeTab= {this.state.activeTab}
                            toggleSideNav={this.toggleSideNav.bind(this)} 
                            setActiveTab = {this.setActiveTab.bind(this)}
                 />
                </MDBCol >
                <MDBCol className="w-auto"  id="main-content">
            
                <div ref={this.mainContent} >
                    <ErrorBoundary>
                        <Routes authState={this.props.authState} {...this.props} {...this.state}
                                handleSideNavActive={this.handleSideNavActive.bind(this)}
                                setActiveTab = {this.setActiveTab.bind(this)}/>
                    </ErrorBoundary>
                </div>
                </MDBCol >
                </MDBRow>
                    {/* </MDBCol> */}
                {/* </MDBRow> */}
                </MDBContainer>
                </div>
            </Router>
        );
    }
}

export default withRouter(MainContent)