import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";


class GroupedButtons extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            counter: props.quantity,
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            counter: nextProps.quantity,
        });
    }

    handleIncrement = () => {
        this.setState(state => ({ counter: state.counter + 1 }));
        this.props.counterCallback(this.state.counter + 1);
    };

    handleDecrement = () => {
        if (this.state.counter > 1) {
            this.setState(state => ({ counter: state.counter - 1 }));
            this.props.counterCallback(this.state.counter - 1);
        }
    };
    render() {
        const displayCounter = this.state.counter >= 1;

        return (
            <ButtonGroup size="small" aria-label="small outlined button group">
                {displayCounter && <Button id='stepincrement' onClick={this.handleDecrement}>-</Button>}
                &nbsp;
                {displayCounter && <Button className='noborder' disabled>{this.state.counter}</Button>}
                &nbsp;
                <Button id='stepincrement' onClick={this.handleIncrement}>+</Button>

            </ButtonGroup>
        );
    }
}

export default GroupedButtons;
