import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

  import {
    MDBCard, MDBCardBody,MDBCardHeader,MDBTabs,MDBTabsLink,MDBTabsItem,MDBCardText,MDBCardTitle,
    MDBTabsPane,MDBTabsContent,  MDBDropdown, MDBSelect, MDBBtn, MDBDatepicker,
    MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBTooltip,
  MDBIcon
  } from 'mdb-react-ui-kit';

import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';


class ImportPeople extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {            
            name: this.props.name,
            isOpen: this.props.isOpen,
            // selectedFiles: undefined,
            // selectedFile: undefined         
        }
    }
    
    // shouldComponentUpdate(props, nextProps) {

    //   if(props.isOpen === nextProps.isOpen)
    //   return false;
    //   else
    //   return true;
      
    // }
    handleFileSelect = (event) => {
      // this.setState({
      //   selectedFiles: event.target.files,
      //   selectedFile: event.target.files[0]
      // });

      var fileName = event.target.files[0]?.name;
      if(!fileName.endsWith(".xlsx") && !fileName.endsWith(".xlsx") && !fileName.endsWith(".csv"))
        alert("Invalid File selected. Allowed file types are .xlsx or .xls or .csv");
      else
        this.props.handleFileSelect(event.target.files[0]);
     
     // console.log("File Selected");
     // this.fileInput.value = "";

    }
    componentWillReceiveProps(props) {
      this.setState({ isOpen: this.props.isOpen
      });
    }

    render() {
        return (
            <>
              <MDBModal id="importPeople" staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props.isOpen} >
              {/* getOpenState={(e) =>  this.setState({ isOpen: e })}> */}
                <MDBModalDialog size="sm" >
                  <MDBModalContent>
                    {/* <MDBModalHeader>
                      <MDBModalTitle>Modal title</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.toggleImportPeople}></MDBBtn>
                    </MDBModalHeader> */}
                    <MDBModalBody>
                    <div className="flexBox-plain padding-bottom-none">
                    {/* <div className="flexAlignRight"> */}
                      <MDBModalTitle className="h6 text-primary">Import from Excel</MDBModalTitle>
                    {/* </div>   */}
                      <div className="flexAlignRight">                        
                          <MDBIcon size="2x" icon="times-circle" onClick={this.props.toggleImportPeople}></MDBIcon>                          
                      </div>
                      
                    </div>
                    <div className="fst-italic padding-top-none fs-70">
                         file must be .xlsx or .xls or .csv format
                    </div>
                    <div className="flexBox-plain padding">
                    <input
                            type="file"
                            style={{ display: 'none' }}
                            id="people-import-file"
                            onChange={this.handleFileSelect.bind(this)}
                            accept=".xlsx,.xls,.csv"
                            value=""
                    />

                    <label htmlFor="people-import-file"
                    >  
                    <Button variant="outlined" className="text-tertiary" component="span">
                        <MDBIcon fas size='2x' icon="file-excel" className="mr-2 text-tertiary padding-hor" >                            
                        </MDBIcon>
                        Select File   
                    </Button>
                    {/* <MDBBtn className= "btnSize" outline size="sm" color="tertiary" >
                        <MDBIcon fas size='2x' icon="file-excel" className="mr-2 text-tertiary" >                            
                        </MDBIcon>
                        Select File                        
                       
                    </MDBBtn> */}
                    </label>
                    
                    </div>
                    <div className={this.props.selectedFile ? "flexBox-plain padding fs-75" : "d-none"}> 
                        <MDBIcon size='1x' icon="check-circle" className="mr-2 text-success"></MDBIcon>
                        <label className="wrap">{this.props.selectedFile?.name || ''}</label>  
                    </div> 
                    <br/>
                    <div className="flexBox-plain padding"> 

                        <MDBBtn disabled={this.props.selectedFile? false: true} onClick={this.props.previewExcel}>
                        <MDBIcon fas size='2x' icon="file-excel" className="mr-4 text-white padding-hor" >                            
                        </MDBIcon>
                        Preview</MDBBtn>    
                    </div>               
                    </MDBModalBody>

                    {/* <MDBModalFooter>
                      <MDBBtn color='secondary' onClick={this.props.toggleImportPeople}>
                        Close
                      </MDBBtn>
                      <MDBBtn>Save changes</MDBBtn>
                    </MDBModalFooter> */}
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </>
        );
        }

}

export default ImportPeople;