import React from 'react'
import { Button as MuiButton, makeStyles} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme =>({
    root:{
        margin:theme.spacing(0.5)
    },
    label:{
        textTransform: "none"
    }
}))

export default function Button(props){

    const{text, size, color, variant, onClick, isPosting, ...other} = props
    const classes = useStyles();
    return(
        <MuiButton 
        variant={variant}
        size={size}
        color={color}
        onClick={onClick}
        {...other}
        classes={{root:classes.root,label:classes.label}}>
            {isPosting ?  <CircularProgress  size={25} color={'secondary'}/> : text}
        </MuiButton>
    )
}