import React, { useState, useEffect } from 'react';
import { Grid, TextField, makeStyles, FormControl, InputBase, InputLabel, Container } from '@material-ui/core';
import { useForm, Form } from './useForm';
import Controls from './Controls/Controls';
import { MDBInput } from "mdbreact";
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import GifterService from '../services/service';
import * as Constants from '../constants/constants';
import { AutoComplete } from 'rsuite';
import AutoDropdown from './Controls/AutoComplete';


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
    }
}))


export default function SampleForm(props) {

    const { formData, toggle1 } = props
    const [company, setCompany] = useState([]);
    const [division, setDivision] = useState([]);
    const [title, setTitle] = useState([]);
    const [phone, setPhone] = useState([]);
    const [email, setEmail] = useState([]);
    const [address, setAddress] = useState([]);
    const [addressupdate, setAddressUpdate] = useState([]);
    const [category, setCategory] = useState([]);
    const [addressTypes, setAddressTypes] = useState([]);
    const [isPosting, setIsPosting] = useState(false);
    const [values, setValues] = useState({
        newUser: formData.newUser ? true : false,
        Name: formData.first_name + ' ' + formData.last_name,
        firstName: formData.first_name,
        lastName: formData.last_name,
        companyName: formData.company_name,
        divisionName: formData.division_name,
        division_id: formData.division_id,
        categoryName: formData.category_name,
        Title: formData.title_name,
        email: formData.email,
        addressName: formData.address,
        phoneName: formData.phone,
        address_type: formData.address_type,
        person_id: formData.person_id,
        p_comp_div_title_id: formData.p_comp_div_title_id,
        person_category_id: formData.person_category_id,
        category_id: formData.category_id,
        person_email_id: formData.person_email_id,
        person_phone_id: formData.person_phone_id,
        address: formData.address,
        company_id: formData.company_id,
        title_id: formData.title_id,
        //address_type_id: formData.address_type && formData.address_type == 'Home' ? '1' : '2',
        address_type_id: (formData.address_type == 'Home' || formData.address_type === null) ? '1' : '2',
    });


    const validate = () => {
        let temp = {}
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //const ph = /^[0-9]*$/i;
        temp.emailname = values.email ? (re.test(values.email) ? "" : "Email is not valid") : ''
        temp.phonename = values.phoneName ? ((/^[0-9]+$/).test(values.phoneName) ? "" : "Phone Number is not valid") : ''
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x == "")
    }

    useEffect(() => {
        loadData();
        loadData1();
        loadData2();
        loadData3();
        loadData4();
        loadData5();
        loadData6();
        loadData7();
    }, []);

    const loadData = () => {

        GifterService.getData(Constants.GifterServiceBaseUrl + '/personCompaniesAndAll?personId=' + formData.person_id)
            .then((response) => {
                response.data.forEach(item => {
                    item['person_history'] = item['person_history'] == 1 ? 'Primary' : 'Secondary'
                })
                setCompany(response.data)
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });
    }
    const loadData1 = () => {

        GifterService.getData(Constants.GifterServiceBaseUrl + '/personDivisionsAndAll?personId=' + formData.person_id)
            .then((response) => {
                response.data.forEach(item => {
                    item['person_history'] = item['person_history'] == 1 ? 'Primary' : 'Secondary'
                })
                setDivision(response.data)
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });
    }
    const loadData2 = () => {

        GifterService.getData(Constants.GifterServiceBaseUrl + '/personTitlesAndAll?personId=' + formData.person_id)
            .then((response) => {
                response.data.forEach(item => {
                    item['person_history'] = item['person_history'] == 1 ? 'Primary' : 'Secondary'
                })
                setTitle(response.data)
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });
    }
    const loadData3 = () => {

        GifterService.getData(Constants.GifterServiceBaseUrl + '/personPhones?personId=' + formData.person_id)
            .then((response) => {
                setPhone(response.data)
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });
    }
    const loadData4 = () => {

        GifterService.getData(Constants.GifterServiceBaseUrl + '/personEmails?personId=' + formData.person_id)
            .then((response) => {
                setEmail(response.data)
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });
    }
    const loadData5 = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/personAddress?personId=' + formData.person_id)
            .then((response) => {
                setAddress(response.data),
                    setAddressUpdate(response.data.filter(x => x.address_type === values.address_type))
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });
    }
    const loadData6 = () => {

        GifterService.getData(Constants.GifterServiceBaseUrl + '/personCatgoriesAndAll?personId=' + formData.person_id)
            .then((response) => {
                response.data.forEach(item => {
                    item['person_history'] = item['person_history'] == 1 ? 'Primary' : 'Secondary'
                })
                setCategory(response.data)
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });

    }
    const loadData7 = () => {

        GifterService.getData(Constants.GifterServiceBaseUrl + '/addressTypes?personId=' + formData.person_id)
            .then((response) => {
                setAddressTypes(response.data)
            },
                (err) => {
                    console.log("Gift Service error for Events: " + err);
                });

    }
    const {
        errors,
        setErrors,
        resetForm,
    } = useForm(toggle1);

    const handleSubmit = e => {

        e.preventDefault()
        let phones = phone;
        let emails = email;
        let personAddress = address;
        if (validate()) {
            setIsPosting(true);
            let finalArray = {
                "person_id": values.person_id,
                "p_comp_div_title_id": (formData.company_id != values.company_id || formData.division_id != values.division_id
                    || formData.title_id != values.title_id) ? null : values.p_comp_div_title_id,
                "company_id": values.company_id,
                "division_id": values.division_id,
                "title_id": values.title_id,
                "person_category_id": formData.category_id != values.category_id ? null : values.person_category_id,
                "category_id": values.category_id,
                "person_email_id": values.email && emails?.filter(item => item.person_email == values.email)?.length > 0
                    ? values.person_email_id : null,
                "email_address": values.email,
                "person_address_id": values.addressName && personAddress?.filter(item => item.address == values.addressName)?.length > 0
                    ? values.person_address_id : null,
                "address": values.addressName,
                "address_type": values.address_type_id,
                "person_phone_id": values.phoneName && phones?.filter(item => item.person_phone == values.phoneName)?.length > 0
                    ? values.person_phone_id : null,
                "phone": values.phoneName
                // "newUser": values.newUser ? true : false,
                // "first_name": values.firstName,
                // "last_name": values.lastName
            }
            if (values.newUser) {
                finalArray['first_name'] = values.firstName;
                finalArray['last_name'] = values.lastName;
            }
            let extensionUrl = values.newUser ? '/addPeopleExtensions' : '/peopleExtensions';
            GifterService.postDataParams(Constants.GifterServiceBaseUrl + extensionUrl, finalArray)
                .then((response) => {
                    setIsPosting(false);
                    console.log(response);
                    props.editCallback(response.data[0]);
                },
                    (err) => {
                        setIsPosting(false);
                        console.log("Gift Service error for Events: " + err);
                    });
        }
    }

    return (
        <Form id="formid" >
            <Grid container className="paddingBottom" spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding">
                        <span className="redStar">*</span>First Name :
                    </label>
                </Grid>
                <Grid item xs={9}>
                    {!values.newUser ? <label className="labelPadding pl8">
                        {values.firstName}
                    </label> :
                        <MDBInput id="firstName" placeholder="First Name" className='nameWidth'
                            value={values.firstName}
                            onChange={(e, v) => {
                                setValues({
                                    ...values,
                                    firstName: e.target.value
                                })
                            }}
                            required name="firstName" type="text">
                        </MDBInput>}
                </Grid>
            </Grid>
            <Grid container className="paddingBottom" spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding">
                        <span className="redStar">*</span>Last Name :
                    </label>
                </Grid>
                <Grid item xs={9}>
                    {!values.newUser ? <label className="labelPadding pl8">
                        {values.lastName}
                    </label> :
                        <MDBInput id="lastName" placeholder="Last Name" className='nameWidth'
                            value={values.lastName}
                            onChange={(e, v) => {
                                setValues({
                                    ...values,
                                    lastName: e.target.value
                                })
                            }}
                            required name="lastName" type="text">
                        </MDBInput>
                    }
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding" >
                        Company :
                    </label>
                </Grid>
                <Grid item xs={9}>
                    <Controls.AutoDropdownNo
                        name="companyName"
                        label="Company"
                        value={values.companyName ? values.companyName : ''}
                        onChange={(e, v) => {
                            setValues({
                                ...values,
                                company_id: v != null ? v.company_id : null
                            })
                        }}
                        groupBy="person_history"
                        options={company ? company : []}
                        columnName="company_name"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding"  >
                        Division :
                    </label>
                </Grid>
                <Grid item xs={9}>

                    <Controls.AutoDropdownNo
                        name="divisionName"
                        label="Division"
                        value={values.divisionName}
                        onChange={(e, v) => {
                            setValues({
                                ...values,
                                division_id: v != null ? v.division_id : null
                            })
                        }}
                        groupBy="person_history"
                        options={division}
                        columnName="division_name"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding" >
                        Title :
                    </label>
                </Grid>
                <Grid item xs={9}>

                    <Controls.AutoDropdownNo
                        label="Title"
                        name="Title"
                        value={values.Title}
                        onChange={(e, v) => {
                            setValues({
                                ...values,
                                title_id: v != null ? v.title_id : null
                            })
                        }}
                        groupBy="person_history"
                        options={title}
                        columnName="title_name"
                    />

                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding"  >
                        Phone :
                    </label>
                </Grid>
                <Grid item xs={9}>
                    <Controls.AutoDropdown
                        name="phoneName"
                        label="Phone"
                        value={values.phoneName}
                        onChange={(e, v) => {
                            setValues({
                                ...values,
                                person_phone_id: v.person_phone_id,
                                phoneName: v.person_phone
                            })
                        }}
                        onInputChange={(e, v) => {
                            setValues({
                                ...values,
                                phoneName: v
                            })
                        }}
                        options={phone}
                        error={errors.phonename}
                        columnName="person_phone"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding">
                        Email :
                    </label>
                </Grid>
                <Grid item xs={9}>
                    <Controls.AutoDropdown
                        name="email"
                        label="Email"
                        value={values.email}
                        onChange={(e, v) => {
                            setValues({
                                ...values,
                                person_email_id: v.person_email_id,
                                email: v.person_email
                            })
                        }}
                        onInputChange={(e, v) => {
                            setValues({
                                ...values,
                                email: v
                            })
                        }}
                        options={email}
                        columnName="person_email"
                        error={errors.emailname}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding"  >
                        Address :
                    </label>
                </Grid>
                <Grid item xs={9} className="d-flex">
                    <Grid item xs={5} className="mr10">
                        <Controls.Select
                            name="address_type"
                            value={values.address_type}
                            onChange={(e, v) => {
                                setValues({
                                    ...values,
                                    address_type: e.target.value,
                                    address_type_id: addressTypes.filter(x => x.address_type === e.target.value)[0].address_type_id
                                })
                                setAddressUpdate(address.filter(x => x.address_type === e.target.value))
                                this.validate()
                            }}
                            options={addressTypes}
                            columnName="address_type"
                            columnID="address_type_id"
                        />
                    </Grid>
                    <Grid xs={7}>
                        <Controls.AutoDropdown
                            id="addressName"
                            name="addressName"
                            label="Address"
                            value={values.addressName}
                            onChange={(e, v) => {
                                setValues({
                                    ...values,
                                    person_address_id: v.person_address_id,
                                    addressName: v.address
                                })
                            }}
                            onInputChange={(e, v) => {
                                setValues({
                                    ...values,
                                    addressName: v
                                })
                            }}
                            options={addressupdate}
                            columnName="address"
                            error={errors.addressname}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <label className="labelPadding"  >
                        Category :
                    </label>
                </Grid>
                <Grid item xs={9}>
                    <Controls.AutoDropdownNo
                        name="categoryName"
                        label="Category"
                        value={values.categoryName}
                        onChange={(e, v) => {
                            setValues({
                                ...values,
                                // Reverting these changes as it is not moving as part of -- WBTCHG000029298,
                                // Will be moving this in next deployment
                                // person_category_id: v != null ? v.person_category_id : null,
                                // category_id: v != null ? v.category_id : null
                                person_category_id: v.person_category_id,
                                category_id: v.category_id
                            })
                        }}
                        options={category}
                        columnName="category_name"
                        groupBy="person_history"
                    />
                </Grid>

            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8} className="buttonalign">
                    {!values.newUser ?
                        <Controls.Button
                            variant="contained"
                            isPosting={isPosting}
                            color="primary"
                            size="small"
                            text="Save"
                            className="float-right"
                            onClick={handleSubmit}
                        /> : <Controls.Button
                            variant="contained" disabled={!(values.firstName && values.lastName)}
                            isPosting={isPosting}
                            color="primary"
                            size="small"
                            text="Add"
                            // disabled={!(value.firstName && value.lastName)}
                            className="float-right"
                            onClick={handleSubmit}
                        />}
                    <Controls.Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        text="Cancel"
                        onClick={resetForm}
                        className="float-right"
                    />

                </Grid>
            </Grid>
        </Form >

    )
}
