export const VIEW_EVENT_PERMISSIONS = 'View Event'; //1
export const VIEW_GIFT_PERMISSIONS = 'View Gift'; //2
export const CREATE_EVENT_PERMISSIONS = 'Create Event'; //1
export const CREATE_GIFT_PERMISSIONS = 'Create Gift'; //2
export const MANAGE_PEOPLE_PERMISSIONS = 'Manage People'; //3
export const MANAGE_DATA_PERMISSIONS = 'Manage Data'; //4
// export const APPROVAL_PERMISSIONS = 'Default Approve Request'; //3
// export const CANCEL_PERMISSIONS = 'Cancel Request'; //4 
// export const VIEWALLDEALS_PERMISSIONS = 'Can see all Deals'; //5
// export const ASSIGN_PERMISSIONS = 'Assign Request'; //6
// export const FINALAPPROVAL_PERMISSIONS = 'Final Request Approval'; //7
// export const CLOSE_PERMISSIONS = 'Close an Approved Request'; //8


export const PERMISSIONS = [CREATE_EVENT_PERMISSIONS, CREATE_GIFT_PERMISSIONS, MANAGE_PEOPLE_PERMISSIONS, MANAGE_DATA_PERMISSIONS ]