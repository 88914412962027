import React from 'react';
import { userRoleConfigObject } from '../PeopleManagement/MockData/userList';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableComponent from '../Common/Table';
import { MDBIcon } from "mdbreact";
import { MDBBtn } from 'mdb-react-ui-kit';
import GifterService from '../../services/service';
import * as Constants from '../../constants/constants';
// import Filters from './PMFilter';
import '../PeopleManagement/PeopleManagement.scss';
import { SignalCellularNullRounded } from '@material-ui/icons';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Modal from '../UserRoleManagement/AddorEditUserRoles';
import Checkbox from '@material-ui/core/Checkbox';

class UserRoleManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalActionType: null,
            openUserModal: false,
            fetchUserWithEmail: null,
            searchTerm: null,
            page: 0,
            rowsPerPage: 10,
            peopleList: [],
            listBackUp: [],
            isPeopleListFetchOnProgress: false,
            sortBy: "Name",
            isSortByAsc: true,
            sortCount: 0,
            configObject: userRoleConfigObject,
            activeUsersList: [],
            isChecked: false
        };
    }

    componentDidMount() {
        this.fetchPeopleList();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => {
            this.handlePagination();
        });
    }

    fetchPeopleList = () => {
        this.setState({ isPeopleListFetchOnProgress: true });
        GifterService.getDataParams(Constants.GifterServiceBaseUrl + '/users', null)
            .then((response) => {
                let formattedList = response.data.map(list => {
                    return {
                        "name": list.name,
                        "email": list.wb_email_id,
                        "roles": list.roles,
                        "division_access": list.division_access && list.division_access.length > 0 ? list.division_access.join('\n') : '',
                        "status": list.status == "Active" ? "Active" : "Inactive"
                    }
                })
                this.setState({ peopleList: formattedList, listBackUp: formattedList, activeUsersList: formattedList, isPeopleListFetchOnProgress: false });
                this.sortList();
            },
                (err) => {
                    this.setState({ isPeopleListFetchOnProgress: false });
                    console.log("User Role Management grid service error: " + err);
                });
    }

    handlePagination = () => {
        let startIndex = this.state.page * this.state.rowsPerPage;
        let lastIndex = startIndex + this.state.rowsPerPage;
        let list = this.state.peopleList?.slice(startIndex, lastIndex);
        this.setState({ listToRender: list });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
            this.handlePagination();
        });
    }


    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (sortBy) => {
        let configObject = this.state.configObject;
        this.setState({ isSortByAsc: this.state.sortCount % 2 === 0 ? true : false }, () => {
            let newHeadingArray = configObject.headings.map(item => {
                if (item.headingLabel === sortBy) {
                    item.icon = this.state.isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
                } else if (sortBy !== this.state.sortBy && item.headingLabel === this.state.sortBy) {
                    item.icon = "";
                }
                return item;
            });
            delete configObject.headings
            configObject.headings = newHeadingArray;
            this.setState({ sortBy: sortBy, page: 0, configObject: configObject }, () => {
                this.sortList();
            })
        })
    }

    sortList = () => {
        let sortedList;
        switch (this.state.sortBy) {
            case "Name":
                if (this.state.isSortByAsc) {
                    sortedList = this.state.peopleList?.sort((a, b) => (a.name.toString() > b.name.toString()) ? 1 : -1);
                } else {
                    sortedList = this.state.peopleList?.sort((a, b) => (b.name.toString() > a.name.toString()) ? 1 : -1);
                }
                this.setState({ list: sortedList, page: 0 }, () => {
                    this.handlePagination();
                });
                break;
        }
    }

    handleSearchByName = (value) => {
        if (value && value !== null) {
            let filteredList = this.state.listBackUp?.filter(item => item.name?.toLowerCase().includes(value.toLowerCase()));
            this.setState({ peopleList: filteredList, searchTerm: value }, () => {
                this.sortList();
            });
        } else {
            this.setState({ peopleList: this.state.listBackUp, searchTerm: "" }, () => {
                this.sortList();
            });
        }
    }

    openUserPopUp = () => {
        this.setState({ modalActionType: 'Add', openUserModal: true });
    }
    showErrorMessage = (action, error) => {
        if (action === 'success') {
            if (this.state.modalActionType === 'Add') {
                NotificationManager.info('User created Successfully.', 'User');
            } else {
                NotificationManager.info('User update Successfully.', 'User');
            }
        } else {
            NotificationManager.error("User update failed." + error);
        }
        this.handleSearchByName(null);
        this.fetchPeopleList();
    }
    handleClosePopup = () => {
        this.setState({ modalActionType: null, openUserModal: false });
        this.handleSearchByName(null);
    }
    handleOnEditClick = (modalActionType) => (fetchUserWithEmail) => {
        this.setState({ fetchUserWithEmail, openUserModal: true, modalActionType });
    }

    handleInactiveUsers = (e) => {
        let peopleListNew = this.state.listBackUp
        let activeUsersListNew = this.state.activeUsersList.filter(item => item.status == "Active")
        this.setState({
            peopleList: this.state.isChecked ? peopleListNew : activeUsersListNew,
        },
            () => {
                this.sortList();
            });
    }

    handleCheck = (e) => {
        this.setState({ isChecked: e.target.checked });
    }

    render() {
        let list = this.state.userList?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage);
        return (
            <div className="userRoleManagement" >
                <div><h5>User Role Management</h5></div>
                {/* <div className="headingNote">You are viewing People included in the WB TV - Marketing Tenant.</div> */}
                <div className="userTableHeaderContainer">
                    <div id="userTableHeader">
                        <div className="row">
                            <div className="col-2" id="newPersonButton" onClick={this.openUserPopUp}>
                                <MDBBtn color="primary">
                                    <MDBIcon icon="user-plus" className="mr-1" />New User</MDBBtn>
                            </div>
                            {/* <div className="col-1" id="filterIcon" onClick={this.handleShowFilter}><MDBIcon icon="filter" className="mr-1" /></div> */}
                            <div className="col-5">
                                <Checkbox
                                    color="primary"
                                    checked={this.state.isChecked}
                                    onChange={(e) => { this.handleCheck(e); this.handleInactiveUsers(e); }}
                                />
                                <label>Hide Inactive Users</label>
                            </div>
                            <div className="col-5" id="search">
                                <label>Search Users:</label><TextField placeholder="Enter Name:" value={this.state.searchTerm} variant="outlined" onChange={(e) => this.handleSearchByName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="userTable">
                        <TableComponent
                            list={this.state.listToRender ? this.state.listToRender : []}
                            config={this.state.configObject}
                            isLoading={this.state.isPeopleListFetchOnProgress}
                            sortCallback={this.handleTableColumnClick}
                            editCallback={this.handleOnEditClick('Edit')}
                        />
                        <TablePagination
                            component="div"
                            count={this.state.peopleList.length > 0 ? this.state.peopleList.length : 0}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                </div>
                {this.state.modalActionType !== null &&
                    <div>
                        <Modal
                            open={this.state.openUserModal}
                            handleClose={this.handleClosePopup}
                            email={this.state.fetchUserWithEmail}
                            type={this.state.modalActionType}
                            showErrorMessage={this.showErrorMessage}
                        />
                    </div>
                }
                <NotificationContainer />
            </div>
        );
    }
}

export default UserRoleManagement;