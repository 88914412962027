import React from 'react';

const Gifting = (props) => (
    <div className="giftContainer">
        <h6>Gifting</h6>
        <div className="row">
            <div className="col-6">
                <label className="fs-75">YTD Gift Spending</label>
            </div>
            <div className="col-6">
                <label className="dollar">{"$"+ (props?.giftAmount ? props?.giftAmount : "00" )}</label>
            </div>
        </div>
    </div>
);

export default Gifting;