import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutoDropdownNo(props) {

  const { name, label, value, error = null, onChange, options, columnName, groupBy, freeSolo } = props;
  return (
    <Autocomplete
      id={name}
      name={name}
      defaultValue={value}
      options={options}
      groupBy={(option) => option[groupBy]}
      getOptionLabel={(option) => (typeof option != 'string') ? option[columnName] : (typeof option == 'string' ? option : '')}
      onChange={(event, value) => { onChange(event, value) }}
      renderInput={(params) => (
        <TextField {...params} placeholder={label} variant="outlined" {...(error && { error: true, helperText: error })} />
      )}
    />
  );
}