import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {
  MDBContainer, MDBCol, MDBRow, MDBModal,
  MDBTableHead, MDBIcon, MDBModalBody, MDBModalHeader, MDBModalFooter
  , MDBDataTableV5, MDBInput
}
  from 'mdbreact';
  import {
    MDBCard, MDBCardBody,MDBCardHeader,MDBTabs,MDBTabsLink,MDBTabsItem,MDBCardText,MDBCardTitle,
    MDBTabsPane,MDBTabsContent,  MDBDropdown, MDBSelect, MDBBtn, MDBDatepicker, MDBCardLink
  } from 'mdb-react-ui-kit';


import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MultiSelectComponent from './MultiSelectComponent';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';


class EventGiftDashboardComponentMD extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: this.props.selectedTab,
            name: this.props.name,
            data: this.props.data,
            user_name: localStorage.getItem("user_name"),
            filterEvent: false,
            filterGift: false,
            inviteeRange: [0,1000],
            minInvitee: 0,
            maxInvitee: 1000,
            filterOpen: false,
            pageSize: 10,
            page: 1,
            rowCount: 11,
            dateFrom: '',
            dateTo: '',
            openDivision: false,
            openShow: false,
            openSlider: false,
            openDate: false,
            divisions: [
                {name: 'Warner Television', divisionName: 'Warner Television', divisionId: 1 },
                {name: 'Warner Animation', divisionName: 'Warner Animation', divisionId: 2, selected: true},
                {name: 'Warner Horizon', divisionName: 'Warner Horizon', divisionId: 3, selected: true }
            ],
            selectedDivisions: [],
            shows: [
                {name: 'Arrow',  showName: 'Arrow', showId: 1 },
                {name: 'Big Bang Theory', showName: 'Big Bang Theory', showId: 2, selected: true },
                {name: 'Shameless', showName: 'Shameless', showId: 3 }
            ],            
            selectedShows: [],           
            MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 100,
                    width: 200,
                    maxWidth: 200
                  },
                },
              }
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ filterOpen: this.props.filterOpen,
        pageSize: this.props.pageSize,
        page: this.props.page,
        rowCount: this.props.rowCount,
        data: this.props.data        
     });
    }

    navigateToLink = (value, routePath) => {
      this.props.history.push(routePath, this.state);
    }

    handleSearchNameChange = (event) => {
      this.props.handleSearchNameChange(event);
    }

    render() {
        return (
            <>
             <div>
                <MDBInput placeholder={"Search Upcoming "+ this.props.name + "s"}  type="text"
                onChange={this.handleSearchNameChange.bind(this)}></MDBInput>
             </div>
             <div className="flexAlignRight text-dark">
               {this.props.rowCount + " Results"}
             </div>

             <div>
                {this.props.data && this.props.data.rows.length > 0 && this.props.data.rows.map((item) => {
                    return <>
                  <MDBCard>
                      <MDBCardBody>
                          <div className="row">
                            <div className="row col-md-10 col-9">
                                <div className="col-md-8 col-12 flexBox-center">
                                  <h5>
                                  {item.name}
                                  </h5>
                                </div>
                                {/* <div className="d-none d-md-block col-md-3">
                                  {item.division}
                                </div> */}
                                <div className="col-md-4 col-12 flexBox-center">
                                  {item.event_date_time}
                                </div>
                             </div>
                             <div className="col-md-2 col-3 flexBox-center">
                              <Link component={RouterLink} to= {"/atdMngmt/"+item.id}> 
                              {/* {this.navigateToLink.bind(this, 'CE', "/event" )}> */}
                              <h5>View</h5>
                             </Link>
                             </div>
                          </div>
                      </MDBCardBody>
                  </MDBCard>  
                  </>
                })}

                </div>
                <TablePagination
                    component="div"
                    count={this.state.rowCount}
                    page={this.state.page}
                    onChangePage={this.props.handlePageChange}
                    rowsPerPage={this.state.pageSize}
                    onChangeRowsPerPage={this.props.handlePageSizeChange}
                    rowsPerPageOptions = {[2, 4, 10, 25, 50, 75, 100]}
            />
            </>
        );
        }

}

export default EventGiftDashboardComponentMD;