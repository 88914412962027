import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {
  MDBContainer, MDBCol, MDBRow, MDBModal,
  MDBTableHead, MDBIcon, MDBModalBody, MDBModalHeader, MDBModalFooter,
 MDBDataTableV5
}
  from 'mdbreact';
  import {
    MDBCard, MDBCardBody,MDBCardHeader,MDBTabs,MDBTabsLink,MDBTabsItem,MDBCardText,MDBCardTitle,
    MDBTabsPane,MDBTabsContent, MDBInput, MDBDropdown,  MDBBtn,  MDBDatepicker,
    MDBSelect
  } from 'mdb-react-ui-kit';

import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import MultiSelectComponent from './MultiSelectComponent';
import TextField from '@material-ui/core/TextField';
import GifterService from '../services/service.js';
import * as Constants from '../constants/constants';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });
class EventGiftDashboardComponent extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: this.props.selectedTab,
            name: this.props.name,
            data: this.props.data,
            user_name: localStorage.getItem("user_name"),
            filterEvent: false,
            filterGift: false,
            inviteeRange: [0,1000],
            minInvitee: 0,
            maxInvitee: 1000,
            invitees: [],
            filterOpen: false,
            pageSize: 10,
            page: 0,
            rowCount: 10,
            dateFrom: '',
            dateTo: '',
            openDivision: false,
            openShow: false,
            openOccasion: false,
            openInvitees: false,
            openSlider: false,
            openDate: false,
            divisions: [
                {text: 'Warner Television', divisionName: 'Warner Television', value: "1" },
                {text: 'Warner Animation', divisionName: 'Warner Animation', value: "2", selected: true},
                {text: 'Warner Horizon', divisionName: 'Warner Horizon', value: "3", selected: true }
            ],
            selectedDivisions: [],
            shows: [
                {name: 'Arrow',  showName: 'Arrow', showId: 1 },
                {name: 'Big Bang Theory', showName: 'Big Bang Theory', showId: 2, selected: true },
                {name: 'Shameless', showName: 'Shameless', showId: 3 }
            ],            
            selectedShows: [], 
            occasions: [],
            selectedOccasions: [],
            MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 100,
                    width: 200,
                    maxWidth: 200
                  },
                },
              }
        }
        this.dateFromRef = React.createRef('');
        this.dateToRef = React.createRef('');
    }

    handleSortModelChange = (params) => {
        this.props.handleSortModelChange(params);
    }

    handleInviteeRange = (event, newValue) => {
        // this.setState({
        //     inviteeRange : newValue
        // });
        this.props.handleInviteeRange(newValue);
    };

    handleDivision = () => {
        this.setState({
            openDivision: !this.state.openDivision
        })
    }

    handleChange = (event) => {
        this.setState( { selectedDivisions: event.target.value});
    }
    

    handleShow(){
        this.setState({
            openShow: !this.state.openShow
        })
    }

    handleOccasion(){
        this.setState({
            openOccasion: !this.state.openOccasion
        })
    }

    handleInvitees(){
        this.setState({
            openInvitees: !this.state.openInvitees
        })
    }
    handleOpen = () => {
        this.setState({openDivision: true});
    }
    handleClose = () => {
        this.setState({openDivision: false});
    }

    expandSlider = () => {
        this.setState({
            openSlider: !this.state.openSlider
        })
    }

    expandDate = () => {
        this.setState({
            openDate: !this.state.openDate
        })
    }
    componentDidMount() {
        this._isMounted = true;
        // this.loadDivisions();
        // this.loadShows();
    }

    handleChangeDivisions = (value) => {
        //this.setState({})
        this.props.handleChangeDivisions(value);
    }

    handleChangeShows = (value) => {
        //this.setState({})
        this.props.handleChangeShows(value);
    }

    handleChangeOccasions = (value) => {
        //this.setState({})
        this.props.handleChangeOccasions(value);
    }

    handleChangeInvitees = (value) => {
        this.props.handleChangeInvitees(value);
    }

    handleChangeCreated = (value, event) =>{
        this.props.handleChangeCreated(event.target.checked);
    }

    clearFilters = () =>{
        this.props.clearFilters();
        this.dateFromRef.current.value = null;
        this.dateToRef.current.value = null;
    }

    handleChangeDateFrom = (event, newValue) => {
        this.setState({
            dateFrom : event.target.value
        });
        this.props.handleChangeDateFrom(event.target.value);
    }

    handleChangeDateTo = (event, newValue) => {
        this.setState({
            dateTo : event.target.value
        });
        this.props.handleChangeDateTo(event.target.value);
    }

    loadDivisions = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/orgUnits?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var divisionsArr = response.data.map((division) => ( {text : division.Division_name, value: division.Division_id.toString()}));
                console.log("Load Divisions-->" + JSON.stringify(divisionsArr));
                this.setState({divisions : divisionsArr});
            },
            (err) => {
                console.log("Load Divisions Error-->"+err);
            })
    }

    loadShows = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/shows?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var showsArr = response.data.map((show) => ( {text : show.show_name, value: show.show_id.toString()}));
                console.log("Load Shows-->" + JSON.stringify(showsArr));
                this.setState({shows : showsArr});
            },
            (err) => {
                console.log("Load Shows Error-->"+err);
            })
    }

    loadOccasions = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/occasions?tenantId=' + localStorage.getItem("active_tenant_id"))//"./user_data.json") //Constants.GifterSharedServiceBaseUrl + 'User?userEmail=' + email)
            .then(response => {
                
                var occasionArr = response.data.map((occ) => ( {text : occ.occasion_name, value: occ.occasion_id.toString()}));
                console.log("Load Occasions-->" + JSON.stringify(occasionArr));
                this.setState({occasions : occasionArr});
            },
            (err) => {
                console.log("Load Occasions Error-->"+err);
            })
    }

    loadInvitees = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/tenantGiftRecipients?tenantId=' + localStorage.getItem("active_tenant_id"))
        .then(response => {
            var inviteesArr = reponse.data.map((inv) => ({text : giftRecipient.name, value: giftRecipient.person_id.toString()}));
            console.log("Load Invitees -->" + JSON.stringify(inviteesArr));
            this.setState({invitees : inviteesArr});
        },
        (err) => {
            console.log("Load invitees Error -->"+err);
        })
    }

    componentWillReceiveProps(props) {
    //     this.setState({ filterOpen: this.props.filterOpen,
    //     pageSize: this.props.pageSize,
    //     page: this.props.page,
    //     rowCount: this.props.rowCount,
    //     data: this.props.data,
    //     divisions: this.props.divisions,
    //     shows: this.props.shows        
    //  });

    // this.setState({
    //     dateFrom : props.dateFrom,
    //     dateTo : props.dateTo
    // });

 
    }

    render() {
        return (
            <>
            {/* <MDBContainer className="evntblock">
            <MDBRow> */}
            <div className={this.props.filterOpen ? "flexBox-plain padding-hor row margin-none": "hide"}>
                {/* <div className="row"> */}
                    {/* <div className="col-2 padding">
                    <MDBSelect size="sm"               
                        data={[
                            { text: 'Warner Television' },
                            { text: 'Warner Animation', selected: true},
                            { text: 'Warner Horizon', selected: true }
                        ]}
                        multiple
                        label='Division'
                        searchLabel="Division"
                        search

                    />
                    </div >
                    <div className="col-2 padding">
                    <MDBSelect   size="sm"             
                        data={[
                            { text: 'Arrow' },
                            { text: 'Big Bang Theory', selected: true },
                            { text: 'Shameless' },

                        ]}
                        multiple
                        label='Show'
                        searchLabel="Show"
                        search
                    />
                    </div> */}
                    {/* <div className="col-lg-2 col-md-4 col-sm-8 padding">                    
                    <MultiSelectComponent items={this.props.divisions} 
                            name={"Divisions:"} >
                        </MultiSelectComponent>
                    
                    </div > */}
                    {/* <div className="col-lg-2 col-md-4 col-sm-8 padding">
                    <MDBSelect  size="sm"                      
                         data=     {this.state.divisions}
                         multiple
                         search
                         searchLabel="Select Division(s)"
                        getData={(e)=>console.log(e)}
                        placeholder="Division"
                        
                    /></div> */}
                    <div className="row col-11 align-center-vertical">
                    <div className="col-xl-2 col-lg-3 padding">
                        {/* <Autocomplete
                            multiple
                            id="divisionsAuto"
                            variant="body1"
                            options={this.state.divisions}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.text}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.text}
                                </React.Fragment>
                            )}
                            style={{ width: '100%' }}
                            filterOptions={filterOptions}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" label="Divisions:" placeholder="Divisions:"
                                InputProps={{ ...params.InputProps, disableUnderline: true }} /> 
                                // <Input  {...params} placeholder="Divisions" inputProps={{ 'aria-label': 'description' }} />                        
                            )}
                            renderTags={(value, getTagProps) => {
                                const numTags = value.length;                    
                                return (
                                //   <div className="multi-select-overflow">   
                                <Typography variant="body1">
                                    {value
                                    .slice(0, 1)
                                    .map((option) => option.text)
                                    .join(", ")}                    
                                    {numTags > 1 && ` +${numTags - 1} more`}
                                </Typography>
                                //   </div>
                                );
                            }}
                            /> */}

                            <MultiSelectComponent items={this.props.divisions} 
                                name={"Divisions"} 
                                handleChange = {this.handleChangeDivisions.bind(this)}
                                selectedVal = {this.props.selDivisions}
                            >
                            </MultiSelectComponent>

                    </div>
                    <div className="col-xl-2 col-lg-3 padding">
                        <MultiSelectComponent items={this.props.shows} 
                            name={"Shows"} 
                            handleChange = {this.handleChangeShows.bind(this)}
                            selectedVal = {this.props.selShows}
                        >
                        </MultiSelectComponent>
                        {/* <MDBSelect                       
                        data={this.state.shows}
                        placeholder="Shows"
                        multiple
                        search
                        size="sm" 
                    /> */}
                    </div>
                    <>
                        {this.props.name === "Gift" ? (<>
                            <div className="col-xl-2 col-lg-3 padding">
                                <MultiSelectComponent items={this.props.occasions}
                                name={"Occasions"}
                                handleChange = {this.handleChangeOccasions.bind(this)}
                                selectedVal = {this.props.selOccasion}
                                >
                                </MultiSelectComponent>
                            </div>
                            <div className="col-xl-3 col-lg-3 padding">
                                <MultiSelectComponent items={this.props.invitees}
                                name={"Recipients"}
                                handleChange = {this.handleChangeInvitees.bind(this)}
                                selectedVal = {this.props.selInvitee}
                                ></MultiSelectComponent>
                            </div></>) : (<> </>
                        )}
                    </> 
                    <div className="col-xl-2 col-lg-3 padding">
                    {/* <Typography   gutterBottom>
                            Invitees#
                    </Typography> */}
                    <InputLabel className = {this.state.openSlider ? "slider-label-selected": "slider-label"}
                     id="range-slider-lbl" htmlFor="range-slider" onClick = {this.expandSlider} >
                         {this.props.name === "Event" ? "# Invitees" : "# Recipients"}
                         <MDBIcon fas icon="caret-down" className="mr-2" ></MDBIcon>
                    </InputLabel>
                     
                    {/* <MDBBtn className = "btn-dropdown" outline onClick = {this.expandSlider}>
                        #Invitees: <MDBIcon fas icon="caret-down" className="mr-2" ></MDBIcon>
                    </MDBBtn>  */}
                    <div className =  {this.state.openSlider ? "txt-septanary" : "d-none"}>
                    <Slider
                            id="range-slider"                                   
                            min = {0}
                            max = {1000}                    
                            value={this.props.inviteeRange || [0,1000]}
                            onChange={this.handleInviteeRange.bind(this)}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider-lbl"    
                                               
                        />
                    </div>
                        {/* className = {this.state.openSlider ? "show" : "hide"}  */}
                    </div>                   

                    <div className= {this.state.openDate ? "col-xl-4 col-lg-3 padding": "col-xl-2 col-lg-3 padding"}>
                    <InputLabel className = {this.state.openDate ? "slider-label-selected": "slider-label"}
                     id="date-lbl" htmlFor="range-slider" onClick = {this.expandDate} >
                     Date
                    <MDBIcon fas icon="caret-down" className="mr-2" ></MDBIcon>
                    </InputLabel>
                     
                    {/* <MDBBtn className = "btn-dropdown" outline onClick = {this.expandDate}>
                        Date: <MDBIcon fas icon="caret-down" className="mr-2" ></MDBIcon>
                    </MDBBtn>  */}
                    {/* <div className = {this.state.openDate ? "flexBox-left" : "hide"} > */}
                    <div className =  {this.state.openDate ? "flexBox-plain padding-hor" : "d-none"}> 
                    
                      {/* <span><MDBDatepicker inline value={this.state.dateFrom} inputStyle={{width: 'auto'}} />
                        -
                        <MDBDatepicker inline value={this.state.dateTo} inputStyle={{width: 'auto'}} />
                        </span> */}
                         <TextField
                            //value={this.state.dateFrom} 
                            id="fromDate"
                            label=""                           
                            type="date"                            
                            className="date-picker"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange= {this.handleChangeDateFrom.bind(this)}
                            inputRef = {this.dateFromRef}
                          /> 
                          <div className="padding-hor"> - </div>
                          <TextField
                            //value={this.state.dateTo} 
                            id="toDate"
                            label=""                            
                            type="date"                            
                            className="date-picker"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange= {this.handleChangeDateTo.bind(this)}
                            inputRef = {this.dateToRef}
                        />
                    </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-4 col-sm-8 padding">
                    <MDBSelect               
                        data={this.state.divisions}
                        multiple
                        placeholder='Division'                        
                    />
                    </div> */}
                    <div className="col-xl-2 col-lg-3 padding flexBox-left">
                        <div className="created-by-me">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value={"created-by-"+ this.props.name}
                                //checked = {this.state.checkedUseImport["import-new-"+ current + "-" + index]}
                                checked = {this.props.createdBy}
                                id={"created-by-"+ this.props.name}                            
                                onChange={this.handleChangeCreated.bind(this, "created-by-"+ this.props.name)}
                                    
                            />
                            <span className="padding-hor">Created By Me</span>
                        </div>
                    </div>

                    <div className="col-auto">

                    </div>
                    </div>
                    <div className="col-1 row align-center-vertical">
                        <div className="col-xl-12 col-lg-12 padding flexBox-left fs-90">
                            <InputLabel className = "text-primary action-icon"
                                id={"clear-filters-"+this.props.name} htmlFor="range-slider"
                                onClick= {this.clearFilters.bind(this)} >
                                Clear filters
                            </InputLabel>
                        </div>
                    </div>
                {/* </div> */}
            </div> 
            {/* </MDBRow>
            </MDBContainer>                         */}
                <br/>
                {/* <MDBDataTableV5
                    
                    entriesOptions={[10, 25, 50, 75]} responsive id='eventsDashboard'
                    entries={25}
                    pagesAmount={4}
                    data={this.state.eventsData}
                    searchTop={false}
                    searchBottom={false} barReverse scrollX
                    sortRows={['show', 'name', 'date']}
                    noRecordsFoundLabel=' '
                    
                    
                >

               </MDBDataTableV5> */}
               <div className="d-none d-lg-block">
                <DataGrid                    
                    columns={this.props.data.columns}
                    rows={this.props.data.rows}
                    autoHeight ={true}
                    pagination                               
                    paginationMode="server"
                    onPageChange={this.props.handlePageChange}
                    onPageSizeChange={this.props.handlePageSizeChange}
                    pageSize={this.props.pageSize}
                    page={this.props.page}
                    rowsPerPageOptions = {[2, 4, 10, 25, 50, 75, 100]}
                    rowCount={this.props.rowCount}                    
                    disableColumnMenu={true}
                    loading = {this.props.loading}
                    rowHeight={40}
                    sortingMode="server"
                    sortingModel = {this.props.sortModel}
                    onSortModelChange={this.handleSortModelChange.bind(this)}
                    //disableColumnResize = {false}
                    />
                </div>
                {/* <div className="d-block d-lg-none">
                     Content goes here for smaller screens - {this.state.name}

                {this.state.data.rows.map((item) => {
                    return <>
                  <MDBCard>
                      <MDBCardBody>
                          <div className="flexBox-plain padding-hor row">
                             <div className="col-2">
                               {item.eventName}
                             </div>
                          </div>
                      </MDBCardBody>
                  </MDBCard>  
                  </>
                })}


                </div> */}
            </>
        );
    }

}

export default EventGiftDashboardComponent;