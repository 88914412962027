import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MDBIcon } from "mdbreact";
import './Table.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";

const TableComponent = (props) => (
    <>
        <div className="tableContainer">
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" size="small">
                    <TableHead id="pmHead">
                        <TableRow>
                            {
                                props?.config?.headings?.map((heading, index) => (
                                    heading.headingLabel === 'Select' ?
                                        <>
                                            <input type='checkbox' className='W2' checked={props.selectAllPeople}
                                                onClick={(event) => {
                                                    props.selectAllCallback(event)
                                                }}></input>
                                        </> :
                                        <>
                                            <TableCell key={index} className="FW800" onClick={() => props?.sortCallback(heading.headingLabel)}>
                                                {heading.icon ? <div>{heading.headingLabel}<MDBIcon icon={heading.icon} /></div> : heading.headingLabel}
                                            </TableCell></>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    {
                        props?.isLoading ? (
                            <div className="tableLoader">
                                <CircularProgress />
                            </div>
                        ) : (
                            <TableBody>
                                {
                                    props?.list?.map(dataItem => (
                                        <TableRow id={dataItem[props.config.primaryKey]} key={dataItem[props.config.primaryKey]}>
                                            {
                                                props?.config?.dataNodes?.map((node, index) => (
                                                    node == 'check' ? <>
                                                        <TableCell>
                                                            <input type='checkbox' checked={dataItem[node]} className='W2'
                                                                onClick={(event, newValue) => {
                                                                    dataItem[node] = event.target.checked;
                                                                    props.arrayCheckUncheck(event, dataItem);
                                                                }}></input></TableCell></> : <>
                                                        <TableCell
                                                            className={props?.config?.changeColourToRed?.includes(dataItem[node])
                                                                ? "textColorRed" : props?.config?.changeColourToGreen?.includes(dataItem[node]) ? "textColorGreen" : ""} key={index} >{dataItem[node]}</TableCell>
                                                    </>
                                                ))
                                            }
                                            {
                                                props?.config?.actions && <TableCell> <div className="row">
                                                    {
                                                        props?.config?.actions?.map((action, indexNo) => (
                                                            <div key={indexNo} className="col-md" onClick={() => action === "pen" ?
                                                                props?.editCallback(dataItem[props.config.primaryKey], dataItem) :
                                                                (action === "plus" ? props?.handleAddCallback(dataItem[props.config.primaryKey]) :
                                                                    (action === "trash-alt" ? props?.handleDelCallBack(dataItem[props.config.primaryKey]) : null))}><MDBIcon icon={action} /></div>
                                                        ))
                                                    }
                                                </div></TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                                {
                                    props?.isCheck ? (
                                        <TableRow>
                                            <TableCell>
                                                <Autocomplete
                                                    className="tags-standard"
                                                    options={props?.listNew}
                                                    getOptionLabel={(option) => (option.first_name + ' ' + option.last_name)}
                                                    onChange={(event, newValue) => {
                                                        if (newValue && newValue != '') {
                                                            props.addInviteeFromSearch(newValue)
                                                        }
                                                    }
                                                    }
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Search By Name"
                                                            margin="normal"
                                                            onChange={ev => {
                                                                // dont fire API if the user delete or not entered anything
                                                                if (ev.target.value !== "" || ev.target.value !== null) {
                                                                    props.onChangeHandle(ev.target.value);
                                                                }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>)
                                        : null}
                            </TableBody>

                        )
                    }
                </Table>
            </TableContainer>
        </div>
    </>
);

export default TableComponent;