import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import BasicInfo from './BasicInfo';
import GiftSupplied from './GiftsSupplied';
import GifterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import AddOrEditModal from './AddEditModal';
import SaveModal from '../../PeopleManagement/AddPerson/SaveModal';
import { Prompt } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateEmail } from '../../Common/helper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import './vendor.scss';

export default class VendorManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorGifts: {},
            vendorList: [],
            isTableLoading: false,
            isEmailValid: true,
            isBlocking: false,
            isPosted: false,
            vendor: {
                "vendor_id": null,
                "name": null,
                "address": null,
                "phone": null,
                "email": null,
                "notes": null,
                "is_active": 1,
                "gifts": []
            },
            postJson: {},
            modalMode: "",
            showModal: false,
            isPosting: false,
            isSaveGiftDisabled: false,
            giftObj: null,
            initialGiftArray: {
                "gift_id": null,
                "gift_name": null,
                "price": null,
                "is_active": 1,
                "index": null,
                "text": null,
                "value": null
            },
            index: 0,
            giftsPostJson: [],
            giftDropDown: [],
            saveVendorCreate: "The new vendor has been created successfully!",
            saveSuccessMessage: "The changes have been saved successfully!",
            saveErrorMessage: "We couldn't save your changes!",
            saveMandatoryFields: "Vendor Name is mandatory!",
            saveUnique: "This entry is already available! Please enter a unique entry!"
        }
    }

    goBack = () => {
        this.props?.history?.push('/manageData', 'MD');
    }

    componentDidMount() {
        if (!this.props?.history?.location?.pathname?.includes('addVendor')) {
            this.fetchVendorGifts(this.props.match.params.id);
        } else {
            this.setState({ vendorGifts: this.state.vendor, postJson: this.state.vendor });
        }
        this.fetchVendorsAll();
    }

    handleValueChange = (value, field) => {
        if (field === "email") {
            this.setState({ isEmailValid: value ? validateEmail(value) : true });
        }
        this.setState(prevState => ({
            postJson: {
                ...prevState.postJson,
                [field]: (value || value === 0) ? value : null
            }
        }));
        this.setState({ isBlocking: true });
    }

    updateGiftDropDown = () => {
        let giftDropDown = this.state.postJson?.gifts?.map(gift => ({ text: gift.gift_name, value: gift.gift_id }));
        this.setState({ giftDropDown: giftDropDown ? giftDropDown : [] });
    }

    postVendor = () => {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        if (this.state.postJson.name) {
            if (this.state.vendorList?.some(item => item.name.toString() === this.state.postJson.name && !this.state.postJson.vendor_id)) {
                NotificationManager.warning(`${this.state.postJson.name} is already available!`, 'Warning!', 3000);
            } else if (this.state.isEmailValid) {
                this.setState({ isPosting: true, isBlocking: false, isPosted: true });
                let paramObject = this.formatGiftArrayBeforeSave();
                console.log("param", paramObject);
                GifterService.postDataParams(Constants.GifterServiceBaseUrl + '/vendorAdd', paramObject)
                    .then((response) => {
                        console.log("post response", response);
                        if (this.state.postJson.vendor_id) {
                            NotificationManager.success(this.state.saveSuccessMessage, 'Success!');
                        } else {
                            NotificationManager.success(this.state.saveVendorCreate, 'Success!');
                        }
                        this.fetchVendorGifts(response.data[0].vendor_id);
                        this.fetchVendorsAll();
                        this.setState({ isPosting: false });
                    },
                        (err) => {
                            NotificationManager.error(this.state.saveErrorMessage, 'Error!', 3000);
                            this.setState({ isPosting: false })
                            console.log("Post Vendor error: " + err);
                        });
            }
        } else {
            NotificationManager.warning(this.state.saveMandatoryFields, 'Warning!', 3000);
        }
    }

    handleSave = () => {
        this.postVendor();
    }

    fetchVendorGifts = (id) => {
        this.state.isPosted ? this.setState({ isPosted: false }) : this.setState({ isTableLoading: true });
        GifterService.getData(Constants.GifterServiceBaseUrl + '/vendorGifts?vendorId=' + id)
            .then(response => {
                this.setState({ isTableLoading: false });
                console.log("response", response.data)
                if (response.data[0]) {
                    this.setState({ vendorGifts: response?.data[0], postJson: response?.data[0] }, () => {
                        this.updateGiftDropDown();
                    });
                } else {
                    this.setState({ vendorGifts: this.state.vendor, postJson: this.state.vendor }, () => {
                        this.updateGiftDropDown();
                    });
                }
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Load vendor gifts all Error-->" + err);
                })
    }

    handleClose = () => {
        this.setState({ showModal: false, giftObj: null });
    }

    handleGiftEditCallback = (id, item) => {
        let gift = this.state.postJson?.gifts?.find(gift => gift.gift_id === item.gift_id);
        if (!gift?.gift_id) {
            gift = this.state.postJson?.gifts?.find(gift => gift.index === item.index);
        }
        gift.text = gift?.gift_name;
        gift.value = gift?.gift_id;
        this.setState({ modalMode: "Edit", giftObj: gift }, () => {
            this.isGiftSaveEnabled();
            this.setState({ showModal: true });
        });
    }

    handleAddGift = () => {
        let giftObj = { ...this.state.initialGiftArray };
        giftObj.index = this.state.index + 1;
        giftObj.text = giftObj?.gift_name;
        giftObj.value = giftObj?.gift_id;
        this.setState({ giftObj: giftObj, index: this.state.index + 1 }, () => {
            this.isGiftSaveEnabled();
            this.setState({ showModal: true, modalMode: "Add" });
        })
    }

    handleGiftEdit = (obj, value, field) => {
        this.setState({ isBlocking: true });
        let gift = this.state.giftObj;
        if (field === 'gift_name' || field === 'price' || field === "is_active") {
            gift[field] = value;
        } else if (field === 'autocomplete') {
            gift.text = value?.text;
            gift[field] = value?.text;
        }
        this.setState({ giftObj: gift }, () => {
            this.isGiftSaveEnabled();
        });
    }

    formatGiftArrayBeforeSave = () => {
        let list = { ...this.state.postJson }
        let modifiedList = list?.gifts?.map(item => item.is_active === 1 || item.is_active === "Active" ? { ...item, is_active: 1 } : { ...item, is_active: 0 });
        list.gifts = modifiedList ? modifiedList : [];
        return list;
    }

    fetchVendorsAll = () => {
        GifterService.getData(Constants.GifterServiceBaseUrl + '/vendorsAll?tenantId=' + localStorage.getItem("active_tenant_id"))
            .then(response => {
                var formattedArray = response.data.map((vendor) => ({
                    id: vendor.vendor_id,
                    name: vendor.name.toString()
                }));
                this.setState({ vendorList: formattedArray });
            },
                (err) => {
                    console.log("Load Vendors all Error-->" + err);
                })
    }

    isGiftSaveEnabled = () => {
        if (this.state.giftObj?.gift_name && this.state.giftObj?.price && this.state.giftObj?.price !== 0) {
            if (this.state.postJson?.gifts?.some(item => item.gift_name === this.state.giftObj?.gift_name &&
                this.state.giftObj?.index && this.state.giftObj?.index !== item?.index)) {
                this.setState({ isSaveGiftDisabled: true });
            } else {
                this.setState({ isSaveGiftDisabled: false });
            }
        } else {
            this.setState({ isSaveGiftDisabled: true });
        }
    }

    handleGiftSave = () => {
        let giftsPostJson = this.state.postJson?.gifts?.map(gift => {
            if (gift.gift_id && gift.gift_id === this.state.giftObj.gift_id) {
                return this.state.giftObj;
            } else if (gift?.index && (gift?.index === this.state.giftObj.index)) {
                return this.state.giftObj;
            } else {
                return gift;
            }
        });
        if (!giftsPostJson?.some(gift => gift.index === this.state.giftObj.index)) {
            giftsPostJson.push(this.state.giftObj);
        }
        let postJson = this.state.postJson;
        postJson.gifts = giftsPostJson;
        this.setState({ showModal: false, giftObj: null, postJson: postJson }, () => {
            this.updateGiftDropDown();
        });
    }

    render() {
        return (
            <div>
                <Prompt when={this.state.isBlocking} message={"You have unsaved changes. Are you sure you want to leave this page?"} />
                {
                    this.state.isTableLoading ? <div className="personScreenLoader">
                        <CircularProgress />
                    </div> : <div className="vendorManagementContainer">
                        {
                            this.state.showModal && <AddOrEditModal open={this.state.showModal} handleClose={this.handleClose}
                                handleEdit={this.handleGiftEdit} modalMode={this.state.modalMode} handleSave={this.handleGiftSave} giftObj={this.state.giftObj}
                                giftDropDown={this.state.giftDropDown} isSaveGiftDisabled={this.state.isSaveGiftDisabled} />
                        }
                        <NotificationContainer />
                        <div className="row" id="vendorHeader">
                            <div className="col-1"> <div className="row">
                                <div className="col-5" onClick={() => this.goBack()}><ArrowBackIcon /></div>
                                <div className="col-7"><h5>Vendors</h5></div>
                            </div></div>
                            <div className="col-9"></div>
                            <div className="col-2">
                                <Button variant="contained" size="small" color="primary" disabled={this.state.isPosting} type="submit" onClick={() => this.handleSave()}>{this.state.isPosting ? <CircularProgress size={25} /> : "Save"}</Button></div>
                        </div>
                        <div className="vendorContainer">
                            <div className="row">
                                <div className="col-5">
                                    <BasicInfo handleValueChange={this.handleValueChange} list={this.state.postJson} isEmailValid={this.state.isEmailValid} />
                                </div>
                                <div className="col-7">
                                    <GiftSupplied vendorGifts={this.state?.postJson?.gifts} handleAddGift={this.handleAddGift} isTableLoading={this.state.isTableLoading} handleGiftEdit={this.handleGiftEditCallback} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}