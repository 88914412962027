// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../fonts/acumin.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fromDate, .toDate {\n  font-size: 0.7rem !important; }\n\n#created-by-Event, #created-by-Gift, #created-by-Card {\n  width: 0.8rem !important;\n  height: 0.8rem !important;\n  top: 3px !important; }\n\n#clear-filters-Event, #clear-filters-Card {\n  white-space: pre;\n  padding: 1.2rem 0rem 0rem 0rem !important;\n  font-size: 0.8rem !important; }\n\n#clear-filters-Gift {\n  white-space: pre;\n  padding: 0rem 0rem 2rem 0rem !important;\n  font-size: 0.8rem !important; }\n\n.created-by-me {\n  padding: 1.2rem 0rem 0rem 0rem !important;\n  font-size: 0.8rem !important; }\n", "",{"version":3,"sources":["webpack://./src/pages/EventGiftLandingPage.scss"],"names":[],"mappings":"AAGA;EACI,4BAA6B,EAAA;;AAGjC;EACI,wBAAwB;EACxB,yBAAyB;EACzB,mBAAmB,EAAA;;AAGvB;EACI,gBAAgB;EAChB,yCAAyC;EACzC,4BAA4B,EAAA;;AAGhC;EACI,gBAAgB;EAChB,uCAAuC;EACvC,4BAA4B,EAAA;;AAEhC;EACI,yCAAyC;EACzC,4BAA4B,EAAA","sourcesContent":["@import \"../fonts/acumin.css\";\n@import \"../assets/scss/custom/_variables.scss\";\n\n.fromDate, .toDate {\n    font-size : 0.7rem !important;\n}\n\n#created-by-Event, #created-by-Gift, #created-by-Card {\n    width: 0.8rem !important;\n    height: 0.8rem !important;\n    top: 3px !important;\n}\n\n#clear-filters-Event, #clear-filters-Card {\n    white-space: pre;\n    padding: 1.2rem 0rem 0rem 0rem !important;\n    font-size: 0.8rem !important;\n}\n\n#clear-filters-Gift {\n    white-space: pre;\n    padding: 0rem 0rem 2rem 0rem !important;\n    font-size: 0.8rem !important;\n}\n.created-by-me{\n    padding: 1.2rem 0rem 0rem 0rem !important;\n    font-size: 0.8rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
