import React, { useState } from 'react'
import {makeStyles} from '@material-ui/core'

export function useForm(toggle1) {

    const [errors, setErrors] = useState({});

    const resetForm = () =>{
        toggle1(),
        setErrors({})
    }

    return{
        errors,
        setErrors,
        resetForm,
    }
}

const useStyles = makeStyles(theme => ({
    root:{
'& .MuiFormControl-root' : {
    width : '60%',
    margin : theme.spacing(1),
},
} 
}))

export function Form(props) {
    const classes = useStyles();
    const { children, ...other } = props;
    return(
        <form className = {classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}